import React, { useEffect } from 'react';
import {
    Box, TextField, InputAdornment, IconButton, Tooltip,
    FormControl, Autocomplete,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import Commas from "../../BidFunctions/commas";

function CFMFan({ fetchedFans, fanId, calculateTotal }) {
    const [fans, setPart, setObj, removeLine] = useBidStoreMain(state =>
        [state.fan, state.setPart, state.setObj, state.removeLine]);
    const INITIAL_STATE = {
        qty: 0,
        total: 0,
        totalCFM: 0,
        partNumber: "",
        description: "",
        cfm: "",
        price: "",
        additionalData: {
            hp: 0,
            staticPressure1: 0,
            staticPressure05: 0,
            staticPressure15: 0,
            staticPressure075: 0,
            staticPressure125: 0,
            staticPressure175: 0
        }
    };
    const handleChange = (e) => {
        if (!e) return;
        let value = e.target.value;
        if (value === 0) value = e.target.innerText;
        setPart("fan", "partNumber", value, fanId);
    };

    const isValid = (e) => {
        const value = e.target.value.trim();
        const foundObj = fetchedFans.find(o => o.partNumber.trim().toLowerCase() === value.trim().toLowerCase());

        if (foundObj) setObj("fan", foundObj, fanId);
        else setObj('fan', INITIAL_STATE, fanId);
    };

    useEffect(() => {
        // calculate total of the line item
        if (fans[fanId] && fans[fanId].partNumber !== "") {

            const totalCFM = parseInt(fans[fanId]?.qty * fans[fanId]?.cfm)
            const total = parseFloat((fans[fanId]?.qty * fans[fanId]?.price).toFixed(2)) || 0;

            setPart('fan', 'total', total, fanId)
            setPart('fan', 'totalCFM', totalCFM, fanId)
            calculateTotal();
        }
    }, [fans[fanId]?.partNumber, fans[fanId]?.qty, fans[fanId]?.total, fans[fanId]?.price])

    return (
        <Box ml={3} mt={1} mb={1}>
            <TextField
                name="qty"
                id="qty"
                label="Quantity"
                variant="outlined"
                type="number"
                sx={{ width: "10ch" }}
                value={fans[fanId]?.qty || ""}
                onChange={(e) => setPart('fan', e.target.name, e.target.value, fanId)}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Box ml={1} style={{ display: 'inline' }}>
                <FormControl>
                    <Autocomplete
                        disableClearable
                        inputValue={fans[fanId]?.partNumber || ""}
                        onInputChange={handleChange}
                        onBlur={isValid}
                        name="partNumber"
                        id="partNumber"
                        options={fetchedFans || []}
                        getOptionLabel={(option) => option.partNumber}
                        filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.partNumber.toLowerCase().includes(inputValue) ||
                                    option.description.toLowerCase().includes(inputValue)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="value"
                                sx={{ width: "24ch" }}
                                fullWidth
                                label="Fan Propeller"
                                id="value"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                </FormControl>
            </Box>
            <TextField
                name="description"
                id="description"
                label="Description"
                variant={fans[fanId]?.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                sx={{ width: fans[fanId]?.partNumber === 'SERPART' ? '27ch' : '29ch', ml: 1 }}
                value={fans[fanId]?.description || ""}
                onChange={(e) => setPart('fan', e.target.name, e.target.value, fanId)}
                InputProps={{
                    readOnly: fans[fanId]?.partNumber !== 'SERPART'
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                name="cfm"
                id="cfm"
                label="CFM"
                type="number"
                variant={fans[fanId]?.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                sx={{ width: "14ch", ml: fans[fanId]?.partNumber === 'SERPART' ? 1 : 0 }}
                value={fans[fanId]?.cfm || ""}
                onChange={(e) => setPart('fan', e.target.name, e.target.value, fanId)}
                InputProps={{
                    readOnly: fans[fanId]?.partNumber !== 'SERPART'
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                name="price"
                id="price"
                label="Dealer Price"
                type="number"
                variant="outlined"
                sx={{ ml: 1, width: "14ch" }}
                value={fans[fanId]?.price || ""}
                onChange={(e) => setPart('fan', e.target.name, e.target.value, fanId)}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                name="total"
                id="total"
                label="Total"
                variant='standard'
                sx={{ width: "14ch", ml: 1 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    readOnly: true,
                }}
                value={Commas.add(fans[fanId]?.total) || 0}
                onChange={(e) => setPart('fan', e.target.name, e.target.value, fanId)}
            />
            <Tooltip title="Remove line">
                <IconButton onClick={() => removeLine('fan', fanId)} sx={{ mt: 1 }}>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default CFMFan;

