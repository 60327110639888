import React from "react";
import {
    Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import Commas from "../../BidFunctions/commas";

function BidRefAgreement({ bidBase, signature, signature2, company, otherTotal, grandTotal }) {
    const styles = StyleSheet.create({
        underline: { textDecoration: 'underline' },
        bold: { fontWeight: 'bold' },
        smallerText: {
            marginLeft: 100,
            marginRight: 10,
            fontSize: 12,
        },
        divider: {
            borderBottom: 2,
            borderColor: 'black',
            flexGrow: 2,
            marginTop: 2,
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        dateLine: {
            borderBottom: 1,
            borderColor: 'black',
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
            width: '40%'
        },
        dateText: {
            marginTop: bidBase.dateServiced ? 15 : 50,
        },
        sigLine: {
            borderBottom: 1,
            borderColor: 'black',
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
            width: '65%',
        },
        sigText: { fontSize: 12 },
        container2: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
            flexDirection: 'row',
            width: '115%',
        },
        rightMargin: {
            marginRight: '20px',
        },
        sig: {
            width: 200,
            marginTop: 15,
        },
        totalContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
    })

    return (
        <>
            <View>
                <View style={styles.totalContainer}>
                    <Text>Installation Labor and Materials:</Text>
                    <Text> ${Commas.add(otherTotal)} </Text>
                </View>
                <Text> </Text>
                <View style={styles.totalContainer}>
                    <Text style={styles.bold}>TOTAL JOB - including Installation:</Text>
                    <Text style={styles.bold}> ${Commas.add(grandTotal + otherTotal)}</Text>
                </View>
                <Text> </Text>
                <View>
                    <Text> </Text>
                    <Text style={styles.underline}>This Proposal Includes:</Text>
                    <Text style={styles.smallerText}>
                        {bidBase.proposalIncludes}
                    </Text>
                    <Text> </Text>
                    <Text style={styles.underline}>Customer to Provide:</Text>
                    <Text style={styles.smallerText}>
                        {bidBase.customerToProvide}
                    </Text>
                    <Text> </Text>
                    <Text style={styles.underline}>Terms:</Text>
                    <Text style={styles.smallerText}>
                        {bidBase.terms}
                    </Text>
                    <Text> </Text>
                    <Text style={styles.rightMargin}>
                        Equipment remains the property of {company} until payment is
                        received in full. {company}, its officers and employees do not assume responsibility
                        for the storage structure or the product held in storage.
                    </Text>
                    <View style={styles.divider} />
                    <View style={styles.center}>
                        <Text style={styles.bold}>***ACCEPTANCE OF PROPOSAL***</Text>
                    </View>
                    <View style={styles.container2}>
                        <View style={{ flex: 1 }}>
                            {signature2.data &&
                                <Image style={styles.sig} src={signature2.data} />
                            }
                            <View style={[styles.sigLine, { marginTop: !signature2.data ? 50 : 0 }]} />
                            <Text style={styles.sigText}>{bidBase.customer}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            {signature.data &&
                                <Image style={styles.sig} src={signature.data} />
                            }
                            <View style={[styles.sigLine, { marginTop: !signature.data ? 50 : 0 }]} />
                            <Text style={styles.sigText}>Signature ({company})</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.dateText}>{bidBase.dateServiced}</Text>
                        <View style={styles.dateLine} />
                        <Text style={styles.sigText}>Date</Text>
                    </View>
                </View>
            </View>
        </>
    )
}

export default BidRefAgreement;