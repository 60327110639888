import React, { useState, useContext } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import UserContext from "../../Users/UserContext";

function UsersMenu({ handleDrawerClose }) {
    const { currentUser } = useContext(UserContext)
    const theme = useTheme();
    const gray = theme.palette.default.secondary;
    const defaultStyle = { textDecoration: 'none', color: "#004e7c" }

    return (
        <>
            {currentUser.dept === 'admin' ?
                <NavLink to="/users" style={defaultStyle}>
                    <Box
                        onClick={handleDrawerClose}
                        sx={{
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'space-between',
                            ml: 3, mt: 2, mb: 2,
                        }}>
                        <Typography variant="h6">Users</Typography>
                    </Box>
                </NavLink >
                : null
            }
            <Divider sx={{ backgroundColor: gray }} />
        </>
    )
}

export default UsersMenu;