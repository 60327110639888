import React, { useEffect } from "react";
import {
    Card, Box, Typography, TextField,
    InputAdornment, Button
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import MiscLine from "./MiscLine";
import EquipmentLine from "./EquipmentLine";
import OptionLine from "./OptionLine";
import HardwareLine from "./HardwareLine";
import Commas from "../../BidFunctions/commas";
function Misc({ title, category, edit, miscData }) {
    const editPass = edit;
    const miscDataPass = miscData || [];
    const [misc, addLine, setTotal] = useBidStoreMisc(state =>
        [state[category], state.addPart, state.setTotal])
    const [formData, handleLocal] = useFields({})

    useEffect(() => {
        for (let i = 1; i < miscData.length; i++) {
            addLine(category, 5)
        }
    }, [])

    const handleMiscCalc = function () {
        let total = 0;
        let refrigerationTotal = 0;
        misc.forEach((item) => {
            const itemValue = item.priceTotal || 0
            if (item.refrigeration) {
                refrigerationTotal = parseFloat((refrigerationTotal + itemValue).toFixed(2))
            } else {
                total = parseFloat((total + itemValue).toFixed(2))
            }
        })
        handleLocal("invalidInput", "total", total + refrigerationTotal)
        setTotal([category] + "Total", total)
        setTotal([category] + "Total", refrigerationTotal, true)
    }

    return (
        <>
            <Box mt={1}>
                <Card sx={{ width: '130ch' }} >
                    <Box m={2} display="flex" justifyContent="space-between">
                        <Typography sx={{ textDecoration: 'underline' }} ml={4} variant="h5">
                            {title}
                        </Typography>
                    </Box>
                    {category === 'equipment' ?
                        misc.map((equipmentPart) => {
                            return <EquipmentLine
                                key={equipmentPart[category + "Id"]}
                                id={equipmentPart[category + "Id"]}
                                category={category}
                                handleMiscCalc={handleMiscCalc}
                                edit={editPass}
                                miscData={miscDataPass}
                            />
                        }) :
                        category === 'option' ?
                            misc.map((option) => {
                                return <OptionLine
                                    key={option[category + "Id"]}
                                    id={option[category + "Id"]}
                                    category={category}
                                    edit={editPass}
                                    miscData={miscDataPass}
                                />
                            })
                            : category === 'hardware' ?
                                misc.map((hardware) => {
                                    return <HardwareLine
                                        key={hardware[category + "Id"]}
                                        id={hardware[category + "Id"]}
                                        handleMiscCalc={handleMiscCalc}
                                        category={category}
                                        edit={editPass}
                                        miscData={miscDataPass}
                                    />
                                })
                                : misc.map((miscPart) => {
                                    return <MiscLine
                                        key={miscPart[category + "Id"]}
                                        id={miscPart[category + "Id"]}
                                        category={category}
                                        handleMiscCalc={handleMiscCalc}
                                        edit={editPass}
                                        miscData={miscDataPass}
                                    />
                                })
                    }
                    <Box
                        mb={1}
                        display="flex"
                        justifyContent="space-between">
                        <Box align="left" mb={1} mr={1} ml={1}>
                            <Button
                                variant='contained'
                                onClick={() => addLine(category, 5)}>
                                Add Line
                            </Button>
                        </Box>
                        {category !== 'option' &&
                            <Box ml={2} mb={1} mr={2} align="right">
                                <TextField
                                    id='total'
                                    name='total'
                                    sx={{ width: '12ch' }}
                                    value={Commas.add(formData.total) || ""}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment
                                                position="start">
                                                $
                                            </InputAdornment>,
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                        }
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default Misc;