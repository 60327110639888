import React, { useEffect } from 'react';
import {
    Box, TextField, InputAdornment, IconButton, Tooltip,
    FormControl, Autocomplete,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import Commas from '../../BidFunctions/commas';
function FreqDrive({ fetchedFreqDrives, freqDriveId, calculateTotal }) {
    const [freqDrives, setPart, setObj, removeLine] = useBidStoreMain(state =>
        [state.freqDrive, state.setPart, state.setObj, state.removeLine]);
    const INITIAL_STATE = {
        qty: 0,
        total: 0,
        partNumber: "",
        description: "",
        price: "",
        additionalData: {
            hp: 0,
            brand: '',
            voltage: 0,
        }
    };
    const handleChange = (e) => {
        if (!e) return;
        let value = e.target.value;
        if (value === 0) value = e.target.innerText;
        setPart("freqDrive", "partNumber", value, freqDriveId);
    };

    const isValid = (e) => {
        const value = e.target.value.trim();
        const foundObj = fetchedFreqDrives.find(o => o.partNumber.trim().toLowerCase() === value.trim().toLowerCase());
        if (foundObj) {
            setObj("freqDrive", foundObj, freqDriveId);
        } else {
            setObj('freqDrive', INITIAL_STATE, freqDriveId);
        }
    };

    useEffect(() => {
        if (freqDrives[freqDriveId]?.partNumber !== undefined) {
            const total = parseFloat((freqDrives[freqDriveId]?.qty * freqDrives[freqDriveId]?.price).toFixed(2)) || 0;
            setPart('freqDrive', 'total', total, freqDriveId)
            calculateTotal();
        }
    }, [freqDrives[freqDriveId]?.partNumber, freqDrives[freqDriveId]?.qty,
    freqDrives[freqDriveId]?.total, freqDrives[freqDriveId]?.price])

    return (
        <Box ml={3} mt={1} mb={1}>
            <TextField
                name="qty"
                id="qty"
                label="Quantity"
                variant="outlined"
                type="number"
                sx={{ width: "10ch" }}
                value={freqDrives[freqDriveId]?.qty || ""}
                onChange={(e) => setPart('freqDrive', e.target.name, e.target.value, freqDriveId)}
            />
            <Box ml={1} style={{ display: 'inline' }}>
                <FormControl>
                    <Autocomplete
                        disableClearable
                        inputValue={freqDrives[freqDriveId]?.partNumber || ""}
                        onInputChange={handleChange}
                        onBlur={isValid}
                        name="partNumber"
                        id="partNumber"
                        options={fetchedFreqDrives || []}
                        getOptionLabel={(option) => option.partNumber}
                        filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.partNumber.toLowerCase().includes(inputValue) ||
                                    option.description.toLowerCase().includes(inputValue)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="value"
                                sx={{ width: "24ch" }}
                                fullWidth
                                label="Frequency Drive"
                                id="value"
                            />
                        )}
                    />
                </FormControl>
            </Box>
            <TextField
                name="description"
                id="description"
                label="Description"
                variant={freqDrives[freqDriveId]?.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                sx={{ width: freqDrives[freqDriveId]?.partNumber === 'SERPART' ? '42ch' : '43ch', ml: 1 }}
                value={freqDrives[freqDriveId]?.description || ""}
                onChange={(e) => setPart('freqDrive', e.target.name, e.target.value, freqDriveId)}
                InputProps={{
                    readOnly: freqDrives[freqDriveId]?.partNumber !== 'SERPART'
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <TextField
                name="price"
                id="price"
                label="Dealer Price"
                type="number"
                variant="outlined"
                sx={{ ml: 1, width: "14ch" }}
                value={freqDrives[freqDriveId]?.price || ""}
                onChange={(e) => setPart('freqDrive', e.target.name, e.target.value, freqDriveId)}
            />
            <TextField
                name="total"
                id="total"
                label="Total"
                variant='standard'
                sx={{ width: "14ch", ml: 1 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    readOnly: true,
                }}
                value={Commas.add(freqDrives[freqDriveId]?.total) || 0}
                onChange={(e) => setPart('freqDrive', e.target.name, e.target.value, freqDriveId)}
            />
            <Tooltip title="Remove line">
                <IconButton onClick={() => removeLine('freqDrive', freqDriveId)} sx={{ mt: 1 }}>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default FreqDrive;

