import React from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';

function VfdTable({ vfdProcessedTable, kwhSaved, currentAirflow, recommendedAirflow }) {
    const styles = StyleSheet.create({
        container2: {
            marginTop: 10,
            marginBottom: 10,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '100%',
            borderBottom: 1,
        },
        header: {
            borderTop: 'none',
            justifyContent: 'space-evenly',
            display: 'flex',
        },
        tableRow1: {
            width: '12%',
            fontSize: '12px',
        },
        nameRow: {
            paddingLeft: '35px',
            width: '30%',
            fontSize: '12px',
        },
        vfdRow: {
            width: '10%',
            fontSize: '12px',
        },
        recAirflowAvg: {
            marginTop: '5px',
            marginLeft: '35px',
            fontSize: '12px',
        },
        kwh: {
            marginTop: '5px',
            marginLeft: '30%',
            fontSize: '12px',
        },
        currAirflowAvg: {
            marginTop: '5px',
            marginLeft: '12px',
            fontSize: '12px',
        },
        avgName: {
            marginTop: '2px',
            marginLeft: '9%',
            fontSize: '16px',
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
    })

    return (
        <View style={styles.container2} >
            <Text>Power / CFM Table</Text>
            <View style={[styles.row, styles.header]}>
                <Text style={styles.tableRow1}>Current kW</Text>
                <Text style={styles.tableRow1}>VFD kW</Text>
                <Text style={styles.tableRow1}>Saved kW</Text>
                <Text style={styles.tableRow1}>Saved kWh</Text>
                <Text style={styles.nameRow}>Storage Phase</Text>
                <Text style={styles.tableRow1}>Current</Text>
                <Text style={styles.vfdRow}>VFD</Text>
            </View>
            <View>
                {Object.keys(vfdProcessedTable).map((phase) => {
                    const phaseObj = vfdProcessedTable[phase];
                    let phaseName;
                    if (phase === 'load') phaseName = 'Harvest / Load'
                    else if (phase === 'cure') phaseName = 'Cure / Suberize'
                    else if (phase === 'ramp') phaseName = 'Ramp to Holding Temp'
                    else if (phase === 'winter') phaseName = 'Winter Holding'
                    else if (phase === 'spring') phaseName = 'Spring Holding'
                    else if (phase === 'summer') phaseName = 'Summer Holding'
                    else if (phase === 'unload') phaseName = 'Unload'

                    return <View key={phaseName} style={[styles.row, styles.header]}>
                        <Text style={styles.tableRow1}>{phaseObj.currPowerUsed}</Text>
                        <Text style={styles.tableRow1}>{phaseObj.recommendedPowerUsed}</Text>
                        <Text style={styles.tableRow1}>{phaseObj.kwSaved}</Text>
                        <Text style={styles.tableRow1}>{phaseObj.kwhSaved}</Text>
                        <Text style={styles.nameRow}>{phaseName}</Text>
                        <Text style={styles.tableRow1}>{phaseObj.currAirflow}</Text>
                        <Text style={styles.vfdRow}>{phaseObj.recommendedAirflow}</Text>
                    </View>
                })}
            </View>
            <View style={styles.footer} >
                <Text style={styles.kwh}>
                    Total: {kwhSaved}
                </Text>
                <Text style={styles.avgName}>Season Average:</Text>
                <Text style={styles.currAirflowAvg}>
                    {currentAirflow}
                </Text>
                <Text style={styles.recAirflowAvg}>
                    {recommendedAirflow}
                </Text>
            </View>
        </View>
    )
}

export default VfdTable;