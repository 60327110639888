import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Users/UserContext";
import DeptComponent from "./DeptComponent";

function CompanyPage({ company }) {
    const { currentUser } = useContext(UserContext)
    const [dept, setDept] = useState([])
    const companyPass = company;

    useEffect(() => {
        if (currentUser.dept === 'admin' || currentUser.dept === 'sales') {
            setDept(['service', 'chemApps', 'labs', 'sales'])
        } else setDept([currentUser.dept])
    }, [company])

    return (
        <>
            {dept.length > 0 &&
                dept.map((deptartment) => {
                    return <DeptComponent
                        key={deptartment}
                        dept={deptartment}
                        company={companyPass}
                    />
                })
            }
        </>
    )
}

export default CompanyPage;