import React, { useState, useContext, useEffect } from 'react'
import {
    Grid, TextField, ToggleButton, ToggleButtonGroup,
    Box, Button, Divider, Typography, TextareaAutosize,
} from '@mui/material/'
import { Masonry } from "@mui/lab";
import { useTheme } from '@mui/material/styles'
import { useHistory } from "react-router-dom"
import useFields from '../../Hooks/useFields'
import UserContext from '../../Users/UserContext'
import BaseSheet from '../SheetComponents/BaseSheet'
import PartsSheet from '../SheetComponents/PartsSheet'
import TechSheet from '../SheetComponents/TechSheet';
import CreateSubmitObj from '../SheetComponents/CreateSubmitObj'
import useBaseStore from "../../stores/baseStore";
import usePartsStore from "../../stores/partsStore";
import useTechStore from '../../stores/techStore';
import SheetsApi from '../../api'
import useNotification from '../../Hooks/useNotification';
import TechSelect from '../SheetComponents/TechSelect';
import DeptSelect from '../SheetComponents/DeptSelect';
import useSettingsStore from '../../stores/settingsStore';

function SalesOrder() {
    const INITIAL_STATE = {
        name: "",
        address: "",
        terms: "",
        shippedVia: "",
        zipCode: "",
        state: "",
        city: "",
        notes: "",
        salesRep: "",
    }
    const { currentUser } = useContext(UserContext)
    const history = useHistory()
    const theme = useTheme()
    const messanger = useNotification()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const [renderCheck, setRenderCheck] = useState(false)
    const [alignment, setAlignment] = useState(false)
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [techs, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])
    const [home] = useSettingsStore((state) => [state.home])
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [partSheet, clearPartsForm] = usePartsStore(state =>
        [state.parts, state.clearPartsForm])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleToggle = (e, newAlignment) => {
        setAlignment(newAlignment)
    }

    useEffect(() => {
        clearTechForm()
        clearBaseForm()
        clearPartsForm()
        resetFormData()
        setRenderCheck(true)
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()
        setIsSubmitting(true)
        const sheetObj = CreateSubmitObj(
            formData, currentUser, messanger,
            baseSheet, partSheet, techs
        )
        if (sheetObj.error) return setIsSubmitting(false);
        console.log(sheetObj)
        const sheet = await SheetsApi.addSalesOrder(sheetObj)
        console.log(sheet)
        clearTechForm()
        clearBaseForm()
        clearPartsForm()
        resetFormData()
        messanger(sheet.message, sheet.error ? "error" : "success");
        setRenderCheck(false)
        history.push(home)
        return setIsSubmitting(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container >
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        {renderCheck && <BaseSheet title="Sales Order" />}
                        <Grid
                            display="grid"
                            className='displayColumns3'
                            gridTemplateColumns='repeat(auto-fill, minmax(350px, 1fr))'
                        >
                            <Box display="flex" width="98%" mb={1} mr={1}>
                                <TechSelect
                                    name="Sales Rep"
                                    id="salesRep"
                                    edit={false}
                                    formData={formData}
                                    handleLocal={handleLocal}
                                />
                            </Box>
                            <Box display="flex" width="98%" mb={1} mr={1}>
                                <TextField
                                    fullWidth
                                    name='terms'
                                    id='terms'
                                    label='Terms'
                                    variant='outlined'
                                    value={formData.terms || ""}
                                    onChange={handleLocal}
                                />
                            </Box>
                            <Box mb={1} display="flex" width="98%">
                                <ToggleButtonGroup
                                    value={alignment}
                                    onChange={handleToggle}>
                                    <ToggleButton
                                        id='shipping'
                                        value={true}
                                    >
                                        Shipping Information
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Grid>
                        <Box mb={1}>
                            <Divider sx={{ borderBottomWidth: 3 }} />
                        </Box>
                        {alignment[0] && <>
                            <Masonry columns={2}>
                                <Box ml={1} mb={1} >
                                    <TextField
                                        fullWidth
                                        name="name"
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        value={formData.name || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        id="address"
                                        label="Address"
                                        variant="outlined"
                                        value={formData.address || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1} >
                                    <TextField
                                        fullWidth
                                        name='shippedVia'
                                        id="shippedVia"
                                        label="Shipped Via"
                                        variant='outlined'
                                        value={formData.shippedVia || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='zipCode'
                                        id='zipCode'
                                        label="Zip Code"
                                        variant='outlined'
                                        value={formData.zipCode || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='state'
                                        id='state'
                                        label="State"
                                        variant='outlined'
                                        value={formData.state || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='city'
                                        id='city'
                                        label="City"
                                        variant='outlined'
                                        value={formData.city || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />

                                </Box>
                            </Masonry>
                            <Box mb={1}>
                                <Divider sx={{ borderBottomWidth: 3 }} />
                            </Box>
                        </>}
                        {renderCheck && <>
                            <PartsSheet toggle={true} calcOn={true} />
                            <TechSheet toggle={true} timeTrack={true} />
                        </>}
                        <Box
                            style={{ width: "100%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                variant="h5"
                                ml={1}
                                style={{ color: defaultColor }}>
                                Comments:
                            </Typography>
                        </Box>
                        <Box mb={1} ml={0.25} >
                            <TextareaAutosize
                                id="notes"
                                name="notes"
                                value={formData.notes || ""}
                                onChange={handleLocal}
                                minRows={4}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="flex-start" mt={1}>
                            <DeptSelect salesOrder={true} />
                            <Button
                                disabled={isSubmitting}
                                style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                SUBMIT
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form >
        </>
    )
}

export default SalesOrder;