import React from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

function DateInput({ state, setState, variableName, label, required }) {
    dayjs.extend(utc);
    const dateObj = dayjs.utc(state);
    const formattedDate = dateObj.isValid() ? dateObj.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY');

    const handleChange = (e) => {
        const newDateObj = dayjs(e);
        const dateStr = newDateObj.isValid() ? newDateObj.format("MM/DD/YYYY") : dayjs().format("MM/DD/YYYY");
        setState(variableName, dateStr);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                label={label}
                name={variableName}
                inputFormat="MM/DD/YYYY"
                value={formattedDate}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        sx={{ width: '100%' }}
                        required={required}
                        {...params}
                        error={false}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

export default DateInput;