import React, { useState, useEffect, useContext } from "react";
import {
    Box, Card, CardContent, CardHeader, useTheme, Collapse, IconButton, Tooltip,
} from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SheetsApi from "../../api";
import useNotification from "../../Hooks/useNotification";
import UserContext from "../UserContext"
import SearchableInput from "../../Sheets/SheetComponents/SearchableInput";
import useFields from "../../Hooks/useFields";
import UserEdit from "./UserEdit";
import RegisterNewUser from "./RegisterNewUser";

function Users({ logout }) {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const messanger = useNotification();
    const { currentUser } = useContext(UserContext)
    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([]);
    const [selectedUser, setSelectedUser, resetUser] = useFields({})
    const [userSettingsCollapse, setUserSettingCollapse] = useState(false);
    const [globalData, setGlobalData, resetGlobal] = useFields({})
    const [newUserCollapse, setNewUserCollapse] = useState(false);

    useEffect(() => {
        async function getAllData() {
            try {
                const companies = await SheetsApi.getAllCompanies();
                const users = await SheetsApi.getInactive()
                return { users, companies: companies.companies }
            } catch (err) {
                console.log(err)
            }
        }
        getAllData().then(data => {
            setUsers(data.users)
            const companyArray = [];
            data.companies.forEach((company) => companyArray.push(company.name))
            setCompanies(companyArray)
        })
    }, []);

    useEffect(() => {
        if (selectedUser.username) {
            setUserSettingCollapse(true);
            setNewUserCollapse(false);
        }
        else setUserSettingCollapse(false);
        setGlobalData("replaceObj", selectedUser)
    }, [selectedUser])

    async function resetPassword(username) {
        console.log(username)
        await SheetsApi.passwordResetRequest({ username })
        if (currentUser.username === username) {
            logout()
        }
        return messanger(`The next time user: ${username} logs in, they will be prompted to reset their password.`, "success")
    }

    const submitUpdate = async function () {
        const response = await SheetsApi.updateUser(globalData)
        messanger(response.message, response.error ? "error" : "success")
    }

    const handleAddUser = function () {
        resetUser();
        resetGlobal();
        setUserSettingCollapse(false);
        setNewUserCollapse(true);
    }

    const handleRegisterUser = async function (e, resetForm) {
        e.preventDefault();
        const response = await SheetsApi.register(globalData)
        resetForm();

        messanger(response.message, response.error ? 'error' : 'success')
    }

    return (<>
        <Card sx={{ width: '100%', bgcolor: background, paddingBottom: 2 }}>
            <CardHeader title="Select a user" />
            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" width="30%">
                        <SearchableInput
                            label="Username"
                            variableName="username"
                            searchableArray={users}
                            setState={setSelectedUser}
                            fullwidth={true}
                        />
                        <Tooltip title="Add new user">
                            <IconButton onClick={handleAddUser}>
                                <AddCircleIcon style={{ fontSize: 30 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box mr={3}>
                        <AccountCircleIcon style={{ fontSize: 50 }} />
                    </Box>
                </Box>
            </CardContent>
        </Card>
        <Collapse in={userSettingsCollapse}>
            <UserEdit
                submitUpdate={submitUpdate}
                resetPassword={resetPassword}
                selectedUser={selectedUser}
                companies={companies}
                handleGlobal={setGlobalData}
            />
        </Collapse >
        <Collapse in={newUserCollapse}>
            <RegisterNewUser
                companies={companies}
                handleRegisterUser={handleRegisterUser}
                setGlobalData={setGlobalData}
            />
        </Collapse>
    </>)
}
export default Users