import React, { useEffect, useContext } from "react";
import useFields from "../Hooks/useFields";
import { TextField, Box } from "@mui/material";
import SearchableInput from "../Sheets/SheetComponents/SearchableInput";
import UserContext from "../Users/UserContext";
import useImageStore from "../stores/imageStore";

function CategorizedInputs({ customers, users, category, sheet, receipt }) {
    const { currentUser } = useContext(UserContext);
    const [customer, setCustomer] = useFields({ customer: sheet ? receipt.receiptData.customer : "" });
    const [user, setUser] = useFields(currentUser);
    const [receiptState, setReceipt, setReceiptState] = useImageStore(state => [state.receipt, state.setReceipt, state.setReceipt]);
    
    useEffect(() => {
        setReceipt("customer", customer.customer)
    }, [setCustomer]);

    useEffect(() => {
        setReceipt("selectedUser", user)
    }, [setUser])

    return (<>
        <Box display={currentUser.mobile ? '' : 'flex'} flexDirection="column" justifyContent={currentUser.mobile ? '' : 'space-evenly'}>
            <Box display={currentUser.mobile ? '' : "flex"} justifyContent={currentUser.mobile ? '' : "space-evenly"}>
                <Box sx={{ mt: currentUser.mobile && 1, mb: currentUser.mobile && 1 }}>
                    <SearchableInput
                        edit={true}
                        editData={currentUser.username}
                        setState={setUser}
                        searchableArray={users}
                        label="Employee Name"
                        variableName="username"
                    />
                </Box>
                {(category === 'other' || category === 'part' || category === 'largeJob' || category === 'entertainment') &&
                    <SearchableInput
                        edit={sheet}
                        editData={receipt.receiptData.customer}
                        setState={setCustomer}
                        searchableArray={customers}
                        label="Customer"
                        variableName="customer"
                    />
                }
            </Box>
            <Box mt={1} display={currentUser.mobile ? '' : "flex"} justifyContent={currentUser.mobile ? '' : "space-evenly"}>
                {(category === 'other' || category === "part") &&
                    <TextField
                        id="partNumber"
                        name="partNumber"
                        label="Part Number"
                        value={receiptState.partNumber || ""}
                        onChange={(e) => setReceiptState(e.target.name, e.target.value)}
                        sx={{ width: currentUser.mobile ? '100%' : '20ch' }}
                    />
                }
                {(category === 'other' || category === "fuel") &&
                    <TextField
                        name="vehicle"
                        id="vehicle"
                        label="Vehicle"
                        value={receiptState.vehicle || ""}
                        onChange={(e) => setReceiptState(e.target.name, e.target.value)}
                        sx={{ width: currentUser.mobile ? '100%' : '20ch', mt: currentUser.mobile && 1 }}
                    />
                }
                {(category === 'other' || category === "part" || category === "largeJob") &&
                    <TextField
                        id="largeJob"
                        name="largeJob"
                        label="Large Job #"
                        sx={{ width: currentUser.mobile ? '100%' : '20ch', mt: currentUser.mobile && 1 }}
                        value={receiptState.largeJob || ""}
                        onChange={(e) => setReceiptState(e.target.name, e.target.value)}
                    />
                }
            </Box>
        </Box>

    </>)
}

export default CategorizedInputs;