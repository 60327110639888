import React, { useContext, useEffect, useState } from "react";
import {
    CardContent, Card, Box, Button, Typography, Divider,
} from "@mui/material";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UndoIcon from '@mui/icons-material/Undo';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import ShowerIcon from '@mui/icons-material/Shower';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useTheme } from '@mui/material/styles';
import UserContext from "../Users/UserContext";
import { Link, useHistory } from "react-router-dom";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

function SheetCard2({ sheet, mobile }) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const theme = useTheme()
    const background = theme.palette.background.main;
    const blue = theme.palette.secondary.alter;
    const { currentUser } = useContext(UserContext)
    const history = useHistory()
    const [displaySettings, setDisplaySettings] = useState({
        rem: '1rem',
    })

    const displayIcon = function () {
        if (sheet.sheet_type === "sheet") {
            return <WarehouseIcon />
        } else if (sheet.sheet_type === "refrigeration") {
            return <AcUnitIcon />
        } else if (sheet.sheet_type === "preseason") {
            return <UndoIcon />
        } else if (sheet.sheet_type === "sales") {
            return <ReceiptIcon />
        } else if (sheet.sheettype === "inspections") {
            return <SearchIcon />
        } else if (sheet.sheettype === "chemAppAgreement") {
            return <CloudSyncIcon />
        } else if (sheet.sheettype === "bid") {
            return <ReceiptLongIcon />
        } else if (sheet.sheettype === 'bidRef') {
            return <ReceiptLongIcon sx={{ color: blue }} />
        } else if (sheet.sheettype === 'freshPack') {
            return <PrecisionManufacturingIcon />
        } else if (sheet.sheettype === 'disinfectantAgreement') {
            return <SanitizerIcon />
        } else if (sheet.sheettype === 'sprayBarAgreement') {
            return <ShowerIcon />
        }
    }

    const pageRouter = function () {
        const accLvl = currentUser.account_level
        const route = function () {
            //sends user to proper page
            if (sheet.sheet_type === "sheet") {
                return `/sheets/edit/${sheet.id}`
            } else if (sheet.sheet_type === "refrigeration") {
                return `/refrigeration/edit/${sheet.id}`
            } else if (sheet.sheet_type === "preseason") {
                return `/preseason/edit/${sheet.id}`
            } else if (sheet.sheet_type === "sales") {
                return `/salesOrders/edit/${sheet.id}`
            } else if (sheet.sheettype === "inspections") {
                return `/inspections/edit/${sheet.id}`
            } else if (sheet.sheettype === "chemAppAgreement") {
                if (accLvl === "Technician") return '/'
                else return `/chemApps/agreement/edit/${sheet.id}`
            } else if (sheet.sheettype === "disinfectantAgreement") {
                if (accLvl === "Technician") return '/';
                return `/disinfectant/agreement/edit/${sheet.id}`
            } else if (sheet.sheettype === "sprayBarAgreement") {
                if (accLvl === "Technician") return "/";
                else return `/sprayBar/agreement/edit/${sheet.id}`
            } else if (sheet.sheettype === 'bidRef') {
                return `bidRef/edit/${sheet.id}`
            } else if (sheet.sheettype === "bid") {
                return `/bids/edit/${sheet.id}`
            } else if (sheet.sheettype === "freshPack") {
                return `/freshPack/edit/${sheet.id}`
            }
        }
        //checks account levels and sheet stages.
        // techs are always allowed into Preliminary Review.
        // managers are not allowed in Ready for Invoicing.
        if (accLvl === "Admin" || accLvl === "Manager" || accLvl === "Regional Admin") {
            if (sheet.stage === "Ready for Invoicing" && accLvl === "Manager") {
                return '/'
            }
            return route()
        } else {
            if (sheet.stage === "Preliminary Review") {
                return route()
            } else {
                if (sheet.sheettype === "chemAppAgreement") return '/'
                if (sheet.sheettype === "disinfectantAgreement") return '/'
                else return `/`
            }
        }
    }
    const chemAppFormRoute = function (e) {
        e.preventDefault()
        if (currentUser.account_level === "Technician"
            && (sheet.stage !== "Signature Pending" && sheet.stage !== "Preliminary Review")) {
            return history.push('/')
        } else {
            if (sheet.sheettype === "chemAppAgreement") {
                return history.push(`/chemApps/form/edit/${sheet.id}`)
            } else if (sheet.sheettype === "disinfectantAgreement") {
                return history.push(`/disinfectant/form/edit/${sheet.id}`)
            } else if (sheet.sheettype === "sprayBarAgreement") {
                return history.push(`/sprayBar/form/edit/${sheet.id}`)
            }
        }
    }

    useEffect(() => {
        if (mobile) {
            setDisplaySettings({ ...displaySettings, rem: '0.7rem' })
        } else if (sheet.location_name) {
            if (sheet.location_name.length + sheet.customer.length > 55) {
                setDisplaySettings({ ...displaySettings, rem: '0.85rem' })
            }
        }
    }, [])
    return (
        <>
            <Box mt={1}>
                <Card
                    elevation={10}
                    sx={{
                        bgcolor: background, textAlign: 'center',
                    }}>
                    <CardContent>
                        <Link
                            style={{ color: 'inherit', textDecoration: 'none' }}
                            to={pageRouter()}>
                            <Box display={mobile ? 'block' : 'flex'}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                >
                                    {displayIcon()}
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} />
                                    <Typography
                                        sx={{ fontSize: `${displaySettings.rem}` }}
                                        style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                        ID# {sheet.id}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    sx={{ textAlign: 'center', flex: 1 }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: `${displaySettings.rem}`,
                                            marginRight: '10px',
                                            position: `${!mobile ? 'absolute' : ''}`,
                                            right: `${!mobile ? '50%' : ''}`,
                                        }}
                                        style={{ fontWeight: 'bold' }}>
                                        {sheet.customer}
                                    </Typography>
                                    {!mobile && <>
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                borderColor: 'gray',
                                                position: `${!mobile ? 'absolute' : ''}`,
                                                height: '25px',
                                                left: `${!mobile ? '50%' : ''}`,
                                            }}
                                        />
                                    </>}
                                    <Typography
                                        sx={{
                                            fontSize: `${displaySettings.rem}`,
                                            position: `${!mobile ? 'absolute' : ''}`,
                                            left: `${!mobile ? '51%' : ''}`,
                                        }}
                                        style={{ fontWeight: 'bold' }}>
                                        {sheet.location_name || sheet.jobName}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ textAlign: 'center' }}
                                >
                                    <Typography
                                        sx={{ fontSize: `${displaySettings.rem}` }}
                                        style={{ fontWeight: 'bold' }}>
                                        {sheet.creator}
                                    </Typography>
                                    {!mobile ? <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} /> :
                                        <Box ml={2} mr={2}></Box>
                                    }
                                    <Typography
                                        sx={{ fontSize: `${displaySettings.rem}` }}
                                        style={{ fontWeight: 'bold' }}>
                                        {dayjs.tz(sheet.dateServiced, 'America/Denver').format('MM-DD-YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Link>
                        {(sheet.sheettype === "chemAppAgreement" ||
                            sheet.sheettype === "sprayBarAgreement" ||
                            sheet.sheettype === "disinfectantAgreement") && <>
                                <Link
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                    to={pageRouter()}>
                                    <Box display={mobile ? "" : "flex"} alignItems="center" mt={2}>
                                        {sheet.product1 &&
                                            <Typography
                                                sx={{ fontSize: `${displaySettings.rem}` }}
                                                style={{ fontWeight: 'bold' }}>
                                                {sheet.product1 + ' @ ' + sheet.rate1}
                                            </Typography>
                                        }
                                        {!mobile &&
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{ borderColor: 'gray', height: '25px', mx: '30px' }} />}
                                        <Box
                                            display={!mobile && 'flex'}
                                            justifyContent="left"
                                            sx={{ flex: 1 }}
                                        >
                                            {sheet.product2 &&
                                                <Typography
                                                    sx={{ fontSize: `${displaySettings.rem}` }}
                                                    style={{ fontWeight: 'bold' }}>
                                                    {sheet.product2 + ' @ ' + sheet.rate2}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                    <Box
                                        mt={mobile ? 1 : 0}
                                        display="flex"
                                        justifyContent="right"
                                        sx={{ textAlign: 'left', flex: 1 }}>
                                        <Button
                                            sx={{ fontSize: '0.7rem' }}
                                            variant="contained"
                                            type="submit">
                                            Edit Agreement
                                        </Button>
                                        <Box ml={2}>
                                            <Button
                                                onClick={(e) => chemAppFormRoute(e)}
                                                sx={{ fontSize: '0.7rem' }}
                                                variant="contained"
                                                type="submit">
                                                Edit Form
                                            </Button>
                                        </Box>
                                    </Box>
                                </Link>
                            </>}
                    </CardContent>
                </Card>
            </Box>

        </>
    )
}

export default SheetCard2;