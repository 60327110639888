import React, { useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import { TextField } from "@mui/material/"
import { Masonry } from "@mui/lab"
import useChemAppStore from "../../../stores/chemAppStore";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


function Regulation({ sprayBar }) {
    const INITIAL_STATE = {
        epaReg: "",
        batchLot: "",
        applicatorLic: "",
        epaReg2: "",
        batchLot2: "",
        startTime: "",
        endTime: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))

    useEffect(() => {
        const regulationObj = {
            epaReg: chemAppForm.epaReg,
            batchLot: chemAppForm.batchLot,
            applicatorLic: chemAppForm.applicatorLic,
            epaReg2: chemAppForm.epaReg2,
            batchLot2: chemAppForm.batchLot2,
            startTime: chemAppForm.startTime,
            endTime: chemAppForm.endTime,
        }
        handleLocal("replaceObj", regulationObj)
    }, []);

    const handleChange = function (e, passedName) {
        if (!e) {
            return
        } else if (e.$L) {
            return setChemAppForm(passedName, e)
        }
        const name = e.target.name
        let value = formData[name]
        setChemAppForm(name, value)
    }
    return (
        <>
            <Masonry columns={{ xs: 1, sm: 3 }}>
                {!sprayBar && <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                            label="Machine Start Time"
                            name="startTime"
                            onChange={(e) => handleChange(e, "startTime")}
                            value={chemAppForm.startTime}
                            inputVariant="standard"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={false}
                                />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                            label="Machine End Time"
                            name="endTime"
                            onChange={(e) => handleChange(e, "endTime")}
                            value={chemAppForm.endTime}
                            inputVariant="standard"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={false}
                                />}
                        />
                    </LocalizationProvider>
                </>}
                <TextField
                    name="applicatorLic"
                    id="applicatorLic"
                    label="Applicator/Lic#"
                    fullWidth
                    value={formData.applicatorLic || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
            </Masonry>
            <Masonry columns={{ xs: 1, sm: 2 }}>
                <TextField
                    name="epaReg"
                    id="epaReg"
                    label="EPA Reg#"
                    fullWidth
                    value={formData.epaReg || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <TextField
                    name="batchLot"
                    id="batchLot"
                    label="Batch/Lot#"
                    fullWidth
                    value={formData.batchLot || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <TextField
                    name="epaReg2"
                    id="epaReg2"
                    label="EPA Reg# 2"
                    fullWidth
                    value={formData.epaReg2 || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <TextField
                    name="batchLot2"
                    id="batchLot2"
                    label="Batch/Lot# 2"
                    fullWidth
                    value={formData.batchLot2 || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
            </Masonry>
        </>
    )
}

export default Regulation;