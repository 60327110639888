import React, { useState, useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import { TextField, Button, Box } from "@mui/material";
import { Masonry } from "@mui/lab";
import { useGeolocated } from "react-geolocated";
import useChemAppStore from "../../../stores/chemAppStore";
import useNotification from "../../../Hooks/useNotification"
function Coords() {
    const INITIAL_STATE = {
        lat: 0,
        long: 0,
    }
    const messanger = useNotification()
    const [render, setRender] = useState(false);
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });
    useEffect(() => {
        const coordObj = {
            lat: chemAppForm.lat,
            long: chemAppForm.long,
        }
        handleLocal("replaceObj", coordObj);
    }, [])
    const getCoords = function () {
        if (!isGeolocationAvailable) {
            return messanger("Your browser does not support Geolocation.", "error");
        } else if (!isGeolocationEnabled) {
            return messanger("Geolocation is not enabled", "error");
        } else {
            const long = coords.longitude
            const lat = coords.latitude
            handleLocal("invalidInput", "lat", lat)
            handleLocal("invalidInput", "long", long)
            setChemAppForm("long", long)
            setChemAppForm("lat", lat)
            setRender(!render)
        }
    }
    const handleChange = function (e) {
        const name = e.target.name
        let value = parseFloat(formData[name])
        setChemAppForm(name, value)
    }
    return (
        <div>
            <Masonry columns={{ xs: 1, sm: 3 }}>
                <TextField
                    id="lat"
                    name="lat"
                    label="Latitude"
                    type="number"
                    value={formData.lat || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <TextField
                    id="long"
                    name="long"
                    label="Longitude"
                    type="number"
                    value={formData.long || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <Box mt={1.05}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={getCoords}
                        sx={{ height: '50px' }}>
                        Get Coords
                    </Button>
                </Box>
            </Masonry>
        </div>
    )
}

export default Coords;