import React, { useState, useContext, useEffect } from "react";
import {
    Grid, Box, Typography, Button,
} from "@mui/material";
import BaseSheet from "../SheetComponents/BaseSheet";
import useFields from "../../Hooks/useFields";
import useFreshPackStore from "../../stores/freshPackStore";
import Pump from "./Pump";
import TechSheet from "../SheetComponents/TechSheet";
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';
import FreshPackVisit from "./FreshPackVisit";
import DeptSelect from "../SheetComponents/DeptSelect";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import UserContext from "../../Users/UserContext";
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";
import { useHistory } from "react-router-dom";
import useSettingsStore from "../../stores/settingsStore";

function FreshPack() {
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    const defaultColor = theme.palette.default.main;
    const { currentUser } = useContext(UserContext);
    const history = useHistory();
    const [home] = useSettingsStore((state) => [state.home])
    const [freshPackForm, setFreshPackFrom, pump1, pump2, clearFreshPack] = useFreshPackStore(state =>
        [state.freshPackForm, state.setFreshPackFrom, state.pump1, state.pump2, state.clearFreshPack]);
    const [baseForm, clearBaseForm] = useBaseStore(state => [state.baseForm, state.clearBaseForm]);
    const [techs, clearTechForm] = useTechStore(state => [state.techs, state.clearTechForm]);
    const [formData, handleLocal] = useFields({
        visitReason: "",
        currPpm: "",
    });
    const [render, setRender] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const messanger = useNotification();

    const handleSubmit = async function (e) {
        e.preventDefault();
        setIsSubmitting(true);
        const mergedSheet = { ...freshPackForm, pump1, pump2 }
        const sheetObj = CreateSubmitObj(
            mergedSheet, currentUser, messanger,
            baseForm, [], techs)
        console.log(sheetObj);
        if (sheetObj.error) return setIsSubmitting(false);
        const response = await SheetsApi.addFreshPack(sheetObj);
        messanger(response.message, response.error ? "error" : "success");
        clearBaseForm();
        clearFreshPack();
        clearTechForm();
        setIsSubmitting(false);
        history.push(home);
    };

    useEffect(() => {
        clearBaseForm();
        clearFreshPack();
        clearTechForm();
        setRender(true);
    }, []);

    return (<> {render && <>
        <form onSubmit={handleSubmit}>
            <Grid container>
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <BaseSheet title="Equipment / Fresh Pack Check" />
                    <FreshPackVisit />
                    <Pump id={1} />
                    <Pump id={2} />
                    <TechSheet timeTrack={true} toggle={true} />
                    <Box
                        style={{ width: "100%" }}
                        sx={{ backgroundColor: primary }}
                    >
                        <Typography
                            ml={1}
                            variant="h5"
                            style={{ color: defaultColor }}>
                            Additional Comments:
                        </Typography>
                    </Box>
                    <Box ml={0.25}>
                        <TextareaAutosize
                            minRows={4}
                            style={{
                                width: "99.0%",
                                outline: '2px solid #000',
                                fontSize: "16px",
                            }}
                            name="comments"
                            id="comments"
                            value={formData.comments || ""}
                            onChange={handleLocal}
                            onBlur={(e) => setFreshPackFrom(e.target.name, e.target.value)}
                        />
                    </Box>
                    <Box mt={1} display="flex" justifyContent="flex-start" >
                        <DeptSelect chemApp={true} />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                            disabled={isSubmitting}
                        >Submit</Button>
                    </Box>
                </Grid>
            </Grid >
        </form>
    </>}
    </>)
}

export default FreshPack;