import React, { useEffect } from "react";
import useFields from "../../Hooks/useFields";
import {
    Box, TextField, Typography, InputAdornment,
} from "@mui/material"
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';
import useFreshPackStore from "../../stores/freshPackStore";

function Pump({ id, data }) {
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    const defaultColor = theme.palette.default.main;
    const [setFreshPackPump] = useFreshPackStore(state => [state.setFreshPackPump])
    const [formData, handleLocal] = useFields({
        pump: "",
        currSettings: "",
        barrelLevel: "",
        gals: "",
        comments: "",
    })

    useEffect(() => {
        if (data) {
            handleLocal("replaceObj", data)
        }
    }, [])
    return (<>
        <Box m={1}>
            <Box alignItems="center" display="flex">
                <Typography variant="h5">Pump #{id}:</Typography>
                <TextField
                    name="pump"
                    value={formData.pump || ""}
                    onChange={handleLocal}
                    size="small"
                    onBlur={(e) => setFreshPackPump(id, e.target.name, e.target.value)}
                />
            </Box>
            <Box mt={1} mb={1} ml={1} style={{ display: 'inline-block' }} >
                <TextField
                    name="currSettings"
                    value={formData.currSettings || ""}
                    label="Current Pump Settings"
                    onChange={handleLocal}
                    onBlur={(e) => setFreshPackPump(id, e.target.name, e.target.value)}
                />
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <TextField
                    name="barrelLevel"
                    type="number"
                    value={formData.barrelLevel || ""}
                    label="Barrel Level"
                    onChange={handleLocal}
                    onBlur={(e) => setFreshPackPump(id, e.target.name, e.target.value)}
                    sx={{ width: '100px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            %</InputAdornment>,
                    }}
                />
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <TextField
                    name="gals"
                    type="number"
                    value={formData.gals || ""}
                    label="Additional Product on Site"
                    onChange={handleLocal}
                    onBlur={(e) => setFreshPackPump(id, e.target.name, e.target.value)}
                    sx={{ width: '175px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            GALS</InputAdornment>,
                    }}
                />
            </Box>
            <Box
                style={{ width: "100%" }}
                sx={{ backgroundColor: primary }}
            >
                <Typography
                    ml={1}
                    variant="h5"
                    style={{ color: defaultColor }}>
                    Comments / Changes Made:
                </Typography>
            </Box>
            <Box ml={0.25}>
                <TextareaAutosize
                    minRows={4}
                    style={{
                        width: "99.0%",
                        outline: '2px solid #000',
                        fontSize: "16px",
                    }}
                    name="comments"
                    id="comments"
                    value={formData.comments || ""}
                    onChange={handleLocal}
                    onBlur={(e) => setFreshPackPump(id, e.target.name, e.target.value)}
                />
            </Box>
        </Box>
    </>)
}

export default Pump;