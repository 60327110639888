import React, { useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import {
    Box, FormControlLabel, Radio,
    RadioGroup, Typography
} from "@mui/material/"
import useChemAppStore from "../../../stores/chemAppStore";

function Bypass({ name, id }) {
    const INITIAL_STATE = {
        [id]: "",
        [id + "OtherText"]: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]
    ))

    useEffect(() => {
        const bypassObj = {
            [id]: chemAppForm[id],
            [id + "OtherText"]: chemAppForm[id + "OtherText"],
        }
        handleLocal("replaceObj", bypassObj);
    }, [])

    const handleChange = function (e) {
        const name = e.target.name
        let value = e.target.value
        setChemAppForm(name, value)
    }
    return (
        <>
            <Box mt={1} style={{ display: 'block' }} >
                <Typography
                    variant="h5">
                    BYPASS: {name}
                </Typography>
                <Box m={1}>
                    <RadioGroup
                        name={id}
                        id={id}
                        value={formData[id] || ""}
                        onBlur={handleChange}
                        onChange={(e) => {
                            handleLocal("invalidInput", e.target.name, e.target.value)
                            handleChange(e);
                        }}
                        row>
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                        <FormControlLabel value="open" control={<Radio />} label="Open" />
                        <FormControlLabel value="closed" control={<Radio />} label="Closed" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </Box>
            </Box>
        </>
    )
}

export default Bypass;