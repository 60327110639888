import { LogLevel } from "@azure/msal-browser";

let BASE_URL;

if (process.env.NODE_ENV == "production") {
    BASE_URL = "https://agristor.com"
} else {
    BASE_URL = "http://localhost:3000"
}

export const msalConfig = {
    auth: {
        clientId: "aaa2baa9-44f9-4ee6-abf3-e743eb7ad0ad", // Application (client) ID from Azure
        authority: "https://login.microsoftonline.com/df39466e-d00d-458a-8329-bf8a8751d4eb", // Directory (tenant) ID from Azure
        redirectUri: BASE_URL,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.verbose,
        },
    },
};

export const loginRequest = {
    scopes: ["Calendars.Read"]
};
