import React, { useEffect, useState } from "react";
import {
    Box, Select, FormControl, InputLabel, MenuItem, TextField,
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import useBayStore from "../../stores/bayStore";
import SheetsApi from "../../api";
import useBaseStore from "../../stores/baseStore";
import DayJS from 'dayjs';

function RunTime({ edit }) {
    const INITIAL_STATE = {
        billing: "",
        lastVisit: 0,
        currentRunTime: 0,
        dailyAvg: 0,
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [baseForm] = useBaseStore(state => [state.baseForm]);
    const [lastVisitData, setLastVisitData] = useState([])
    const [inspectionEtc, setInspectionEtc] = useBayStore(state =>
        [state.inspectionEtc, state.setInspectionEtc])

    const handleChange = function (e) {
        if (e.target.name === 'currentRunTime') {
            setInspectionEtc("dailyAvg", parseFloat(formData.dailyAvg))
        }
        setInspectionEtc(e.target.name, e.target.value);
    }

    const findSheetObj = function (id) {
        return lastVisitData.find((obj) => {
            return obj.id === id
        })
    }

    const setDailyAvg = function (foundObj) {
        if ((formData.lastVisit && formData.currentRunTime && lastVisitData.length > 0) || foundObj) {

            if (!foundObj) {
                foundObj = findSheetObj(formData.lastVisit);
                if (!foundObj) return null;
            }

            const parseDate = (dateString) => {
                const dateFormats = ['YYYY/MM/DD', 'MM/DD/YYYY'];
                let parsedDate;
                for (const format of dateFormats) {
                    parsedDate = DayJS(dateString, format);
                    if (parsedDate.isValid()) {
                        return parsedDate;
                    }
                }
                return null;
            };

            const lastVisitDate = parseDate(foundObj.dateServiced);
            let today;
            if (baseForm.dateServiced === "") {
                today = DayJS();
            } else {
                today = parseDate(baseForm.dateServiced);
            }

            if (!lastVisitDate || !today) {
                return console.error('Invalid date format detected.');
            }
            const daysDifference = today.diff(lastVisitDate, 'days');
            const runTimeDifference = (inspectionEtc.currentRunTime - foundObj.currentRunTime);
            const dailyAvg = (runTimeDifference / daysDifference).toFixed(2);

            handleLocal("invalidInput", "dailyAvg", dailyAvg);
        }
    }

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", inspectionEtc)
            const foundDate = findSheetObj(formData.lastVisit);
            if (!foundDate) return;
            handleLocal("invalidInput", "dailyAvg", foundDate.dailyAvg);
        }
    }, [])

    useEffect(() => {
        const getRecentInspection = async () => {
            const serviceDate = baseForm.dateServiced || DayJS().format('MM/DD/YYYY');
            const response = await SheetsApi.getRecentInspection({
                customer: baseForm.customerName,
                locationId: baseForm.locationId,
                serviceDate,
            });
            setLastVisitData(response);

            if (edit && inspectionEtc.lastVisit) {
                //Makes sure that the data populates onto the form.
                const foundObj = response.find(obj => obj.id === inspectionEtc.lastVisit);
                if (foundObj) {
                    setDailyAvg(foundObj)
                    handleLocal("invalidInput", "lastVisitRunTime", foundObj.currentRunTime);
                }
            }
        };

        if (baseForm.customerName && baseForm.locationName) {
            // Reset inputs
            handleLocal("invalidInput", "dailyAvg", "");
            handleLocal("invalidInput", "lastVisitRunTime", "");
            getRecentInspection();
        }
    }, [baseForm.customerName, baseForm.locationName]);


    useEffect(() => {
        setDailyAvg()
    }, [formData.lastVisit, formData.currentRunTime, baseForm.dateServiced]);


    useEffect(() => {
        if (formData.lastVisit) {
            const foundDate = findSheetObj(formData.lastVisit);
            if (!foundDate) return;
            handleLocal("invalidInput", "lastVisitRunTime", foundDate.currentRunTime);
        }
    }, [formData.lastVisit])

    return (
        <>
            <Box mt={1}>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <FormControl>
                        <InputLabel htmlFor="billing">
                            Billing
                        </InputLabel>
                        <Select
                            label="Billing"
                            id="billing"
                            name="billing"
                            value={formData.billing || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                            sx={{ width: '30ch', fontSize: '0.8rem' }}
                        >
                            <MenuItem value="none"><em>None</em></MenuItem>
                            <MenuItem value="atp">ATP</MenuItem>
                            <MenuItem value="retail">Retail</MenuItem>
                            <MenuItem value="storageCheck">Storage Check</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <FormControl>
                        <InputLabel htmlFor="lastVisit">
                            Last Visit
                        </InputLabel>
                        <Select
                            label="Last Visit "
                            sx={{ width: '25ch', fontSize: '0.8rem' }}
                            id="lastVisit"
                            name="lastVisit"
                            value={formData.lastVisit || ""}
                            onChange={handleLocal}
                            onBlur={(e) => handleChange(e)}>
                            <MenuItem value={0}><em>None</em></MenuItem>
                            {lastVisitData.map((data) => {
                                return <MenuItem key={data.id} value={data.id}>
                                    ID# {data.id} - {data.dateServiced}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} style={{ display: 'inline-block' }} >
                    <TextField
                        name="lastVisitRunTime"
                        id="lastVisitRunTime"
                        label="Last Run Time"
                        variant="standard"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.lastVisitRunTime || 0}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                </Box>
                <Box ml={1} mb={1} style={{ display: 'inline-block' }} >
                    <TextField
                        name="currentRunTime"
                        id="currentRunTime"
                        label="Current Run Time"
                        type="number"
                        sx={{ width: "19ch" }}
                        value={formData.currentRunTime || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                    />
                </Box>
                <Box ml={1} style={{ display: 'inline-block' }} >
                    <TextField
                        name="dailyAvg"
                        id="dailyAvg"
                        label="Daily Average"
                        variant="standard"
                        type="number"
                        sx={{ width: "16ch" }}
                        value={formData.dailyAvg || ""}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
            </Box >
        </>
    )
}

export default RunTime;