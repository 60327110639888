import { create } from "zustand"
const emptyTotals = {
    fanPrice: 0,
    fanSpeed: 0,
    totalCFM: 0,
    motorPrice: 0,
    actualCFM: 0,
    doorPrice: 0,
    actuatorPrice: 0,
    climaCellPrice: 0,
    humidifierPrice: 0,
    damperPrice: 0,
    lightBlockPrice: 0,
    damperSqFtTotal: 0,
    partsTotal: 0,
    equipmentTotal: 0,
};

const useBidTotalsStore = create((set) => ({
    totals: emptyTotals,
    actuator: 0,
    humidifier: 0,
    control: 0,
    climaCell: 0,
    fan: 0,

    setTotal: function (name, value) {
        set(prevState => {
            return { ...prevState, [name]: value };
        });
    },

    setTotalObj: function (name, value) {
        set(prevState => {
            const totals = { ...prevState.totals }
            totals[name] = value;
            return { ...prevState, totals: totals }
        });
    },

    setTotalObjFan: function (
        fanPrice, fanSpeed, totalCfm, actualCfm, cfmRequested) {
        set(prevState => {
            const totals = { ...prevState.totals };
            totals.fanTotal = fanPrice;
            totals.fanSpeed = fanSpeed;
            totals.totalCfm = totalCfm;
            totals.actualCfm = actualCfm;
            totals.cfmRequested = cfmRequested;
            return { ...prevState, totals: totals }
        })
    },

    setGlobalState: function (name, value) {
        set(prevState => {
            const globalState = { ...prevState.globalState };
            globalState[name] = value
            return { ...prevState, globalState: globalState }
        })
    },
}))

export default useBidTotalsStore;