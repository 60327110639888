import { create } from 'zustand';

const useFilterStore = create((set) => ({
    filter: {
        customer: "",
        location_name: "",
        startDate: "",
        endDate: "",
        sheetType: [],
        username: "",
    },
    filterResults: [],

    setFilter: function (name, value) {
        set(prevState => {
            const newState = { ...prevState.filter, [name]: value };
            return { ...prevState, filter: newState }
        })
    },
    
    setFilterResults: function (results) {
        set(prevState => {
            return { ...prevState, filterResults: results }
        })
    },
}))

export default useFilterStore;