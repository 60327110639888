import React, { useEffect } from "react";
import useFields from "../../../../../../Hooks/useFields";
import {
    Box, FormControl, Select, MenuItem, TextField, InputLabel,
} from "@mui/material";

function BidEvap({ handleGlobalState, edit, coolerData }) {
    const INITIAL_STATE = {
        height: '',
        width: '',
        rows: '',
        fins: 'aluminum',
        fpi: 6,
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    useEffect(() => {
        if (edit && coolerData) {
            const evapData = {
                height: coolerData.height,
                width: coolerData.width,
                rows: coolerData.rows,
                fins: coolerData.fins,
                fpi: coolerData.fpi,
            }
            handleLocal("replaceObj", evapData)
        }
    }, [])
    return (
        <>
            <Box ml={1} display="inline">
                <TextField
                    name="height"
                    id="height"
                    label="Height"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.height || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="width"
                    id="width"
                    label="Width"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.width || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="rows"
                    id="rows"
                    label="Rows"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.rows || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="fpi"
                    id="fpi"
                    label="FPI"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.fpi || 6}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline" >
                <FormControl>
                    <InputLabel>Fins</InputLabel>
                    <Select
                        inputlabelprops={{ shrink: true }}
                        id="fins"
                        name="fins"
                        label="Fins"
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        value={formData.fins || "aluminum"}
                        sx={{ input: { background: "white" }, width: "18ch" }}
                    >
                        <MenuItem value="aluminum">
                            Aluminum
                        </MenuItem>
                        <MenuItem value="copper">
                            Copper
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default BidEvap;