import React, { useEffect, useState } from "react";
import {
    useTheme, FormControl, Select, InputLabel, MenuItem,
} from "@mui/material";
import useFields from "../../Hooks/useFields";

function SelectInput({ darkTheme, label, name, options, setGlobal, edit, editData, required }) {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const [formData, handleLocal] = useFields({});
    const [colorTheme, setColorTheme] = useState({});

    useEffect(() => {
        if (darkTheme) {
            setColorTheme({
                width: "300px",
                "& .MuiInputLabel-root": {
                    color: background,
                },
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: background,
                    },
                    "&:hover fieldset": {
                        borderColor: "white",
                    },
                },
                "& .MuiSelect-icon": {
                    color: background,
                },
                "& .MuiSelect-select": {
                    color: background,
                }
            })
        } if (edit) {
            handleLocal("invalidInput", name, editData)
        }
    }, []);

    useEffect(() => {
        handleLocal("invalidInput", name, editData)
    }, [editData])

    return (<>
        <FormControl sx={colorTheme}>
            <InputLabel>{label}</InputLabel>
            <Select
                required={required}
                label={label}
                name={name}
                id={name}
                value={formData[name] || ""}
                onChange={handleLocal}
                onBlur={setGlobal}
            >
                {options.map(option => {
                    return <MenuItem
                        value={option}
                        key={option}>
                        {option}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </>)
}

export default SelectInput;