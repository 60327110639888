import React, { useEffect, useState } from "react";
import {
    Typography, useTheme, Button, CardActions, TextField, MenuItem, Box,
    FormControl, InputLabel, Select, OutlinedInput, Collapse,
} from "@mui/material";
import SearchableInput from "../Sheets/SheetComponents/SearchableInput";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useFields from "../Hooks/useFields";
import DayJS from 'dayjs'
import SheetsApi from "../api";
import useNotification from "../Hooks/useNotification";
import useFilterStore from "../stores/filterStore";
import AdvancedSettings from "./AdvancedSettings";

function SheetsFilter({ customer, users, setFilteredSheets, setRender, locationData }) {
    const [filter, setFilter, filterResults, setFilterResults] = useFilterStore(state =>
        [state.filter, state.setFilter, state.filterResults, state.setFilterResults]);
    const [username, setUsername] = useFields({})
    const [advancedSettings, setAdvancedSettings] = useState(false)
    const theme = useTheme();
    const messanger = useNotification()
    const background = theme.palette.background.main;

    const handleDate = (e, name) => {
        let dateObj = DayJS(e)
        let dateStr = dateObj.format("MM/DD/YYYY")
        if (dateStr === "Invalid Date") dateStr = ""
        setFilter(name, dateStr)
    };

    useEffect(() => {
        if (filter.username) setUsername("invalidInput", "username", filter.username)
        if (Object.keys(filterResults).length > 0) {
            setFilteredSheets(filterResults)
        }
    }, [])

    useEffect(() => {
        setFilter("username", username.username)
    }, [username])

    const handleSubmit = async function (e) {
        console.log("***************")
        e.preventDefault();
        if (filter.startDate !== "" && filter.endDate !== "") {
            const startDate = DayJS(filter.startDate, "MM/DD/YYYY", true).isValid();
            const endDate = DayJS(filter.endDate, "MM/DD/YYYY", true).isValid();
            if (!startDate && !endDate) {
                return messanger("There was an error with the dates entered. Please check.", "warning")
            }
        } else if (filter.startDate && filter.endDate === "" || filter.endDate && filter.startDate === "") {
            return messanger("Both a start date and an end date are required before filtering. Please ensure proper date formats.", "warning")
        }
        setRender(false);
        const newObj = {
            ...filter,
            customer: customer,
            username: username.username || "",
            location: locationData.id || "",
        }
        console.log(newObj)
        if (newObj.customerBypass) {
            const constraintCheck = checkConstraints(newObj)
            if (constraintCheck) return; // Cancel this function
        }
        const filteredResults = await SheetsApi.getSheetsByFilter(newObj)
        setFilterResults(filteredResults)
        setFilteredSheets(filteredResults)
    }
    const checkConstraints = function (obj) {
        console.log(obj, "**********")
        // Checks to make sure the back end will have a WHERE clause.
        if (!obj.startDate && !obj.location && !obj.partNumber && !obj.username) {
            messanger("You must add additional constraints when bypassing the customer constraint; please add a date, part, or username", "warning")
            return true;
        }
    }

    return (<form onSubmit={handleSubmit}>
        <Typography variant="h6">Sheets Filter</Typography>
        <Box m={2} display="flex" justifyContent="space-evenly" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Start Date"
                    name="startDate"
                    id="startDate"
                    inputFormat="MM/DD/YYYY"
                    value={filter.startDate || ""}
                    onChange={(e) => handleDate(e, "startDate")}
                    renderInput={(params) =>
                        <TextField
                            value={filter.startDate || ""}
                            {...params}
                            sx={{
                                width: '100%',
                                '& .MuiInputBase-root': {
                                    backgroundColor: background,
                                },
                                '& .MuiOutlinedInput-input': {
                                    backgroundColor: background,
                                },
                            }}
                            error={false}
                        />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="End Date"
                    name="endDate"
                    id="endDate"
                    inputFormat="MM/DD/YYYY"
                    value={filter.endDate || ""}
                    onChange={(e) => handleDate(e, "endDate")}
                    renderInput={(params) =>
                        <TextField
                            value={filter.endDate || ""}
                            {...params}
                            sx={{
                                width: '100%',
                                '& .MuiInputBase-root': {
                                    backgroundColor: background,
                                },
                                '& .MuiOutlinedInput-input': {
                                    backgroundColor: background,
                                },
                            }}
                            error={false}
                        />}
                />
            </LocalizationProvider>
            <FormControl sx={{ width: '100%' }} required>
                <InputLabel>Sheet Type</InputLabel>
                <Select
                    label="Sheet Type"
                    name="sheetType"
                    value={filter.sheetType || []}
                    onChange={(e) => setFilter("sheetType", e.target.value)}
                    fullWidth
                    multiple
                    input={<OutlinedInput label="Sheet Type" />}
                >
                    <MenuItem value="sheets">Service</MenuItem>
                    <MenuItem value="refrigeration">Refrigeration Start-up</MenuItem>
                    <MenuItem value="preseason">Preseason</MenuItem>
                    <MenuItem value="salesOrders">Sales Order</MenuItem>
                    <MenuItem value="chemAppAgreement">Custom Application</MenuItem>
                    <MenuItem value="disinfectantAgreement">Disinfectant Application</MenuItem>
                    <MenuItem value="sprayBarAgreement">Spray Bar</MenuItem>
                    <MenuItem value="inspections">Storage Inspection</MenuItem>
                    <MenuItem value="freshPack">Fresh Pack</MenuItem>
                    <MenuItem value="bids">Ventilation Bid</MenuItem>
                    <MenuItem value="bidRef">Refrigeration Bid</MenuItem>
                </Select>
            </FormControl>
            <Box>
                <SearchableInput
                    edit={true}
                    editData={filter.username}
                    setState={setUsername}
                    searchableArray={users}
                    label="Username"
                    variableName='username'
                />
            </Box>
        </Box>
        <AdvancedSettings advancedSettings={advancedSettings} />
        <CardActions>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Button variant="contained" color="primary" type="submit">
                    Filter
                </Button>
                <Button onClick={() => setAdvancedSettings(!advancedSettings)}>
                    Advanced Settings
                </Button>
            </Box>
        </CardActions>

    </form>)
}

export default SheetsFilter;