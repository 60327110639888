import React, { useEffect } from "react";
import {
    Box, Button, FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import useFields from "../../../../Hooks/useFields";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import FanContainer from "../BidContainers/FanContainer";
import FreqDriveContainer from "../BidContainers/FreqDriveContainer";
import MotorContainer from "../BidContainers/MotorContainer";

function AddCFMPart({ edit, fetchedBid, setRenderCheckCfm, handleFanTotals }) {
    const fetchedBidPass = fetchedBid;
    const editPass = edit;
    const [formData, handleLocal] = useFields({ selectPart: '' });
    const [addPart, fans, motors, freqDrives] = useBidStoreMain(state =>
        [state.addPart, state.fan, state.motor, state.freqDrive]);

    const addSelectedPart = function () {
        addPart(formData.selectPart);
    };

    useEffect(() => {
        addPart("fan")
        addPart("motor")
        addPart("freqDrive")
    }, [])

    return (<>
        <Box display="flex" justifyContent="flex-end" >
            <FormControl sx={{ ml: 1, mb: 1 }}>
                <InputLabel>Select Part</InputLabel>
                <Select
                    name="selectPart"
                    id="selectPart"
                    label="Select Part"
                    onChange={handleLocal}
                    value={formData.selectPart || ""}
                    sx={{ width: "22ch" }}>
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="fan">Fan</MenuItem>
                    <MenuItem value="motor">Motor</MenuItem>
                    <MenuItem value="freqDrive">Frequency Drive</MenuItem>
                </Select>
            </FormControl>
            <Button
                sx={{ ml: 1, mt: 1, mr: 4, maxHeight: 40 }}
                size="small"
                disabled={formData.selectPart === ""}
                onClick={addSelectedPart}
                variant="contained">
                Add Part
            </Button>
        </Box>
        {fans.length > 0 &&
            <FanContainer
                handleFanTotals={handleFanTotals}
                setRenderCheckCfm={setRenderCheckCfm}
                edit={editPass}
                fetchedBid={fetchedBidPass}
            />
        }
        {motors.length > 0 &&
            <MotorContainer
                edit={editPass}
                fetchedBid={fetchedBidPass}
            />
        }
        {freqDrives.length > 0 &&
            <FreqDriveContainer
                edit={editPass}
                fetchedBid={fetchedBidPass}
            />
        }
    </>)
}

export default AddCFMPart;