import Calculator from "./calculator";
import { v4 as uuid } from 'uuid';

class BidQuoteFunctions {
    static setProfitAndMargin(tables, overHead, bidBase, add) {
        // Modifies each line item in the table to reflect profit and margin
        // Returns the tables with the modified prices and the total amount of all those tables.
        // Before sending tables through this function make sure that you have the correct variables
        // set into table.data, specifically price, qty, and total.
        let total = 0;
        let tankTotal;
        let mediaTotal;
        tables.forEach(async (table) => {
            const profit = bidBase.profit;
            if (table.title === 'ClimaCell Evaporative Cooler:') {
                tankTotal = Calculator.profitAndOverHead(1, table.extraData.tankTotal, overHead, profit) || 0;
                mediaTotal = Calculator.profitAndOverHead(1, table.extraData.mediaTotal, overHead, profit) || 0;
                total += tankTotal + mediaTotal
                table.climaCellTotals = { tankTotal, mediaTotal }
            }
            table.data.forEach(async (item) => {
                item.id = uuid();
                if (item.description || item.partNumber) {
                    let itemLineTotal = 0;
                    if (table.title !== "Options:") {
                        if (!table.skipQtyCalc) {
                            itemLineTotal = Calculator.profitAndOverHead(item.qty, item.price, overHead, profit) || 0;
                        } else {
                            itemLineTotal = Calculator.profitAndOverHead(1, item.total, overHead, profit) || 0;
                        }
                    }
                    item.total = itemLineTotal;
                    total = parseFloat((total + item.total).toFixed(2));
                } else {
                    item.total = 0;
                }
            });
        })
        add(total);
        return { tables, total };
    }

    static tableToPageCalc(allTables, noVentilation, refrigeration) {
        // Based on the amount of line items, populates groups of tables into the pages2 Arr..
        // This is so the pdf doesnt page break in the middle of a table.
        const pagesLocal = [[]];
        let pageIndex = 0;
        let currentPageLines = 0;
        const maxLines = 31;
        const tableLines = 3.2;
        // each table takes up 3.2 lines of space.

        allTables.map((table, index) => {
            if (table.title === "ClimaCell Evaporative Cooler:") {
                if (!table.extraDataclimaCellQty && !table.data[0].qty) return null;
            }
            if (pageIndex === 0 && index === 0) {
                if (noVentilation && refrigeration) currentPageLines += 25;
                else if (noVentilation) currentPageLines += 9;
                else currentPageLines += 12;
            }
            let totalTableLines = table.data.length + tableLines;
            // returns nothing if FAD table is empty
            if (table.title === "ClimaSeal Fresh Air Doors:" &&
                table.data[0].qty === 0 && table.data[1].partNumber === "") return null;
            // returns nothing if 
            if (table.data.length === 1 && table.title !== 'ClimaCell Evaporative Cooler:' &&
                (table.data[0].qty === 0 || table.data[0].description === "")) return null;

            // returns nothing if there is no data. This will prevent the whole table from loading.
            if (table.extraData) {
                // add additional lines 
                totalTableLines += table.extraData.length || 0;
                if (table.title === "Ruskin Aluminum Exhaust Dampers:") totalTableLines += 2;
                if (table.title === "ClimaCell Evaporative Cooler:") totalTableLines += 3;
                if (table.title === "ClimaSeal Fresh Air Doors:") totalTableLines += 2;
            }
            // add all of the lines as a complete table
            currentPageLines += totalTableLines;
            if (currentPageLines <= maxLines) {
                return pagesLocal[pageIndex].push({ table: table, id: uuid() });
            } else if (currentPageLines >= maxLines) {
                pagesLocal.push([])
                pageIndex++
                currentPageLines = totalTableLines;
                return pagesLocal[pageIndex].push({ table: table, id: uuid() });
            } else {
                return console.error("There was an error while calculating the pages")
            }
        })
        return pagesLocal;
    }

    static async getTaxLaborMaterialsPrice(materialsTotal, laborAndMisc, overHead, bidBase, add, refrigeration) {
        //Adds other total to the global state aka Installation labor and materials total
        let otherTotal = 0;
        for (let item in laborAndMisc) {
            if (item !== 'hardwareTotal' && item !== 'customTotal') {
                otherTotal += laborAndMisc[item] || 0;
            }
        }
        const laborAndMiscWithProfit = Calculator.profitAndOverHead(1, otherTotal, overHead, bidBase.profit);
        const hardwareProfit = Calculator.profitAndOverHead(1, laborAndMisc.hardwareTotal, overHead, bidBase.profit) || 0;
        const materialsTotalWithHardware = materialsTotal + hardwareProfit;
        const taxRate = bidBase.taxRate / 100
        const taxTotal = parseFloat((materialsTotalWithHardware * taxRate).toFixed(2)) || 0
        const total = laborAndMiscWithProfit + hardwareProfit + taxTotal;
        add(total, true, refrigeration);
    }
}

export default BidQuoteFunctions;
