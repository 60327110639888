import { create } from "zustand"
const emptyLabor = {
    technician: "",
    laborId: 0,
    priceTotal: 0,
    regHours: 0,
    overTimePercent: 0,
    overTimeHours: 0,
    wageRate: 0,
    fuel: 0,
    fuelPrice: 0,
    cost: 0,
};
const emptyEquipment = {
    equipmentId: 0,
    description: "",
};
const emptyContractor = {
    contractorId: 0,
    description: "",
};
const emptyOther = {
    otherId: 0,
    description: "",
};
const emptyTotals = {
    otherTotal: 0,
    contractorTotal: 0,
    equipmentTotal: 0,
    laborTotal: 0,
    hardwareTotal: 0,
    customTotal: 0,
};
const emptyOption = {
    optionId: 0,
    description: "",
    total: 0,
};
const emptyHardware = {
    hardwareId: 0,
    qty: 0,
    partNumber: "",
    description: "",
    cost: 0,
};
const emptyObjects = {
    labor: emptyLabor,
    equipment: emptyEquipment,
    contractor: emptyContractor,
    other: emptyOther,
    option: emptyOption,
    hardware: emptyHardware,
};
const emptyCustom = {
    customId: 0,
    qty: 0,
    partNumber: "",
    description: "",
    cost: 0,
};

const useBidStoreMisc = create((set) => ({
    labor: [emptyLabor],
    equipment: [emptyEquipment],
    contractor: [emptyContractor],
    other: [emptyOther],
    totals: emptyTotals,
    refrigerationTotals: emptyTotals,
    option: [emptyOption],
    hardware: [emptyHardware],
    custom: [emptyCustom],

    resetState: function () {
        set(prevState => {
            return {
                ...prevState,
                totals: emptyTotals,
                labor: [emptyLabor],
                equipment: [emptyEquipment],
                contractor: [emptyContractor],
                other: [emptyOther],
                option: [emptyOption],
                hardware: [emptyHardware],
                custom: [emptyCustom],
            }
        })
    },

    setTotal: function (name, value, refrigeration) {
        set(prevState => {
            const totals = { ...prevState.totals }
            const refrigerationTotals = { ...prevState.refrigerationTotals }
            if (refrigeration) {
                refrigerationTotals[name] = value;
            } else {
                totals[name] = value;
            }
            return { ...prevState, totals, refrigerationTotals }
        });
    },

    setObj: function (category, obj, id) {
        set(prevState => {
            const objects = [...prevState[category]];
            objects[id] = { ...objects[id], ...obj };
            return { ...prevState, [category]: objects };
        })
    },

    setPart: function (category, name, value, id) {
        set(prevState => {
            const array = [...prevState[category]];
            array[id] = { ...array[id], [name]: value };
            return { ...prevState, [category]: array }
        })
    },

    addPart: function (category, maxParts) {
        set(prevState => {
            const parts = [...prevState[category]]
            const emptyNewPart = emptyObjects[category]
            if (parts.length < maxParts) {
                const newPart = { ...emptyNewPart, [category + "Id"]: parts.length }
                parts.push(newPart)
            }
            return { ...prevState, [category]: parts }
        })
    },
}))

export default useBidStoreMisc;