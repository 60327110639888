import React, { useContext, useEffect, useState, useRef } from "react";
import BaseSheet from "../SheetComponents/BaseSheet";
import TechSheet from "../SheetComponents/TechSheet";
import VisualInspection from "./VisualInspection";
import BaySheet from "./BaySheet";
import {
    Box, Grid, FormControlLabel, Checkbox,
    TextareaAutosize, Card, CardContent,
} from "@mui/material/"
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import useBayStore from "../../stores/bayStore";
import useImageStore from "../../stores/imageStore";
import SheetsApi from "../../api";
import { useHistory, useParams } from "react-router-dom"
import UserContext from "../../Users/UserContext";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import useFields from "../../Hooks/useFields";
import InspectionEtc from "./InspectionEtc";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage"
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import StageSelector from "../SheetComponents/StageSelector";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import useSettingsStore from "../../stores/settingsStore";
import useNotification from "../../Hooks/useNotification";
import RunTime from "./RunTime";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function StorageInspectionEdit() {
    const { currentUser } = useContext(UserContext)
    const { id } = useParams()
    const componentRef = useRef();
    const history = useHistory();
    const messanger = useNotification();
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home]);
    const [renderCheckTech, setRenderCheckTech] = useState(false);
    const [logs, setLogs] = useState([]);
    const [render, setRender] = useState(false);
    const [renderVinspection, setRenderVinspection] = useState(false);
    const [renderCheckBay, setRenderCheckBay] = useState(false);
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages]);
    const [bays, visualInspectionForm, setBayFormObject,
        clearInspectionForm, setVisualInspectionFormObj,
        setInspectionEtcObj, inspectionEtc] = useBayStore(state =>
            [state.bays, state.visualInspectionForm, state.setBayFormObject,
            state.clearInspectionForm, state.setVisualInspectionFormObj,
            state.setInspectionEtcObj, state.inspectionEtc])
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj])
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj])
    const INITIAL_STATE = {
        followUp: false,
        visualInspectionNotes: "",
    }
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const mergedInspectionForm = { ...formData, ...visualInspectionForm, ...inspectionEtc }
        const inspectionObj = CreateSubmitObj(
            mergedInspectionForm, currentUser, messanger,
            baseSheet, [], techSheet)
        inspectionObj.sheet.sheetType = "inspections"
        inspectionObj.id = id
        inspectionObj.bays = bays;
        console.log(inspectionObj)
        HandleImage.addAndRemove(images, inspectionObj)
        await SheetsApi.editInspectionSheet(inspectionObj).then((response) => {
            getNotifications(currentUser)
            messanger(response.message, response.error ? "error" : "success")
        })
        setRenderCheckBay(false)
        setRenderCheckTech(false)
        setRenderVinspection(false)
        clearBaseForm()
        clearTechForm()
        clearInspectionForm()
        resetFormData()
        clearImages()
        history.push(home);
        return setIsSubmitting(false);
    }
    useEffect(() => {
        clearBaseForm()
        clearTechForm()
        clearInspectionForm()
        clearImages()
        async function getInspectionSheet() {
            try {
                const inspectionSheet = await SheetsApi.getInspectionSheet({ id })
                const images = inspectionSheet.images
                const techs = await SheetsApi.getTechsById({ id, type: "inspectionId" })
                const bays = await SheetsApi.getBays({ id })
                const locationId = inspectionSheet.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                inspectionSheet.location = locationName.location_name
                inspectionSheet.locationId = locationId
                setLogs(inspectionSheet.logs)
                return { inspectionSheet, techs, bays, images }
            } catch (err) {
                console.log(err)
            }
        }
        getInspectionSheet().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'inspectionSheet') {
                    const split = sheet[section]
                    console.log(split)
                    const baseFormObj = {
                        company: split.company,
                        creator: split.creator,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                        po: split.po,
                        requestedBy: split.requestBy,
                        stage: split.stage,
                        dateCreated: split.dateCreated,
                        locationId: split.locationId,
                        dept: split.dept,
                    }
                    const inspectionEtc = {
                        plenumSetPoint: split.plenumSetPoint,
                        systemMode: split.systemMode,
                        vfdFan: split.vfdFan,
                        osaControl: split.osaControl,
                        osaTemp: split.osaTemp,
                        osaHumidity: split.osaHumidity,
                        ductLiquid: split.ductLiquid,
                        odors: split.odors,
                        bugs: split.bugs,
                        billing: split.billing,
                        lastVisit: split.lastVisit,
                        dailyAvg: split.dailyAvg,
                        currentRunTime: split.currentRunTime,
                        co2: split.co2,
                    }
                    const visualInspectionForm = {
                        doorsLouvers: split.doorsLouvers,
                        fans: split.fans,
                        climaCell: split.climaCell,
                        humidifiers: split.humidifiers,
                        controlPanel: split.controlPanel,
                        plenumDucts: split.plenumDucts,
                        refrigeration: split.refrigeration,
                    }
                    const baseInspectionForm = {
                        followUp: split.followUp,
                        stage: split.stage,
                        visualInspectionNotes: split.visualInspectionNotes,
                        followUpDept: split.followUpDept,
                    }
                    handleLocal("replaceObj", baseInspectionForm)
                    setInspectionEtcObj(inspectionEtc)
                    setBaseFormObj(baseFormObj)
                    setVisualInspectionFormObj(visualInspectionForm)
                    setRenderVinspection(true)
                } else if (section === 'bays') {
                    setBayFormObject(sheet[section])
                    setRenderCheckBay(true)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes,
                            timeStart: obj.timestart,
                            timeEnd: obj.timeend,
                        }
                        setTechObj(techObj, id)
                    }
                    setRenderCheckTech(true)
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        if (img.imageType === 'img' || img.imageType === 'receipt') {
                            addImage(imageObj)
                        }
                    }
                }
            }
            setRender(true)
        })
    }, []);
    return (<> {render && <>
        <Box ref={componentRef} className="page">
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BaseSheet id={id} title="Inspection" />
                        {renderVinspection && <>
                            <RunTime edit={true} />
                            <InspectionEtc edit={true} />
                        </>}
                        {renderCheckBay && <BaySheet edit={true} />}
                        {renderVinspection && <>
                            <Box display={currentUser.mobile ? "" : "flex"}>
                                <VisualInspection name={'Doors & Louvers'} vId={"doorsLouvers"} edit={true} />
                                <VisualInspection name={'Fans'} vId={"fans"} edit={true} />
                                <VisualInspection name={'ClimaCell'} vId={"climaCell"} edit={true} />
                                <VisualInspection name={'Humidifiers'} vId={"humidifiers"} edit={true} />
                                <VisualInspection name={'Control Panel'} vId={"controlPanel"} edit={true} />
                                <VisualInspection name={'Plenum & Ducts'} vId={"plenumDucts"} edit={true} />
                                <VisualInspection name={'Refrigeration'} vId={"refrigeration"} edit={true} />
                            </Box>
                            <Box>
                                <TextareaAutosize
                                    minRows={4}
                                    style={{
                                        width: "100%",
                                        outline: '2px solid #000',
                                        fontSize: "16px",
                                    }}
                                    aria-label="maximum height"
                                    name={`visualInspectionNotes`}
                                    value={formData.visualInspectionNotes || ""}
                                    onChange={handleLocal}
                                />
                            </Box>
                            {renderCheckTech && <TechSheet
                                toggle={true}
                                edit={false}
                                timeTrack={true}
                            />}
                        </>}
                        <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                            <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                <CardContent>
                                    <Box display="inline">
                                        <FollowUp
                                            state={formData}
                                            setState={handleLocal}
                                        />
                                    </Box>
                                    <Box mt={1} display="flex" justifyContent="left" className="hideForPrint" >
                                        <Box style={{ maxWidth: '400px' }}>
                                            <StageSelector
                                                currentUser={currentUser}
                                                handleChange={handleLocal}
                                                value={formData.stage}
                                            />
                                            <DeptSelect />
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                componentRef={componentRef}
                                                toggle={false}
                                            />
                                        </Box>
                                        {!currentUser.mobile ? <ImageDropZone sheetType="inspections" /> : null}
                                    </Box>
                                    {currentUser.mobile ? <ImageDropZone sheetType="inspections" /> : null}
                                </CardContent>
                            </Card>
                            {logs.length > 0 &&
                                <HistoryCard logs={logs} />
                            }
                        </Box>
                    </Grid>
                </Grid >
            </form >
        </Box>
    </>} </>)
}

export default StorageInspectionEdit;