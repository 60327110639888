import { create } from "zustand"

const techObj = {
    technician: "",
    truck: "",
    labor: null,
    travel: null,
    nc: null,
    notes: "",
    timeStart: "",
    timeEnd: "",
}
const useTechStore = create((set) => ({
    techs: [techObj],

    setTech: function (name, value, id) {
        set(prevState => {
            if (value instanceof Array) {
                value = value[0]
            }
            const techs = [...prevState.techs];
            techs[id] = { ...techs[id], [name]: value };
            return { ...prevState, techs };
        });
    },

    clearTech: function (id) {
        set(prevState => {
            let techs = [...prevState.techs]
            techs[id] = techObj
            return { ...prevState, techs }
        })
    },

    clearTechForm: function () {
        set(prevState => ({
            ...prevState,
            techs: [techObj],
        }))
    },

    addTech: function () {
        set(prevState => {
            let techs = [...prevState.techs]
            techs.push(techObj)
            return { ...prevState, techs }
        })
    },

    setTechObj: function (obj, id) {
        set(prevState => {
            let techs = [...prevState.techs]
            techs[id] = obj
            return { ...prevState, techs }
        })
    }
}))

export default useTechStore

