import React, { useEffect } from 'react';
import useFields from '../../../Hooks/useFields';
import useChemAppStore from '../../../stores/chemAppStore';
import {
    Box, FormControlLabel, Checkbox,
    TextField, Typography,
} from "@mui/material/"

function Sprouting() {
    const INITIAL_STATE = {
        none: false,
        eyesOpen: false,
        peeping: false,
        lessThanHalfIn: false,
        greaterThanHalfIn: false,
        sproutingOther: false,
        sproutingOtherText: "",
    }
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))
    const [formData, handleLocal] = useFields(INITIAL_STATE)

    useEffect(() => {
        const sproutingObj = {
            none: chemAppForm.none,
            eyesOpen: chemAppForm.eyesOpen,
            peeping: chemAppForm.peeping,
            lessThanHalfIn: chemAppForm.lessThanHalfIn,
            greaterThanHalfIn: chemAppForm.greaterThanHalfIn,
            sproutingOther: chemAppForm.sproutingOther,
            sproutingOtherText: chemAppForm.sproutingOtherText,
        }
        handleLocal("replaceObj", sproutingObj)
    }, [])
    const handleChange = function (e, checkbox) {
        let value;
        const name = e.target.name
        if (checkbox) {
            value = e.target.checked;
        } else value = e.target.value;
        setChemAppForm(name, value)
    }
    return (
        <>
            <Typography
                variant="h5">
                Sprouting:
            </Typography>
            <Box >
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="None"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="none"
                            checked={formData.none || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="Eyes Open"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="eyesOpen"
                            checked={formData.eyesOpen || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="Peeping"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="peeping"
                            checked={formData.peeping || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Less than 1/2"'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="lessThanHalfIn"
                            checked={formData.lessThanHalfIn || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Greater than 1/2"'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="greaterThanHalfIn"
                            checked={formData.greaterThanHalfIn || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Other'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="sproutingOther"
                            checked={formData.sproutingOther || false}
                        />}
                    />
                </Box>
                <TextField
                    name="sproutingOtherText"
                    id="sproutingOtherText"
                    label="Sprouting Notes"
                    value={formData.sproutingOtherText || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    fullWidth
                />
            </Box>
        </>
    )
}

export default Sprouting;