import React, { useCallback, useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone'
import { createThumbnail } from './imageUtilities/createThumbnail';
import useImageStore from '../../../stores/imageStore';
import Compressor from 'compressorjs';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DownloadIcon from '@mui/icons-material/Download';
import useNotification from "../../../Hooks/useNotification";
import { v4 as uuid } from 'uuid';
import UserContext from '../../../Users/UserContext';
import {
    Card, CardContent, Typography, Box, Tooltip, IconButton,
    ImageList, ImageListItem, ImageListItemBar,
} from "@mui/material"
import { useTheme } from '@mui/material/styles';
import ImagePopUp from './imageUtilities/ImagePopUp';
import ReceiptPopUp from '../../../Receipts/ReceiptPopUp';
import useBaseStore from '../../../stores/baseStore';

function ImageDropZone({ noReceipt, sheetType }) {
    const theme = useTheme()
    const { currentUser } = useContext(UserContext)
    const background = theme.palette.background.main
    const messanger = useNotification();
    const [refresh, setRefresh] = useState(false);
    const [baseForm] = useBaseStore(state => [state.baseForm]);
    const [images, addImage] = useImageStore(state =>
        [state.images, state.addImage]);
    const [open, setOpen] = useState(false);
    const [receipt, setReceipt] = useState(false);
    const [lastUpload, setLastUpload] = useState({});
    const onDrop = useCallback(async (acceptedFiles) => {
        acceptedFiles.map(async (file) => {
            // create thumbnail
            const thumbnailURL = await createThumbnail(file);
            // compress file
            return new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const dataURL = URL.createObjectURL(result);
                    const fileName = uuid();
                    const imageObj = {
                        imageType: "img",
                        sheetType: sheetType,
                        name: fileName,
                        thumbnail: thumbnailURL,
                        downloadURL: dataURL,
                        file: result,
                        receiptData: {
                            receiptDept: currentUser.dept,
                            customer: baseForm.customerName || "",
                            selectedUser: currentUser,
                            stage: 'open',
                        }
                    };
                    // add to global state
                    addImage(imageObj);
                    setLastUpload(imageObj);
                    setOpen(true);
                },
                error(err) {
                    messanger(err.message, "error");
                    console.log(err.message);
                },
            });
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
    });

    const removeImage = function (index) {
        images.splice(index, 1)
        setRefresh(!refresh)
    }

    const handleReceiptEdit = function (imgObj) {
        setLastUpload({ ...imgObj, edit: true })
        setReceipt(true)
    }

    return (
        <Box mb={5} ml={1} mr={1} className="hideForPrint">
            {!noReceipt &&
                <ImagePopUp open={open} setOpen={setOpen} setReceipt={setReceipt} />
            }
            {lastUpload.receiptData &&
                <ReceiptPopUp open={receipt} setOpen={setReceipt} receipt={lastUpload} sheet={true} />
            }
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <ImageList cols={3}>
                    <Card
                        {...getRootProps()}
                        sx={{
                            bgcolor: background,
                            textAlign: "center",
                            width: 100,
                            height: 100,
                            cursor: 'pointer' // Add cursor pointer for better UX
                        }}
                    >
                        <CardContent>
                            <input {...getInputProps()} type='file' name='img' multiple />
                            <AddAPhotoIcon sx={{ fontSize: 48 }} />
                            <Typography>Upload</Typography>
                        </CardContent>
                    </Card>
                    {images.length > 0 &&
                        images.map((imgObj, index) => (
                            <Box
                                key={index}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: imgObj.imageType === 'receipt' ? 'rgba(0, 0, 0, 0.1)' : 'inherit',
                                    },
                                }}
                            >
                                <ImageListItem>
                                    <img
                                        src={imgObj.thumbnail}
                                        alt={`img${index}`}
                                        loading="lazy"
                                        onClick={imgObj.imageType === 'receipt' ? (e) => { e.stopPropagation(); handleReceiptEdit(imgObj); } : null}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            cursor: imgObj.imageType === 'receipt' ? 'pointer' : 'default',
                                        }}
                                    />
                                    <ImageListItemBar
                                        position="below"
                                        actionIcon={
                                            <Box>
                                                <Tooltip title="Download">
                                                    <IconButton
                                                        color="default"
                                                        href={imgObj.downloadURL}
                                                        download={`image_${index}.png`}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Remove">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            removeImage(index);
                                                        }}
                                                    >
                                                        <RemoveCircleOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                    />
                                </ImageListItem>
                            </Box>
                        ))}

                </ImageList>
            </Box >
        </Box >
    );
}

export default ImageDropZone;