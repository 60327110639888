import React, { useState } from "react";
import {
    Box, Badge, Tooltip, IconButton, Menu, Divider,
} from "@mui/material"
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material/styles';
import Followup from "./Followup";

function NotifyBar({ notifications }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme()
    const background = theme.palette.background.main
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFullClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {notifications.length > 0 && <>
                <Box mt={1}>
                    <Tooltip title="Notifications">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Badge badgeContent={notifications.length} color="primary">
                                <NotificationsIcon color="background" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="notification-menu"
                    open={open}
                    onClose={handleFullClose}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            maxHeight: '380px',
                            overflowY: 'auto',
                        },
                        sx: {
                            background: background,
                            width: 200,
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {notifications.map((notification, index) => [
                        <Followup
                            indexId={index}
                            key={notification.keyId}
                            notification={notification}
                            handleFullClose={handleFullClose}
                        />,
                        notification.length > 1 && <Divider key={`divider-${notification.keyId}`} />
                    ])}
                </Menu>
            </>}
        </>
    )
}

export default NotifyBar;