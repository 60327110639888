import React, { useEffect, useState } from "react";
import {
    TextField, Box, Typography, FormControlLabel, Checkbox,
    FormControl, Select, InputLabel, MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useFreshPackStore from "../../stores/freshPackStore";
import useBaseStore from "../../stores/baseStore";
import DayJS from 'dayjs';
import SheetsApi from "../../api";

function FreshPackVisit() {
    const [freshPackForm, setFreshPackFrom] = useFreshPackStore(state =>
        [state.freshPackForm, state.setFreshPackFrom])
    const [baseForm] = useBaseStore(state => [state.baseForm]);
    const [lastVisitData, setLastVisitData] = useState([]);

    const handleDate = function (e, name) {
        if (!e) return
        let dateObj = DayJS(e)
        let dateStr = dateObj.format("MM-DD-YYYY")
        setFreshPackFrom(name, dateStr)
    }

    useEffect(() => {
        const getRecentSheets = async function () {
            const serviceDate = baseForm.dateServiced || DayJS().format('MM/DD/YYYY');
            return await SheetsApi.getRecentFreshPack({
                customer: baseForm.customerName,
                locationId: baseForm.locationId,
                serviceDate,
            });
        }
        getRecentSheets().then((response) => {
            setLastVisitData(response)
        })

    }, [baseForm.customerName, baseForm.locationId])

    return (<>
        <Box mt={1}>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <TextField
                    name='visitReason'
                    label="Reason for Visit"
                    value={freshPackForm.visitReason || ""}
                    onChange={(e) => setFreshPackFrom(e.target.name, e.target.value)}
                    sx={{ width: '375px' }}
                />
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <TextField
                    name="currPpm"
                    label="Current PPM on Sensor"
                    type="number"
                    value={freshPackForm.currPpm || ""}
                    onChange={(e) => setFreshPackFrom(e.target.name, e.target.value)}
                    sx={{ width: '160px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <FormControl>
                    <InputLabel htmlFor="lastVisit">
                        Last Visit Date
                    </InputLabel>
                    <Select
                        label="Last Visit Date"
                        sx={{ width: '25ch' }}
                        id="lastVisit"
                        name="lastVisit"
                        value={freshPackForm.lastVisit || ""}
                        onChange={(e) => setFreshPackFrom(e.target.name, e.target.value)}
                    >
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {lastVisitData.map((data) => {
                            return <MenuItem key={data.id} value={data.id}>
                                ID# {data.id} - {data.dateServiced}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Last Date Membrane was changed"
                        name="membrane"
                        inputFormat="MM/DD/YYYY"
                        value={freshPackForm.membrane || ""}
                        onChange={(e) => handleDate(e, "membrane")}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{ width: '225px' }}
                                error={false}
                                InputLabelProps={{
                                    shrink: true,
                                }} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                <Typography>
                    <FormControlLabel
                        label="Checked Nozzles"
                        control={<Checkbox
                            name="nozzles"
                            checked={freshPackForm.nozzles || false}
                            onChange={(e) => setFreshPackFrom(e.target.name, e.target.checked)}
                        />}
                    />
                </Typography>
            </Box>
        </Box>
    </>)
}

export default FreshPackVisit;