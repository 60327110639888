import React, { useEffect } from "react";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import { TextField, Box, InputAdornment, } from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import Commas from "../../BidFunctions/commas";
function MiscLine({ category, id, handleMiscCalc, edit, miscData }) {
    const [formData, handleLocal, resetFormData] = useFields({});
    const [parts, setPart, setObj] = useBidStoreMisc(state =>
        [state[category], state.setPart, state.setObj]);
    const localPart = parts[id];

    useEffect(() => {
        const qty = parseFloat(formData.qty) || 0;
        const cost = parseFloat(formData.cost) || 0;
        const fixedCost = parseFloat(formData.fixedCost) || 0;
        const total = parseFloat(((cost * qty) + fixedCost).toFixed(2))
        handleLocal("invalidInput", "total", total)
        setPart(category, "priceTotal", total, id)
    }, [formData.qty, formData.cost, formData.fixedCost])

    useEffect(() => {
        handleMiscCalc(formData.refrigeration)
    }, [localPart])

    useEffect(() => {
        resetFormData()
        if (edit && miscData[id]) {
            handleLocal("replaceObj", miscData[id])
            setObj(category, miscData[id], id)
        }
    }, [])

    const handleGlobalState = function (e) {
        let value;
        const name = e.target.name;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setPart(category, name, value, id)
    }

    return (
        <>
            <Box m={1}>
                <Box display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        sx={{ width: "59ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="unitOfMeasure"
                        id="unitOfMeasure"
                        label="Unit of Measure"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "14ch" }}
                        value={formData.unitOfMeasure || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="cost"
                        id="cost"
                        label="Cost"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "13ch" }}
                        value={formData.cost || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="fixedCost"
                        id="fixedCost"
                        label="Fixed Cost"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "13ch" }}
                        value={formData.fixedCost || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "13ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default MiscLine;