import { create } from "zustand"
// Global state for doors, lightblock, and dampers
const emptyDoor = {
    doorId: 0,
    qty: 0,
    doorType: "",
    hingeType: "",
    width: 0,
    height: 0,
    sqFtPerDoor: 0,
    totalSqFtDoor: 0,
};
const emptyDamper = {
    damperId: 0,
    priceTotal: 0,
    partNumber: "",
};
const emptyLightBlock = {
    qty: 0,
    lightBlockId: 0,
    priceTotal: 0,
    totalSqFt: 0,
};
const emptyVariance = {
    doors: 0,
    climaCell: 0,
    dampers: 0,
    lightBlock: 0
};
const emptyTotals = {
    doorsTotal: 0,
    dampersTotal: 0,
    lightBlockTotal: 0,
    damperSqFtTotal: 0,
};

const useBidStoreSub = create((set) => ({
    door: [emptyDoor],
    damper: [emptyDamper],
    lightBlock: [emptyLightBlock],
    variance: [emptyVariance],
    totals: emptyTotals,

    resetState: function () {
        set(prevState => {
            return {
                ...prevState,
                door: [emptyDoor],
                damper: [emptyDamper],
                lightBlock: [emptyLightBlock],
            }
        })
    },
    setTotal: function (name, value) {
        set(prevState => {
            const totals = { ...prevState.totals }
            totals[name] = value;
            return { ...prevState, totals: totals }
        });
    },
    setObj: function (category, obj, id) {
        set(prevState => {
            const objects = [...prevState[category]];
            objects[id] = { ...objects[id], ...obj };
            return { ...prevState, [category]: objects };
        })
    },
    setPart: function (category, name, value, id) {
        set(prevState => {
            const array = [...prevState[category]];
            array[id] = { ...array[id], [name]: value };
            return { ...prevState, [category]: array }
        })
    },
    addPart: function (category, maxParts) {
        set(prevState => {
            const category2 = category.charAt(0).toUpperCase() + category.slice(1);
            const parts = [...prevState[category]]
            if (parts.length < maxParts) {
                const newPart = { ...["empty" + category2], [category + "Id"]: parts.length }
                parts.push(newPart)
            }
            return { ...prevState, [category]: parts }
        })
    },
}))

export default useBidStoreSub;