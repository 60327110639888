import React, { useState, useEffect, useContext } from "react";
import SheetsApi from "../api"
import {
    Box, Card, Button, TextField, Grid
} from '@mui/material';
import useFields from "../Hooks/useFields"
import CompanyCard from "./CompanyCard";
import { useTheme } from '@mui/material/styles';
import { Masonry } from "@mui/lab";
import UserContext from "../Users/UserContext";
import useNotification from "../Hooks/useNotification";

function Companies() {
    const notification = useNotification()
    const [companies, setCompanies] = useState([])
    const [formData, handleChange, resetFormData] = useFields({
        company: ""
    })
    const [searchData, handleSearch, resetSearchData] = useFields({
        search: ""
    })
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const { currentUser } = useContext(UserContext)
    useEffect(() => {
        async function getAllCompanies() {
            try {
                let companies;
                if (currentUser.account_level === 'Admin') {
                    companies = await SheetsApi.getAllCompanies()
                    return companies.companies
                } else {
                    companies = await SheetsApi.getCompaniesByFilter({ search: currentUser.company });
                    return companies
                }
            } catch (err) {
                console.log(err)
                notification("There was an error getting the available companies. Please contact an admin", "error")
            }
        }
        getAllCompanies().then(companies => {
            setCompanies(companies)
            window.scrollTo(0, 0)
        })
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            let result = await SheetsApi.addCompany(formData)
            setCompanies([...companies, { name: result.company.name }])
            notification(`You have successfully added the company: ${result.company.name}`, "success")
            resetFormData()
        } catch (err) {
            console.log(err)
            notification("There was an error when adding the company. Please contact an admin.", "error")
        }
    }

    async function search(e) {
        e.preventDefault()
        let res = await SheetsApi.getCompaniesByFilter(searchData);
        setCompanies(res);
    }

    return (
        <div>
            {currentUser.company === 'Admin' &&
                <Grid m={4} justifyContent="center">
                    <form onSubmit={search}>
                        <TextField
                            name="search"
                            variant="outlined"
                            autoFocus
                            fullWidth
                            id="search"
                            label="Search for a Company"
                            value={searchData.search}
                            onChange={handleSearch}
                        />
                        <Box textAlign="center">
                            <Button type="submit">Search</Button>
                        </Box>
                    </form>
                </Grid>
            }
            <Card sx={{ bgcolor: defaultColor }} >
                <Grid m={7} >
                    <Masonry columns={3}>
                        {companies ? companies.map((company) => (
                            <CompanyCard key={company.name} company={company} />
                        )) : null}
                    </Masonry>
                </Grid>
            </Card>
            {currentUser.account_level === 'Admin' &&
                <Grid m={4} justifyContent="center">
                    <h3>Add a new Company</h3>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="company"
                            variant="outlined"
                            required
                            fullWidth
                            id="company"
                            label="Company Name"
                            value={formData.company}
                            onChange={handleChange}
                        />
                        <Box textAlign="center">
                            <Button type="submit">Submit</Button>
                        </Box>
                    </form>
                </Grid>
            }
        </div >
    )
}

export default Companies
