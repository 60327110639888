import React, { useState, useContext } from 'react';
import UserContext from './UserContext';
import { Redirect } from 'react-router-dom';
import { TextField, Box, Grid, Button, Typography } from "@mui/material";
import useFields from "../Hooks/useFields"
import SheetsApi from "../api"
import useNotification from "../Hooks/useNotification";
function LoginForm({ login }) {

    const INITIAL_STATE = {
        username: "",
        password: "",
        password2: "",
    }
    const messanger = useNotification();
    const [formData, handleChange] = useFields(INITIAL_STATE)
    const { currentUser } = useContext(UserContext)
    const [renderCheck, setRenderCheck] = useState()
    const [inputEnabled, setInputEnabled] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const user = await SheetsApi.getCurrentUser(formData.username)
            if (user.password_reset === true) {
                //password reset required - clear pw and disable username field.
                setInputEnabled(true)
                handleChange('invalidInput', 'password', "")
                if (renderCheck === true) {
                    //on second login attempt change password
                    if (formData.password === formData.password2) {
                        await SheetsApi.passwordReset(formData)
                        setInputEnabled(false)
                        messanger("Your password has been successfully reset", "success")
                        setRenderCheck(false)
                        return await login(formData)
                    } else {
                        handleChange('invalidInput', 'password2', "")
                        return messanger("The passwords do not match. Please try again.", "error");
                    }
                }
                messanger('Password reset is required before logging in.', "warning");
                return setRenderCheck(true)
            } else {
                return await login(formData)
            }
        } catch (err) {
            console.log(err)
            messanger(err[0].response.data.error.message, "error")
        }
    }

    function loggedIn() {
        return <Redirect to="/" />
    }

    function loggedOut() {
        return (
            <>
                <Box mt={5} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h4" >Login</Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '7vh' }}>
                        <Grid sx={{ mt: 1 }} >
                            <TextField
                                disabled={inputEnabled}
                                name="username"
                                autoComplete='username'
                                variant="outlined"
                                required
                                id="username"
                                label="Username"
                                autoFocus
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sx={{ mt: 1 }} >
                            <TextField
                                variant="outlined"
                                required
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        {renderCheck &&
                            <Grid sx={{ mt: 1 }} >
                                <TextField
                                    variant="outlined"
                                    required
                                    name="password2"
                                    label="Verify Password"
                                    type="password"
                                    id="password2"
                                    autoComplete="current-password"
                                    value={formData.password2}
                                    onChange={handleChange} />
                            </Grid>
                        }
                        <Button
                            sx={{ mt: 1 }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Login
                        </Button>
                    </Grid>
                </form>
            </>
        )
    }
    return (
        <div>
            {currentUser ? loggedIn() : loggedOut()}
        </div>
    )
}

export default LoginForm;