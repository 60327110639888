import React, { useEffect, useState, useContext } from "react";
import SheetsApi from "../../../api";
import UserContext from "../../../Users/UserContext";
import useFields from "../../../Hooks/useFields";
import {
    Autocomplete, Box, TextField, Typography, Divider,
} from "@mui/material";
import { Masonry } from "@mui/lab";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DayJS from 'dayjs';
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import CustomSelector from "../../SheetComponents/CustomSelector";
import useNotification from "../../../Hooks/useNotification";

function BidBase({ edit, data, id, customer }) {
    const editPass = edit;
    const notification = useNotification()
    const [customers, setCustomers] = useState([])
    const [formData, handleLocal] = useFields({ customer: "" })
    const [renderCheck, setRenderCheck] = useState(false)
    const { currentUser } = useContext(UserContext)
    const [bidBase, setBidBase] = useBidStoreMain(state =>
        [state.bidBase, state.setBidBase])

    let logo;
    if (process.env.NODE_ENV === "production") {
        logo = "https://sheets5-f.azurewebsites.net/logo.png"
    } else {
        logo = process.env.PUBLIC_URL + '/logo.png'
    }

    useEffect(() => {
        async function getCustomers() {
            try {
                const companyName = currentUser.company
                const customers = await SheetsApi.getAllCustomers({ companyName })
                return customers
            } catch (err) {
                notification(err, "error")
            }
        }
        getCustomers().then(customers => {
            setCustomers(customers)
        })
        if (customer) handleLocal("invalidInput", "customer", customer)
        if (edit) handleLocal("replaceObj", data)
        setRenderCheck(true)
    }, [])

    const isValid = function (e) {
        const name = e.target.name.trim()
        const value = e.target.value.trim()
        if (name === "customer" && !customers.find(
            o => o.customer.trim() == value)) {
            handleLocal("invalidInput", name, "")
            return setBidBase(name, "");
        } else {
            const name = e.target.name
            const value = e.target.value
            handleLocal("invalidInput", name, value)
            return setBidBase(name, value)
        }
    }
    const handleChange = (e, passedName) => {
        if (!e) {
            return
        } if (e.$L) {
            let dateObj = DayJS(e)
            let dateStr = dateObj.format("YYYY-MM-DD")
            return setBidBase(passedName, dateStr)
        } else {
            const name = e.target.name || passedName
            const value = e.target.value
            return setBidBase(name, value)
        }
    }

    return (
        <>
            {renderCheck && <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box mb={2}>
                        <img
                            src={logo} style={{ width: '200px', height: '125px' }}
                            alt="logo" />
                    </Box>
                    <Box mb={5} sx={{ display: 'flex-end', alignItems: 'center', flexGrow: 5 }}>
                        <Box ml={5}>
                            <Typography
                                sx={{ typography: { xs: 'h6', sm: 'h5' } }}>
                                Bids
                            </Typography>
                        </Box>
                        <Box mr={2} sx={{ display: 'flex-end', alignItems: 'right' }}>
                            <Typography sx={{ fontWeight: 'bold' }} align="right">
                                {id}
                            </Typography>
                        </Box>
                        <Divider sx={{
                            marginRight: '10px',
                            flexGrow: 1,
                            borderTop: '2px solid',
                            borderColor: 'black',
                            fontWeight: 'bold'
                        }} />
                    </Box>
                </Box>
                <Masonry columns={3} >
                    <Autocomplete
                        disableClearable
                        inputValue={formData.customer || ""}
                        onInputChange={(e) => handleLocal(e, "customer")}
                        onBlur={(e) => isValid(e)}
                        name="customer"
                        id="customer"
                        options={customers ? customers.map((customer) => customer.customer) : null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="customer"
                                variant="outlined"
                                required
                                fullWidth
                                id="customer2"
                                label="Customer"
                            />)}
                    />
                    <TextField
                        name="primaryTech"
                        label={`Created on ${bidBase.dateCreated || DayJS().format("YYYY-MM-DD")} By:`}
                        value={bidBase.creator || currentUser.username}
                        disabled
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Service Date"
                            name="dateServiced"
                            inputFormat="MM/DD/YYYY"
                            value={bidBase.dateServiced || DayJS().format("MM-DD-YYYY")}
                            onChange={(e) => handleChange(e, "dateServiced")}
                            renderInput={(params) =>
                                <TextField
                                    required
                                    {...params}
                                    error={false} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        name="jobName"
                        id="jobName"
                        label="Job Name"
                        value={formData.jobName || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                    />
                    <TextField
                        name="contact"
                        id="contact"
                        label="Contact"
                        value={formData.contact || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                    />
                    <TextField
                        name="phone"
                        id="phone"
                        label="Phone"
                        value={formData.phone || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                    />
                </Masonry>
                <Box>
                    <Masonry columns={3}>
                        <TextField
                            name="builder"
                            id="builder"
                            label="Builder"
                            value={formData.builder || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />
                        <CustomSelector
                            edit={editPass}
                            data={formData.crop}
                            title="Crop"
                            handleGlobal={setBidBase}
                            name="crop"
                            menuOptions={["Potato", "Onion", "Pecan", "Bee"]}
                        />
                        <CustomSelector
                            edit={editPass}
                            data={formData.buildingType}
                            title="Select Building Type"
                            handleGlobal={setBidBase}
                            name="buildingType"
                            menuOptions={[
                                "Single Plenum",
                                "Split Plenum",
                                "Single Dual Fan House Plenum",
                                "Split Dual Fan House Plenum"]}
                        />
                    </Masonry>
                </Box>
                <TextField
                    name="address"
                    id="address"
                    label="Address"
                    value={formData.address || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    fullWidth
                />
                <Box mt={1}>
                    <Masonry columns={3}>
                        <TextField
                            name="city"
                            id="city"
                            label="City"
                            value={formData.city || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />
                        <TextField
                            name="state"
                            id="state"
                            label="State"
                            value={formData.state || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />
                        <TextField
                            name="zip"
                            id="zip"
                            label="Zip"
                            value={formData.zip || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />

                    </Masonry>
                </Box>

            </>}
        </>
    )
}

export default BidBase;