import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom"
import UserContext from "./UserContext";

function LoginGuard({ exact, path, children, admin }) {

    const { currentUser } = useContext(UserContext)
    if (!currentUser) {
        return <Redirect to="/" />
    }
    if (admin) {
        if (currentUser.account_level !== "Admin") return <Redirect to="/" />
    }
    return (
        <Route exact={exact} path={path}>
            {children}
        </Route>
    )
}

export default LoginGuard