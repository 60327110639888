import React, { useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import useChemAppStore from "../../../stores/chemAppStore";
import {
    TextField, Typography, Box,
    FormControlLabel, Checkbox
} from "@mui/material";

function CleanAndFinish() {
    const INITIAL_STATE = {
        cleanedYes: false,
        cleanedOther: false,
        cleanedOtherText: "",
        airSysOk: false,
        airSysOkOther: false,
        airSysOkOtherText: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))
    useEffect(() => {
        const cleanAndFinishObj = {
            cleanedYes: chemAppForm.cleanedYes,
            cleanedOther: chemAppForm.cleanedOther,
            cleanedOtherText: chemAppForm.cleanedOtherText,
            airSysOk: chemAppForm.airSysOk,
            airSysOkOther: chemAppForm.airSysOkOther,
            airSysOkOtherText: chemAppForm.airSysOkOtherText,
        }
        handleLocal("replaceObj", cleanAndFinishObj)
    }, [])
    const handleChange = function (e, checkbox) {
        const name = e.target.name
        let value;
        if (checkbox) {
            value = e.target.checked;
        } else value = e.target.value;
        setChemAppForm(name, value)
    }
    return (
        <>
            <Box style={{ display: "flex" }}>
                <Typography
                    variant="h5">
                    Cleaned:
                </Typography>
                <Box ml={2} style={{ display: "flex" }}>
                    <FormControlLabel
                        label="Yes"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            id="cleanedYes"
                            name="cleanedYes"
                            checked={formData.cleanedYes || false}
                        />}
                    />
                    <FormControlLabel
                        label="Other"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            id="cleanedOther"
                            name="cleanedOther"
                            checked={formData.cleanedOther || false}
                        />}
                    />
                </Box>
            </Box>
            <TextField
                name="cleanedOtherText"
                id="cleanedOtherText"
                label="Cleaning Notes"
                fullWidth
                value={formData.cleanedOtherText || ""}
                onChange={handleLocal}
                onBlur={handleChange}
            />
            <Box mt={1} style={{ display: "flex" }} >
                <Typography
                    variant="h5">
                    Air System / Ducts:
                </Typography>
                <Box ml={2} style={{ display: "flex" }}>
                    <FormControlLabel
                        label="OK"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            id="airSysOk"
                            name="airSysOk"
                            checked={formData.airSysOk || false}
                        />}
                    />
                    <FormControlLabel
                        label="Other"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            id="airSysOkOther"
                            name="airSysOkOther"
                            checked={formData.airSysOkOther || false}
                        />}
                    />
                </Box>
            </Box>
            <TextField
                name="airSysOkOtherText"
                id="airSysOkOtherText"
                label="Air System / Ducts Notes"
                fullWidth
                onChange={handleLocal}
                onBlur={handleChange}
                value={formData.airSysOkOtherText || ""}
            />
        </>
    )
}

export default CleanAndFinish;