import React, { useEffect } from "react";
import { TextField, Box, InputAdornment } from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import useBidPartsStore from "../../../../stores/BidStore/bidPartsStore";
import SearchableInput from "../../../SheetComponents/SearchableInput";
import Commas from "../../BidFunctions/commas";
function ClimaCellPart({ id, parts, handleClimaTotal, climaCellData, edit }) {
    const editPass = edit;
    const INITIAL_STATE = {
        qty: 0,
        partNumber: "",
        description: "",
        price: 0,
        total: 0,
        serPart: false,
    }
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [climaCellParts, setClimaCellPartObj, setClimaCellPart,
        setObj] = useBidPartsStore(state =>
            [state.climaCell, state.setObj, state.setPart, state.setObj])

    useEffect(() => {
        resetFormData()
        // Set data to the global / local state
        if (edit && climaCellData.length > 0) {
            let data = INITIAL_STATE
            if (climaCellData[id]) data = climaCellData[id]
            setObj("climaCell", data, id)
            handleLocal("replaceObj", data);
        }
    }, []);

    useEffect(() => {
        if (formData.partNumber === "") return;

        const { qty, price } = formData
        const total = parseFloat((qty * price).toFixed(2));

        handleLocal("invalidInput", "total", total);
        setClimaCellPartObj("climaCell", { ...formData, total }, id);
    }, [formData.qty, formData.partNumber, formData.price]);

    useEffect(() => {
        handleClimaTotal()
    }, [climaCellParts])

    const handleGlobalPart = function (e, valueIsNum) {
        let value = e.target.value
        if (valueIsNum) value = parseFloat(value)
        setClimaCellPart("climaCell", e.target.name, value, id)
    }

    return (
        <>
            <Box m={1}>
                <Box display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                    />
                </Box>
                <Box display="inline" ml={1} mr={1}>
                    <SearchableInput
                        edit={editPass}
                        editData={climaCellData?.[id]?.partNumber}
                        setState={handleLocal}
                        variableName="partNumber"
                        searchableArray={parts}
                        label="Part Number"
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        variant={formData.partNumber !== "SERPART" ? "standard" : "outlined"}
                        sx={{ width: "64ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: formData.partNumber === "SERPART" && false }} />
                </Box>
                <Box display="inline">
                    <TextField
                        name="price"
                        id="price"
                        label="Dealer Price"
                        variant="outlined"
                        type="number"
                        sx={{ ml: 1, width: "14ch" }}
                        value={formData.price || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                        }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ ml: 1, width: "12ch" }}
                        value={Commas.add(formData.total) || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }} />
                </Box>
            </Box>
        </>
    )
}

export default ClimaCellPart;