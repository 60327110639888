async function createThumbnail(singleFile) {
    // console.log(singleFile);
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            // console.log(event)
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set the dimensions for the thumbnail
                const width = 100;
                const height = (image.height / image.width) * width;

                canvas.width = width;
                canvas.height = height;

                // Draw the image on the canvas
                ctx.drawImage(image, 0, 0, width, height);
                // Convert the canvas to a data URL (thumbnail)
                const thumbnailURL = canvas.toDataURL('image/jpeg');
                resolve(thumbnailURL);
            };
            image.onerror = (err) => {
                console.error("There was an error when creating the thumbnail")
                console.log(err);
            }
        };
        reader.readAsDataURL(singleFile);
    });
}

export { createThumbnail };
