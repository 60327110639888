import React, { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { Client } from "@microsoft/microsoft-graph-client";
import { loginRequest } from "./CalendarAuthConfig";
import CalendarDisplay from "./CalendarDisplay";
import useNotification from "../../../Hooks/useNotification";
import SheetsApi from "../../../api";
import UserContext from "../../../Users/UserContext";

function Calendar() {
    const [events, setEvents] = useState([]);
    const [cals, setCals] = useState([]);
    const messanger = useNotification()
    const { currentUser } = useContext(UserContext);
    const [primaryCal, setPrimaryCal] = useState('');

    const formatEvents = function (outlookEvents) {
        //formats events to a readable object for FullCalendar
        const eventArray = []
        outlookEvents.forEach((event) => {
            let category = event?.categories[0]?.split(" ")[0]
            if (category === 'Yellow') category = "#e6b800"
            eventArray.push({
                id: event.id,
                allDay: event.isAllDay,
                start: new Date(event.start.dateTime),
                end: new Date(event.end.dateTime),
                startStr: event.start.dateTime,
                endStr: event.end.dateTime,
                title: event.subject,
                url: '', // Link to the onClick when clicking on the event
                classNames: [], // Array of strings that determines HTML classes
                editable: false,
                startEditable: false,
                durationEditable: false,
                resourceEditable: false,
                display: 'block',
                backgroundColor: category || 'black',
            });
        });
        return eventArray
    }

    const refreshEvents = async () => {
        const token = await SheetsApi.getCalToken();
        const calendarEmail = 'ascalendar@agri-stor.com';
        if (primaryCal) {
            try {
                const client = Client.init({
                    authProvider: (done) => {
                        done(null, token);
                    },
                });
                // Fetch the user's calendars
                const calendarsResponse = await client
                    .api(`/users/${calendarEmail}/calendars`)
                    .get();

                const calendarId = calendarsResponse.value.find((cal) => cal.name === primaryCal);

                const eventsResponse = await client
                    .api(`/users/${calendarEmail}/calendars/${calendarId.id}/events`)
                    .top(150)
                    .orderby('lastModifiedDateTime desc')
                    .get();
                setEvents(formatEvents(eventsResponse.value));

            } catch (error) {
                messanger(`There was an error trying to refresh the calendar. Please contact an admin`, "error");
                console.error("Error fetching calendar events:", error);
            }
        }
    };

    useEffect(() => {
        const getData = async function () {
            return await SheetsApi.getCal();
        }
        getData().then((calendars) => {
            // Sets the viewable calendars.
            const filteredCalendars = calendars.filter((calendar) => {
                return calendar.company === currentUser.company
            })
            if (currentUser.account_level === 'admin') {
                setCals(calendars);
            } else {
                setPrimaryCal(filteredCalendars[0].name)
                setCals(filteredCalendars);
            }
        })
    }, [])

    useEffect(() => {
        //When changing the calendar, refresh
        refreshEvents()
    }, [setPrimaryCal, primaryCal])

    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshEvents();
        }, 150000); // 5 minutes
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [setPrimaryCal, primaryCal]);

    return (
        <>
            <CalendarDisplay
                refresh={refreshEvents}
                events={events}
                cals={cals}
                primaryCal={primaryCal}
                setPrimaryCal={setPrimaryCal}
            />
        </>
    );
}

export default Calendar;