import React from "react";
import {
    Card, CardContent, CardHeader, CardActions, useTheme,
    TextField, Box, Button, Divider,
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import SelectInput from "../../Sheets/SheetComponents/SelectInput";

function RegisterNewUser({ companies, handleRegisterUser, setGlobalData }) {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const defaultColor = theme.palette.default.main;
    const [formData, handleLocal, resetForm] = useFields({})

    const darkTheme = {
        "& .MuiInputLabel-root": {
            color: background,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: background,
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
        "& .MuiInputBase-input": {
            color: background,
        },
    }

    return (<>
        <Card sx={{ width: '100%', bgcolor: defaultColor, paddingBottom: 2 }}>
            <CardHeader sx={{ color: background }} title="Register new user" />
            <Divider sx={{ ml: 2, mr: 2, bgcolor: background }} />
            <CardContent>
                <form onSubmit={(e) => handleRegisterUser(e, resetForm)}>
                    <Box>
                        <Box m={2}>
                            <TextField
                                name="first_name"
                                id="first_name_reg"
                                label="First Name"
                                required
                                value={formData.first_name || ""}
                                onChange={handleLocal}
                                onBlur={setGlobalData}
                                sx={{ ...darkTheme, mr: 2, width: '300px' }}
                            />
                            <TextField
                                name="last_name"
                                id="last_name_reg"
                                label="Last Name"
                                required
                                value={formData.last_name || ""}
                                onChange={handleLocal}
                                onBlur={setGlobalData}
                                sx={{ ...darkTheme, width: '300px' }}
                            />
                        </Box>
                        <Box display="flex" m={2}>
                            <Box mr={2}>
                                <SelectInput
                                    darkTheme={true}
                                    label="Account Level"
                                    name="account_level"
                                    options={['Admin', 'Regional Admin', 'Manager', 'Technician', 'Inactive']}
                                    setGlobal={setGlobalData}
                                />
                            </Box>
                            <SelectInput
                                darkTheme={true}
                                required={true}
                                label="Department"
                                name="dept"
                                options={['sales', 'service', 'chemApps', 'labs', 'admin']}
                                setGlobal={setGlobalData}
                            />
                        </Box>
                        <Box ml={2}>
                            <SelectInput
                                darkTheme={true}
                                required={true}
                                label="Company"
                                name="company"
                                options={companies}
                                setGlobal={setGlobalData}
                            />
                            <TextField
                                name="email"
                                id="email_reg"
                                label="Email"
                                required
                                value={formData.email || ""}
                                onChange={handleLocal}
                                onBlur={setGlobalData}
                                sx={{ ...darkTheme, width: '300px', ml: 2 }}
                            />
                        </Box>
                        <Box m={2} >
                            <TextField
                                name="username"
                                id="username_reg"
                                label="Username"
                                required
                                value={formData.username || ""}
                                onChange={handleLocal}
                                onBlur={setGlobalData}
                                sx={{ ...darkTheme, mr: 2, width: '300px' }}
                            />
                        </Box>
                    </Box>
                    <CardActions>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            type="submit">
                            Submit
                        </Button>
                    </CardActions>
                </form>
            </CardContent>
        </Card>
    </>)
}

export default RegisterNewUser;