import React, { useEffect, useState } from "react";
import {
    Box, Card, Typography, Button, TextField, InputAdornment,
} from "@mui/material";
import useBidPartsStore from "../../../../stores/BidStore/bidPartsStore";
import { shallow } from "zustand/shallow";
import SheetsApi from "../../../../api";
import Part from "../BidParts/Part";
import Commas from "../../BidFunctions/commas";
function PartContainer({ title, category, maxParts, edit, partData }) {
    const editPass = edit
    const partDataPass = partData
    const [localParts, setLocalParts] = useState([])
    const [render, setRender] = useState(false)
    const [parts, addPart, total, setPart] = useBidPartsStore(state =>
        [state[category], state.addPart, state.totals,
        state.setPart], shallow)
    const partsTotal = total[0][category + "Total"]
    useEffect(() => {
        async function getParts() {
            const parts = await SheetsApi.getBidPartsByCategory({ category: category })
            const serPart = {
                qty: 0,
                partNumber: "SERPART",
                description: "",
                price: 0,
                total: 0,
                serPart: true,
            }
            setLocalParts([...parts, serPart])
        }
        getParts()
        if (edit) {
            for (let i = 1; i < partData.length; i++) {
                addPart(category, maxParts)
            }
        }
        setRender(true);
    }, [])

    const handlePartsTotal = function () {
        let priceTotal = 0;
        const categoryName = category + "Total"
        parts.forEach((part) => {
            if (!isNaN(part.total)) {
                const total = parseFloat(part.total);
                priceTotal = parseFloat((priceTotal + total).toFixed(2));
            }
        })
        setPart("totals", categoryName, priceTotal, 0);
    }

    return (
        <>
            {render && <>
                <Box ml={1} mb={1} mt={1}>
                    <Card sx={{ width: '130ch' }} >
                        <Box display="flex" justifyContent="left" m={2}>
                            <Typography sx={{ textDecoration: "underline" }} ml={4} variant="h5">
                                {title}
                            </Typography>
                        </Box>
                        {localParts.length > 0 && parts.map((part) => {
                            return <Part
                                key={part[category + "Id"]}
                                id={part[category + "Id"]}
                                localParts={localParts}
                                category={category}
                                handlePartsTotal={handlePartsTotal}
                                edit={editPass}
                                partData={partDataPass}
                            />
                        })
                        }
                        <Box
                            mb={1}
                            display="flex"
                            justifyContent="space-between">
                            <Box align="left" mr={1} ml={1}>
                                <Button
                                    variant='contained'
                                    onClick={() => addPart(category, maxParts)}>
                                    Add Part
                                </Button>
                            </Box>
                            <Box mb={1} mr={2} align="right">
                                <TextField
                                    id='total'
                                    name='total'
                                    sx={{ width: '12ch' }}
                                    value={Commas.add(partsTotal) || ""}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment
                                                position="start">
                                                $
                                            </InputAdornment>,
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </>}
        </>
    )

}
export default React.memo(PartContainer); 