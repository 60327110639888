import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, CardHeader, TextField, MenuItem,
    Button, Box, useTheme, Typography,
} from '@mui/material';
import useFields from '../../Hooks/useFields';
import useNotification from '../../Hooks/useNotification';
import DayJS from 'dayjs'
import SheetsApi from '../../api'; import ReceiptsFilter from './ReceiptsFilter';
import ReceiptCard from './ReceiptCard';
import useImageStore from '../../stores/imageStore';
import { useHistory } from 'react-router-dom';
import HandleImage from '../../Sheets/SheetComponents/images/imageUtilities/HandleImage';
import useReceiptStore from '../../stores/receiptStore';

function ReceiptsHome() {
    const theme = useTheme();
    const history = useHistory();
    const defaultColor = theme.palette.default.main;
    const primaryColor = theme.palette.primary.main;
    const messanger = useNotification();
    const [addReceiptsToPrint] = useImageStore(state => [state.addReceiptsToPrint]);
    const [receipts, setReceipts, clearReceipts] = useReceiptStore(state =>
        [state.receipts, state.setReceipts, state.clearReceipts]);
    const [formData, handleLocal] = useFields({
        stage: "open",
        startDate: "",
        endDate: "",
        username: "",
        category: "",
    })

    const handleSubmit = async () => {
        clearReceipts();
        const finalObj = {
            stage: formData.stage,
            selectedUser: formData.username,
            category: formData.category,
        };
        if (formData.startDate !== "" && formData.endDate !== "") {
            const startDate = DayJS(formData.startDate, "MM/DD/YYYY", true).isValid();
            const endDate = DayJS(formData.endDate, "MM/DD/YYYY", true).isValid();
            if (startDate && endDate) {
                finalObj.startDate = formData.startDate;
                finalObj.endDate = formData.endDate;
            } else return messanger("There was an error with the dates entered. Please check.", "warning")
        } else if (formData.startDate && formData.endDate === "" || formData.endDate && formData.startDate === "") {
            return messanger("Both a start date and an end date are required before filtering. Please ensure proper date formats.", "warning")
        }
        const results = await SheetsApi.getFilteredReceipts(finalObj)
        const setImageObj = async () => {
            const promises = results.map(async (receipt) => {
                return await HandleImage.setImage(receipt);
            });
            return await Promise.all(promises);
        };
        setImageObj().then((images) => {
            setReceipts(images);
        });
    };

    const handlePrintAll = function () {
        addReceiptsToPrint(receipts);
        history.push("/receipts/print")
    }

    const handleDismissAll = async function () {
        const receiptsToDismiss = [];
        if (receipts.length === 0) return messanger("There are no recipts to dismiss.", "warning")
        receipts.map((receipt) => {
            return receiptsToDismiss.push({ id: receipt.receiptData.id, stage: 'open' })
        })
        const response = await SheetsApi.changeStage(receiptsToDismiss);
        setReceipts([])
        return messanger(response.message, response.error ? "error" : "success")
    }

    return (<>
        <ReceiptsFilter
            handleSubmit={handleSubmit}
            handleLocal={handleLocal}
            formData={formData}
            handlePrintAll={handlePrintAll}
            handleDismissAll={handleDismissAll}
        />
        <Box>
            <Card sx={{ bgcolor: primaryColor }}>
                <CardHeader
                    title="Receipts"
                    titleTypographyProps={{ sx: { color: defaultColor } }}
                />
                <CardContent>
                    {receipts.length > 0 ? receipts.map((receipt) => {
                        return <ReceiptCard
                            key={receipt.receiptData.id}
                            receipt={receipt}
                            receipts={receipts}
                            setReceipts={setReceipts} />
                    }) :
                        <Typography sx={{ ml: 4 }}><em>None</em></Typography>
                    }
                </CardContent>
            </Card >
        </Box >
    </>)
}

export default ReceiptsHome;
