import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone'
import { createThumbnail } from '../Sheets/SheetComponents/images/imageUtilities/createThumbnail';
import useImageStore from '../stores/imageStore';
import Compressor from 'compressorjs';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import useNotification from '../Hooks/useNotification';
import { v4 as uuid } from 'uuid';
import UserContext from '../Users/UserContext';
import {
    Typography, Box, IconButton,
} from "@mui/material"
import ReceiptPopUp from './ReceiptPopUp';

function ReceiptUpload({ skipDelete }) {
    const skipDeletePass = skipDelete;
    const { currentUser } = useContext(UserContext)
    const messanger = useNotification();
    const [addImage] = useImageStore(state =>
        [state.addImage]);
    const [receipt, setReceipt] = useState(false);
    const [lastUpload, setLastUpload] = useState({});

    const onDrop = useCallback(async (acceptedFiles) => {
        acceptedFiles.map(async (file) => {
            // create thumbnail
            const thumbnailURL = await createThumbnail(file);
            // compress file
            return new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const dataURL = URL.createObjectURL(result);
                    const fileName = uuid();
                    const imageObj = {
                        imageType: "img",
                        name: fileName,
                        thumbnail: thumbnailURL,
                        downloadURL: dataURL,
                        file: result,
                        receiptData: {
                            receiptDept: currentUser.dept,
                            customer: "",
                            selectedUser: currentUser,
                            stage: "open",
                        }
                    };
                    // add to global state
                    addImage(imageObj);
                    setLastUpload(imageObj);
                    setReceipt(true);
                },
                error(err) {
                    messanger(err.message, "error");
                    console.log(err.message);
                },
            });
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
    });

    return (
        <Box mb={5} ml={1} mr={1} className="hideForPrint">
            {lastUpload.receiptData &&
                <ReceiptPopUp open={receipt} setOpen={setReceipt} receipt={lastUpload} sheet={false} skipDelete={skipDeletePass} />
            }
            <Box {...getRootProps()} display="flex" justifyContent="space-between" alignItems="center">
                <input {...getInputProps()} type='file' name='img' multiple />
                <IconButton>
                    <AddAPhoto sx={{ color: 'white' }} />
                    <Typography
                        sx={{ color: 'white', ml: 1, mt: .5 }}>
                        Add Receipt
                    </Typography>
                </IconButton>
            </Box >
        </Box >
    );
}

export default ReceiptUpload;