import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom"
import useSettingsStore from "../stores/settingsStore";
import Refrigeration from "../Sheets/Refrigeration/Refrigeration"
import ServiceSheets from "../Sheets/serviceSheets/ServiceSheets"
import PreSeason from "../Sheets/PreSeason/PreSeason";
import Companies from "../Companies/Companies"
import Customers from "../Customers/Cutomers"
import LoginForm from "../Users/LoginForm"
import LoginGuard from "../Users/LoginGuard";
import Home from "../Home/Home"
import ServiceSheetEdit from "../Sheets/serviceSheets/serviceSheetEdit";
import PreSeasonEdit from "../Sheets/PreSeason/PreSeasonEdit";
import RefrigerationEdit from "../Sheets/Refrigeration/RefrigerationEdit";
import SalesOrder from "../Sheets/SalesOrder/SalesOrder";
import SalesOrderEdit from "../Sheets/SalesOrder/SalesOrderEdit";
import StorageInspection from "../Sheets/StorageInspection/StorageInspection";
import StorageInspectionEdit from "../Sheets/StorageInspection/StorageInspectionEdit";
import ChemAppAgreement from "../Sheets/ChemApps/ChemAppAgreement";
import ChemAppAgreementEdit from "../Sheets/ChemApps/ChemAppAgreementEdit";
import ChemAppForm from "../Sheets/ChemApps/chemAppForm/ChemAppFormBase";
import Bid from "../Sheets/Bids/Bid"
import BidRef from "../Sheets/Bids/BidRef";
import SheetsApi from "../api";
import BidEdit from "../Sheets/Bids/BidEdit";
import BidRefEdit from "../Sheets/Bids/BidRefEdit";
import BidQuote from "../Sheets/Bids/BidQuote/BidQuote";
import SiteSettings from "../Users/SiteSettings/SiteSettings";
import useNotification from "../Hooks/useNotification";
import BidRefrigerationQuote from "../Sheets/Bids/BidQuote/BidRefrigerationQuote";
import SalesDashboard from "../Home/SalesDashboard/SalesDashboard";
import BidBuilderSizes from "../Sheets/Bids/BidQuote/BidBuilderSizes";
import FreshPack from "../Sheets/FreshPack/FreshPack";
import FreshPackEdit from "../Sheets/FreshPack/FreshPackEdit";
import ReceiptsHome from "../Receipts/ReceiptsHome/ReceiptsHome";
import ReceiptsPdfBase from "../Receipts/ReceiptsPdf/ReceiptsPdfBase";
import LabsBase from "../Sheets/Labs/LabsBase";
import Users2 from "../Users/UserPage/newUsers";
import Calendar from "../Home/SalesDashboard/Calendar/Calendar";

function Routes({ setToken, logout }) {
    const messanger = useNotification();
    const location = useLocation();
    const [setHome] = useSettingsStore((state) => [state.setHome])

    async function handleLogin(data) {
        let response = await SheetsApi.login(data)
        if (response.error) {
            return messanger(response.message, "error");
        } else {
            setToken(response.token)
            return { success: true }
        }
    };

    useEffect(() => {
        //Set the home page based on the last home page visited.
        if (location.pathname.startsWith("/home/")) {
            setHome(location.pathname)
        } else if (location.pathname === "/") {
            setHome("/")
        }
    }, [location])

    return (
        <div>
            <Switch>
                <LoginGuard exact path="/">
                    <Home />
                </LoginGuard>
                <LoginGuard exact path="/home/:company">
                    <Home />
                </LoginGuard>
                <LoginGuard exact path="/sheets">
                    <ServiceSheets />
                </LoginGuard>
                <LoginGuard exact path="/sheets/edit/:id">
                    <ServiceSheetEdit />
                </LoginGuard>
                <LoginGuard exact path="/refrigeration">
                    <Refrigeration />
                </LoginGuard>
                <LoginGuard exact path="/refrigeration/edit/:id">
                    <RefrigerationEdit />
                </LoginGuard>
                <LoginGuard exact path="/preseason">
                    <PreSeason />
                </LoginGuard>
                <LoginGuard exact path="/preseason/edit/:id">
                    <PreSeasonEdit />
                </LoginGuard>
                <LoginGuard exact path="/serviceViewer">
                    <SalesDashboard />
                </LoginGuard>
                <LoginGuard exact path="/serviceViewer/calendar">
                    <Calendar />
                </LoginGuard>
                <LoginGuard exact path="/salesOrders">
                    <SalesOrder />
                </LoginGuard>
                <LoginGuard exact path="/salesOrders/edit/:id">
                    <SalesOrderEdit />
                </LoginGuard>
                <LoginGuard exact path="/inspections">
                    <StorageInspection />
                </LoginGuard>
                <LoginGuard exact path="/inspections/edit/:id">
                    <StorageInspectionEdit />
                </LoginGuard>
                <LoginGuard exact path="/labs">
                    <LabsBase />
                </LoginGuard>
                <LoginGuard exact path="/chemApps/newAgreement">
                    <ChemAppAgreement />
                </LoginGuard>
                <LoginGuard exact path="/chemApps/agreement/edit/:id">
                    <ChemAppAgreementEdit />
                </LoginGuard>
                <LoginGuard exact path="/ChemApps/form/edit/:id">
                    <ChemAppForm />
                </LoginGuard>
                <LoginGuard exact path="/sprayBar">
                    <ChemAppAgreement sprayBar={true} />
                </LoginGuard>
                <LoginGuard exact path="/sprayBar/form/edit/:id">
                    <ChemAppForm sprayBar={true} />
                </LoginGuard>
                <LoginGuard exact path="/sprayBar/agreement/edit/:id">
                    <ChemAppAgreementEdit sprayBar={true} />
                </LoginGuard>
                <LoginGuard exact path="/disinfectant/">
                    <ChemAppAgreement disinfectant={true} />
                </LoginGuard>
                <LoginGuard exact path="/disinfectant/agreement/edit/:id">
                    <ChemAppAgreementEdit disinfectant={true} />
                </LoginGuard>
                <LoginGuard exact path="/disinfectant/form/edit/:id">
                    <ChemAppForm disinfectant={true} />
                </LoginGuard>
                <LoginGuard exact path="/bids/edit/:id">
                    <BidEdit />
                </LoginGuard>
                <LoginGuard exact path="/bids/newBid">
                    <Bid />
                </LoginGuard>
                <LoginGuard exact path="/bids/newBid/:customer">
                    <Bid />
                </LoginGuard>
                <LoginGuard exact path="/bidRef/newBid">
                    <BidRef />
                </LoginGuard>
                <LoginGuard exact path="/bidRef/edit/:id">
                    <BidRefEdit />
                </LoginGuard>
                <LoginGuard exact path="/bids/generateQuote/:id">
                    <BidQuote gellertQuote={false} />
                </LoginGuard>
                <LoginGuard exact path="/bids/generateGellertOrder/:id">
                    <BidQuote gellertQuote={true} />
                </LoginGuard>
                <LoginGuard exact path="/bidRef/refrigerationQuote/:id">
                    <BidRefrigerationQuote />
                </LoginGuard>
                <LoginGuard exact path="/bids/builderSizes/:id">
                    <BidBuilderSizes />
                </LoginGuard>
                <LoginGuard exact path="/freshPack">
                    <FreshPack />
                </LoginGuard>
                <LoginGuard exact path="/freshPack/edit/:id">
                    <FreshPackEdit />
                </LoginGuard>
                <LoginGuard exact path="/companies">
                    <Companies />
                </LoginGuard>
                <LoginGuard exact path="/customers">
                    <Customers />
                </LoginGuard>
                <LoginGuard exact path="/customers/search/:searchTerm/:company">
                    <Customers />
                </LoginGuard>
                <LoginGuard exact path="/customers/:company">
                    <Customers />
                </LoginGuard>
                <LoginGuard exact path="/receipts">
                    <ReceiptsHome />
                </LoginGuard>
                <LoginGuard exact path="/receipts/print">
                    <ReceiptsPdfBase />
                </LoginGuard>
                <LoginGuard exact path="/users">
                    <Users2 logout={logout} />
                </LoginGuard>
                <LoginGuard exact path="/siteSettings" admin={true}>
                    <SiteSettings />
                </LoginGuard>
                <Route exact path="/login">
                    <LoginForm login={handleLogin} />
                </Route>
            </Switch>
        </div >
    )
}

export default Routes