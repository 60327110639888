import React, { useState, useEffect } from "react";
import {
    Menu, MenuItem, ListItemIcon, ListItemText,
    TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CustomSelector({ title, menuOptions, name, handleGlobal, edit, data }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('');
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setValue(e.target.innerText);
        setAnchorEl(null);
    };
    const handleCustomValueChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        if (edit) {
            setValue(data)
        }
    }, [])

    return (
        <>
            <TextField
                name="value"
                onClick={handleClick}
                value={value || ""}
                onChange={handleCustomValueChange}
                onBlur={() => handleGlobal(name, value)}
                variant="outlined"
                label={title}
                InputProps={{ endAdornment: <ArrowDropDownIcon /> }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: anchorEl ? anchorEl.clientWidth : 'auto',
                    },
                }}
            >
                {menuOptions.length > 0 && menuOptions.map((option, index) => {
                    return <MenuItem
                        key={index}
                        onClick={(e) => handleClose(e)}>
                        {option}
                    </MenuItem>
                })}
                <MenuItem onClick={(e) => handleClose(e)}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="" />
                </MenuItem>

            </Menu>
        </>
    );
}

export default CustomSelector;