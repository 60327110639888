import React, { useState, useEffect } from "react";
import {
    Collapse, Box, FormControlLabel, Checkbox
} from "@mui/material";
import SheetsApi from "../api";
import SearchableInput from "../Sheets/SheetComponents/SearchableInput";
import useFields from "../Hooks/useFields";
import useFilterStore from "../stores/filterStore";

function AdvancedSettings({ advancedSettings }) {
    const [parts, setParts] = useState([])
    const [formData, handleLocal] = useFields({ customerBypass: false })
    const [partData, setPartData] = useFields({})
    const [setFilter] = useFilterStore(state => [state.setFilter])

    useEffect(() => {
        const getParts = async function () {
            return await SheetsApi.getAllParts()
        }
        getParts().then((response) => {
            setParts(response)
        })
    }, []);

    useEffect(() => {
        setFilter("partNumber", partData.partNumber)
    }, [partData])

    useEffect(() => {
        setFilter("customerBypass", formData.customerBypass)
    }, [formData.customerBypass])

    return (<>
        <Collapse in={advancedSettings}>
            <Box m={2} display="flex" justifyContent="space-evenly" gap={2}>
                <SearchableInput
                    setState={setPartData}
                    searchableArray={parts}
                    label="Parts"
                    variableName="partNumber"
                    fullwidth={true}
                />
                <FormControlLabel
                    label="Bypass customer constraint"
                    control={
                        <Checkbox
                            name="customerBypass"
                            checked={formData.customerBypass || false}
                            onChange={handleLocal}
                        />
                    }
                />
            </Box>
        </Collapse>
    </>)
}

export default AdvancedSettings;