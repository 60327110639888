import React, { useEffect, useState } from "react";
import {
    Card, CardContent, Box, Typography,
    Collapse, useTheme, Button, CardActions,
} from "@mui/material"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SheetsApi from "../../api";
import useNotification from "../../Hooks/useNotification";
import { useHistory } from "react-router-dom"
import useImageStore from "../../stores/imageStore";

function ReceiptCard({ receipt, receipts, setReceipts }) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const [collapse, setCollapse] = useState(false);
    const [addReceiptsToPrint] = useImageStore(state => [state.addReceiptsToPrint]);
    const messanger = useNotification()
    const theme = useTheme();
    const defaultColor = theme.palette.default.main;
    const background = theme.palette.background.main;
    const [date, setDate] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setDate(dayjs(receipt.receiptData.timeStamp).tz('America/Denver').format('MM-DD-YYYY h:mm A'));
    }, []);

    const printReceipt = function () {
        addReceiptsToPrint([receipt]);
        history.push("/receipts/print")
    }

    const dismissReceipt = async function () {
        const response = await SheetsApi.changeStage([{ id: receipt.receiptData.id, stage: receipt.receiptData.stage }]);
        const newReceiptArray = receipts.filter(receipt2 => receipt2.receiptData.id !== receipt.receiptData.id);
        setReceipts(newReceiptArray)
        messanger(response.message, response.error ? "error" : "success")
    }

    function convertString(camelCaseString) {
        if (!camelCaseString) return '';
        const words = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().split(' ');
        const properCaseString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return properCaseString;
    }

    return (<>
        <Card
            key={receipt.receiptData.id}
            sx={{ ml: 4, mr: 4, mt: 1, mb: 1, bgcolor: defaultColor }}
            onClick={() => setCollapse(!collapse)}
        >
            <Box mt={1} display="flex" justifyContent="space-between">
                <Typography sx={{ ml: 1, color: background }}>Stage: {receipt.receiptData.stage}</Typography>
                <Typography sx={{ mr: 1, color: background }}>ID #{receipt.receiptData.id}</Typography>
            </Box>
            <Box mt={1} mb={1} position="relative">
                <Box display="flex" justifyContent="space-between" position="relative">
                    <Typography sx={{ ml: 8, color: background }}>
                        Category: {convertString(receipt.receiptData.category)}
                    </Typography>
                    <Typography sx={{ mr: 8, color: background }}>
                        Date:  {dayjs.tz(receipt.receiptData.receiptDate, 'America/Denver').format('MM-DD-YYYY')}
                    </Typography>
                </Box>
                <Box position="absolute" left="50%" top="0%" style={{ transform: 'translateX(-50%)' }}>
                    <Typography sx={{ color: background }}>
                        Username: {receipt.receiptData.selectedUser}
                    </Typography>
                </Box>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Card sx={{ m: 2, bgcolor: background }}>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography>Department: {receipt.receiptData.receiptDept}</Typography>
                                <Typography>Description: {receipt.receiptData.description}</Typography>
                                <Box mt={4} display="flex" justifyContent="flex-start">
                                    {receipt.receiptData.category === "part" ? <Box display="flex">
                                        <Typography sx={{ ml: 2 }}>
                                            <span style={{ textDecoration: 'underline' }}>Customer:</span> {receipt.customer}
                                        </Typography>
                                        <Typography sx={{ ml: 2 }}>
                                            <span style={{ textDecoration: 'underline' }}>Large Job #:</span> {receipt.largeJob}
                                        </Typography>
                                        <Typography sx={{ ml: 2 }}>
                                            <span style={{ textDecoration: 'underline' }}>Part #:</span> {receipt.partNumber}
                                        </Typography>
                                    </Box> :
                                        receipt.receiptData.category === "fuel" ?
                                            <Typography sx={{ ml: 2 }}>
                                                <span style={{ textDecoration: 'underline' }}>Vehicle:</span> {receipt.vehicle}
                                            </Typography> :
                                            receipt.receiptData.category === "largeJob" ? <Box display="flex">
                                                <Typography sx={{ ml: 2 }}>
                                                    <span style={{ textDecoration: 'underline' }}>Customer:</span> {receipt.customer}
                                                </Typography>
                                                <Typography sx={{ ml: 2 }}>
                                                    <span style={{ textDecoration: 'underline' }}>Large Job #:</span> {receipt.largeJob}
                                                </Typography>
                                            </Box> :
                                                receipt.receiptData.category === "entertainment" ?
                                                    <Typography>Customer: {receipt.receiptData.customer}</Typography> :
                                                    receipt.receiptData.category === "other" ? <Box display="flex">
                                                        <Typography sx={{ ml: 2 }}>
                                                            <span style={{ textDecoration: 'underline' }}>Customer:</span> {receipt.customer}
                                                        </Typography>
                                                        <Typography sx={{ ml: 2 }}>
                                                            <span style={{ textDecoration: 'underline' }}>Large Job #:</span> {receipt.largeJob}
                                                        </Typography>
                                                        <Typography sx={{ ml: 2 }}>
                                                            <span style={{ textDecoration: 'underline' }}>Part #:</span> {receipt.partNumber}
                                                        </Typography>
                                                        <Typography sx={{ ml: 2 }}>
                                                            <span style={{ textDecoration: 'underline' }}>Vehicle:</span> {receipt.vehicle}
                                                        </Typography>
                                                    </Box> : null}
                                </Box>
                                {receipt.receiptData.sheetId && <Box>
                                    <Typography sx={{ ml: 2 }}>
                                        {receipt.receiptData.sheetType === 'sheet' ? 'Service' :
                                            receipt.receiptData.sheetType === 'chemApps' ? 'Chemical Application' :
                                                receipt.receiptDatasheetType === 'sales' ? 'Sales Order' :
                                                    receipt.receiptData.sheetType === 'preseason' ? 'Preseason' :
                                                        receipt.receiptData.sheetType === 'refrigeration' ? 'Refrigeration' :
                                                            receipt.receiptData.sheetType === 'freshPack' ? 'Fresh Pack' :
                                                                receipt.receiptData.sheetType === 'inspection' ? 'Storage Inspection' :
                                                                    'Sheet Type not found'
                                        } ID# {receipt.receiptData.sheetId}
                                    </Typography>
                                </Box>}
                            </Box>
                            <Box>
                                <img src={receipt.thumbnail}
                                    alt={`receipt${receipt.receiptData.id}}`} />
                            </Box>


                        </Box>
                    </CardContent>
                    <Box ml={2} display="flex" justifyContent="space-between">
                        <Typography sx={{ mt: 2 }}>{receipt.receiptData.creator} created this receipt on {date} Mountain time</Typography>
                        <CardActions>
                            <Box>
                                <Button onClick={printReceipt} sx={{ mr: 1 }} variant="contained" >PRINT</Button>
                                <Button onClick={dismissReceipt} variant="contained">{receipt.receiptData.stage === 'open' ? 'CLOSE' : 'OPEN'}</Button>
                            </Box>
                        </CardActions>
                    </Box>
                </Card>
            </Collapse>
        </Card>
    </>);
}

export default ReceiptCard;