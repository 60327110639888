import dayjs from "dayjs";

function CreateSubmitObj(
    sheetType, currentUser, messanger,
    baseSheet, partSheet, techSheet) {
    const techCheck = function () {
        if (currentUser.account_level === 'Technician' || currentUser.account_level === 'Manger') {
            if (sheetType.stage === "Archive") {
                return { archive: true };
            }
        }
        //checks to make sure that technicians with nc filled out also have notes and that all number values are valid floats
        for (let tech of techSheet) {
            if (tech.technician) {
                tech.nc = parseFloat(tech.nc) || 0;
                tech.labor = parseFloat(tech.labor) || 0;
                tech.travel = parseFloat(tech.travel) || 0;
                if (tech.nc) {
                    if (tech.notes) return false
                    else return { nc: true }
                }
            }
        }
        return false
    }
    const costCheck = function () {
        //checks to make sure that misc parts have a cost associated with them
        for (let part of partSheet) {
            if (part.part === "MISC. WIRE") return true
            if (part.isMisc) {
                if (part.cost) return true
                else return false
            }
        }
        return true
    }
    if (baseSheet.dateServiced === "") {
        //auto fills a blank date to current day
        baseSheet.dateServiced = dayjs().format("MM/DD/YYYY")
    }
    if (dayjs(baseSheet.dateServiced).isValid()) {
        const techCheckObj = techCheck()
        if (costCheck()) {
            if (!techCheckObj) {
                baseSheet.dateCreated = dayjs().format("MM/DD/YYYY")
                if (baseSheet.company === "" || baseSheet.company === null || baseSheet.company === undefined) {
                    baseSheet.company = currentUser.company
                }
                let deptValue;
                if (baseSheet.dept === "") deptValue = currentUser.dept;
                else deptValue = deptValue = baseSheet.dept;
                let sheetObj = {
                    sheet: {
                        ...baseSheet,
                        ...sheetType,
                        creator: currentUser.username,
                        actionUser: currentUser.username,
                        dept: deptValue,
                        locationId : baseSheet.locationId,
                        timeStamp: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
                    },
                    parts: [],
                    techs: [],
                    images: [],
                }
                for (let part of partSheet) {
                    if (part.part) sheetObj.parts = [...sheetObj.parts, part]

                }
                for (let tech of techSheet) {
                    if (tech.technician) sheetObj.techs = [...sheetObj.techs, tech]
                }
                return sheetObj
            } else {
                if (techCheckObj.nc) messanger('Notes are required in the technician notes if you have a number in N/C', "warning")
                if (techCheckObj.archive) messanger(`You must be an Admin to set the sheet to: ${sheetType.stage} or to make changes to an Archived sheet.`, "warning")
                return { error: true }
            }
        } else {
            messanger('There must be a cost associated with MISC parts.', "warning")
            return { error: true }
        }
    } else {
        messanger("The date you entered is not valid. Please check the Service Date and try again.", "warning")
        return { error: true }
    }
}

export default CreateSubmitObj