import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

function BuilderTables({ table }) {
    const [localTable, setLocalTable] = useState({});
    const [render, setRender] = useState(false);
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 5,
            width: '100%',
        },
        border: {
            borderColor: 'black',
            borderBottom: 1,
        },
        header: { borderTop: 'none' },
        container2: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
        },
        qtyRow: { width: '3.5%', fontSize: '10px' },
        descriptionRow: { width: '70%', fontSize: '10px' },
        electricalRow: { width: '25%', fontSize: '10px' },
        electricalTitle: { width: '25%' },
        title: { width: '75%' },
        table: { marginTop: 10 }
    })

    useEffect(() => {
        setLocalTable(tableGenerator(table.data, table.title, table.description, table.electricalDescription));
        setRender(true)
    }, [])

    const tableGenerator = function (data, title, description, electricalDescription) {
        const tableObj = {
            id: uuid(),
            title: title,
            data: [],
        }
        for (let item of data) {
            let electricalDescription2 = '';
            let electricalQty = 0;
            const dampers = title === 'Ruskin Aluminum Exhaust Dampers:' ? true : false;
            const climaCell = title === 'ClimaCell Evaporative Cooler:' ? true : false;
            const doors = title === 'ClimaSeal Fresh Air Doors:' ? true : false;
            const freqDrive = title === 'Frequency Drives:' ? true : false;
            const humidifiers = title === 'Gellert Bahnson Humidifiers:' ? true : false;
            if (freqDrive) {
                //make sure that there is a frequency drive.
                if (item.partNumber === "") continue;
                console.log(item)
                let amperage = item.hp === 20 ? 60 : item.hp === 15 ? 40 : item.hp === 10 ? 25 : "N/A";
                electricalDescription2 = `${item.voltage}V 3P ${amperage}Amp Circut(s)`
            } else if (doors) {
                item.description = `${item.width}" Wide X ${item.height}" High Framed Opening`
            } else if (climaCell) {
                item.qty = item.climaCellQty;
                item.description = `${item.climaCellWidth}' 4" Wide X ${item.climaCellHeight}' 6" High Framed Opening`
            } else if (dampers) {
                item.description = `Ruskin ${item.width}" Wide X ${item.height}" Framed Opening`
            } else if (humidifiers) {
                // If the humidifier is a head only, then it will only require 1 circuit,
                // If it is an HA head then it will require 120v, while LA will require 240v
                const headOnlyFinder = (string) => string.includes('Head Only');
                item.headOnly = headOnlyFinder(item.description);
                if (item.description.startsWith('HA')) electricalDescription2 = '120V 20Amp Circuit'
                else if (item.description.startsWith('LA')) electricalDescription2 = '240V 20Amp Circuit'
                else electricalDescription2 = electricalDescription
                if (!item.headOnly) electricalQty = item.qty * 2
                else electricalQty = item.qty
            }
            const newObj = {
                id: uuid(),
                qty: item.qty,
                description: description ? description : item.description,
                electricalDescription: freqDrive || humidifiers ? electricalDescription2 : electricalDescription,
                electricalQty: electricalQty,
            }
            tableObj.data.push(newObj)
        }
        return tableObj;
    }
    return (
        <>
            {render &&
                <View style={styles.table} key={table.id}>
                    <View style={[styles.row, styles.border]}>
                        <Text style={styles.title}>{localTable.title}</Text>
                        <Text style={styles.electricalTitle}>Electrical:</Text>
                    </View>
                    <View style={styles.table}>
                        {localTable.data.map((lineItem) => {
                            return <View key={lineItem.id} style={[styles.row, styles.header]} >
                                <Text style={styles.qtyRow}>{lineItem.qty}</Text>
                                <Text style={styles.qtyRow}>-</Text>
                                <Text style={styles.descriptionRow}>{lineItem.description}</Text>
                                {lineItem.electricalDescription &&
                                    <Text style={styles.electricalRow}>
                                        {localTable.title === 'Gellert Bahnson Humidifiers:' ?
                                            lineItem.electricalQty :
                                            localTable.title === 'ClimaSeal Fresh Air Doors:' ?
                                                1 : lineItem.qty}  -  {lineItem.electricalDescription}
                                    </Text>
                                }
                            </View>
                        })}
                        {table.extraElectrical &&
                            <View style={styles.row}>
                                <Text style={styles.qtyRow}></Text>
                                <Text style={styles.descriptionRow}></Text>
                                <Text style={styles.electricalRow}>{table.extraElectrical}</Text>
                            </View>
                        }
                    </View>
                </View>
            }
        </>
    )
}

export default BuilderTables;
