import { create } from "zustand"

const emptyBaseForm = {
    customerName: "",
    locationName: "",
    requestedBy: "",
    dateServiced: "",
    po: "",
    total: 0,
    creator: "",
    dateCreated: "",
    locationId: null,
    company: "",
    dept: "",
}

const useBaseStore = create((set) => ({

    baseForm: emptyBaseForm,

    setBaseForm: function (name, value) {
        set(prevState => ({
            ...prevState,
            baseForm: {
                ...prevState.baseForm,
                [name]: value
            }
        }));
    },

    clearBaseForm: function () {
        set(prevState => ({
            ...prevState,
            baseForm: emptyBaseForm
        }))
    },

    setBaseFormObj: function (obj) {
        set(prevState => ({
            ...prevState,
            baseForm: obj
        }))
    },
}))

export default useBaseStore

