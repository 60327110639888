import React from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';
import Commas from "../../BidFunctions/commas";

function QuoteTableLine({ qty, description, title, total, noPrices }) {
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 8,
            width: '100%',
        },
        qtyRow: { width: '5%', fontSize: '12px' },
        descriptionRow: { width: '75%', fontSize: '12px' },
        priceRow: {
            marginLeft: '20px',
            width: '15%',
            fontSize: '12px',
        },
    })
    return (
        <View style={styles.row}>
            <Text style={styles.qtyRow}>{title === "Options:" ? "ADD" : qty}</Text>
            <Text style={styles.qtyRow}>-</Text>
            <Text style={styles.descriptionRow}>{description}</Text>
            {!noPrices &&
                <Text style={styles.priceRow}>$ {Commas.add(total)}</Text>
            }
        </View>
    )
}

export default QuoteTableLine;