import React, { useContext } from "react";
import { Box, Typography } from "@mui/material"
import UserContext from "../../Users/UserContext";
import { NavLink } from "react-router-dom";

function SettingsMenu({ handleDrawerClose }) {
    const { currentUser } = useContext(UserContext);
    const defaultStyle = { textDecoration: 'none', color: "#004e7c" }

    return (
        <>
            {currentUser.account_level === 'Admin' &&
                <NavLink to="/siteSettings" style={defaultStyle}>
                    <Box
                        onClick={handleDrawerClose}
                        sx={{
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'left',
                            m: 2,
                        }}>
                        <Typography variant="h6">Site Settings</Typography>
                    </Box>
                </NavLink>
            }
        </>
    )
}

export default SettingsMenu;