import React, { useEffect, useState } from "react";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import {
    Typography, Box, Table, TableBody, TableCell, TextField,
    TableContainer, TableHead, TableRow, Paper, Button, InputAdornment,
} from "@mui/material";
import Calculator from "../../BidFunctions/calculator"
import SheetsApi from "../../../../api";
import useFields from "../../../../Hooks/useFields";
import useNotification from "../../../../Hooks/useNotification";

function Vfd({ cwt, tons, fetchedFans, selectWeight, edit, bidData, staticPressure }) {
    const [bidBase, recommendedFans, setRecommendedFans,
        setBidBase, currFans, currMotors, currFreqDrives,
        setVfdObj] = useBidStoreMain((state) =>
            [state.bidBase, state.recommendedFans,
            state.setRecommendedFans, state.setBidBase, state.fan,
            state.motor, state.freqDrive, state.setVfdObj,])
    const [motors, setMotors] = useState([]);
    const [freqDrives, setFreqDrives] = useState([]);
    const [settings, setSettings] = useState([]);
    const [extraFanData, setExtraFanData] = useState({ maxFanSpeed: 0, cfmTotal: 0 });
    const [formData, handleLocal] = useFields({ energyCost: "", demandSavings: "" })
    const messanger = useNotification();

    useEffect(() => {
        setRecommendedFans([])
        async function getPartsAndSettings() {
            const freqDrives1 = await SheetsApi.getBidPartsByCategory({ category: "freqDrive" });
            const motors1 = await SheetsApi.getBidPartsByCategory({ category: "motor" });
            const settings1 = await SheetsApi.getSettings()
            return { freqDrives1, motors1, settings1 };
        }
        getPartsAndSettings().then(({ freqDrives1, motors1, settings1 }) => {
            setMotors(motors1);
            setFreqDrives(freqDrives1);
            setSettings(settings1);
        })
        if (edit) {
            const vfdFormData = {
                energyCost: bidData.bidBase.energyCost,
                demandSavings: bidData.bidBase.demandSavings,
            }
            handleLocal("replaceObj", vfdFormData)
        }
    }, [])

    const getCfmCalculation = async function () {
        try {
            let tonsValue;
            if (selectWeight === 'tons') {
                tonsValue = tons
            } else if (selectWeight === 'cwt') {
                tonsValue = cwt / 20
            }
            const optimalFanSpeed = .81
            const newCfm = bidBase.requestedCfm / optimalFanSpeed;
            const newTotalCfm = tonsValue * newCfm;
            const fetchedFansNoSerPart = fetchedFans.filter(part => part.partNumber !== "SERPART");
            const result = findClosestCombination(fetchedFansNoSerPart, newTotalCfm);
            setExtraFanData({ maxFanSpeed: result.maxFanSpeed, cfmTotal: result.cfmTotal })
            const maxFanSpeed = parseFloat((result.maxFanSpeed).toFixed(2));
            const refinedFanArray = [];
            result.fanArray.forEach((fan) => {
                // Refine the result by combining fans together that have the same part number
                // This happens because sometimes the best combination of fans is 2 of the same fan.
                // But both get added beacuse the first loop only adds fans UNDER the target CFM.
                const existingFan = refinedFanArray.find((refinedFan) => refinedFan.partNumber === fan.partNumber);
                if (existingFan) {
                    const existingFanIndex = refinedFanArray.findIndex((refinedFan) => refinedFan.partNumber === fan.partNumber);
                    refinedFanArray[existingFanIndex].qty += fan.qty;
                } else {
                    refinedFanArray.push(fan);
                }
            });
            const newParts = { refinedFanArray, motors, freqDrives }
            const currentParts = { currFans, currMotors, currFreqDrives }
            const oldAndNewParts = await Calculator.prepairOldAndNewFans(newParts, settings, currentParts);
            const vfdTable = await Calculator.compareFansPowerUsage(maxFanSpeed, bidBase.fanSpeed, oldAndNewParts, bidBase.actualCfm);
            setVfdObj(vfdTable)
            setRecommendedFans(refinedFanArray)
        } catch (err) {
            console.error(err)
            return messanger("Before getting the optimal CFM calculation, you must fill out the requested CFM and CWT / TONS inputs and have at least 1 fan & motor selected with a quantity.", "warning");
        }
    }

    const findClosestCombination = (array, targetCfm) => {
        // Checks available fans and creates a combination of 1-2 different part numbers
        // to get the optimal air flow at a fan speed of 81%
        const buildingType = bidBase.buildingType;
        let requireEvenFanNumbers = false;
        if (buildingType === 'Dual Fan House Plenum' ||
            buildingType === 'Split Plenum' ||
            buildingType === 'Single Dual Fan House Plenum' ||
            buildingType === 'Split Dual Fan House Plenum') {
            requireEvenFanNumbers = true;
        }
        const newFanArray = [];
        let lowestFanCountArray = [];
        let lowestFanCount = Infinity;
        array.forEach((fan) => {
            // Calculate how many of a single part number (unique fan) is required to get UNDER the target Cfm
            const staticPressure2 = `staticPressure${staticPressure.toString().replace(/\./g, '')}`
            const cfm = fan.additionalData[staticPressure2];
            const fansQty = Math.floor(targetCfm / cfm);
            // Round down because we can only ever add fans.
            const newFanObj = {
                totalFanQty: fansQty,
                cfmTotal: fansQty * cfm,
                fanArray: [{
                    ...fan,
                    qty: fansQty
                }],
            };
            newFanArray.push(newFanObj)
            //Keep track of the lowest qty of fans
            if (fansQty < lowestFanCount) {
                // if its lower, then reset the array to only have the current fan.
                lowestFanCount = fansQty;
                lowestFanCountArray = [newFanObj];
            } else if (fansQty === lowestFanCount) {
                // if the count is the same then add to the array
                lowestFanCountArray.push(newFanObj)
            }
        })
        lowestFanCountArray.forEach((lowCountFanObj) => {
            // For every fan (part number) that has the lowest fan count
            // get the difference in cfm
            const cfmDifference = targetCfm - lowCountFanObj.cfmTotal;
            array.forEach((fan) => {
                // compare that fan to the original fan array and find the best combination of fans to meet the target cfm
                const cfm = fan.cfm;
                const ratio = cfmDifference / cfm;
                const fansQty = Math.round(ratio);
                const cfmTotal = (fansQty * cfm) + lowCountFanObj.cfmTotal;
                const refinedLowCountFanObj = {
                    cfmTotal: lowCountFanObj.cfmTotal,
                    totalFanQty: lowCountFanObj.totalFanQty,
                    ...lowCountFanObj.fanArray[0]
                }
                const newFanObj = {
                    totalFanQty: fansQty + lowCountFanObj.totalFanQty,
                    cfmTotal: cfmTotal,
                    fanArray: [refinedLowCountFanObj,
                        { ...fan, qty: fansQty, cfmTotal: fansQty * cfm },
                    ]
                }
                newFanArray.push(newFanObj);
            })
        })
        function findBestCfm(targetNumber, newFanArray) {
            let smallestDifference = Infinity;
            let bestFanCombo = null;
            for (let i = 0; i < newFanArray.length; i++) {
                const fanCombo = newFanArray[i];
                // Check if even fan quantity is required and if the current combo meets the requirement
                if (requireEvenFanNumbers && fanCombo.totalFanQty % 2 !== 0) {
                    // Skip combos that dont meet the building requirements
                    continue;
                } else if (fanCombo.totalFanQty > 13) {
                    // Skip combos that are too large
                    continue;
                }
                const difference = Math.abs(targetNumber - fanCombo.cfmTotal)
                if (difference < smallestDifference) {
                    // Set the best possible fan combo outside of the loop
                    // calculate the max fan speed for later use
                    const actualCfm = (fanCombo.cfmTotal / bidBase.tons)
                    const fanSpeed = (bidBase.requestedCfm / actualCfm) * 100
                    fanCombo.maxFanSpeed = fanSpeed
                    smallestDifference = difference;
                    bestFanCombo = fanCombo;
                }
            }
            console.log(bestFanCombo)
            return bestFanCombo;
        }
        return findBestCfm(targetCfm, newFanArray)
    }

    return (
        <>
            <Box m={3}>
                <Box>
                    <TableContainer sx={{ maxWidth: 600 }} component={Paper}>
                        <Table sx={{ maxWidth: 600 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Typography variant="body1" fontWeight="bold">
                                            Fan Speed: {extraFanData.maxFanSpeed.toFixed(2)}% @ {bidBase.requestedCfm} CFM
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1" fontWeight="bold">
                                            Total CFM: {extraFanData.cfmTotal}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Part Number</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recommendedFans.length > 0 &&
                                    recommendedFans.map((fan) => (
                                        <TableRow
                                            key={fan.partNumber}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{fan.qty}</TableCell>
                                            <TableCell>{fan.partNumber}</TableCell>
                                            <TableCell>{fan.description}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex">
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ mt: 2, ml: 2, maxHeight: 40 }}
                            onClick={getCfmCalculation}
                        >
                            Get Optimal CFM
                        </Button>
                        <TextField
                            name="energyCost"
                            label="Energy Cost ($/kWh)"
                            value={formData.energyCost}
                            onChange={handleLocal}
                            onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                            type="number"
                            sx={{ ml: 2, mt: 2, width: 150 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment
                                        position="start">
                                        $
                                    </InputAdornment>,
                            }}
                        >

                        </TextField>
                        <TextField
                            name="demandSavings"
                            label="Est. Demand Savings"
                            value={formData.demandSavings}
                            onChange={handleLocal}
                            onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                            type="number"
                            sx={{ ml: 2, mt: 2, width: 150 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment
                                        position="end">
                                        %
                                    </InputAdornment>,
                            }}
                        >
                        </TextField>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Vfd;
