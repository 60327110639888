import React, { useEffect } from "react";
import {
    TextField, InputAdornment
} from "@mui/material";
import { Masonry } from "@mui/lab"
import useFields from "../../../Hooks/useFields";
import useChemAppStore from "../../../stores/chemAppStore";

function PulpTemps({ id }) {
    const INITIAL_STATE = {
        [`bay${id}`]: "",
        [`temp${id}_1`]: "",
        [`temp${id}_2`]: "",
        [`temp${id}_3`]: "",
        [`temp${id}_4`]: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))

    useEffect(() => {
        const pulpTempObj = {
            [`bay${id}`]: chemAppForm[`bay${id}`],
            [`temp${id}_1`]: chemAppForm[`temp${id}_1`],
            [`temp${id}_2`]: chemAppForm[`temp${id}_2`],
            [`temp${id}_3`]: chemAppForm[`temp${id}_3`],
            [`temp${id}_4`]: chemAppForm[`temp${id}_4`],
        }
        handleLocal("replaceObj", pulpTempObj)
    }, [])

    const handleChange = function (e) {
        const name = e.target.name
        let value = formData[name]
        setChemAppForm(name, value)
    }

    return (
        <div>
            <Masonry columns={{ xs: 2, sm: 6 }} >
                <TextField
                    name={"bay" + id}
                    id={"bay" + id}
                    label="Bay"
                    value={formData["bay" + id] || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
                <TextField
                    name={"temp" + id + "_1"}
                    id={"temp" + id + "_1"}
                    type="number"
                    value={formData["temp" + id + "_1"] || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            °</InputAdornment>,
                    }}
                />
                <TextField
                    name={"temp" + id + "_2"}
                    id={"temp" + id + "_2"}
                    type="number"
                    value={formData["temp" + id + "_2"] || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            °</InputAdornment>,
                    }}
                />
                <TextField
                    name={"temp" + id + "_3"}
                    id={"temp" + id + "_3"}
                    type="number"
                    value={formData["temp" + id + "_3"] || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            °</InputAdornment>,
                    }}
                />
                <TextField
                    name={"temp" + id + "_4"}
                    id={"temp" + id + "_4"}
                    type="number"
                    value={formData["temp" + id + "_4"] || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            °</InputAdornment>,
                    }}
                />

            </Masonry>
        </div>
    )
}

export default PulpTemps;