import React, { useEffect } from "react";
import useFields from "../../../../../../Hooks/useFields";
import {
    Box, TextField, InputAdornment,
} from "@mui/material";

function BidUnitCooler({ handleGlobalState, edit, coolerData }) {
    const INITIAL_STATE = {
        coolerAmbientTemp: "",
        coolerSuctionTemp: "",
        capacity: '',
        fans: '',
        cfm: '',
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE)

    useEffect(() => {
        if (edit && coolerData) {
            const unitCoolerData = {
                coolerAmbientTemp: coolerData.coolerAmbientTemp,
                coolerSuctionTemp: coolerData.coolerSuctionTemp,
                capacity: coolerData.capacity,
                fans: coolerData.fans,
                cfm: coolerData.cfm,
            }
            handleLocal("replaceObj", unitCoolerData)
        }
    }, [])
    return (
        <>
            <Box ml={1} display="inline">
                <TextField
                    name="coolerAmbientTemp"
                    id="coolerAmbientTemp"
                    label="Ambient"
                    type="number"
                    sx={{ width: "13ch" }}
                    value={formData.coolerAmbientTemp || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                    InputProps={{
                        endAdornment:
                            <InputAdornment
                                position="end">
                                °F
                            </InputAdornment>,
                    }}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="coolerSuctionTemp"
                    id="coolerSuctionTemp"
                    label="Suction"
                    type="number"
                    sx={{ width: "13ch" }}
                    value={formData.coolerSuctionTemp || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                    InputProps={{
                        endAdornment:
                            <InputAdornment
                                position="end">
                                °F
                            </InputAdornment>,
                    }}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="capacity"
                    id="capacity"
                    label="Capacity"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.capacity || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="fans"
                    id="fans"
                    label="Fans"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.fans || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="cfm"
                    id="cfm"
                    label="CFM"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.cfm || ""}
                    onChange={handleLocal}
                    onBlur={handleGlobalState}
                />
            </Box>
        </>
    )
}
export default BidUnitCooler;