import React, { useEffect, useState, useContext } from 'react';
import {
    Page, Document, StyleSheet, PDFViewer, Font, View, Text, Image,
} from '@react-pdf/renderer';
import useImageStore from '../../stores/imageStore';
import dayjs from 'dayjs'
function ReceiptsPdfBase() {
    const [receiptsToPrint] = useImageStore(state => [state.receiptsToPrint])

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf', fontWeight: 700, fontStyle: 'bold' },
        ],
    })

    const styles = StyleSheet.create({
        page: {
            margin: 10,
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 30,
            paddingBottom: 50,
            flexGrow: 1,
            fontSize: 12,
            fontFamily: "Roboto",
        },
        image: {
            width: '70%', height: '70%',
        },
        dataView: {
            paddingTop: 4,
        },
    });

    return (<>
        <PDFViewer width={1200} height={1200}>
            <Document>
                {receiptsToPrint.length > 0 && receiptsToPrint.map(receipt => {
                    return <Page key={receipt.receiptData.id} style={styles.page}>
                        {receipt.downloadURL ?
                            <Image style={styles.image} src={receipt.downloadURL} /> :
                            <Text>The image failed to load</Text>
                        }
                        <View style={styles.dataView}>
                            <Text>ID# {receipt.receiptData.id}</Text>
                            <Text>Description: {receipt.receiptData.description}</Text>
                            <Text>Username: {receipt.receiptData.selectedUser}</Text>
                            <Text>Date: {dayjs(receipt.receiptData.receiptDate).format('MM/DD/YYYY')}</Text>
                            <Text>Department: {receipt.receiptData.receiptDept}</Text>
                            <Text>Customer: {receipt.receiptData.customer}</Text>
                            <Text>Part #: {receipt.receiptData.partNumber}</Text>
                            <Text>Large Job #: {receipt.receiptData.largeJob}</Text>
                            <Text>Vehicle: {receipt.receiptData.vehicle}</Text>
                        </View>
                    </Page>
                })}
            </Document>
        </PDFViewer>
    </>)
}

export default ReceiptsPdfBase;