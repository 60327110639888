import React, { useState, useContext } from "react";
import UserContext from "../../Users/UserContext";
import { NavLink } from "react-router-dom";
import {
    Box, Typography, Divider, Collapse, ListItem,
    ListItemText, List
} from "@mui/material"
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function ServiceViewerMenu({ handleDrawerClose, salesNotifications }) {
    const { currentUser } = useContext(UserContext);
    const theme = useTheme();
    const defaultStyle = { textDecoration: 'none', color: "#004e7c" }
    const primary = theme.palette.primary.main
    const gray = theme.palette.default.secondary;
    const [open, setOpen] = useState(false)

    return (
        <>
            {currentUser.dept === 'admin' || currentUser.dept === 'sales' || currentUser.account_level === 'Manager' ?
                <Box
                    onClick={() => setOpen(!open)}
                    sx={{
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                        m: 2,
                    }}>
                    <Typography variant="h6">Service Viewer</Typography>
                    {salesNotifications > 0 &&
                        <Box
                            mt={.5}
                            style={{
                                width: "24px",
                                height: "24px",
                                backgroundColor: primary,
                                borderRadius: "50%"
                            }}
                        >
                            <Box display="flex" justifyContent="space-around">
                                <Typography>{salesNotifications}</Typography>
                            </Box>
                        </Box>
                    }
                    {open ? <ExpandLess sx={{ color: 'white' }} /> :
                        <ExpandMore sx={{ color: 'white' }} />}
                </Box>
                : null
            }
            <Collapse in={open}>
                <List>
                    <NavLink to="/serviceViewer" style={defaultStyle}>
                        <ListItem onClick={handleDrawerClose} sx={{ paddingLeft: 5 }}>
                            <ListItemText sx={{ color: 'white' }} primary="Notifications" />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/serviceViewer/calendar" style={defaultStyle}>
                        <ListItem onClick={handleDrawerClose} sx={{ paddingLeft: 5 }}>
                            <ListItemText sx={{ color: 'white' }} primary="Calendar" />
                        </ListItem>
                    </NavLink>
                </List>
            </Collapse>
            <Divider sx={{ borderColor: gray }} />
        </>
    )
}

export default ServiceViewerMenu;