import React, { useEffect, useState } from "react";
import {
    Card, CardContent, CardHeader, Box, Divider, CardActions, TextField,
    Button, ListItem, ListItemText, List, Select, MenuItem, FormControl, InputLabel,
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";

function CalendarSettings() {
    const [formData, handleLocal] = useFields({});
    const messanger = useNotification();
    const [cals, setCals] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const getData = async function () {
            const calendars = await SheetsApi.getCal()
            const companies = await SheetsApi.getAllCompanies();
            return { calendars, companies }
        }
        getData().then((data) => {
            setCals(data.calendars)
            const companies = data.companies.companies.map(company => company.name)
            setCompanies(companies)
        })
    }, [])

    const addNew = async function () {
        if (formData.calName && formData.company) {
            const response = await SheetsApi.addCal({
                name: formData.calName.trim(),
                company: formData.company
            })
            messanger(response.message, response.error ? "error" : "success");
            if (!response.error) {
                setCals([...cals, response.data]);
            }
        } else {
            messanger("Please enter a valid name and company before submiting", "warning");
        }
    }

    const removeCal = async function (id) {
        const response = await SheetsApi.removeCal({ id });
        const newCalArray = cals.filter((cal) => cal.id !== id);
        setCals(newCalArray);
        messanger(response.message, response.error ? "error" : "success");
    }

    return (<Box m={10}>
        <Card sx={{ width: '90ch' }} >
            <Box m={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <CardHeader title="Calendar Settings" />
                    <Divider sx={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        flexGrow: 1,
                        borderColor: 'black',
                    }} />
                </Box>
                <Divider sx={{
                    marginRight: '10px',
                    flexGrow: 1,
                    borderColor: 'black',
                }} />
            </Box>
            <CardContent>
                <List>
                    {cals.length > 0 && cals.map((cal) => {
                        return <Box key={cal.id}>
                            <ListItem>
                                <ListItemText primary={`ID# ${cal.id} - ${cal.name} - ${cal.company}`} />
                                <Button onClick={() => removeCal(cal.id)}>
                                    DELETE
                                </Button>
                            </ListItem>
                            <Divider />
                        </Box>
                    })}
                </List>
                <CardActions>
                    <Box display="flex" gap={1} justifyContent="space-between">
                        <TextField
                            name="calName"
                            id="calName"
                            label="Calendar Name"
                            sx={{ width: '300px' }}
                            value={formData.calName || ""}
                            onChange={handleLocal}
                        />
                        <FormControl>
                            <InputLabel>Company</InputLabel>
                            <Select
                                name="company"
                                id="company"
                                label="Company"
                                value={formData.company || ""}
                                onChange={handleLocal}
                                sx={{ width: "300px" }}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {companies.length > 0 && companies.map((name) => {
                                    return <MenuItem
                                        key={name}
                                        value={name}>
                                        {name}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={addNew}>Add new</Button>
                    </Box>
                </CardActions>
            </CardContent>
        </Card>
    </Box>)
}

export default CalendarSettings;