import React, { useEffect } from "react";
import {
    TextField, FormControl, Autocomplete,
} from "@mui/material";
import useFields from "../../Hooks/useFields";

function SearchableInput({ edit, editData, setState, searchableArray, label, variableName, fullwidth }) {
    // An input that allows the user to search through specified options
    // setState should always be the formData which comes from useFields
    // variableName is the key that is used for the inputs field's value
    // This component assumes that the objects within searchableArray have the "description" key
    const [formData, handleLocal] = useFields({ [variableName]: "" })
    const handleChange = (e) => {
        if (!e) return
        let value = e.target.value;
        if (value === 0) value = e.target.innerText;
        handleLocal("invalidInput", variableName, value)
    };

    const isValid = (e) => {
        const value = e.target.value.trim();
        const foundObj = searchableArray.find(o => o[variableName].trim().toLowerCase() === value.trim().toLowerCase())
        if (foundObj) {
            handleLocal("invalidInput", variableName, foundObj[variableName]);
            setState("replaceObj", foundObj);
        } else {
            handleLocal("invalidInput", variableName, "");
            setState("replaceObj", { [variableName]: "", description: "", price: "" });
        }
    };

    useEffect(() => {
        if (edit && editData) {
            handleLocal("invalidInput", variableName, editData);
        }
    }, [])

    return (
        <FormControl sx={{ width: fullwidth ? '100%' : null }}>
            <Autocomplete
                disableClearable
                inputValue={formData[variableName] || ""}
                onInputChange={handleChange}
                onBlur={isValid}
                name={variableName}
                id={variableName}
                options={searchableArray || []}
                getOptionLabel={(option) => option[variableName]}
                filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter((option) => {
                        const variableNameValue = option[variableName] || "";
                        const descriptionValue = option.description || "";
                        return (
                            variableNameValue.toLowerCase().includes(inputValue) ||
                            descriptionValue.toLowerCase().includes(inputValue)
                        );
                    });
                }}
                // Use isOptionEqualToValue to customize how options are compared to the value
                isOptionEqualToValue={(option, value) => {
                    return option[variableName] === value[variableName];
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="value"
                        sx={{ width: fullwidth ? '100%' : "24ch" }}
                        fullWidth
                        id="value"
                        label={label}
                    />
                )}
            />
        </FormControl>

    );
}

export default SearchableInput;
