import { useSnackbar } from 'notistack';

function useNotification() {
    const action = function () {

    }
    const { enqueueSnackbar } = useSnackbar();
    const messanger = (message, type, actionArray) => {
        if (type === 'downloadCard') {
            return enqueueSnackbar(message, {
                variant: 'downloadCard',
                persist: true,
                action: action,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } else {
            return enqueueSnackbar(message, {
                variant: type,
                persist: false,
                autoHideDuration: 7000,
            })
        }
    };

    return messanger;
}

export default useNotification;
