import { create } from "zustand"

const emptyActuator = {
    actuatorId: 0,
    qty: 0,
    partNumber: "",
    description: "",
    price: "",
};
const emptyHumidifier = {
    humidifierId: 0,
    qty: 0,
    partNumber: "",
    description: "",
    price: 0,
    total: 0,
};
const emptyControl = {
    controlId: 0,
    qty: 0,
    partNumber: "",
};
const emptyClimaCell = {
    climaCellId: 0,
    qty: 0,
    partNumber: "",
    description: "",
    price: "",
    total: "",
};
const emptyClimaCellTotals = {
    climaCellQty: 0,
    climaCellWidth: 12,
    climaCellHeight: 12,
    climaCellDepth: 12,
    climaCellMediaPartNumber: "",
};
const emptyVariance = {

};
const emptyTotals = {
    actuatorTotal: 0,
    climaCellTotal: 0,
    humidifierTotal: 0,
    controlTotal: 0,
    equipmentTotal: 0,
    taxTotal: 0,
};
const emptyRefrigeration = {
    refrigerationId: 0,
    
};
const useBidPartsStore = create((set) => ({
    actuator: [emptyActuator],
    climaCell: [emptyClimaCell],
    climaCellStatic: [],
    control: [emptyControl],
    humidifier: [emptyHumidifier],
    climaCellTotals: [emptyClimaCellTotals],
    variance: [emptyVariance],
    totals: [emptyTotals],
    refrigeration: [emptyRefrigeration],

    resetState: function () {
        set(prevState => {
            return {
                ...prevState,
                actuator: [emptyActuator],
                climaCell: [emptyClimaCell],
                control: [emptyControl],
                humidifier: [emptyHumidifier],
                climaCellTotals: [emptyClimaCellTotals],
                variance: [emptyVariance],
                totals: [emptyTotals],
                climaCellStatic: [],
                refrigeration: [emptyRefrigeration],
            }
        })
    },
    setObj: function (category, obj, id) {
        set(prevState => {
            const objects = [...prevState[category]];
            objects[id] = { ...objects[id], ...obj };
            return { ...prevState, [category]: objects };
        })
    },

    replaceObj: function (category, obj) {
        set(prevState => {
            return { ...prevState, [category]: obj };
        })
    },

    setPart: function (category, name, value, id) {
        set(prevState => {
            const array = [...prevState[category]];
            array[id] = { ...array[id], [name]: value };
            return { ...prevState, [category]: array }
        })
    },

    addPart: function (category, maxParts) {
        set(prevState => {
            const category2 = category.charAt(0).toUpperCase() + category.slice(1);
            const parts = [...prevState[category]]
            if (parts.length < maxParts) {
                const newPart = { ...["empty" + category2], [category + "Id"]: parts.length }
                parts.push(newPart)
            }
            return { ...prevState, [category]: parts }
        })
    },

    appendPart: function (category, obj) {
        set(prevState => {
            const objects = [...prevState[category]]
            objects.push(obj)
            return { ...prevState, [category]: objects }
        })
    },
}))

export default useBidPartsStore;