import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Typography, Box, Divider, Collapse,
} from '@mui/material'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

function HistoryCard({ logs, orientation }) {
    const theme = useTheme();
    const defautBackground = theme.palette.background.default;
    const [cardStyles, setCardStyles] = useState({})
    const [render, setRender] = useState(false);
    dayjs.extend(timezone);
    dayjs.extend(utc);

    useEffect(() => {
        logs.sort((a, b) => {
            // sorts by date
            const dateA = new Date(a.timeStamp);
            const dateB = new Date(b.timeStamp);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            return 0;
        });
        logs.forEach((log) => {
            const readableDate = dayjs(log.timeStamp).tz('America/Denver').format('MM-DD-YYYY h:mm A');
            if (log.stage === 'new') {
                log.message = `Created by ${log.actionUser} on ${readableDate} MNT`;
            } else if (log.stage === 'archive') {
                log.message = `Sent to the archive by ${log.actionUser} on ${readableDate} MNT`;
            } else {
                log.message = `Updated by ${log.actionUser} on ${readableDate} MNT in ${log.stage}`;
            }
        })
        if (orientation === 'horizontal') {
            setCardStyles({
                width: '100%',
                height: '300px',
                overflowY: 'auto',
                backgroundColor: defautBackground,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
            })
        } else {
            setCardStyles({
                width: '44ch',
                height: '400px',
                overflowY: 'auto',
                backgroundColor: defautBackground,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
            }
            )
        }
        setRender(true)
    }, [])

    return (
        <>
            {render &&
                <Box className='hideForPrint' mt={1} ml={1} display="flex" flexDirection={{ xs: "column", sm: 'row' }}>
                    <Card sx={cardStyles}>
                        <Box justifyContent="space-between" display="flex">
                            <Typography sx={{ m: 3, fontSize: '1.5rem', fontWeight: 'bold', color: '#333' }} variant='h4'>Sheet History</Typography>
                            <WorkHistoryIcon sx={{ mr: 3, mt: 2, fontSize: 40 }} />
                        </Box>
                        <CardContent>
                            <Divider />
                            {logs.map((log, index) => (
                                <Box key={log.id}>
                                    <Typography key={index} sx={{ m: 3, fontSize: '1rem', color: '#666' }}>
                                        {log.message}
                                    </Typography>
                                    <Divider />
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                </Box>
            }
        </>
    )
}

export default HistoryCard;
