import React, { useEffect, useState } from 'react';
import {
    Page, Document, StyleSheet, PDFViewer, Text, View, Image,
} from '@react-pdf/renderer';
import QuoteHeader from './QuoteComponents/QuoteHeader';
import useBidStoreMain from '../../../stores/BidStore/bidStoreMain';
import useBidStoreSub from '../../../stores/BidStore/bidStoreSub';
import useBidRefrigerationStore from '../../../stores/BidStore/bidRefrigerationStore';
import useBidPartsStore from '../../../stores/BidStore/bidPartsStore';
import BuilderTables from './QuoteComponents/BuilderSizes/BuilderTables';
import refImage from '../../../images/refDiagramBidBuilderSheet.png';
function BidBuilderSizes() {
    const [bidBase, fans, freqDrives, totals] = useBidStoreMain(state =>
        [state.bidBase, state.fan, state.freqDrive, state.totals])
    const [dampers, doors] = useBidStoreSub(state => [state.damper, state.door])
    const [climaCell, humidifiers, controls] = useBidPartsStore(state =>
        [state.climaCellTotals, state.humidifier, state.control])
    const [cooler, condenser] = useBidRefrigerationStore(state =>
        [state.cooler, state.condenser])
    const [pages, setPages] = useState([])
    const [refrigerationPresent, setRefrigerationPresent] = useState(true);
    const styles = StyleSheet.create({
        page: {
            margin: 10,
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 30,
            paddingBottom: 50,
            flexGrow: 1,
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
            flexDirection: 'row',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '100%',
            borderBottom: 1,
        },
        header: { borderTop: 'none' },
        container2: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
        },
        box: { padding: '10px' },
        underline: { textDecoration: 'underline' },
        flexDirection: { flexDirection: 'row' },
        qtyRow: { width: '5%', fontSize: '10px' },
        descriptionRow: { width: '70%', fontSize: '10px' },
        electricalRow: { width: '25%', fontSize: '10px' },
        electricalRow2: { width: '40%', fontSize: '10px' },
        electricalTitle: { width: '25%' },
        title: { width: '75%' },
        table: { marginTop: 10 },
        etcRow: { marginLeft: 27, fontSize: '10px' },
    })

    useEffect(() => {
        const partNumbersToFind = ['PNAS2O', 'PNAS2DBL', 'PNASSYBOX24'];
        const foundControls = controls.filter((control) =>
            partNumbersToFind.includes(control.partNumber)
        );
        const ductPipeQty = function () {
            if (bidBase.pipeSize === 18) return Math.ceil(bidBase.pipeSize / 1.76)
            else if (bidBase.pipeSize === 21) return Math.ceil(bidBase.pipeSize / 2.41)
            else if (bidBase.pipeSize === 24) return Math.ceil(bidBase.pipeSize / 3.14)
            else return 0
        };
        const ductPipeQty1 = ductPipeQty()
        const allTables = [
            fans.length > 0 ? {
                data: fans,
                title: 'Aerovent Galvanized Panel Fans:',
                description: '54 1/4" x 54 1/4" Framed Openings',
                electricalDescription: false,
            } : null,
            freqDrives.length > 0 ? {
                data: freqDrives,
                title: 'Frequency Drives:',
                description: false,
                electricalDescription: 'Amp Circuits(s)',
            } : null,
            doors.length > 0 ? {
                data: doors,
                title: 'ClimaSeal Fresh Air Doors:',
                description: false,
                electricalDescription: '120V 20Amp Circuit',
                extraElectrical: 'Heat Tape',
            } : null,
            climaCell.length > 0 ? {
                data: climaCell,
                title: 'ClimaCell Evaporative Cooler:',
                description: false,
                electricalDescription: '120V 20Amp Circuit',
                extraElectrical: 'Water Pump',
            } : null,
            humidifiers.length > 0 ? {
                data: humidifiers,
                title: 'Gellert Bahnson Humidifiers:',
                description: false,
                electricalDescription: '120V 20Amp Circuit',
            } : null,
            dampers.length > 0 ? {
                data: dampers,
                title: 'Ruskin Aluminum Exhaust Dampers:',
                description: false,
                electricalDescription: false,
            } : null,
            foundControls.length > 0 ? {
                data: foundControls,
                title: 'Controls:',
                description: false,
                electricalDescription: '120V 20Amp Circuit',
            } : null,
            {
                data: [{ qty: Math.ceil(totals.totalCfm / 1000) }],
                title: 'Plunum Minimum Dimensions:',
                description: `Cross Sectional Sq. Ft.`
            },
            ductPipeQty1 ? {
                data: [{ qty: ductPipeQty1 }],
                title: 'Duct Pipe Minimums:',
                description: 'Rows of Pipe',
            } : null,
            {
                data: [cooler],
                title: 'Refrigeration Evaporator Coils:',
                description: '',
                electricalDescription: '',
            },
            {
                data: [condenser],
                title: 'Refrigeration Condensing Unit:',
                description: '',
                electricalDescription: `480V 3P 200Amp Circut`,
                extraElectrical: `${2 * condenser.qty} - 120V Circuit from Condensing Unit to LLS`,
            }
        ].filter(item => item !== null);

        setPages(tablesToPageCalc(allTables));
    }, [])

    const tablesToPageCalc = function (tables) {
        let pageIndex = 0;
        let currentPageLines = 0;
        const pageArray = [[]];
        let maxLines = 22.8;
        const tableLines = 1.5

        tables.map((table) => {
            // Do some checks to make sure there is data present before creating a table
            if (table.title === 'Refrigeration Condensing Unit:' || table.title === 'Refrigeration Evaporator Coils:') {
                if (table.data[0].partNumber === "") {
                    return setRefrigerationPresent(false);
                }
            } else if (table.title === 'Duct Pipe Minimums:') {
                // Checks if Duct pipe size was selected.                
                if (table.data[0].qty === 0) return;
            }
            let totalTableLines = table.data.length + tableLines;
            if (table.extraElectrical) totalTableLines = totalTableLines + 2;
            currentPageLines += totalTableLines;
            if (currentPageLines <= maxLines) {
                return pageArray[pageIndex].push(table);
            } else if (currentPageLines >= maxLines) {
                maxLines = 34;
                pageArray.push([]);
                pageIndex++;
                currentPageLines = totalTableLines;
                return pageArray[pageIndex].push(table);
            } else return console.error("There was an error calculating the pages.")
        })
        return pageArray;
    }

    return (
        <>
            <PDFViewer width={1200} height={1200}>
                <Document>
                    {pages.map((page, index) => {
                        if (index === 0) {
                            return <Page key={index} wrap size="A4" style={styles.page}>
                                <QuoteHeader
                                    bidBase={bidBase}
                                    totals={totals}
                                    builderSizes={true}
                                    grandTotal={0}
                                />
                                {page.map((table) => {
                                    return <BuilderTables key={table.title} table={table} />
                                })}
                            </Page>
                        } else {
                            return <Page key={index} wrap size="A4" style={styles.page}>
                                {page.map((table) => {
                                    return <BuilderTables key={table.title} table={table} />
                                })}
                                {pages.length - 1 === index && <>
                                    {refrigerationPresent && <>
                                        <Text>Width: {cooler.width}" Height: {((parseInt(cooler.qty) * 3) + parseInt(cooler.height * parseInt(cooler.qty)))}" </Text>
                                        <Image src={refImage}></Image>
                                    </>}
                                </>}
                            </Page>
                        }
                    })}
                </Document>
            </PDFViewer >
        </>
    )
}

export default BidBuilderSizes;