import React, { useEffect, useState } from 'react';
import {
    Box, Card, Typography, Button, TextField, InputAdornment,
} from "@mui/material";
import useBidStoreSub from "../../../stores/BidStore/bidStoreSub";
import { shallow } from "zustand/shallow";
import SheetsApi from "../../../api";
import useFields from '../../../Hooks/useFields';
import LightBlockPart from './BidParts/LightBlockPart';
import Commas from '../BidFunctions/commas';
function LightBlock({ edit, lightBlockData }) {
    const editPass = edit;
    const lightBlockDataPass = lightBlockData;
    const [renderCheck, setRenderCheck] = useState(false);
    const [totals, setTotal, lightBlock, addLightBlock, setPart] = useBidStoreSub(state =>
        [state.totals, state.setTotal, state.lightBlock, state.addPart, state.setPart], shallow)
    const damperSqFtTotal = totals.damperSqFtTotal
    const [localLightBlock, setLocalLightBlock] = useState([])
    const [formData, handleLocal] = useFields({})

    const handleLightBlockTotal = function () {
        let priceTotal = 0;
        let sqFtTotal = 0;
        lightBlock.forEach((lb) => {
            priceTotal = parseFloat((priceTotal + lb.priceTotal).toFixed(2)) || 0;
            sqFtTotal = parseFloat((sqFtTotal + lb.totalSqFt).toFixed(2)) || 0;
        })
        const variance = parseFloat((((sqFtTotal / damperSqFtTotal) - 1) * 100).toFixed(2))
        setPart("variance", "lightBlock", variance, 0)
        handleLocal("invalidInput", "variance", variance)
        handleLocal("invalidInput", "totalSqFt", sqFtTotal)
        handleLocal("invalidInput", "total", priceTotal)
        setTotal("lightBlockTotal", priceTotal)
    }

    useEffect(() => {
        handleLocal("invalidInput", "totalSqFtReq", damperSqFtTotal)
        handleLightBlockTotal()
    }, [damperSqFtTotal])

    useEffect(() => {
        if (edit) {
            for (let i = 1; i < lightBlockData.length; i++) {
                addLightBlock("lightBlock", 3)
            }
        }
        async function getLightBlock() {
            const serPart = {
                qty: "",
                partNumber: "SERPART",
                description: "",
                price: 0,
                additionalData: {},
            }
            let lightBlock = await SheetsApi.getBidPartsByCategory({ category: "lightBlock" })
            setLocalLightBlock([...lightBlock, serPart])
            setRenderCheck(true)
        }
        getLightBlock()
    }, [])

    return (
        <>
            <Box ml={1} mt={1}>
                <Card sx={{ width: '130ch' }} >
                    <Box m={2}>
                        <Typography sx={{ textDecoration: "underline" }} ml={4} variant="h5">
                            Total•E•Clips™ Light Block
                        </Typography>
                    </Box>
                    {renderCheck && lightBlock.map((lb) => {
                        return <LightBlockPart
                            key={lb.lightBlockId}
                            id={lb.lightBlockId}
                            localLightBlock={localLightBlock}
                            handleLightBlockTotal={handleLightBlockTotal}
                            edit={editPass}
                            lightBlockData={lightBlockDataPass}
                        />
                    })}
                    <Box
                        mb={1}
                        display="flex"
                        justifyContent="space-between">
                        <Box align="left" mr={1} ml={1}>
                            <Button
                                variant='contained'
                                onClick={() => addLightBlock("lightBlock", 3)}>
                                Add Light Block
                            </Button>
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <TextField
                                id="totalSqFtReq"
                                name="totalSqFtReq"
                                label="Total SQ. FT. Required"
                                variant="standard"
                                sx={{ width: "16ch" }}
                                value={formData.totalSqFtReq || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="totalSqFt"
                                name="totalSqFt"
                                label="Total SQ. FT."
                                variant="standard"
                                sx={{ width: "10ch" }}
                                value={formData.totalSqFt || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="variance"
                                name="variance"
                                label="Variance"
                                variant="standard"
                                error={
                                    formData.variance >= -10
                                        && formData.variance <= 10
                                        ? false : true
                                }
                                sx={{ width: "8ch" }}
                                value={formData.variance || ""}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            %
                                        </InputAdornment>,
                                    readOnly: true
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box ml={2} mb={1} mr={2} align="right">
                            <TextField
                                id='total'
                                name='total'
                                label=" "
                                sx={{ width: '12ch' }}
                                value={Commas.add(formData.total) || ""}
                                variant="standard"
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default LightBlock;