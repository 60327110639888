import React from "react";
import {
    Dialog, DialogTitle, DialogContent, TextField,
    DialogActions, Button, Typography,
} from "@mui/material";

function ImagePopUp({ open, setOpen, setReceipt }) {
    function receiptStatus(status) {
        setReceipt(status);
        return setOpen(false);
    }
    return (<>
        <Dialog open={open} onClose={() => setOpen(false)} >
            <DialogTitle>Is this a Receipt?</DialogTitle>
            <DialogContent>
                <DialogActions>
                    <Button onClick={() => receiptStatus(true)}>Yes</Button>
                    <Button onClick={() => receiptStatus(false)}>No</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </>)
}

export default ImagePopUp;