import React, { useState } from "react";
import {
    Box, FormControl, Select, MenuItem, InputLabel, Button,
    FormControlLabel, Checkbox,
} from "@mui/material"
import { useHistory, useParams } from "react-router-dom"
import useFields from "../../../../Hooks/useFields";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import useNotification from "../../../../Hooks/useNotification";
import TermsPopUp from "./TermsPopUp";

function PdfSelect({ handleSubmit }) {
    const messanger = useNotification();
    const history = useHistory()
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const [formData, handleLocal] = useFields({ pdf: "generateQuote", pipeSize: 0, noPrices: true });
    const [setBidBase, bidBase, recommendedFans] = useBidStoreMain(state =>
        [state.setBidBase, state.bidBase, state.recommendedFans]);

    const handlePdfGeneration = function () {
        if (bidBase.showVfdRec) {
            if (recommendedFans.length < 1) {
                return messanger("Before you can get the VFD recommendation you must click the 'GET OPTIMAL' button at the top of the page.", 'warning')
            }
        }
        handleSubmit()
        return history.push(`/bids/${formData.pdf}/${id}`)
    }

    return (
        <>
            <Box display="flex" justifyContent="left" m={1}>
                <TermsPopUp open={open} setOpen={setOpen} />
                <Box>
                    <FormControl>
                        <InputLabel>Select PDF to generate</InputLabel>
                        <Select
                            inputlabelprops={{ shrink: true }}
                            id="pdf"
                            name="pdf"
                            label="Select PDF to generate"
                            onChange={handleLocal}
                            value={formData.pdf || "generateQuote"}
                            sx={{ input: { background: "white" }, width: "28ch" }}
                        >
                            <MenuItem value="generateQuote">
                                Ventilation Quote
                            </MenuItem>
                            <MenuItem value="refrigerationQuote">
                                Refrigeration Quote
                            </MenuItem>
                            <MenuItem value="generateGellertOrder">
                                Gellert Order
                            </MenuItem>
                            <MenuItem value="builderSizes" >
                                Builder Sizes
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} mt={1.3}>
                    <Button
                        onClick={handlePdfGeneration}
                        size="small"
                        variant="contained">
                        Generate PDF
                    </Button>
                </Box>
            </Box>
            {formData.pdf === "generateQuote" ?
                <Box display="flex" justifyContent="flex-start">
                    <Box ml={1}>
                        <Button onClick={() => setOpen(true)} >Edit Terms</Button>
                    </Box>
                    <Box ml={2}>
                        <FormControlLabel
                            control={<Checkbox
                                name="noVentilation"
                                onChange={handleLocal}
                                onBlur={(e) => setBidBase("noVentilation", e.target.checked)}
                                checked={formData.noVentilation || false}
                            />}
                            label="Hide Ventilation"
                            labelPlacement="end"
                        />
                    </Box>
                    <Box ml={2}>                    
                        <FormControlLabel
                            control={<Checkbox
                                name="noPrices"
                                onChange={handleLocal}
                                onBlur={(e) => setBidBase("noPrices", e.target.checked)}
                                checked={formData.noPrices}
                            />}
                            label="Hide Prices"
                            labelPlacement="end"
                        />
                    </Box>
                    <Box ml={2}>
                        <FormControlLabel
                            control={<Checkbox
                                name="showVfdRec"
                                onChange={handleLocal}
                                onBlur={(e) => setBidBase("showVfdRec", e.target.checked)}
                                checked={formData.showVfdRec || false}
                            />}
                            label="Show VFD Recommendation"
                            labelPlacement="end"
                        />
                    </Box>
                </Box>
                : formData.pdf === 'builderSizes' ? <>
                    <Box ml={2} display="flex" justifyContent="flex-start">
                        <FormControl>
                            <InputLabel>Select pipe size</InputLabel>
                            <Select
                                inputlabelprops={{ shrink: true }}
                                id="pipeSize"
                                name="pipeSize"
                                label="Select pipe size"
                                onChange={handleLocal}
                                onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                value={formData.pipeSize || 0}
                                sx={{ width: "18ch" }}
                            >
                                <MenuItem value={0}><em>None</em></MenuItem>
                                <MenuItem value={18}> 18" </MenuItem>
                                <MenuItem value={21}> 21" </MenuItem>
                                <MenuItem value={24}> 24" </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </> : null}
        </>
    )
}

export default PdfSelect;