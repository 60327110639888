import React, { useContext, useState } from "react";
import {
    Box, Typography, Card, CardContent, Button,
} from "@mui/material";
import ESign from "../../SheetComponents/images/ESign";
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import UserContext from "../../../Users/UserContext";
import HandleImage from "../../SheetComponents/images/imageUtilities/HandleImage";
import useImageStore from "../../../stores/imageStore";
import useNotification from "../../../Hooks/useNotification";
import { useHistory } from "react-router-dom";

function BidSignatureAndSave() {
    const [bidBase] = useBidStoreMain(state => [state.bidBase])
    const history = useHistory();
    const messanger = useNotification()
    const { currentUser } = useContext(UserContext);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [signature, signature2] = useImageStore(state =>
        [state.signature, state.signature2]);

    const handleSaveAndReturn = async function () {
        setButtonDisabled(true);
        await HandleImage.addAndRemove([signature, signature2], {
            sheet: {
                sheetType: "bid",
                dateServiced: bidBase.dateServiced,
                customerName: bidBase.customer,
                locationId: 0,
            }, id: bidBase.id
        });
        messanger("You have successfully saved the signatures", "success")
        return history.push(`/bids/edit/${bidBase.id}`)
    }

    return (
        <>
            <Card sx={{ width: 1000, mt: 5, ml: 10, mb: 5 }} elevation={8}>
                <CardContent>
                    <Typography variant="h4" >Get Signatures</Typography>
                    <Box m={5} display="flex" justifyContent="flex-start">
                        <Box ml={1}>
                            <ESign formHelperText={bidBase.customer} optionalSig={true} sheetType="bids" />
                        </Box>
                        <Box ml={8} >
                            <ESign formHelperText={currentUser.company} optionalSig={false} sheetType="bids" />
                        </Box>
                    </Box>
                    <Box justifyContent="right">
                        <Button
                            disabled={buttonDisabled}
                            onClick={handleSaveAndReturn}
                            variant="contained"
                        >
                            Save And Return to Bid
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default BidSignatureAndSave;