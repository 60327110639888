import React, { useEffect, useState, useContext } from "react";
import SheetsApi from "../../../../api";
import UserContext from "../../../../Users/UserContext";
import {
    Card, TextField, Typography, Box, Button, InputAdornment,
} from "@mui/material"
import LaborLine from "./LaborLine";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import useFields from "../../../../Hooks/useFields";
import Commas from "../../BidFunctions/commas";
function Labor({ edit, data }) {
    const laborData = data?.labor
    const editPass = edit;
    const [techs, setTechs] = useState([])
    const { currentUser } = useContext(UserContext)
    const [labor, addLaborLine, setTotal] = useBidStoreMisc(state =>
        [state.labor, state.addPart, state.setTotal])
    const [formData, handleLocal] = useFields({})

    useEffect(() => {
        if (edit) {
            for (let i = 1; i < laborData.length; i++) {
                addLaborLine("labor", 5)
            }
        }
        async function getTechs() {
            let techs;
            if (currentUser.account_level === 'Admin' && edit && data.bidBase.stage === 'Archive') {
                techs = await SheetsApi.getInactive();
            } else techs = await SheetsApi.getAllUsers();
            return techs
        }
        getTechs().then(techs => {
            setTechs(techs)
        })
    }, [])

    const handleLaborCalc = function () {
        let total = 0;
        let refrigerationTotal = 0;
        labor.map((line) => {
            const lineTotal = line.priceTotal || 0
            if (line.refrigeration) {
                return refrigerationTotal = refrigerationTotal + lineTotal;
            } else {
                return total = total + lineTotal;
            }
        })
        total = parseFloat(total.toFixed(2));
        const bothTotals = parseFloat((total + refrigerationTotal).toFixed(2));
        handleLocal("invalidInput", "total", bothTotals);
        setTotal("laborTotal", total,);
        setTotal("laborTotal", refrigerationTotal, true)
    }

    return (
        <>
            {techs.length &&
                <Box mt={1}>
                    <Card sx={{ width: '130ch' }} >
                        <Box m={2} display="flex" justifyContent="space-between">
                            <Typography sx={{ textDecoration: 'underline' }} ml={4} variant="h5">
                                Direct Labor and Fuel
                            </Typography>
                        </Box>
                        {labor.map((tech) => {
                            return <LaborLine
                                id={tech.laborId}
                                key={tech.laborId}
                                techs={techs}
                                handleLaborCalc={handleLaborCalc}
                                edit={editPass}
                                laborData={laborData}
                            />
                        })}
                        <Box
                            mb={1}
                            display="flex"
                            justifyContent="space-between">
                            <Box align="left" mb={1} mr={1} ml={1}>
                                <Button
                                    variant='contained'
                                    onClick={() => addLaborLine("labor", 5)}
                                >
                                    Add Technician
                                </Button>
                            </Box>
                            <Box ml={2} mb={1} mr={2} align="right">
                                <TextField
                                    id='total'
                                    name='total'
                                    sx={{ width: '12ch' }}
                                    value={Commas.add(formData.total) || ""}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment
                                                position="start">
                                                $
                                            </InputAdornment>,
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Box>
            }
        </>
    )
}

export default Labor;
