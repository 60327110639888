import React, { useEffect, useState, useRef } from "react";
import BidRefCondenser from "./BidComponents/BidRefrigeration/BidRefCondenser";
import BidRefCooler from "./BidComponents/BidRefrigeration/BidRefCooler/BidRefCooler";
import BidBase from "./BidComponents/BidBase";
import useBidStoreMain from "../../stores/BidStore/bidStoreMain";
import useBidStoreSub from "../../stores/BidStore/bidStoreSub";
import useBidPartsStore from "../../stores/BidStore/bidPartsStore";
import useBidStoreMisc from "../../stores/BidStore/bidStoreMisc";
import usePartsStore from "../../stores/partsStore";
import PartContainer from "./BidComponents/BidContainers/PartContainer";
import Labor from "./BidComponents/BidMisc/Labor";
import Misc from "./BidComponents/BidMisc/Misc";
import useBidRefrigerationStore from "../../stores/BidStore/bidRefrigerationStore";
import GrandTotal from "./BidComponents/BidSubmit/GrandTotal";
import { Grid } from "@mui/material"
import SheetsApi from "../../api";
import { useParams } from "react-router-dom";

function BidRefEdit() {
    const { id } = useParams()
    const componentRef = useRef()
    const [render, setRender] = useState(false)
    const [fetchedBid, setFetchedBid] = useState({})
    const [resetStateMain, setBidBaseObj] = useBidStoreMain(state =>
        [state.resetState, state.setBidBaseObj])
    const [resetStateSub] = useBidStoreSub(state => [state.resetState])
    const [resetStateMisc] = useBidStoreMisc(state => [state.resetState])
    const [resetStateParts] = useBidPartsStore(state => [state.resetState])
    const [resetStateRefrigeration] = useBidRefrigerationStore(state =>
        [state.resetState])
    const [clearPartsForm] = usePartsStore(state => [state.clearPartsForm])
    useEffect(() => {
        resetStateMain();
        resetStateSub();
        resetStateParts();
        resetStateMisc();
        clearPartsForm();
        resetStateRefrigeration();

        async function getSheet() {
            const sheet = await SheetsApi.getBidRefSheet({ id })
            return sheet;
        }

        getSheet().then(async (sheet) => {
            console.log(sheet)
            setBidBaseObj({ ...sheet.bidBase, noVentilation: true });
            setFetchedBid(sheet)
            setRender(true);
        })
    }, [])

    return (<div ref={componentRef} >
        {render && <>
            <Grid container >
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <BidBase
                        id={id}
                        data={fetchedBid.bidBase}
                        edit={true}
                    />
                    <BidRefCondenser
                        condenserData={fetchedBid.condenser}
                        id={id}
                        data={fetchedBid}
                        edit={true}
                    />
                    <BidRefCooler
                        coolerData={fetchedBid.cooler}
                        id={id}
                        data={fetchedBid}
                        edit={true}
                    />
                    <PartContainer
                        category="refrigeration"
                        title="Refrigeration Misc"
                        maxParts={8}
                        partData={fetchedBid.refrigeration}
                        edit={true}
                    />
                    <Labor
                        edit={true}
                        data={fetchedBid}
                    />
                    <Misc
                        title="Hardware and Supplies: (SerParts, Wire, Tie wraps, etc.)"
                        category="hardware"
                        miscData={fetchedBid.hardware}
                        edit={true}
                    />
                    <Misc
                        title="Equipment Rental: (Scissor Life, Fork Lift, Crane, Etc.)"
                        category="equipment"
                        miscData={fetchedBid.equipment}
                        edit={true}
                    />
                    <Misc
                        title="Sub Contractors: (Concrete, Builder, Electrical, etc.)"
                        category="contractor"
                        miscData={fetchedBid.contractors}
                        edit={true}
                    />,
                    <Misc
                        title="Other: (Licenses, Permits, Disposals, Hotels, Meals, etc.)"
                        category="other"
                        miscData={fetchedBid.other}
                        edit={true}
                    />
                    <Misc
                        title="Options:"
                        category="option"
                        miscData={fetchedBid.option}
                        edit={true}
                    />
                    <GrandTotal
                        refrigerationBid={true}
                        componentRef={componentRef}
                        edit={true}
                    />
                </Grid>
            </Grid >
        </>} </div>)
}


export default BidRefEdit;