import React, { useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import useChemAppStore from "../../../stores/chemAppStore";
import {
    Box, FormControlLabel, Checkbox,
    InputAdornment, TextField, Typography,
} from "@mui/material/"

function OutDoorConditions() {
    const INITIAL_STATE = {
        outDoorTemp: "",
        clear: false,
        cloudy: false,
        snow: false,
        rain: false,
        windy: false,
        windDirection: "",
        weatherOtherText: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))

    const handleChange = function (e, checkbox) {
        let value;
        const name = e.target.name
        if (checkbox) {
            value = e.target.checked;
        } else value = e.target.value;
        setChemAppForm(name, value)
    }
    useEffect(() => {
        const outDoorConditionsObj = {
            outDoorTemp: chemAppForm.outDoorTemp,
            clear: chemAppForm.clear,
            cloudy: chemAppForm.cloudy,
            snow: chemAppForm.snow,
            rain: chemAppForm.rain,
            windy: chemAppForm.windy,
            windDirection: chemAppForm.windDirection,
            weatherOtherText: chemAppForm.weatherOtherText,
        }
        handleLocal("replaceObj", outDoorConditionsObj)
    }, [])
    return (
        <>
            <Typography
                variant="h5">
                Outdoor Conditions:
            </Typography>
            <Box>
                <Box
                    mb={1}
                    style={{ display: 'inline-block' }}>
                    <TextField
                        name="outDoorTemp"
                        id="outDoorTemp"
                        label="Tempurature"
                        type="number"
                        style={{ width: '150px' }}
                        value={formData.outDoorTemp || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °</InputAdornment>,
                        }}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Clear'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="clear"
                            checked={formData.clear || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Cloudy'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="cloudy"
                            checked={formData.cloudy || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Snow'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="snow"
                            checked={formData.snow || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Rain'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="rain"
                            checked={formData.rain || false}
                        />}
                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label='Windy'
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e, true);
                            }}
                            name="windy"
                            checked={formData.windy || false}
                        />}
                    />
                </Box>
                <Box
                    mb={1}
                    style={{ display: 'inline-block' }}>
                    <TextField
                        name="windDirection"
                        id="windDirection"
                        label="Wind Direction"
                        style={{ width: '150px' }}
                        value={formData.windDirection || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}
                    />
                </Box>
                <TextField
                    name="weatherOtherText"
                    id="weatherOtherText"
                    label="Weather Conditions Notes"
                    fullWidth
                    value={formData.weatherOtherText || ""}
                    onChange={handleLocal}
                    onBlur={handleChange}
                />
            </Box>
        </>
    )
}

export default OutDoorConditions;