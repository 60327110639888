import React, { useEffect } from "react";
import useBayStore from "../../stores/bayStore";
import useFields from "../../Hooks/useFields";
import {
    Typography, Checkbox, Box, TextareaAutosize,
    TextField, Divider, useTheme, InputAdornment,
    RadioGroup, FormControlLabel, Radio, Button,
} from '@mui/material';
import DeltaT from "./DeltaT";
import RemoveCircle from '@mui/icons-material/RemoveCircleOutlineSharp';

function Bay({ id, item, edit }) {
    const idPass = id;
    const itemPass = item;
    const editPass = edit;
    const INITIAL_STATE = {
        bay: "",
        avgDeltaTTotal: 0,
        topPileConditions: "",
        none: false,
        eyesOpen: false,
        peeping: false,
        lessThanHalfIn: false,
        greaterThanHalfIn: false,
        sporadic: false,
        moderate: false,
        heavy: false,
        hotSpot: false,
        notes: "",
    }
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [bay, setBayForm, removeBay] = useBayStore(state => [state.bays[id], state.setBayForm, state.removeBay])
    const [formData, handleLocal] = useFields(INITIAL_STATE)

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", item)
        }
    }, [])

    useEffect(() => {
        //calculates the average delta T total
        let total = 0;
        let avgDivider = 0;
        const deltaTs = [parseFloat(bay.frontAvgDeltaT) || 0, parseFloat(bay.middleAvgDeltaT) || 0, parseFloat(bay.backAvgDeltaT) || 0]
        for (let number of deltaTs) {
            if (number !== 0) {
                avgDivider++;
                total += number;
            }
        }
        const totalAverage = parseFloat((total / avgDivider).toFixed(3));
        handleLocal("invalidInput", "avgDeltaTTotal", totalAverage)
    }, [bay.frontAvgDeltaT, bay.middleAvgDeltaT, bay.backAvgDeltaT])

    const handleChange = function (e, checkBox) {
        let value;
        const name = e.target.name
        if (checkBox === true) {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setBayForm(name, value, id);
    }

    const removeBayLocal = function (e) {
        e.preventDefault()
        removeBay(id)
    }
    return (
        <>
            <Box className={id === 2 ? "nextPage" : ""}>
                <Box mb={1} >
                    <Divider sx={{ borderBottomWidth: 3 }} />
                </Box>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Box>
                        <TextField
                            name={`bay`}
                            id={`bay${id}`}
                            label={`Bay ${id + 1}`}
                            sx={{ width: "75%" }}
                            value={formData.bay || ""}
                            onBlur={handleChange}
                            onChange={(e) => handleLocal(e)}
                        />
                        <Button
                            sx={{ paddingTop: "15px" }}
                            onClick={removeBayLocal}
                            color="secondary"
                            type="submit">
                            <RemoveCircle />
                        </Button>
                    </Box>
                    <Box m={1} justifyContent="start" display="flex">
                        <Typography variant="h5">
                            Pulp Temps:
                        </Typography>
                        <TextField
                            id="avgDeltaTTotal"
                            name="avgDeltaTTotal"
                            label="Average Delta T"
                            variant="standard"
                            value={formData.avgDeltaTTotal || 0}
                            sx={{ ml: 2, width: '13ch' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    °F</InputAdornment>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>
                <Box ml={2} mb={1} >
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topFront"
                        bottomName="bottomFront"
                        avgDeltaTName="frontAvgDeltaT"
                    />
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topMiddle"
                        bottomName="bottomMiddle"
                        avgDeltaTName="middleAvgDeltaT"
                    />
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topBack"
                        bottomName="bottomBack"
                        avgDeltaTName="backAvgDeltaT"
                    />
                    <Box>
                        <Typography
                            variant="h5">
                            Top of Pile Moisture Conditions:
                        </Typography>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>

                            <RadioGroup
                                name="topPileConditions"
                                id="topPileConditions"
                                value={formData.topPileConditions || ""}
                                onBlur={handleChange}
                                onChange={(e) => {
                                    handleLocal("invalidInput", e.target.name, e.target.value)
                                    handleChange(e)
                                }}
                                row>
                                <FormControlLabel value="Dry" control={<Radio />} label="Dry" />
                                <FormControlLabel value="Damp" control={<Radio />} label="Damp" />
                                <FormControlLabel value="Wet" control={<Radio />} label="Wet" />
                            </RadioGroup>
                        </Box>
                        <br></br>
                        <Typography
                            variant="h5">
                            Sprouting:
                        </Typography>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="None"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="none"
                                    checked={formData.none || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="Eyes Open"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="eyesOpen"
                                    checked={formData.eyesOpen || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="Peeping"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="peeping"
                                    checked={formData.peeping || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label='Less Than 1/2"'
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="lessThanHalfIn"
                                    checked={formData.lessThanHalfIn || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label='Greater Than 1/2"'
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="greaterThanHalfIn"
                                    checked={formData.greaterThanHalfIn || false}
                                />}
                            />
                        </Box>
                        <br></br>
                        <Typography
                            variant="h5">
                            Breakdown:
                        </Typography>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="Sporadic"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="sporadic"
                                    checked={formData.sporadic || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="Moderate"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="moderate"
                                    checked={formData.moderate || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label="Heavy"
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="heavy"
                                    checked={formData.heavy || false}
                                />}
                            />
                        </Box>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                label='Hot Spot'
                                control={<Checkbox
                                    onChange={(e) => {
                                        handleLocal(e)
                                        handleChange(e, true)
                                    }}
                                    name="hotSpot"
                                    checked={formData.hotSpot || false}
                                />}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            variant="h5"
                            ml={1}
                            style={{ color: defaultColor }}>
                            Notes:
                        </Typography>
                    </Box>
                    <Box ml={0.25} >
                        <TextareaAutosize
                            minRows={4}
                            style={{
                                width: "99.0%",
                                outline: '2px solid #000',
                                fontSize: "16px",
                            }}
                            aria-label="maximum height"
                            name="notes"
                            value={formData.notes || ""}
                            onBlur={handleChange}
                            onChange={handleLocal}
                        />
                    </Box>
                </Box >
            </Box>
        </>
    )
}

export default Bay;