import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, CardHeader, TextField, MenuItem,
    Button, Box, useTheme,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DayJS from 'dayjs'
import SheetsApi from '../../api';

function ReceiptsFilter({ handleSubmit, handleLocal, formData, handlePrintAll, handleDismissAll }) {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const [users, setUsers] = useState([])
    const handleDate = (e, name) => {
        let dateObj = DayJS(e)
        let dateStr = dateObj.format("MM/DD/YYYY")
        if (dateStr === "Invalid Date") dateStr = ""
        handleLocal("invalidInput", name, dateStr)
    };

    useEffect(() => {
        const getAllUsers = async function () {
            return SheetsApi.getInactive();
        }
        getAllUsers().then((response) => {
            setUsers(response)
        })
    }, [])

    return (<>
        <Box display="flex" justifyContent="space-between">
            <Card sx={{ width: '100%', padding: 2, bgcolor: background }}>
                <CardHeader title="Filter Options" />
                <CardContent>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <TextField
                            select
                            label="Stage"
                            name="stage"
                            value={formData.stage || "open"}
                            onChange={handleLocal}
                            fullWidth
                        >
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="closed">Closed</MenuItem>
                        </TextField>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Start Date"
                                name="startDate"
                                id="startDate"
                                inputFormat="MM/DD/YYYY"
                                value={formData.startDate || ""}
                                onChange={(e) => handleDate(e, "startDate")}
                                renderInput={(params) =>
                                    <TextField
                                        value={formData.startDate || ""}
                                        {...params}
                                        sx={{
                                            width: '100%',
                                            '& .MuiInputBase-root': {
                                                backgroundColor: background,
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                backgroundColor: background,
                                            },
                                        }}
                                        error={false}
                                    />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                inputFormat="MM/DD/YYYY"
                                value={formData.endDate || ""}
                                onChange={(e) => handleDate(e, "endDate")}
                                renderInput={(params) =>
                                    <TextField
                                        value={formData.endDate || ""}
                                        {...params}
                                        sx={{
                                            width: '100%',
                                            '& .MuiInputBase-root': {
                                                backgroundColor: background,
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                backgroundColor: background,
                                            },
                                        }}
                                        error={false}
                                    />}
                            />
                        </LocalizationProvider>
                        <TextField
                            select
                            label="Username"
                            name="username"
                            value={formData.username || ""}
                            onChange={handleLocal}
                            fullWidth
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {users.length > 0 && users.map((user) => (
                                <MenuItem key={user.username} value={user.username}>
                                    {user.username}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            label="Category"
                            name="category"
                            value={formData.category || ""}
                            onChange={handleLocal}
                            fullWidth
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="part">Part</MenuItem>
                            <MenuItem value="fuel">Fuel</MenuItem>
                            <MenuItem value="largeJob">Large Job</MenuItem>
                            <MenuItem value="entertainment">Entertainment</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </TextField>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Filter
                        </Button>
                        <Box>
                            <Button variant="contained" color="primary" onClick={handlePrintAll}>
                                Print All
                            </Button>
                            <Button onClick={handleDismissAll} sx={{ ml: 1 }}>
                                Dismiss All
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </>)
}

export default ReceiptsFilter;
