import React from "react";
import {
    Dialog, DialogTitle, DialogContent, TextField,
    DialogActions, Button, Typography,
} from "@mui/material";
import useFields from "../Hooks/useFields";
import SheetsApi from "../api";
import useNotification from "../Hooks/useNotification";

function AddLocationPopUp({ open, setOpen, customerName, locations, setLocations }) {
    const [formData, handleLocal, resetForm] = useFields({
        location_name: "",
        customer_name: customerName,
    })
    const messanger = useNotification()
    const handleSubmit = async function (e) {
        e.preventDefault();
        if (formData.location_name === "") {
            messanger("No site was created", "warning")
            return setOpen(false);
        }
        let result = await SheetsApi.addLocation({ customer_name: customerName, location_name: formData.location_name })
        messanger(result.message, result.error ? "error" : "success")
        setLocations([...locations, { location_name: result.location }])
        resetForm()
        return setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add New Site</DialogTitle>
            <DialogContent>
                <Typography>Adding a site to {customerName}:</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="location_name"
                    name="location_name"
                    label="Site Name"
                    fullWidth
                    required
                    value={formData.location_name || ""}
                    onChange={handleLocal}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Add Site</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddLocationPopUp;

