import React, { useEffect, useState } from "react";
import useFields from "../Hooks/useFields";
import SheetsApi from "../api";
import { useHistory } from "react-router-dom"
import {
    Box, FormControl, InputLabel, Select,
    MenuItem, Button, Card, CardContent,
    CardHeader, Typography, useTheme,
} from "@mui/material"
import useNotification from "../Hooks/useNotification";

function LocationMerge({ customer, location, locations, id }) {
    const history = useHistory()
    const messanger = useNotification();
    const theme = useTheme()
    const background = theme.palette.background.main;
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main;
    const [formData, handleLocal] = useFields({
        mergeSiteName: "",
    })

    const mergeSite = async function (e) {
        e.preventDefault()
        if (formData.mergeSiteName !== "") {
            const result = await SheetsApi.mergeLocation({
                id: id,
                customer: customer.customer,
                locationToDelete: formData.mergeSiteName,
            })
            messanger(result.message, "success")
            return history.push("/")
        } else {
            messanger("You must select a location to merge to before submitting.", "warning")
        }
    }

    return (
        <>
            <Box m={3}>
                <Card sx={{ width: '100%', bgcolor: defaultColor }} >
                    <CardHeader
                        titleTypographyProps={{ sx: { color: 'white' } }}
                        title="Merge Site" />
                    <CardContent>
                        <Typography sx={{ color: 'white' }}>
                            This action will take all sheets associated with "{formData.mergeSiteName}" and merge them into "{location}".
                            It will then DELETE "{formData.mergeSiteName}" permanently.
                            This action is IRREVERSIBLE. Please proceed with caution.
                        </Typography>
                        <Box mt={2}>
                            <form onSubmit={mergeSite} >
                                <FormControl>
                                    <InputLabel sx={{ color: 'white' }}>Merge a Site</InputLabel>
                                    <Select
                                        id="mergeSiteName"
                                        name="mergeSiteName"
                                        label="Merge a Site"
                                        sx={{ width: "26ch", color: 'white' }}
                                        onChange={handleLocal}
                                        value={formData.mergeSiteName || ""}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {locations.length > 0 &&
                                            locations.map((location2) => (
                                                location2.location_name !== location &&
                                                <MenuItem
                                                    key={location2.id}
                                                    value={location2.location_name}>
                                                    {location2.location_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <Box display="inline" ml={1}>
                                    <Button
                                        type="submit"
                                        variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default LocationMerge;