import React, { useEffect } from 'react';
import useBidPartsStore from '../../../../stores/BidStore/bidPartsStore';
import { TextField, Box, InputAdornment } from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import { shallow } from "zustand/shallow";
import SearchableInput from '../../../SheetComponents/SearchableInput';
import Commas from "../../BidFunctions/commas";
function Part({ id, localParts, category, handlePartsTotal, edit, partData }) {
    const editPass = edit;
    const [parts, setPartObj, setPart] = useBidPartsStore(state =>
        [state[category], state.setObj, state.setPart], shallow);
    const [formData, handleLocal] = useFields({ qty: 0, partNumber: "", description: "", price: 0, total: 0 })

    useEffect(() => {
        // Fills inputs with edit data if it exists
        if (!edit || partData.length === 0) return;

        const partDataEntry = partData[id];
        if (!partDataEntry) return;

        const part = localParts.find((part) => part.partNumber === partDataEntry.partNumber);
        if (!part) return;

        if (part.serPart) {
            setPartObj(category, partDataEntry, id);
            handleLocal("replaceObj", partDataEntry);
        } else {
            const updatedPart = { ...partDataEntry, total: parseFloat((partDataEntry.qty * partDataEntry.price).toFixed(2)) };
            setPartObj(category, updatedPart, id);
            handleLocal("replaceObj", updatedPart,);
        }
    }, []);

    useEffect(() => {
        if (formData.partNumber === "") return;

        const { qty, price } = formData;
        const total = parseFloat((qty * price).toFixed(2)) || 0;

        handleLocal("invalidInput", "total", total);
        setPartObj(category, { ...formData, total }, id);
    }, [formData.qty, formData.partNumber, formData.price]);

    useEffect(() => {
        handlePartsTotal()
    }, [parts]);

    const handleGlobalPart = function (e, valueIsNum) {
        let value = e.target.value
        if (valueIsNum) value = parseFloat(value)
        setPart(category, e.target.name, value, id)
    }

    return (
        <>
            <Box m={1}>
                <Box ml={1} display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                    />
                </Box>
                <Box display="inline" ml={1} mr={1}>
                    <SearchableInput
                        edit={editPass}
                        editData={partData?.[id]?.partNumber}
                        setState={handleLocal}
                        variableName="partNumber"
                        searchableArray={localParts}
                        label="Part Number"
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        variant={formData.partNumber !== "SERPART" ? "standard" : "outlined"}
                        sx={{ width: "63ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalPart}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: formData.partNumber === "SERPART" && false }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="price"
                        id="price"
                        label="Dealer Price"
                        type="number"
                        variant="outlined"
                        sx={{ width: "14ch", ml: 1 }}
                        value={formData.price || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                        }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "12ch", ml: 1 }}
                        value={Commas.add(formData.total) || ""}
                        onChange={handleLocal}
                        onBlur={(e) => handleGlobalPart(e, true)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }}
                    />
                </Box>
            </Box >
        </>
    )
}

export default React.memo(Part);