import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Button, Box, Drawer, Toolbar, Modal,
  Typography, Divider, IconButton,
} from "@mui/material"
import MuiAppBar from '@mui/material/AppBar';
import { MenuOpen, Home, Menu, AddAPhoto } from '@mui/icons-material';
import { NavLink, useHistory } from "react-router-dom"
import UserContext from '../../Users/UserContext';
import UsersMenu from './UsersMenu';
import NavMenu from './NavMenu';
import SheetMenu from './SheetMenu';
import SheetsApi from '../../api';
import ServiceViewerMenu from './ServiceViewerMenu';
import NotifyBar from '../../Notifications/NotifyBar';
import SettingsMenu from './SettingsMenu';
import useSettingsStore from '../../stores/settingsStore';
import ReceiptUpload from '../../Receipts/ReceiptUpload';
import ReceiptMenu from './ReceiptMenu';

function DrawerComponent({ logout }) {
  const history = useHistory()
  const { currentUser } = useContext(UserContext)
  const theme = useTheme();
  const drawerWidth = 300;
  const defaultColor = theme.palette.default.main
  const gray = theme.palette.default.secondary;
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([])
  const [salesNotifications, setSalesNotifications] = useState(0)
  const [notifications, setNotifications, getNotifications] = useSettingsStore(state =>
    [state.notifications, state.setNotifications, state.getNotifications])
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: defaultColor,
      marginLeft: drawerWidth,
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const defaultStyle = { textDecoration: 'none', color: "#004e7c" }

  const handleLink = function (link) {
    history.push(link)
  }

  useEffect(() => {
    const getCompanies = async function () {
      return await SheetsApi.getAllCompanies()
    }
    if (currentUser.dept === 'admin') {
      getCompanies().then(response => {
        setCompanies(response.companies)
      })
    } else setCompanies([{ name: currentUser.company }])
  }, [])

  useEffect(() => {
    const getServiceVeiwerBadgeLength = async function () {
      return await SheetsApi.getSalesNotificationLength({ username: currentUser.username })
    }
    getServiceVeiwerBadgeLength().then((response) => {
      setSalesNotifications(parseInt(response.count))
    })
    getNotifications(currentUser).then((response) => {
      setNotifications(response)
    })
  }, [open]);

  return (<>
    {currentUser ?
      <Box className='hideForPrint' sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{
            display: { xs: "flex" },
            flexDirection: "row",
            backgroundColor: defaultColor,
            justifyContent: "space-between"
          }} >
            <Box display="flex" justifyContent="space-between">
              <IconButton
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: 'none' }), mr: 2, color: 'white' }}
              >
                <Menu sx={{ fontSize: 30 }} />
              </IconButton>
              {!open && <>
                <IconButton
                  onClick={() => handleLink("/")}>
                  <Home sx={{ color: 'white' }} />
                  <Typography
                    sx={{ color: 'white', ml: 1, mt: .5 }}>
                    Home
                  </Typography>
                </IconButton>
                <NotifyBar notifications={notifications} />
              </>}
            </Box>
            <Box>
              <Button
                varient="containted"
                size="large"
                sx={{ minWidth: 100, color: "white" }}
                onClick={logout}>
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <DrawerHeader />
        <Modal open={open} onClose={handleDrawerClose}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                backgroundColor: defaultColor
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', ml: 2, mt: 1 }}>
                <IconButton onClick={handleDrawerClose} >
                  <MenuOpen sx={{ mr: 1, color: 'white', fontSize: 30 }} />
                </IconButton>
                <IconButton
                  onClick={() => handleLink("/")}>
                  <Home sx={{ color: 'white' }} />
                  <Typography
                    sx={{ color: 'white', ml: 1, mt: .5 }}>
                    Home
                  </Typography>
                </IconButton>
                <NotifyBar notifications={notifications} />
              </Box>
            </DrawerHeader>
            <Divider sx={{ backgroundColor: gray }} />
            <Box
              display="flex"
              flexDirection="column"
              height="100vh"
              padding="16px"
            >
              <Box flexGrow={1}>
                <NavMenu
                  name="Home"
                  companies={companies}
                  urlParam="home"
                  handleDrawerClose={handleDrawerClose}
                />
                <UsersMenu
                  handleDrawerClose={handleDrawerClose}
                />
                <NavMenu
                  name="Customers"
                  companies={companies}
                  urlParam="customers"
                  handleDrawerClose={handleDrawerClose}
                />
                <SheetMenu
                  handleDrawerClose={handleDrawerClose}
                />
                <ServiceViewerMenu
                  salesNotifications={salesNotifications}
                  handleDrawerClose={handleDrawerClose}
                />
                <ReceiptMenu
                  handleDrawerClose={handleDrawerClose}
                />
                <SettingsMenu
                  handleDrawerClose={handleDrawerClose}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <ReceiptUpload skipDelete={true} />
              </Box>
            </Box>
          </Drawer>
        </Modal>
      </Box >
      : <Toolbar sx={{
        display: { xs: "flex" },
        flexDirection: "row",
        backgroundColor: defaultColor,
        justifyContent: "space-between"
      }}>
        <Box sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center', textAlign: 'center'
        }}>
        </Box>
        <Box sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          justifyContent: "flex-end",
          textAlign: 'center'
        }}>
          <NavLink to="/login" style={defaultStyle}>
            <Button varient="containted" size="large" sx={{ minWidth: 100, color: "white" }}>Login</Button>
          </NavLink>
        </Box>
      </Toolbar>
    } </>
  );
}

export default DrawerComponent;