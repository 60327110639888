import React, { useContext, useEffect, useState } from "react";
import BaseSheets from "../SheetComponents/BaseSheet";
import PartsSheet from "../SheetComponents/PartsSheet";
import TechSheet from "../SheetComponents/TechSheet";
import {
    Box, Grid, Typography, Button, TextareaAutosize,
    FormControl, InputLabel, Select, MenuItem,
} from "@mui/material/"
import { useTheme } from '@mui/material/styles';
import useSheetsStore from "../../stores/SheetsStore";
import useBaseStore from "../../stores/baseStore";
import usePartsStore from "../../stores/partsStore";
import useTechStore from "../../stores/techStore";
import UserContext from "../../Users/UserContext";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";
import { useHistory } from "react-router-dom"
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import DeptSelect from "../SheetComponents/DeptSelect";
import useSettingsStore from "../../stores/settingsStore";

function ServiceSheets() {
    const { currentUser } = useContext(UserContext)
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const messanger = useNotification();
    const defaultColor = theme.palette.default.main
    const history = useHistory()
    const [home] = useSettingsStore((state) => [state.home]);
    const [setServiceSheet, serviceSheet, clearServiceSheet] = useSheetsStore(state =>
        [state.setServiceSheet, state.serviceSheet, state.clearServiceSheet])
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [partSheet, clearPartsForm] = usePartsStore(state =>
        [state.parts, state.clearPartsForm])
    const [techSheet, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])
    const [renderCheck, setRenderCheck] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setRenderCheck(true)
        clearServiceSheet()
        clearBaseForm()
        clearPartsForm()
        clearTechForm()
    }, [])

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const sheetObj = CreateSubmitObj(
            serviceSheet, currentUser, messanger,
            baseSheet, partSheet, techSheet
        )
        console.log(sheetObj)
        if (sheetObj.error) return setIsSubmitting(false);
        const sheet = await SheetsApi.addServiceSheet(sheetObj)
        setRenderCheck(false)
        clearBaseForm()
        clearServiceSheet()
        clearPartsForm()
        clearTechForm()
        messanger(sheet.message, sheet.error ? "error" : "success")
        history.push(home);
        return setIsSubmitting(false)
    }

    const handleChange = (e, passedName) => {
        if (!e) {
            return
        }
        const name = e.target.name || passedName
        const value = e.target.value
        return setServiceSheet(name, value)
    }
    return (
        <div>
            <form onSubmit={handleFormSubmit}>
                <Grid container >
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <Box style={{ width: "100%" }}>
                            {renderCheck && <BaseSheets title="Service" />}
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            mb={1}
                        >
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>Service Type</InputLabel>
                                <Select
                                    label="Service Type"
                                    onChange={handleChange}
                                    id="serviceType"
                                    name="serviceType"
                                    defaultValue=""
                                >
                                    <MenuItem value="ventilation">Ventilation</MenuItem>
                                    <MenuItem value="refrigeration">Refrigeration</MenuItem>
                                    <MenuItem value="electrical">Electrical</MenuItem>
                                    <MenuItem value="vfdPump">VFD / Pump</MenuItem>
                                    <MenuItem value="serviceCheck">Service Check</MenuItem>
                                    <MenuItem value="overtimeVent">Overtime Ventilation</MenuItem>
                                    <MenuItem value="overtimeRef">Overtime Refrigeration</MenuItem>
                                    <MenuItem value="largeJob">Large Job</MenuItem>
                                    <MenuItem value="productive">Productive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            sx={{
                                backgroundColor: primary,
                                "@media print": {
                                    backgroundColor: primary,
                                },
                            }}
                        >
                            <Typography
                                variant="h5"
                                ml={1}
                                style={{ color: defaultColor }}>
                                Service Requested
                            </Typography>
                        </Box>
                        <Box ml={0.25}>
                            <TextareaAutosize
                                minRows={4}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                aria-label="maximum height"
                                name="serviceRequested"
                                id="serviceRequested"
                                value={serviceSheet.serviceRequested || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                ml={1}
                                variant="h5"
                                style={{ color: defaultColor }}>
                                Work Performed & Comments
                            </Typography>
                        </Box>
                        <Box ml={0.25}>
                            <TextareaAutosize
                                minRows={8}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                aria-label="maximum height"
                                name="workPerformed"
                                id="workPerformed"
                                value={serviceSheet.workPerformed || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        {renderCheck && <PartsSheet toggle={true} />}
                        {renderCheck && <TechSheet edit={false} toggle={true} />}
                        <Box display="flex" justifyContent="flex-start">
                            <DeptSelect />
                            <Button
                                disabled={isSubmitting}
                                variant="contained"
                                style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                type="submit">
                                Submit
                            </Button>

                        </Box>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default ServiceSheets