import React, { useEffect } from "react";
import {
    TextField, Box, useTheme, TextareaAutosize, Typography,
} from '@mui/material';
import useLabDataStore from "../../../stores/labDataStore";

function DataEntryFries() {
    const theme = useTheme();
    const background = theme.palette.background.main;
    const [selectedEntry, setValue] = useLabDataStore(state =>
        [state.selectedEntry, state.setValue]);

    useEffect(() => {
        const fryColor0 = Number(selectedEntry.fryColor0);
        const fryColor1 = Number(selectedEntry.fryColor1);
        const fryColor2 = Number(selectedEntry.fryColor2);
        const fryColor3 = Number(selectedEntry.fryColor3);
        const fryColor4 = Number(selectedEntry.fryColor4);

        const result = ((0 * fryColor0) + (1 * fryColor1)
            + (2 * fryColor2) + (3 * fryColor3) + (4 * fryColor4)) / (fryColor0
                + fryColor1 + fryColor2 + fryColor3 + fryColor4);
        const roundedResult = Math.round(result * 100) / 100;
        const result2 = roundedResult * (fryColor0 + fryColor1 + fryColor2
            + fryColor3 + fryColor4);
        const roundedResult2 = Math.round(result2);

        setValue("weightedAvgFryColor", roundedResult);
        setValue("calculatedFryPoints", roundedResult2);

    }, [selectedEntry.fryColor0, selectedEntry.fryColor1,
    selectedEntry.fryColor2, selectedEntry.fryColor3, selectedEntry.fryColor4]);

    return (<>
        <Box mt={1}>
            <Box>               
                <TextField
                    id='fryColor0'
                    name='fryColor0'
                    label='Fry Color 0'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.fryColor0 || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='fryColor1'
                    name='fryColor1'
                    label='Fry Color 1'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.fryColor1 || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='fryColor2'
                    name='fryColor2'
                    label='Fry Color 2'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.fryColor2 || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='fryColor3'
                    name='fryColor3'
                    label='Fry Color 3'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.fryColor3 || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='fryColor4'
                    name='fryColor4'
                    label='Fry Color 4'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.fryColor4 || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='sugarEnds'
                    name='sugarEnds'
                    label='Sugar Ends'
                    type="number"
                    sx={{ width: 90, ml: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.sugarEnds || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
            </Box>
            <Box display="flex">
                <Box ml={2} mt={2}>
                    <TextField
                        id='weightedAvgFryColor'
                        name='weightedAvgFryColor'
                        label='Weighted Average Fry Color'
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: 250 }}
                        value={selectedEntry.weightedAvgFryColor || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                    />
                    <TextField
                        id='calculatedFryPoints'
                        name='calculatedFryPoints'
                        label='Calculated Fry Points'
                        type="number"
                        sx={{ mt: 1, width: 250 }}
                        InputLabelProps={{ shrink: true }}
                        value={selectedEntry.calculatedFryPoints || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                    />
                </Box>
                <Box mb={1} mt={2} sx={{ width: '100%' }}>
                    <Typography sx={{ ml: 2 }} variant="h5">Other Fry Notes</Typography>
                    <TextareaAutosize
                        id="otherFryNotes"
                        name="otherFryNotes"
                        value={selectedEntry.otherFryNotes || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        minRows={3.5}
                        style={{
                            backgroundColor: background,
                            width: "80%",
                            outline: '2px solid #000',
                            fontSize: "16px",
                            padding: '8px 12px',
                            borderRadius: '8px',
                        }}
                        aria-label="maximum height"
                    />
                </Box>
            </Box>         
        </Box >
    </>)
}

export default DataEntryFries;