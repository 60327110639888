import React, { useContext, useEffect, useState, useRef } from "react";
import BaseSheet from "../SheetComponents/BaseSheet"
import CompressorSheet from "./CompressorSheet";
import PartsSheet from "../SheetComponents/PartsSheet"
import UserContext from "../../Users/UserContext";
import { useHistory, useParams } from "react-router-dom"
import usePartsStore from "../../stores/partsStore";
import useBaseStore from "../../stores/baseStore";
import SheetsApi from "../../api";
import useRefrigerationStore from "../../stores/refrigerationStore";
import useTechStore from "../../stores/techStore";
import useImageStore from "../../stores/imageStore";
import {
    Typography, Checkbox, FormControlLabel, useTheme,
    Grid, Box, TextareaAutosize, Card, CardContent,
} from "@mui/material";
import TechSheet from "../SheetComponents/TechSheet";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import StageSelector from "../SheetComponents/StageSelector";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useNotification from "../../Hooks/useNotification";
import useSettingsStore from "../../stores/settingsStore";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function RefrigerationEdit() {
    const componentRef = useRef()
    const { id } = useParams()
    const history = useHistory()
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const { currentUser } = useContext(UserContext)
    const [logs, setLogs] = useState([])
    const [toggle, setToggle] = useState(true)
    const [renderCheck, setRenderCheck] = useState(false)
    const [renderCheckComp, setRenderCheckComp] = useState(false)
    const [renderCheckTech, setRenderCheckTech] = useState(false)
    const messanger = useNotification();
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home])
    const [partSheet, clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.parts, state.clearPartsForm, state.setPartsObj])
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj])
    const [refForm, setRefForm, clearRefForm, compressors, setCompressorFormObj,
        clearCompressorForm, setRefFormObj] = useRefrigerationStore(state =>
            [state.refForm, state.setRefForm, state.clearRefForm,
            state.compressors, state.setCompressorFormObj,
            state.clearCompressorForm, state.setRefFormObj])
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj])
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleToggle = () => {
        setToggle(!toggle)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        const refObj = CreateSubmitObj(
            refForm, currentUser, messanger,
            baseSheet, partSheet, techSheet)
        if (refObj.error) return setIsSubmitting(false);
        refObj.id = id
        refObj.sheet.sheetType = 'refrigeration'
        refObj.compressors = []
        console.log(refObj)
        for (let compressor of compressors) {
            refObj.compressors = [...refObj.compressors, compressor]
        }
        HandleImage.addAndRemove(images, refObj)
        await SheetsApi.editRefrigerationSheet(refObj).then((response) => {
            messanger(response.message, "success")
            getNotifications(currentUser)
        })
        setRenderCheck(false)
        setRenderCheckComp(false)
        setRenderCheckTech(false)
        clearTechForm()
        clearBaseForm()
        clearPartsForm()
        clearRefForm()
        clearCompressorForm()
        clearImages()
        history.push(home)
        return setIsSubmitting(false)
    }

    useEffect(() => {
        clearImages()
        clearRefForm()
        clearPartsForm()
        clearTechForm()
        clearBaseForm()
        clearCompressorForm()

        async function getRefrigerationSheet() {
            try {
                const refrigerationSheet = await SheetsApi.getRefrigerationSheet({ id });
                const images = refrigerationSheet.images;
                const parts = await SheetsApi.getPartsById({ id, type: "refrigeration_id" });
                const techs = await SheetsApi.getTechsById({ id, type: "refrigeration_id" });
                const compressorSheet = await SheetsApi.getCompressors({ id });
                const locationId = refrigerationSheet.location;
                const locationName = await SheetsApi.getLocationName({ locationId });
                refrigerationSheet.location = locationName.location_name;
                refrigerationSheet.locationId = locationId;
                setLogs(refrigerationSheet.logs);
                return { refrigerationSheet, parts, techs, compressorSheet, images };
            } catch (err) {
                console.log(err)
            }
        }
        getRefrigerationSheet().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'refrigerationSheet') {
                    const split = sheet[section]
                    let baseFormObj = {
                        company: split.company,
                        creator: split.creator,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                        po: split.po,
                        requestedBy: split.request_by,
                        stage: split.stage,
                        dateCreated: split.dateCreated,
                        locationId: split.locationId,
                        dept: split.dept,
                    }
                    let refrigerationSheetObj = {
                        vacuum: split.vacuum,
                        crankHeater: split.crank_heater,
                        doors: split.doors,
                        contactors: split.contactors,
                        notes: split.notes,
                        followUp: split.followUp,
                        control: split.control_check,
                        circuit: split.circuit_check,
                        followUpDept: split.followUpDept,
                    }
                    setRefFormObj(refrigerationSheetObj)
                    setBaseFormObj(baseFormObj)
                    setRefForm("stage", split.stage)
                } else if (section === 'parts') {
                    let partsArry = []
                    for (let obj of sheet[section]) {

                        let partObj = {
                            qty: obj.quantity,
                            type: obj.quantity_type,
                            part: obj.part_number,
                            description: obj.description,
                            cost: obj.cost,
                            isMisc: obj.ismisc,
                            location: obj.location,
                            name: obj.name,
                            invoice: obj.invoice,
                            po: obj.po,
                        }
                        partsArry.push(partObj)
                    }
                    setPartsObj(partsArry)
                    setRenderCheck(true)
                } else if (section === 'compressorSheet') {
                    let compressorArry = []
                    for (let obj of sheet[section]) {
                        compressorArry.push(obj)
                    }
                    setCompressorFormObj(compressorArry)
                    setRenderCheckComp(true)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes
                        }
                        setTechObj(techObj, id)
                    }
                    setRenderCheckTech(true)
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        if (img.imageType === 'img' || img.imageType === 'receipt') {
                            addImage(imageObj)
                        }
                    }
                }
            }
        })
    }, [])

    const handleChange = function (e) {
        let value = e.target.value
        const name = e.target.name
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        }
        setRefForm(name, value)
    }
    return (
        <div className="page custom-page" ref={componentRef} >
            <Grid container>
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <Box ml={2}>
                        <form onSubmit={handleSubmit}>
                            <Box style={{ width: "100%" }}>
                                <BaseSheet id={id} title="Refrigeration" />
                            </Box>
                            <Typography
                                variant="h5"
                                style={{
                                    textDecoration: 'underline',
                                    fontWeight: "bold",
                                    display: 'inline-block'
                                }}>
                                Refrigeration:
                            </Typography>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Vacuum Control Panel(s)"
                                    control={<Checkbox
                                        name="vacuum"
                                        onChange={handleChange}
                                        checked={refForm.vacuum || false}
                                    />}
                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Crank Case Heaters"
                                    control={<Checkbox
                                        name="crankHeater"
                                        onChange={handleChange}
                                        checked={refForm.crankHeater || false}
                                    />}
                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Check Bypass Doors"
                                    control={<Checkbox
                                        name="doors"
                                        onChange={handleChange}
                                        checked={refForm.doors || false}
                                    />}

                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Inspect Contactors"
                                    control={<Checkbox
                                        name="contactors"
                                        onChange={handleChange}
                                        checked={refForm.contactors || false}
                                    />}

                                />
                            </Box>
                            {renderCheckComp && <CompressorSheet edit={true} />}
                            <Box
                                ml={2}
                                mt={1}
                            >
                                <FormControlLabel
                                    label="Check Controls (Calibration, Operation)"
                                    control={<Checkbox
                                        name="control"
                                        onChange={handleChange}
                                        checked={refForm.control || false}
                                    />}
                                />
                                <Box
                                    ml={2}
                                    style={{ display: 'inline-block' }}
                                >
                                    <FormControlLabel
                                        label="Check failure circuit operation"
                                        control={<Checkbox
                                            name="circuit"
                                            onChange={handleChange}
                                            checked={refForm.circuit || false}
                                        />}
                                    />
                                </Box>
                            </Box>
                            <Box
                                mt={2}
                                style={{ width: "100%" }}
                                sx={{ backgroundColor: primary }}
                            >
                                <Typography
                                    variant="h5"
                                    ml={1}
                                    style={{ color: defaultColor }}>
                                    Notes:
                                </Typography>
                            </Box>
                            <Box ml={.25} >
                                <TextareaAutosize
                                    minRows={4}
                                    style={{
                                        width: "99.0%",
                                        outline: '2px solid #000',
                                        fontSize: "16px",
                                    }}
                                    aria-label="maximum height"
                                    name="notes"
                                    value={refForm.notes || ""}
                                    onChange={handleChange}
                                />
                            </Box>
                            {renderCheck && <PartsSheet toggle={toggle} />}
                            {renderCheckTech && <TechSheet edit={true} toggle={toggle} />}
                            <Box m={1}>
                                <FollowUp
                                    state={refForm}
                                    setState={handleChange}
                                />
                            </Box>
                            <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                                <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                    <CardContent>
                                        <Box display="flex" justifyContent="left" className="hideForPrint" >
                                            <Box>
                                                <StageSelector
                                                    currentUser={currentUser}
                                                    handleChange={handleChange}
                                                    value={refForm.stage}
                                                />
                                                <DeptSelect />
                                                <SubmitAndPrint
                                                    isSubmitting={isSubmitting}
                                                    componentRef={componentRef}
                                                    showToggle={true}
                                                    toggle={toggle}
                                                    handleToggle={handleToggle} />
                                            </Box>
                                            {!currentUser.mobile && renderCheck ? <ImageDropZone sheetType="refrigeration" /> : null}
                                        </Box>
                                        {currentUser.mobile && renderCheck ? <ImageDropZone sheetType="refrigeration" /> : null}
                                    </CardContent>
                                </Card>
                                {logs.length > 0 &&
                                    <HistoryCard logs={logs} />
                                }
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default RefrigerationEdit