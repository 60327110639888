import React, { useEffect, useContext } from "react";
import {
    Box, Card, Typography, TextField, InputAdornment,
    Button, InputLabel, FormControl, Select, MenuItem,
} from "@mui/material"
import UserContext from "../../../../Users/UserContext";
import Commas from "../../BidFunctions/commas";
import useFields from "../../../../Hooks/useFields";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import { useHistory, useParams } from "react-router-dom"

function RefSubmit({ grandTotal, handleSubmit, edit, handleCopy, isSubmitting }) {
    const [formData, handleLocal] = useFields({ profit: 15.6 })
    const [setBidBase, stage] = useBidStoreMain(state =>
        [state.setBidBase, state.bidBase.stage])
    const { currentUser } = useContext(UserContext);
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        // need to import overhead and add it to calculation ~
        const profitPercent = 1 - (formData.profit / 100);
        const grandTotalFloat = Commas.remove(grandTotal);
        const profitTotal = parseFloat((grandTotalFloat / profitPercent - grandTotalFloat).toFixed(2)) || 0
        const priceEstimate = parseFloat((profitTotal + grandTotalFloat).toFixed(2)) || 0
        handleLocal("invalidInput", "profitTotal", Commas.add(profitTotal))
        handleLocal("invalidInput", "priceEstimate", Commas.add(priceEstimate))
    }, [formData.profit, grandTotal])

    const generatePdf = function () {
        handleSubmit()
        return history.push(`/bidRef/refrigerationQuote/${id}`)
    }
    return (<>
        <Card sx={{ width: '70ch' }} >
            <Box m={1}>
                <Typography ml={4} variant="h5">
                    Submit and Finalize
                </Typography>
            </Box>
            <Box ml={2}>
                <Typography variant="h6">Total Job Cost: $ {grandTotal}</Typography>
            </Box>
            <Box display="flex" mb={2} mt={2} ml={2}>
                <Box>
                    <TextField
                        id="profit"
                        name="profit"
                        label="Profit"
                        type="number"
                        sx={{ width: "14ch" }}
                        value={formData.profit === 0 ? 0 : formData.profit || 15.6}
                        onChange={handleLocal}
                        onBlur={(e) => setBidBase("profit", e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box ml={3}>
                    <TextField
                        id="profitTotal"
                        name="profitTotal"
                        type=""
                        label="Profit Total"
                        variant="standard"
                        value={formData.profitTotal || 0}
                        sx={{ width: "16ch" }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </Box>
            <Box ml={2} mb={2} >
                <Typography
                    variant="h4">
                    Price Estimate: $ {formData.priceEstimate}
                </Typography>
            </Box>
            <Box mr={1} justifyContent="right" display="flex">
                {edit && <Box mr={3} mt={1}>
                    <Button
                        variant="contained"
                        onClick={generatePdf}
                    >
                        Generate PDF
                    </Button>
                </Box>}
                <Box m={1}>
                    <Button
                        disabled={isSubmitting}
                        onClick={() => (handleSubmit())}
                        variant="contained">
                        Save
                    </Button>
                </Box>
                {edit && <>
                    <Box mt={1} mb={1} mr={1} >
                        <Button
                            onClick={handleCopy}
                            variant="contained">
                            Copy
                        </Button>
                    </Box>
                    <Box mb={1} className="hideForPrint">
                        {currentUser.account_level !== 'Technician' &&
                            <FormControl style={{ width: '200px' }}>
                                <InputLabel>Stage</InputLabel>
                                <Select
                                    label="Stage"
                                    onChange={(e) => setBidBase("stage", e.target.value)}
                                    id="stage"
                                    name="stage"
                                    value={stage || ""}
                                    defaultValue=""
                                >
                                    <MenuItem value="Signature Pending">Signature Pending</MenuItem>
                                    <MenuItem value="Down Payment">Down Payment</MenuItem>
                                    <MenuItem value="Progress Payment">Progress Payment</MenuItem>
                                    <MenuItem value="Final Payment">Final Payment</MenuItem>
                                    <MenuItem value="Archive">Archive</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    </Box>
                </>}
            </Box>
        </Card>
    </>)
}

export default RefSubmit;