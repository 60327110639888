import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Users/UserContext";
import {
    FormControl, Select, InputLabel, MenuItem, Box,
} from "@mui/material";
import useBaseStore from "../../stores/baseStore";

function DeptSelect({ disable, chemApp, salesOrder }) {
    const { currentUser } = useContext(UserContext)
    const [render, setRender] = useState(false);
    const [baseForm, setBaseForm] = useBaseStore(state =>
        [state.baseForm, state.setBaseForm])

    useEffect(() => {
        if (currentUser.dept === 'admin' || currentUser.dept === 'sales') {
            setRender(true);
        }
    }, [])

    return (
        <>
            {render &&
                <Box mt={1} mr={1}>
                    <FormControl style={{ width: '30ch' }}>
                        <InputLabel>Department</InputLabel>
                        <Select
                            label="Department"
                            onChange={(e) => setBaseForm(e.target.name, e.target.value)}
                            disabled={disable}
                            required
                            id="dept"
                            name="dept"
                            value={baseForm.dept || ""}
                            defaultValue=""
                        >
                            <MenuItem disabled={chemApp} key="service" value="service">Service</MenuItem>,
                            <MenuItem key="chemApps" value="chemApps">Chem Apps</MenuItem>,
                            <MenuItem disabled={chemApp} key="labs" value="labs">Storage Managment</MenuItem>
                            {salesOrder &&
                                <MenuItem disabled={chemApp} key="sales" value="sales">Sales</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Box>
            }
        </>
    )
}

export default DeptSelect;