import React, { useEffect, useState } from "react";
import {
    Typography, Card, CardContent, CardHeader, useTheme, TextField,
    IconButton, Box, Tooltip,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useFields from "../../Hooks/useFields";
import dayjs from 'dayjs'
import SheetsApi from "../../api";
import DataEntryCard from "./DataEntry/DataEntryCard";
import useLabDataStore from "../../stores/labDataStore";
import useNotification from "../../Hooks/useNotification"
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useImageStore from "../../stores/imageStore";
function LabsBase() {
    const [formData, handleLocal] = useFields({});
    const [render, setRender] = useState(false);
    const [loading, setLoading] = useState(false);  // New loading state
    const [labData, setLabData, selectedEntry, setSelectedEntry, addLabData] =
        useLabDataStore((state) => [
            state.labData,
            state.setLabData,
            state.selectedEntry,
            state.setSelectedEntry,
            state.addLabData,
        ]);
    const [images, addImage, clearImages] = useImageStore((state) => [
        state.images,
        state.addImage,
        state.clearImages,
    ]);
    const theme = useTheme();
    const background = theme.palette.background.main;
    const primary = theme.palette.primary.main;
    const messanger = useNotification();

    const handleDate = async (e, name) => {
        let dateObj = dayjs(e);
        let dateStr = dateObj.format("MM/DD/YYYY");
        if (dateStr === "Invalid Date") dateStr = "";
        handleLocal("invalidInput", name, dateStr);
        const dataResults = await SheetsApi.getLabDataByDate({
            date: dateObj.format("YYYY-MM-DD"),
        });
        setLabData(dataResults);
    };

    const addEntry = async function () {
        if (formData.date) {
            const sampleId = labData.length + 1;
            const newEntryId = await SheetsApi.addEntry({
                sampleId,
                dateProcessed: formData.date,
            });
            addLabData({ id: newEntryId.id, sampleId });
        } else messanger("Please select a date", "warning");
    };

    const saveEntry = async function (id, saveOnly) {
        // Prevent click while loading
        if (loading) return;
        setLoading(true);
        if (selectedEntry.id) {
            // Saves the entry that was switched away from
            const updatedArray = [];
            labData.forEach((data) => {
                if (data.id === selectedEntry.id) {
                    updatedArray.push({ ...selectedEntry, images });
                } else updatedArray.push(data);
            });
            setLabData(updatedArray);

            const result = await SheetsApi.saveLabData(selectedEntry);
            if (result.error) {
                messanger(result.message, "error");
                setLoading(false);
                return;
            }

            await HandleImage.addAndRemove(images, {
                id: selectedEntry.id,
                sheet: {
                    sheetType: "labData",
                    dateServiced: selectedEntry.dateProcessed,
                    customerName: selectedEntry.customer,
                    locationId: selectedEntry.locationId,
                },
            });
        }

        if (!saveOnly) {
            // loads in newly selected entry
            clearImages();
            const foundEntry = labData.find((entry) => entry.id === id);
            if (foundEntry.images?.length > 0) {
                for (let img of foundEntry.images) {
                    const imageObj = await HandleImage.setImage(img);
                    addImage(imageObj);
                }
            }

            setSelectedEntry(id);
            setRender(true);
        }

        setLoading(false);
    };

    useEffect(() => {
        setRender(false);
    }, [formData.date]);

    return (
        <Box display="flex">
            <Card sx={{ maxWidth: "275px", height: "100vh", bgcolor: background }}>
                <Box mt={3} ml={1} mr={1} display="flex" justifyContent="space-between">
                    <Tooltip title="Add new entry">
                        <IconButton onClick={addEntry}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Date Processed"
                            name="date"
                            id="date"
                            inputFormat="MM/DD/YYYY"
                            value={formData.date || ""}
                            onChange={(e) => handleDate(e, "date")}
                            renderInput={(params) => (
                                <TextField
                                    value={formData.date || ""}
                                    {...params}
                                    sx={{
                                        width: "80%",
                                        "& .MuiInputBase-root": {
                                            backgroundColor: background,
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            backgroundColor: background,
                                        },
                                    }}
                                    error={false}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                <CardContent sx={{ maxHeight: "80vh", overflow: "auto" }}>
                    {labData.length > 0 &&
                        labData.map((entry) => {
                            return (
                                <Box
                                    key={entry.id}
                                    sx={{
                                        mb: 2,
                                        p: 1,
                                        borderRadius: "8px",
                                        boxShadow: 1,
                                        bgcolor:
                                            selectedEntry.id === entry.id
                                                ? "background.default"
                                                : primary,
                                        cursor: loading ? "not-allowed" : "pointer",
                                        "&:hover": {
                                            bgcolor: "background.default",
                                            boxShadow: 3,
                                        },
                                    }}
                                    display="flex"
                                    justifyContent="space-between"
                                    onClick={() => saveEntry(entry.id)}
                                >
                                    <Typography sx={{ fontSize: 12 }}>
                                        {entry.binName || "None"}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }}>
                                        Sample: {entry.sampleId}
                                    </Typography>
                                </Box>
                            );
                        })}
                </CardContent>
            </Card>
            <DataEntryCard render={render} saveEntry={saveEntry} />
        </Box>
    );
}

export default LabsBase;
