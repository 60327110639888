import React, { useState } from "react";
import {
    Box, Divider, Card, CardContent, Typography,
    ToggleButton, ToggleButtonGroup,
} from "@mui/material";
import useNotification from "../../../Hooks/useNotification";
import PartAdd from "./PartAdd";
import PartUpdate from "./PartUpdate";
import SheetsApi from "../../../api";

function PartsSettings() {
    const messanger = useNotification()
    const [toggleValue, setToggleValue] = useState('');

    const handleToggle = (e, newValue) => {
        if (newValue !== null) {
            setToggleValue(newValue);
        }
    }

    const transformObject = (inputObj) => {
        const mainObjKeys = ["partNumber", "description", "price", "category", "discontinued"];
        const additionalDataKeys = ["hp", "voltage", "brand", "staticPressure05", "staticPressure075", "staticPressure1", "staticPressure125", "staticPressure15", "staticPressure175"];
        const mainObj = { additionalData: {} };
        const additionalData = {};

        Object.entries(inputObj).forEach(([key, value]) => {
            //formats object for back end
            if (mainObjKeys.includes(key)) {
                if (key === "price") mainObj[key] = parseFloat(value);
                else mainObj[key] = value;
            } else if (additionalDataKeys.includes(key)) {
                if (key === 'brand') additionalData[key] = value;
                else additionalData[key] = parseFloat(value);
            }
        });
        if (Object.keys(additionalData).length > 0) {
            mainObj.additionalData = additionalData;
        }
        return mainObj;
    };


    const savePart = async function (formData, apiCallType) {
        if (formData.partNumber === "") return messanger("You must provide a part number", "warning")
        const newObj = transformObject(formData)
        let response;
        if (apiCallType === "post") {
            response = await SheetsApi.addPart(newObj)
        } else if (apiCallType === "patch") {
            response = await SheetsApi.updatePart(newObj);
        } else response = { message: "Something went wrong...", error: true }
        return messanger(response.message, response.error ? "error" : "success")
    }

    return (
        <Box m={10}>
            <Card sx={{ width: '100ch' }} >
                <Box m={1}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography ml={4} variant="h5">
                            Parts Settings
                        </Typography>
                        <Divider sx={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            flexGrow: 1,
                            borderColor: 'gray',
                        }} />
                        <ToggleButtonGroup
                            sx={{}}
                            value={toggleValue}
                            exclusive
                            onChange={handleToggle}
                            aria-label="toggle buttons group"
                        >
                            <ToggleButton
                                value="add"
                                sx={{ width: '100px' }}
                            >
                                Add
                            </ToggleButton>
                            <ToggleButton
                                value="update"
                                sx={{ width: '100px' }}
                            >
                                Update
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Divider sx={{
                        mt: 1,
                        marginRight: '10px',
                        flexGrow: 1,
                        borderColor: 'black',
                    }} />
                </Box>
                <CardContent>
                    {toggleValue === "add" ? (
                        <PartAdd savePart={savePart} />
                    ) : toggleValue === "update" ? (
                        <PartUpdate savePart={savePart} />
                    ) : <Typography>Choose an action</Typography>}
                </CardContent>
            </Card>
        </Box >
    )
}

export default PartsSettings;
