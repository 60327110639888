import { create } from 'zustand';

const useLabDataStore = create((set) => ({
    selectedEntry: {},
    labData: [],

    setLabData: function (data) {
        set(prevState => {
            return { ...prevState, labData: data }
        })
    },

    setSelectedEntry: function (id) {
        set(prevState => {
            const foundEntry = prevState.labData.find((entry) => entry.id === id);
            return { ...prevState, selectedEntry: foundEntry }
        });
    },

    setValue: function (name, value) {
        set(prevState => {
            const oldState = prevState.selectedEntry;
            return { ...prevState, selectedEntry: { ...oldState, [name]: value } }
        })
    },

    addLabData: function (newObject) {
        set(prevState => {
            return { ...prevState, labData: [...prevState.labData, newObject] }
        })
    }
}))

export default useLabDataStore;