import React, { useEffect } from "react";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import { TextField, Box, InputAdornment } from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import Commas from "../../BidFunctions/commas";
function EquipmentLine({ category, id, handleMiscCalc, edit, miscData }) {
    const [formData, handleLocal, resetFormData] = useFields({});
    const [parts, setPart, setObj] = useBidStoreMisc(state =>
        [state[category], state.setPart, state.setObj]);
    const localPart = parts[id];

    useEffect(() => {
        const days = parseFloat(formData.days) || 0
        const fixedCost = parseFloat(formData.fixedCost) || 0
        const dailyRate = parseFloat(formData.dailyRate) || 0
        const mileage = parseFloat(formData.mileage) || 0
        const miles = parseFloat(formData.miles) || 0

        const total = parseFloat(((days * dailyRate) + (mileage * miles) + fixedCost).toFixed(2))
        handleLocal("invalidInput", "total", total)
        setPart(category, "priceTotal", total, id)

    }, [formData.qty, formData.dailyRate, formData.days,
    formData.fixedCost, formData.mileage, formData.miles])

    useEffect(() => {
        handleMiscCalc()
    }, [localPart])

    useEffect(() => {
        resetFormData()
        if (edit && miscData[id]) {
            handleLocal("replaceObj", miscData[id])
            setObj(category, miscData[id], id)
        }
    }, [])

    const handleGlobalState = function (e) {
        let value;
        const name = e.target.name;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setPart(category, name, value, id)
    }

    return (
        <>
            <Box m={1}>
                <Box display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        sx={{ width: "50ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="days"
                        id="days"
                        label="Days"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "12ch" }}
                        value={formData.days || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="dailyRate"
                        id="dailyRate"
                        label="Daily Rate"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "12ch" }}
                        value={formData.dailyRate || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="mileage"
                        id="mileage"
                        label="Mileage Rate"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "12ch" }}
                        value={formData.mileage || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="miles"
                        id="miles"
                        label="Miles"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "12ch" }}
                        value={formData.miles || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="fixedCost"
                        id="fixedCost"
                        label="Fixed Cost"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "12ch" }}
                        value={formData.fixedCost || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "12ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default EquipmentLine;