import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Masonry } from '@mui/lab';
import {
    Box, Modal, Button, FormControl, Divider, FormHelperText,
} from "@mui/material";
import useImageStore from '../../../stores/imageStore';
import { v4 as uuid } from 'uuid';
function ESign({ formHelperText, setSigned, optionalSig, sheetType }) {
    const [open, setOpen] = useState(false);
    const [signature, signature2, setSignature, clearSignature] = useImageStore(state =>
        [state.signature, state.signature2, state.setSignature, state.clearSignature]
    )
    const sigCanvasRef = useRef({});
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClear = () => {
        if (setSigned) setSigned(false);
        sigCanvasRef.current.clear();
        return clearSignature(optionalSig);
    }
    const handleSave = () => {
        const sigCanvas = sigCanvasRef.current;
        if (sigCanvas.isEmpty()) {
            clearSignature(optionalSig)
            if (setSigned) setSigned(false);
            return setOpen(false);
        }
        const signatureDataUrl = sigCanvas.toDataURL();
        // Create a new canvas with the desired dimensions for resizing
        const resizedCanvas = document.createElement('canvas');
        const context = resizedCanvas.getContext('2d');
        const newWidth = 400;
        const newHeight = 50;
        resizedCanvas.width = newWidth
        resizedCanvas.height = newHeight

        // Draw the resized image on the new canvas
        const img = new Image();
        img.src = signatureDataUrl;
        img.onload = function () {
            context.drawImage(img, 0, 0, newWidth, newHeight);
            // Get the resized image as a data URL
            const resizedSignatureDataUrl = resizedCanvas.toDataURL();
            const signatureData = {
                name: uuid(),
                data: resizedSignatureDataUrl,
                sheetType,
            }
            if (optionalSig) {
                setSignature(signatureData, optionalSig);
            } else {
                setSignature(signatureData);
            }
            if (setSigned) setSigned(true);
            return setOpen(false);
        };
    };

    return (
        <Box display="inline">
            <FormControl>
                {optionalSig ? signature2.data ?
                    <img
                        src={signature2.data}
                        alt="Signature" /> :
                    <Box mt={2} >
                    </Box> :
                    signature.data ?
                        <img
                            src={signature.data}
                            alt="Signature" /> :
                        <Box mt={2} >
                        </Box>
                }
                <Divider
                    sx={{ width: '370px', borderTop: '1px solid #000' }}
                />
                {FormHelperText &&
                    <FormHelperText>{formHelperText}</FormHelperText>
                }
                <Button
                    className='hideForPrint'
                    onClick={handleOpen}>
                    E-Sign
                </Button>
                {open && (
                    <Modal
                        open={true}
                        onClose={handleClose}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                p: 2,
                            }}>
                            <SignatureCanvas
                                ref={sigCanvasRef}
                                penColor="black"
                                canvasProps={{ width: 600, height: 200 }}
                            />
                            <Masonry columns={3}>
                                <Button onClick={handleSave}>Save</Button>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleClear}>Clear</Button>
                            </Masonry>
                        </Box>
                    </Modal>
                )}
            </FormControl>
        </Box>
    );
}

export default ESign;
