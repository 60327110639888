import { useState, useMemo } from "react";

const useFields = (initialState) => {
    const [formData, setFormData] = useState(initialState);

    const handleChange = useMemo(
        () => (e, passedName, passedValue) => {            
            if (!e) return;
            let newFormData = {};
            if (e === "invalidInput") {
                //If e is not available then this is used.
                newFormData[passedName] = passedValue;
            } else if (e === "replaceObj") {
                return setFormData(passedName)
            } else if (e.target.className === "MuiAutocomplete-option Mui-focused") {
                //Special case: autoComplete mui component
                newFormData[passedName] = e.target.innerText;
            } else if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
                //Special case: checkBox mui component
                newFormData[e.target.name] = e.target.checked
            } else if (passedValue) {
                newFormData[passedName] = [passedValue];
            } else {
                newFormData[e.target.name] = e.target.value;
            }
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    ...newFormData,
                }
            });
        },
        []
    );

    const resetFormData = () => {
        setFormData(initialState);
    };

    return [formData, handleChange, resetFormData];
};

export default useFields;