import { create } from "zustand"

let emptyPartObj = {
    isMisc: false,
    qty: "",
    type: "",
    part: "",
    description: "",
    cost: "",
    location: "",
    name: "",
    invoice: "",
    po: ""
}

const usePartsStore = create((set) => ({
    parts: [emptyPartObj, emptyPartObj, emptyPartObj],
    total: 0,

    setTotal: function (total) {
        set(prevState => {
            return { ...prevState, total: total }
        })
    },

    addMiscPart: function (miscPartObj) {
        set(prevState => {
            let parts = [...prevState.parts]
            parts.push(miscPartObj)
            return { ...prevState, parts }
        })
    },

    addPart: function (partObj) {
        set(prevState => {
            let parts = [...prevState.parts]
            parts.push(partObj)
            return { ...prevState, parts }
        })
    },

    setPart: function (name, value, id) {
        set(prevState => {
            const parts = [...prevState.parts];
            parts[id] = { ...parts[id], [name]: value };
            return { ...prevState, parts };
        });
    },

    clearPart: function (id) {
        set(prevState => {
            let parts = [...prevState.parts]
            parts[id] = emptyPartObj
            return { ...prevState, parts }
        })
    },

    clearPartsForm: function () {
        set(prevState => ({
            ...prevState,
            parts: [emptyPartObj, emptyPartObj, emptyPartObj]
        }))
    },

    setPartsObj: function (parts) {
        set(prevState => {
            return { ...prevState, parts }
        })
    },
}))

export default usePartsStore

