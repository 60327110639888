import React, { useEffect, useState } from "react";
import {
    Box, Card, CardContent, Typography, CardHeader,
    Button, InputLabel, MenuItem, Select, FormControl,
    useTheme,
} from "@mui/material";
import SheetsApi from "../api";
import useFields from "../Hooks/useFields";
import useNotification from "../Hooks/useNotification";

function LocationInactive({ locationId, customer }) {
    const [formData, handleLocal] = useFields({ locationStatus: false })
    const messanger = useNotification();
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main;
    const blue = theme.palette.secondary.alter
    useEffect(() => {
        const getLocationData = async function () {
            return await SheetsApi.getLocationData({ locationId });
        }
        getLocationData().then((response) => {
            handleLocal("invalidInput", "locationStatus", response.inactive)
        })
    }, [])

    const changeStatus = async function (e) {
        e.preventDefault();
        const locationObj = {
            locationId,
            inactive: formData.locationStatus,
        }
        const response = await SheetsApi.updateLocationStatus(locationObj)
        return messanger(response.message, response.error ? "error" : "success")
    }
    return (
        <>
            <Box m={3}>
                <Card sx={{ width: '100%', bgcolor: defaultColor }} >
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex">
                            <CardHeader
                                titleTypographyProps={{ sx: { color: 'white' } }}
                                title="Site Status" />
                        </Box>
                        <Box m={2} display="flex">
                            <div
                                style={{
                                    width: "36px",
                                    height: "36px",
                                    backgroundColor: formData.locationStatus === false ? blue : primary,
                                    borderRadius: "50%"
                                }}
                            ></div>
                        </Box>
                    </Box>
                    <CardContent>
                        <Typography sx={{ color: 'white' }}>
                            This action will mark the site as inactive. By doing so,
                            you will hide this site name from the list of available options for customer: "{customer}".
                        </Typography>
                        <Box justifyContent="space-between" mt={2}>
                            <form onSubmit={changeStatus} >
                                <FormControl sx={{ color: 'white' }}>
                                    <InputLabel sx={{ color: 'white' }}>Site Status</InputLabel>
                                    <Select
                                        id="locationStatus"
                                        name="locationStatus"
                                        label="Site Status"
                                        value={formData.locationStatus || false}
                                        onChange={handleLocal}
                                        sx={{ width: "24ch", color: 'white' }}
                                    >
                                        <MenuItem value={false}>Active</MenuItem>
                                        <MenuItem value={true}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box display="inline" ml={1}>
                                    <Button
                                        type="submit"
                                        variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default LocationInactive;