import React, { useEffect, useState } from "react";
import {
    Card, CardContent, useTheme, Paper, TableContainer,
    Table, TableHead, CardHeader, TableRow, TableCell,
    TableBody,
} from "@mui/material"
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

function SheetTypeCard({ sheetType, filteredSheets }) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const history = useHistory();
    const theme = useTheme();
    const defaultColor = theme.palette.default.main;
    const primaryColor = theme.palette.primary.main;
    const [data, setData] = useState([])
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (sheetType === "sheets") setTitle("Service")
        else if (sheetType === "chemAppAgreement") setTitle("Custom Applications")
        else if (sheetType === "disinfectantAgreement") setTitle("Disinfectant Applications")
        else if (sheetType === "sprayBarAgreemnet") setTitle("Spray Bar Applications")
        else if (sheetType === "freshPack") setTitle("Fresh Pack")
        else if (sheetType === "inspections") setTitle("Storage Inspection")
        else if (sheetType === "salesOrders") setTitle("Sales Orders")
        else if (sheetType === "preseason") setTitle("Preseason")
        else if (sheetType === "refrigeration") setTitle("Refrigeration Start-up")
        else if (sheetType === "bids") setTitle("Ventilation Bids")
        else if (sheetType === "bidRef") setTitle("Refrigeration Bids")
        setData(filteredSheets[sheetType]);
    }, [])

    const handleLink = function (id) {
        let type = sheetType;
        if (sheetType === 'chemAppAgreement') type = 'chemApps/agreement'
        if (sheetType === 'disinfectantAgreement') type = 'disinfectant/agreement'
        return history.push(`/${type}/edit/${id}`)
    }
    const handleFormLink = function (e, id) {
        e.stopPropagation();
        let type = sheetType;
        if (sheetType === 'chemAppAgreement') type = 'chemApps'
        if (sheetType === 'disinfectantAgreement') type = 'disinfectant'
        return history.push(`/${type}/form/edit/${id}`)
    }
    return (<>
        <Card sx={{ width: '100%', bgcolor: primaryColor, mb: 1 }}>
            <CardHeader
                title={title}
                titleTypographyProps={{ sx: { color: defaultColor } }}
            />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ bgcolor: defaultColor, }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white' }}>ID #</TableCell>
                                <TableCell sx={{ color: 'white' }} align="right">Service Writer</TableCell>
                                {sheetType === 'sheets' &&
                                    <TableCell sx={{ color: 'white' }} align="right">Service Type</TableCell>
                                }
                                <TableCell sx={{ color: 'white' }} align="right">PO</TableCell>
                                <TableCell sx={{ color: 'white' }} align="right">Date Serviced</TableCell>
                                {(sheetType === 'disinfectantAgreement' || sheetType === 'chemAppAgreement') &&
                                    <TableCell sx={{ color: 'white' }} align="right">Form Link</TableCell>
                                }
                                <TableCell sx={{ color: 'white' }} align="right">Stage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 && data.map((data) => (
                                <TableRow
                                    key={data.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '&:hover': { cursor: 'pointer' }
                                    }}
                                    onClick={() => handleLink(data.id)}
                                >
                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{data.id}</TableCell>
                                    <TableCell sx={{ color: 'white' }} align="right">{data.creator}</TableCell>
                                    {sheetType === 'sheets' &&
                                        <TableCell sx={{ color: 'white' }} align="right">{data.service_type}</TableCell>
                                    }
                                    <TableCell sx={{ color: 'white' }} align="right">{data.po}</TableCell>
                                    <TableCell sx={{ color: 'white' }} align="right">{dayjs.tz(data.dateserviced, 'America/Denver').format('MM-DD-YYYY')}</TableCell>
                                    {(sheetType === 'disinfectantAgreement' || sheetType === 'chemAppAgreement') &&
                                        <TableCell onClick={(e) => handleFormLink(e, data.id)} sx={{ color: 'white' }} align="right">Form</TableCell>
                                    }
                                    <TableCell sx={{ color: 'white' }} align="right">{data.stage}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </>)
}

export default SheetTypeCard;