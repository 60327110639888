import React, { useEffect, useState } from "react";
import VfdTable from "./VfdTable";
import VfdTotal from "./VfdTotal";
import { Image } from '@react-pdf/renderer';
import asCoHeader from "../../../../../images/BidHeaders/asCoHeader.jpg";

function VfdPage({ vfdTable, bidBase }) {
    const [vfdProcessedTable, setVfdProcessedTable] = useState({})
    const [kwhSaved, setKwhSaved] = useState(0)
    const [currentAirflow, setCurrentAirflow] = useState(0);
    const [recommendedAirflow, setRecommendedAirflow] = useState(0);
    const [render, setRender] = useState(false);

    useEffect(() => {
        //Reprocess the data to display it easily into a table
        const reProcessedObj = {};
        Object.keys(vfdTable).map((dataType) => {
            if (dataType !== 'newPriceObj') {
                const obj = vfdTable[dataType];
                return Object.keys(obj).map((phase) => {
                    if (!reProcessedObj[phase]) reProcessedObj[phase] = {};
                    return reProcessedObj[phase][dataType] = obj[phase];
                })
            }
        })

        const createAvg = function (obj) {
            let total = 0;
            Object.keys(obj).map((phase) => {
                return total += obj[phase];
            })
            return parseFloat((total / Object.keys(obj).length + 1).toFixed(2))
        }
        const createTotal = function (obj) {
            let total = 0;
            Object.keys(obj).map((phase) => {
                return total += obj[phase];
            })
            return parseInt(total);
        }
        setKwhSaved(createTotal(vfdTable.kwhSaved))
        setCurrentAirflow(createAvg(vfdTable.currAirflow))
        setRecommendedAirflow(createAvg(vfdTable.recommendedAirflow))
        setVfdProcessedTable(reProcessedObj);
        setRender(true)
    }, [])

    return (
        <>
            {render && <>                
                <VfdTable
                    vfdProcessedTable={vfdProcessedTable}
                    kwhSaved={kwhSaved}
                    currentAirflow={currentAirflow}
                    recommendedAirflow={recommendedAirflow}
                />
                <VfdTotal
                    newPriceObj={vfdTable.newPriceObj}
                    kwhSaved={kwhSaved}
                    bidBase={bidBase}
                />
            </>}
        </>
    )
}

export default VfdPage;