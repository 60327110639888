import React, { useContext, useEffect, useState } from "react";
import BaseSheet from "../SheetComponents/BaseSheet"
import {
    Grid, Box, TextareaAutosize, useTheme, Button,
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import BaySheet from "./BaySheet";
import useBaseStore from "../../stores/baseStore";
import useBayStore from "../../stores/bayStore";
import VisualInspection from "./VisualInspection";
import TechSheet from "../SheetComponents/TechSheet";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import useTechStore from "../../stores/techStore";
import { useHistory } from "react-router-dom"
import UserContext from "../../Users/UserContext";
import useNotification from "../../Hooks/useNotification";
import useSettingsStore from "../../stores/settingsStore";
import SheetsApi from "../../api";
import InspectionEtc from "./InspectionEtc";
import RunTime from "./RunTime";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function StorageInspection() {
    const INITIAL_STATE = {
        followUp: false,
        visualInspectionNotes: "",
    }
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const history = useHistory()
    const { currentUser } = useContext(UserContext)
    const messanger = useNotification()
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home]);
    const [renderCheck, setRenderCheck] = useState(false)
    const [bays, visualInspectionForm, clearInspectionForm,
        inspectionEtcForm] = useBayStore(state => [state.bays,
        state.visualInspectionForm, state.clearInspectionForm, state.inspectionEtc])
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [techSheet, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const inspectionSheet = { ...formData, ...visualInspectionForm, ...inspectionEtcForm }
        const sheetObj = CreateSubmitObj(
            inspectionSheet, currentUser, messanger,
            baseSheet, [], techSheet
        )
        //convert empty strings to 0 or convert from string to float
        const properties = ['plenumSetPoint',
            'osaControl', 'osaTemp', 'osaHumidity']
        properties.forEach(property => {
            if (sheetObj.sheet[property] === "") {
                sheetObj.sheet[property] = 0;
            } else {
                sheetObj.sheet[property] = parseFloat(sheetObj.sheet[property])
            }
        })
        sheetObj.bays = bays;
        console.log(sheetObj)
        await SheetsApi.addInspectionSheet(sheetObj).then((response) => {
            getNotifications(currentUser)
            messanger(response.message, response.error ? "error" : "success")
        })
        clearBaseForm()
        clearTechForm()
        resetFormData()
        clearInspectionForm()
        setRenderCheck(false)
        history.push(home)
        return setIsSubmitting(false)
    }

    useEffect(() => {
        clearBaseForm()
        clearTechForm()
        resetFormData()
        clearInspectionForm()
        setRenderCheck(true)
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        {renderCheck && <>
                            <BaseSheet title="Storage Inspection" />
                            <RunTime />
                            <InspectionEtc />
                            <Box mb={1}>
                                <BaySheet edit={false} />
                            </Box>
                            <Box display={currentUser.mobile ? "" : "flex"}>
                                <VisualInspection name={'Doors & Louvers'} vId={"doorsLouvers"} />
                                <VisualInspection name={'Fans'} vId={"fans"} />
                                <VisualInspection name={'ClimaCell'} vId={"climaCell"} />
                                <VisualInspection name={'Humidifiers'} vId={"humidifiers"} />
                                <VisualInspection name={'Control Panel'} vId={"controlPanel"} />
                                <VisualInspection name={'Plenum & Ducts'} vId={"plenumDucts"} />
                                <VisualInspection name={'Refrigeration'} vId={"refrigeration"} />
                            </Box>
                        </>}
                        <Box>
                            <TextareaAutosize
                                minRows={4}
                                style={{
                                    width: "100%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                aria-label="maximum height"
                                name={`visualInspectionNotes`}
                                value={formData.visualInspectionNotes || ""}
                                onChange={handleLocal}
                            />
                        </Box>
                        {renderCheck &&
                            <TechSheet
                                toggle={true}
                                edit={false}
                                timeTrack={true}
                            />
                        }
                        <Box style={{ display: 'inline-block' }} >
                            <FollowUp
                                state={formData}
                                setState={handleLocal}
                            />
                            <Box display="flex" justifyContent="flex-start">
                                <DeptSelect />
                                <Button
                                    disabled={isSubmitting}
                                    className="hideForPrint"
                                    variant="contained"
                                    style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                    type="submit">
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid >
            </form >
        </>
    )
}

export default StorageInspection;