import React, { useEffect, useState } from "react";
import {
    Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import Commas from "../../BidFunctions/commas";

function QuoteAgreement({ bidBase, option, otherTotal, grandTotal, signature, signature2, company, laborIncluded }) {
    const [companyLocal, setCompanyLocal] = useState('');
    const styles = StyleSheet.create({
        underline: { textDecoration: 'underline' },
        bold: {
            fontSize: 16, color: '#000000',
            fontWeight: 700,
        },
        smallerText: {
            marginLeft: 100,
            marginRight: 10,
            fontSize: 10,
        },
        divider: {
            borderBottom: 2,
            borderColor: 'black',
            flexGrow: 2,
            marginTop: 2,
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        dateLine: {
            borderBottom: 1,
            borderColor: 'black',
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
            width: '40%'
        },
        dateText: {
            marginTop: bidBase.dateServiced ? 15 : 50,
        },
        sigLine: {
            borderBottom: 1,
            borderColor: 'black',
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '1px solid',
            width: '65%',
        },
        sigText: { fontSize: 12 },
        container2: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
            flexDirection: 'row',
            width: '115%',
        },
        rightMargin: {
            marginRight: '20px',
        },
        sig: {
            width: 200,
            marginTop: 15,
        },
        totalContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        optionLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        description: {
            flex: 1,
            textAlign: 'right',
            marginRight: 10,
        },
        price: {
            width: '15%',
            textAlign: 'right',
        },
    })
    useEffect(() => {
        if (company === 'Agri-Stor Companies') return setCompanyLocal('The Agri-Stor Company')
        else if (company === 'Agri-Stor Company North West') return setCompanyLocal('Agri-Stor Company NorthWest, Inc.')
        else if (company === 'Agri-Stor Company Colorado') return setCompanyLocal('Agri-Stor Company Colorado')
        else if (company === 'The Gellert Company') return setCompanyLocal('The Gellert Company')
        else return setCompanyLocal('----- NOT A VALID COMPANY ------')
    }, [])

    return (
        <>
            {bidBase.noPrice &&
                <View style={styles.totalContainer}>
                    <Text>Installation Labor and Materials:</Text>
                    <Text>$ {Commas.add(otherTotal)}</Text>
                </View>
            }
            <Text> </Text>
            <View style={styles.totalContainer}>
                <Text style={styles.bold}>TOTAL JOB {laborIncluded && '- including Installation:'}</Text>
                <Text style={styles.bold}>$ {Commas.add(grandTotal + otherTotal)}</Text>
            </View>
            <Text> </Text>
            {option.length > 0 && option.map((line) => {
                if (line.description !== "") return (
                    <View style={styles.optionLine} key={line.optionId}>
                        <Text style={styles.description}>{line.description}:</Text>
                        <Text style={styles.price}>
                            {!line.hideTotal ?
                                `${Commas.add(line.price)}`
                                : null}
                        </Text>
                    </View>
                );
                else return null;
            })}
            <View>
                <Text> </Text>
                <Text style={styles.underline}>This Proposal Includes:</Text>
                <Text style={styles.smallerText}>
                    {bidBase.proposalIncludes}
                </Text>
                <Text> </Text>
                <Text style={styles.underline}>Customer to Provide:</Text>
                <Text style={styles.smallerText}>
                    {bidBase.customerToProvide}
                </Text>
                <Text> </Text>
                <Text style={styles.underline}>Terms:</Text>
                <Text style={styles.smallerText}>
                    {bidBase.terms}
                </Text>
                <Text> </Text>
                <Text style={styles.rightMargin}>
                    Equipment remains the property of {companyLocal} until payment is
                    received in full. {companyLocal}, its officers and employees do not assume responsibility
                    for the storage structure or the product held in storage.
                </Text>
                <View style={styles.divider} />
                <View style={styles.center}>
                    <Text style={styles.bold}>***ACCEPTANCE OF PROPOSAL***</Text>
                </View>
                <View style={styles.container2}>
                    <View style={{ flex: 1 }}>
                        {signature2.data &&
                            <Image style={styles.sig} src={signature2.data} />
                        }
                        <View style={[styles.sigLine, { marginTop: !signature2.data ? 50 : 0 }]} />
                        <Text style={styles.sigText}>{bidBase.customer}</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        {signature.data &&
                            <Image style={styles.sig} src={signature.data} />
                        }
                        <View style={[styles.sigLine, { marginTop: !signature.data ? 50 : 0 }]} />
                        <Text style={styles.sigText}>{companyLocal}</Text>
                    </View>
                </View>
                <View>
                    <Text style={styles.dateText}> </Text>
                    <View style={styles.dateLine} />
                    <Text style={styles.sigText}>Date</Text>
                </View>
            </View>
        </>
    )
}

export default QuoteAgreement;