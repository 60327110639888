import React, { useEffect, useState, useContext, useRef } from "react";
import {
    Box, Grid, FormControlLabel, InputLabel, Checkbox,
    TextField, Select, MenuItem, Autocomplete, FormControl,
    Typography, useTheme, RadioGroup, Radio, TextareaAutosize,
    Card, CardContent,
} from "@mui/material/"
import useSettingsStore from "../../../stores/settingsStore";
import { Masonry } from "@mui/lab"
import useFields from "../../../Hooks/useFields";
import useBaseStore from "../../../stores/baseStore";
import useImageStore from "../../../stores/imageStore";
import useChemAppStore from "../../../stores/chemAppStore";
import { useParams, useHistory } from "react-router-dom"
import UserContext from "../../../Users/UserContext";
import CreateSubmitObj from "../../SheetComponents/CreateSubmitObj"
import BaseSheet from "../../SheetComponents/BaseSheet";
import useTechStore from "../../../stores/techStore";
import SheetsApi from "../../../api";
import TechSheet from "../../SheetComponents/TechSheet";
import PulpTemps from "./PulpTemps";
import OutDoorConditions from "./OutDoorConditions";
import Sprouting from "./Sprouting";
import Chemicals from "./Chemicals";
import Bypass from "./Bypass";
import Regulation from "./Regulation";
import CleanAndFinish from "./CleanAndFinish";
import Coords from "./Coords";
import SubmitAndPrint from "../../SheetComponents/SubmitAndPrint";
import ImageDropZone from "../../SheetComponents/images/imageDropZone";
import HandleImage from "../../SheetComponents/images/imageUtilities/HandleImage";
import useNotification from "../../../Hooks/useNotification";

function ChemAppForm({ disinfectant, sprayBar }) {
    const INITIAL_STATE = {
        phone: "",
        billing: "",
        appType: "",
        amount: "",
        cwt1: "",
        cwt2: "",
        product1: "",
        product2: "",
        rate1: "",
        rate2: "",
        lat: "",
        long: "",
        instructions: "",
        topPileConditions: "",
        topPileConditionsOtherText: "",
        ducts: "",
        ductsOther: false,
        ductsOtherText: "",
        notes: "",
        stage: false,
    }
    const [home] = useSettingsStore((state) => [state.home]);
    const history = useHistory()
    const { currentUser } = useContext(UserContext)
    const messanger = useNotification();
    const { id } = useParams()
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const [chemAppForm, setChemAppForm, setChemAppAgreementObj,
        chemAppAgreement, clearChemAppForm, clearChemAppAgreement,
        setChemAppFormObj] = useChemAppStore((state) => (
            [state.chemAppForm, state.setChemAppForm, state.setChemAppAgreementObj,
            state.chemAppAgreement, state.clearChemAppForm,
            state.clearChemAppAgreement, state.setChemAppFormObj]))
    const [baseForm, setBaseFormObj, clearBaseForm] = useBaseStore(state => (
        [state.baseForm, state.setBaseFormObj, state.clearBaseForm]))
    const [techs, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj])
    const [signature, images, addImage, setSignature, clearImages] = useImageStore(state =>
        [state.signature, state.images, state.addImage, state.setSignature, state.clearImages])
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [renderCheck, setRenderCheck] = useState(false)
    const [renderTechCheck, setRenderTechCheck] = useState(false)
    const componentRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setRenderTechCheck(false)
        setRenderCheck(false)
        clearBaseForm()
        clearTechForm()
        clearChemAppAgreement()
        clearChemAppForm()
        clearImages();
        async function getChemAppAgreement() {
            try {
                const chemAppAgreement = await SheetsApi.getAgreement({ id, disinfectant, sprayBar })
                const images = chemAppAgreement.images;
                const locationId = chemAppAgreement.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                const chemAppForm = await SheetsApi.getChemAppForm({ id, disinfectant, sprayBar })
                const fetchTechs = await SheetsApi.getTechsById({
                    id,
                    type: sprayBar ? "sprayBarId" : disinfectant ? "disinfectantId" : "chemAppId"
                })
                chemAppAgreement.location = locationName.location_name
                chemAppAgreement.locationId = locationId
                return { chemAppAgreement, chemAppForm, fetchTechs, images }
            } catch (err) {
                console.log(err)
            }
        }
        getChemAppAgreement().then(async sheetObj => {
            console.log(sheetObj)
            const sheet = sheetObj.chemAppAgreement
            const chemAppFormObj = sheetObj.chemAppForm
            const fetchTechs = sheetObj.fetchTechs
            const images = sheetObj.images
            let baseFormObj = {
                ...sheet,
                customerName: sheet.customer,
                locationName: sheet.location,
                requestedBy: sheet.requestBy,
            }
            let chemAppAgreementObj = {
                ...sheet,
                wps: chemAppForm.wps,
                purge: chemAppForm.purge,
            }
            const baseChemAppForm = {
                phone: sheet.phone,
                billing: sheet.billing,
                appType: sheet.appType,
                amount: sheet.amount,
                cwt1: sheet.cwt1,
                cwt2: sheet.cwt2,
                product1: sheet.product1,
                product2: sheet.product2,
                product3: sheet.product3,
                rate1: sheet.rate1,
                rate2: sheet.rate2,
                rate3: sheet.rate3,
                lat: chemAppFormObj.lat,
                long: chemAppFormObj.long,
                instructions: chemAppFormObj.instructions,
                topPileConditions: chemAppFormObj.topPileConditions,
                topPileConditionsOtherText: chemAppFormObj.topPileConditionsOtherText,
                ducts: chemAppFormObj.ducts,
                ductsOther: chemAppFormObj.ductsOther,
                ductsOtherText: chemAppFormObj.ductsOtherText,
                notes: chemAppFormObj.notes,
            }

            let id = -1
            for (let obj of fetchTechs) {
                id++
                let techObj = {
                    technician: obj.technician,
                    truck: obj.truck,
                    labor: obj.labor,
                    travel: obj.travel,
                    nc: obj.nc,
                    notes: obj.notes,
                    date: obj.date,
                    timeStart: obj.timestart,
                    timeEnd: obj.timeend,
                }
                setTechObj(techObj, id)
            }
            for (let img of images) {
                const imageObj = await HandleImage.setImage(img)
                if (img.imageType === 'img' || img.imageType === 'receipt') {
                    addImage(imageObj)
                } else {
                    setSignature(imageObj)
                }
            }
            setChemAppAgreementObj(chemAppAgreementObj)
            setBaseFormObj(baseFormObj)
            setChemAppFormObj(chemAppFormObj)
            handleLocal("replaceObj", baseChemAppForm)
            setRenderCheck(true)
            setRenderTechCheck(true)
        })
    }, [])

    const handleSubmit = async function (e) {
        e.preventDefault();
        setIsSubmitting(true);
        const chemAppFormData = {
            ...chemAppForm,
            customerName: baseForm.customerName,
            locationId: baseForm.locationId,
            sheetType: baseForm.sheetType,
            dateServiced: baseForm.dateServiced,
            stage: baseForm.stage,
            total1: chemAppAgreement.total1,
            total2: chemAppAgreement.total2,
            total3: chemAppAgreement.total3,
        }
        console.log(chemAppFormData)
        const chemAppObj = CreateSubmitObj(
            chemAppFormData, currentUser, messanger,
            {}, [], techs)
        console.log(techs)
        if (chemAppObj.error) return setIsSubmitting(false);
        chemAppObj.id = id
        chemAppObj.disinfectant = disinfectant;
        chemAppObj.sprayBar = sprayBar;
        if (formData.stage === true) {
            //set the stage to appropriate name, this is for Sheet history / logs
            chemAppObj.sheet.stage = "Ready for Review"
            //change the stage of chemAppAgreement if completed.
            await SheetsApi.editChemAppAgreementStage({
                stage: "Ready for Review",
                id: id,
                disinfectant,
                sprayBar,
            })
        }
        HandleImage.addAndRemove([...images, signature], chemAppObj)
        setRenderTechCheck(false)
        setRenderCheck(false)
        const response = await SheetsApi.editChemAppForm(chemAppObj)
        clearTechForm()
        clearChemAppAgreement()
        clearChemAppForm()
        clearBaseForm()
        clearImages()
        messanger(response.message, "success")
        history.push(home)
        return setIsSubmitting(false);
    }

    const handleChange = function (e, checkbox) {
        const name = e.target.name
        let value;
        if (checkbox) {
            value = e.target.checked
        } else value = e.target.value;
        const fieldsToParseAsFloat = [
            'temp1_1', 'temp1_2', 'temp1_3', 'temp1_4',
            'temp2_1', 'temp2_2', 'temp2_3', 'temp2_4',
            'total', 'outDoorTemp'
        ];
        if (fieldsToParseAsFloat.includes(name)) {
            value = parseFloat(value);
        }
        setChemAppForm(name, value)
    }

    return (
        <div className="page" ref={componentRef} id="chemAppForm">
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BaseSheet disableFields={true} id={id} />
                        <Masonry columns={3}>
                            <TextField
                                disabled={true}
                                name="phone"
                                id="phone"
                                label="Phone Number"
                                value={formData.phone || ""}
                                onChange={handleLocal}
                                onBlur={handleChange}
                            />
                            <Autocomplete
                                disabled={true}
                                disableClearable
                                inputValue={formData.billing || ""}
                                name="customer"
                                id="customer"
                                options={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="customer"
                                        variant="outlined"
                                        sx={{ input: { background: "white" } }}
                                        id="customer2"
                                        label="Billing Customer"
                                    />)}
                            />
                            <FormControl>
                                <InputLabel id="appTypeLabel">Application Type</InputLabel>
                                <Select
                                    disabled={true}
                                    labelId="appTypeLabel"
                                    id="appType"
                                    name="appType"
                                    label="Application Type"
                                    value={formData.appType || ""}>
                                    <MenuItem value="potato">Potatoes</MenuItem>
                                    <MenuItem value="onion">Onions</MenuItem>
                                    <MenuItem value="storage">Disinfect</MenuItem>
                                    <MenuItem value="sprayBar">Spray Bar</MenuItem>
                                </Select>
                            </FormControl>
                        </Masonry>
                        {renderCheck && <Coords />}
                        <Box
                            style={{ width: "100%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                variant="h5"
                                ml={1}
                                style={{ color: defaultColor }}>
                                Special Instructions:
                            </Typography>
                        </Box>
                        <Box ml={0.25} >
                            <TextareaAutosize
                                name="instructions"
                                id="instructions"
                                value={formData.instructions || ""}
                                onChange={handleLocal}
                                onBlur={handleChange}
                                minRows={4}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                            />
                        </Box>
                        {renderCheck && <Chemicals sprayBar={sprayBar} />}
                        {!disinfectant && !sprayBar && <>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h5">
                                Pulp Tempuratures:
                            </Typography>
                            {renderCheck && <>
                                <PulpTemps id={1} />
                                <PulpTemps id={2} />
                            </>}
                            <Typography
                                variant="h5">
                                Top of Pile Moisture Conditions:
                            </Typography>
                            <Box
                                ml={2}
                                style={{ display: 'flex' }}>
                                <RadioGroup
                                    name="topPileConditions"
                                    id="topPileConditions"
                                    value={formData.topPileConditions || ""}
                                    onChange={(e) => {
                                        handleLocal("invalidInput", e.target.name, e.target.value)
                                        handleChange(e)
                                    }}
                                    row>
                                    <FormControlLabel value="Dry" control={<Radio />} label="Dry" />
                                    <FormControlLabel value="Damp" control={<Radio />} label="Damp" />
                                    <FormControlLabel value="Wet" control={<Radio />} label="Wet" />
                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                                <TextField
                                    name="topPileConditionsOtherText"
                                    id="topPileConditionsOtherText"
                                    label="Top of Pile Conditions Notes"
                                    fullWidth
                                    value={formData.topPileConditionsOtherText || ""}
                                    onChange={handleLocal}
                                    onBlur={handleChange}
                                />
                            </Box>
                            {renderCheck && <Sprouting />}
                        </>}
                        <Box className="nextPage" >
                            {renderCheck && !sprayBar && <OutDoorConditions />}
                            {!disinfectant && !sprayBar && <>
                                <Typography
                                    variant="h5">
                                    Ducts:
                                </Typography>
                                <Box
                                    ml={2}
                                    style={{ display: 'inline-block' }}>
                                    <FormControlLabel
                                        label='All Open'
                                        control={<Checkbox
                                            name="ducts"
                                            onChange={(e) => {
                                                handleLocal(e);
                                                handleChange(e, true);
                                            }}
                                            checked={formData.ducts || false}
                                        />}
                                    />
                                </Box>
                                <Box
                                    ml={2}
                                    style={{ display: 'inline-block' }}>
                                    <FormControlLabel
                                        label='Other'
                                        control={<Checkbox
                                            name="ductsOther"
                                            onChange={(e) => {
                                                handleLocal(e);
                                                handleChange(e, true);
                                            }}
                                            checked={formData.ductsOther || false}
                                        />}
                                    />
                                </Box>
                                <Box style={{ display: 'flex' }} >
                                    <TextField
                                        name="ductsOtherText"
                                        id="ductsOtherText"
                                        label="Ducts Notes"
                                        fullWidth
                                        value={formData.ductsOtherText || ""}
                                        onChange={handleLocal}
                                        onBlur={handleChange}
                                    />
                                </Box>
                                <Box
                                    columns={{ sm: 1 }}
                                    display="flex"
                                    justifyContent="space-between">
                                    {renderCheck && <>
                                        <Bypass name="Plenum" id="plenum2" />
                                        <Bypass name="Refrigeration" id="refrigeration" />
                                        <Bypass name="Climacell" id="climaCell" />
                                    </>}
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ backgroundColor: primary }}
                                >
                                    <Typography
                                        variant="h5"
                                        ml={1}
                                        style={{ color: defaultColor }}>
                                        Comments:
                                    </Typography>
                                </Box>
                                <Box ml={0.25} >
                                    <TextareaAutosize
                                        id="notes"
                                        name="notes"
                                        value={formData.notes || ""}
                                        onChange={handleLocal}
                                        onBlur={handleChange}
                                        minRows={4}
                                        style={{
                                            width: "99.0%",
                                            outline: '2px solid #000',
                                            fontSize: "16px",
                                        }}
                                    />
                                </Box>
                            </>}
                            {renderCheck && <>
                                <Box mt={1}>
                                    <Regulation sprayBar={sprayBar} />
                                </Box>
                                {!sprayBar && <CleanAndFinish />}
                            </>}
                            {renderTechCheck &&
                                <TechSheet timeTrack={true} toggle={true} edit={false} chemApp={true} />
                            }
                            <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                                <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                    <CardContent>
                                        <Box display="flex" justifyContent="left" className="hideForPrint" >
                                            {chemAppAgreement.stage === 'Preliminary Review' &&
                                                <FormControlLabel
                                                    label='Complete'
                                                    control={<Checkbox
                                                        name="stage"
                                                        onChange={(e) => {
                                                            handleLocal(e);
                                                            handleChange(e, true);
                                                        }}
                                                        checked={formData.stage || false}
                                                    />}
                                                />
                                            }
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                width={300}
                                                height={300}
                                                componentRef={componentRef}
                                                toggle={false}
                                            />
                                        </Box>
                                        {/* If we want to add imaged to sprayBar / disinfectant sheets then we need to add the IDs to the image db */}
                                        {(!sprayBar && !disinfectant) && <ImageDropZone sheetType="chemAppAgreement" />}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form >
        </div >
    )
}

export default ChemAppForm;
