import React, { useEffect } from "react";
import useBidStoreSub from "../../../../stores/BidStore/bidStoreSub";
import {
    Box, MenuItem, TextField, FormControl, InputLabel,
    Select, InputAdornment,
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import SearchableInput from "../../../SheetComponents/SearchableInput";
import Commas from "../../BidFunctions/commas";
function Damper({ id, damperParts, handleDamperTotal, edit, damperData }) {
    const editPass = edit;
    const [dampers, setDamper, setObj] = useBidStoreSub(state =>
        [state.damper, state.setPart, state.setObj])
    const [formData, handleLocal, resetFormData] = useFields({ qty: 0 })

    const calculateDamperTotal = function (width, height, qty) {
        const returnObj = {}
        const price = formData.price || 0;
        if (!isNaN(width) && !isNaN(height)) {
            const sqFt = parseFloat((width * height / 144).toFixed(2))
            returnObj.sqFt = sqFt;
            if (!isNaN(qty)) {
                const totalSqFt = parseFloat((sqFt * qty).toFixed(1))
                const total = parseFloat((price * totalSqFt).toFixed(2))
                returnObj.totalSqFt = totalSqFt;
                returnObj.total = total;
            }
        }
        return returnObj;
    }

    useEffect(() => {
        const width = parseFloat(formData.width)
        const height = parseFloat(formData.height)
        const qty = parseFloat(formData.qty)
        const results = calculateDamperTotal(width, height, qty)
        handleLocal("invalidInput", "sqFt", results.sqFt)
        handleLocal("invalidInput", "sqFtTotal", results.totalSqFt)
        handleLocal("invalidInput", "total", results.total)
        setObj("damper", {
            partNumber: formData.partNumber,
            total: results.total,
            totalSqFt: results.totalSqFt,
            price: formData.price,
            height, width, qty,
        }, id)
    }, [formData.qty, formData.height, formData.width, formData.price]);

    useEffect(() => {
        resetFormData()
        const damper = damperData ? damperData[id] : false;
        if (edit && damper) {
            handleLocal("replaceObj", damper)
            const results = calculateDamperTotal(damper.width, damper.height, damper.qty)
            const newObj = { ...damper, total: results.total }
            setObj("damper", newObj, id)
        }
    }, []);

    useEffect(() => {
        handleDamperTotal()
    }, [dampers]);

    return (
        <>
            <Box m={1}>
                <Box ml={1} display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <SearchableInput
                        edit={editPass}
                        editData={damperData?.[id]?.partNumber}
                        setState={handleLocal}
                        variableName="partNumber"
                        searchableArray={damperParts}
                        label="Part Number"
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="width"
                        id="width"
                        label="Width"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.width || ""}
                        onChange={handleLocal}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="height"
                        id="height"
                        label="Height"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.height || ""}
                        onChange={handleLocal}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <FormControl>
                        <InputLabel>Flange</InputLabel>
                        <Select
                            name="flange"
                            id="flange"
                            label="Flange"
                            value={formData.flange || ""}
                            onChange={handleLocal}
                            onBlur={(e) => setDamper("damper", "flange", e.target.value, id)}
                            sx={{ width: formData.partNumber === 'SERPART' ? "11ch" : "13ch" }}
                        >
                            <MenuItem value="front">Front</MenuItem>
                            <MenuItem value="rear">Rear</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="sqFt"
                        id="sqFt"
                        label="Sq. Ft. Per Louver"
                        variant="standard"
                        sx={{ width: "14ch" }}
                        value={formData.sqFt || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="sqFtTotal"
                        id="sqFtTotal"
                        label="Total Sq. Ft."
                        variant="standard"
                        sx={{ width: "12ch" }}
                        value={formData.sqFtTotal || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="price"
                        id="price"
                        label="Dealer Price"
                        type="number"
                        variant={formData.partNumber === 'SERPART' ? "outlined" : "standard"}
                        sx={{ width: "12ch", ml: formData.partNumber === 'SERPART' ? 1 : 0, mr: formData.partNumber === 'SERPART' ? 1 : 0 }}
                        value={formData.price || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setDamper("damper", "price", e.target.value, id)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: formData.partNumber === 'SERPART' ? false : true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "12ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Damper;