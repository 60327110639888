import React from "react";
import BidSettings from "./BidSettings";
import PartsSettings from "./Parts/PartsSettings";
import CalendarSettings from "./CalendarSettings";

function SiteSettings() {
    return (
        <>
            <BidSettings />
            <PartsSettings />
            <CalendarSettings />
        </>
    )
}

export default SiteSettings;
