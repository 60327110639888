import { create } from "zustand"

const emptyCondenser = {
    qty: "",
    partNumber: "",
    description: "",
    price: "",
    ambientTemp: "",
    suctionTemp: "",
    capacity: "",
    refrigerant: "",
    amps: "",
    total: 0,
}
const emptyCooler = {
    qty: "",
    partNumber: "",
    description: "",
    price: "",
    type: "evaporatorCoils",
    expansionValve: "txv",
    electricDefrost: false,
    height: '',
    width: '',
    rows: '',
    fins: '',
    coolerAmbientTemp: "",
    coolerSuctionTemp: "",
    capacity: '',
    fans: '',
    cfm: '',
    total: 0,
    fpi: 6,
}
const useBidRefrigerationStore = create((set) => ({
    cooler: emptyCooler,
    condenser: emptyCondenser,

    resetState: function () {
        set(prevState => {
            return {
                ...prevState,
                cooler: emptyCooler,
                condenser: emptyCondenser,
            }
        })
    },

    setPart: function (category, name, value) {
        set(prevState => {
            const partObj = prevState[category]
            partObj[name] = value
            return { ...prevState, [category]: partObj }
        })
    },

    setObj: function (category, obj) {
        set(prevState => {
            return { ...prevState, [category]: obj }
        })
    }

}))

export default useBidRefrigerationStore;