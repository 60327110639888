import React, { useEffect } from "react";
import useFields from "../../../../Hooks/useFields";
import { TextField, Box, InputAdornment, FormControlLabel, Checkbox } from "@mui/material"
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";

function OptionLine({ category, id, edit, miscData }) {
    const [formData, handleLocal, resetFormData] = useFields({})
    const [setPart, setObj] = useBidStoreMisc(state =>
        [state.setPart, state.setObj]);

    const handleGlobalState = function (e) {
        let value;
        const name = e.target.name;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
            handleLocal("invalidInput", name, value)
        } else {
            value = e.target.value
        }
        return setPart(category, name, value, id)
    }
    useEffect(() => {
        resetFormData()
        if (edit && miscData[id]) {
            handleLocal("replaceObj", miscData[id])
            setObj(category, miscData[id], id)
        }
    }, [])

    return (
        <>
            <Box m={1}>
                <Box display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        sx={{ width: "95ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="price"
                        id="price"
                        label="Total"
                        type="number"
                        sx={{ width: "15ch" }}
                        onChange={handleLocal}
                        value={formData.price || ""}
                        onBlur={handleGlobalState}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                        }}
                    />
                </Box>
                <FormControlLabel
                    sx={{ display: 'inline', }}
                    labelPlacement="start"
                    label="Hide Total"
                    control={
                        <Checkbox
                            name="hideTotal"
                            checked={formData.hideTotal || false}
                            onChange={handleGlobalState}
                        />
                    }
                />
            </Box>
        </>
    )
}

export default OptionLine;