import { create } from "zustand"
const emptyPreSeasonForm = {
    fadlube: false,
    fadsetting: false,
    louverlube: false,
    louvers: false,
    louverexhaust: false,
    faninspect: false,
    fanmotor: false,
    fanamp: false,
    fanamp1: "",
    fanamp2: "",
    fanamp3: "",
    fanamp4: "",
    fanamp5: "",
    fanamp6: "",
    fanamp7: "",
    fanamp8: "",
    fanfreq: false,
    climaclean: false,
    climapump: false,
    climapipe: false,
    climawick: false,
    climaamp: false,
    climaamp1: "",
    climaamp2: "",
    humclean: false,
    hummotor: false,
    humamp: false,
    humamp1: "",
    humamp2: "",
    humamp3: "",
    humamp4: "",
    humamp5: "",
    panelmode: false,
    panelcal: false,
    panellight: false,
    panelclean: false,
    panelset: false,
    notes: "",
    followUp: false,
    followUpDept: "",
}
const usePreSeasonStore = create((set) => ({

    preSeasonForm: emptyPreSeasonForm,
    setPreSeasonForm: function (name, value) {
        set(prevState => ({
            ...prevState,
            preSeasonForm: {
                ...prevState.preSeasonForm,
                [name]: value
            }
        }))
    },
    clearPreSeasonForm: function () {
        set(prevState => ({
            ...prevState,
            preSeasonForm: emptyPreSeasonForm
        }))
    },
    setPreSeasonFormObj: function (obj) {
        set(prevState => ({
            ...prevState,
            preSeasonForm: obj
        }))
    }
}))

export default usePreSeasonStore;