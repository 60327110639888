import React, { useEffect, useState } from "react";
import {
    FormControlLabel, Checkbox, Box, Grid
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import useBayStore from "../../stores/bayStore";

function VisualInspection({ name, vId, edit }) {
    const INITIAL_STATE = {
        [vId]: false,
    };
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [visualInspectionForm, setVisualInspectionForm] = useBayStore(state =>
        [state.visualInspectionForm, state.setVisualInspectionForm]);
    const [renderCheck, setRenderCheck] = useState(false)

    useEffect(() => {
        if (edit) {
            let value = visualInspectionForm[vId]
            handleLocal("invalidInput", vId, value)
        }
        setRenderCheck(true)
    }, [])
    const handleChange = function (e) {
        let name = e.target.name;
        let value = e.target.checked
        setVisualInspectionForm(name, value)
    }
    return (
        <>
            {renderCheck &&
                <Grid item xs={6} sm={4} md={2}>
                    <FormControlLabel
                        label={`${name}`}
                        control={<Checkbox
                            checked={formData[vId] || false}
                            onChange={(e) => {
                                handleChange(e);
                                handleLocal(e);
                            }}
                            name={vId}
                        />}
                    />
                </Grid>
            }
        </>
    )
}

export default VisualInspection;