import { create } from "zustand"

const useSheetsStore = create((set) => ({

    serviceSheet: {
        serviceRequested: "",
        workPerformed: "",
        serviceType: "",
        stage: "",
    },

    setServiceSheet: function (name, value) {
        set(prevState => ({
            ...prevState,
            serviceSheet: {
                ...prevState.serviceSheet,
                [name]: value
            }
        }));
    },

    clearServiceSheet: function () {
        set(prevState => ({
            ...prevState,
            serviceSheet: {
                serviceRequested: "",
                workPerformed: ""
            }
        }))
    },
    setServiceSheetObj: function (obj) {
        set(prevState => ({
            ...prevState,
            serviceSheet: obj
        }))
    }
}))

export default useSheetsStore

