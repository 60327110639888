import React from 'react';
import {
    TextField, Box, useTheme, TextareaAutosize, Typography,
} from '@mui/material';
import useLabDataStore from "../../../stores/labDataStore";
function DataEntryChips() {
    const theme = useTheme();
    const background = theme.palette.background.main;
    const [selectedEntry, setValue] = useLabDataStore(state =>
        [state.selectedEntry, state.setValue]);

    return (<>
        <Box>
            <TextField
                id='undesirable'
                name='undesirable'
                label='Undesirable (g)'
                type="number"
                sx={{ width: 110, ml: 1 }}
                InputLabelProps={{ shrink: true }}
                value={selectedEntry.undesirable || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
            />
            <TextField
                id='internal'
                name='internal'
                label='Internal (g)'
                type="number"
                sx={{ width: 110, ml: 1 }}
                InputLabelProps={{ shrink: true }}
                value={selectedEntry.internal || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
            />
            <TextField
                id='external'
                name='external'
                label='External (g)'
                type="number"
                sx={{ width: 110, ml: 1 }}
                InputLabelProps={{ shrink: true }}
                value={selectedEntry.external || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
            />
            <TextField
                id='green'
                name='green'
                label='Green (g)'
                type="number"
                sx={{ width: 110, ml: 1 }}
                InputLabelProps={{ shrink: true }}
                value={selectedEntry.green || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
            />
            <TextField
                id='noDefects'
                name='noDefects'
                label='No Defects (g)'
                type="number"
                sx={{ width: 110, ml: 1 }}
                InputLabelProps={{ shrink: true }}
                value={selectedEntry.noDefects || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
            />
        </Box>
        <Box display="flex">
            <Box ml={2} mt={2}>
                <TextField
                    id='gravity'
                    name='gravity'
                    label='Specific Gravity'
                    type="number"
                    sx={{ width: 250 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.gravity || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
                <TextField
                    id='sfaColor'
                    name='sfaColor'
                    label='sfaColor'
                    type="number"
                    sx={{ width: 250, mt: 1 }}
                    InputLabelProps={{ shrink: true }}
                    value={selectedEntry.sfaColor || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
            </Box>
            <Box mb={1} mt={2} sx={{ width: '100%' }}>
                <Typography sx={{ ml: 2 }} variant="h5">Other Chip Notes</Typography>
                <TextareaAutosize
                    id="otherChipNotes"
                    name="otherChipNotes"
                    value={selectedEntry.otherChipNotes || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                    minRows={3.5}
                    style={{
                        backgroundColor: background,
                        width: "80%",
                        outline: '2px solid #000',
                        fontSize: "16px",
                        padding: '8px 12px',
                        borderRadius: '8px',
                    }}
                    aria-label="maximum height"
                />
            </Box>
        </Box>
    </>)
}

export default DataEntryChips;