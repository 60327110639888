import React, { useEffect, useState } from "react";
import useFields from "../../../Hooks/useFields";
import {
    Box, TextField, Select, MenuItem, InputLabel, FormControl,
    Tooltip, Typography, FormControlLabel, Checkbox, Button, Divider,
} from "@mui/material"
import SheetsApi from "../../../api";
import HelpIcon from '@mui/icons-material/Help';
import { Autocomplete } from "@mui/material";

function PartUpdate({ savePart }) {
    const [categories, setCategories] = useState([])
    const [partsList, setPartsList] = useState([])
    const [formData, handleLocal, resetFormData] = useFields({
        partNumber: "",
        description: "",
        price: "",
        category: "",
        discontinued: false,
        horsePower: "",
        brand: "",
        voltage: "",
        cfm: "",
    })

    useEffect(() => {
        const getCategoriesAndParts = async function () {
            const parts = await SheetsApi.getAllParts()
            const categories = await SheetsApi.getPartsCategories()
            return { parts, categories: categories.categories }
        }
        getCategoriesAndParts().then((response) => {
            const categoryArray = [];
            for (let obj of response.categories) {
                categoryArray.push(obj.category);
            }
            setCategories(categoryArray)
            setPartsList(response.parts)
        })
    }, [])

    const handlePartUpdate = (e) => {
        e.preventDefault();
        savePart(formData, "patch")
        return resetFormData()
    }

    const handleChange = function (e, passedName) {
        if (!e) return;
        const value = e.target.value;
        const isPart = passedName === "partNumber";
        const keyToFind = isPart ? 'partNumber' : 'description';
        const partObj = partsList.find(obj => obj[keyToFind] === value);
        if (partObj) {
            const additionalData = partObj.additionalData
            if (additionalData && Object.keys(additionalData).length > 0) {
                if (Object.keys(additionalData).length > 0) {
                    handleLocal("replaceObj", { ...additionalData, ...partObj })
                }
            } else {
                handleLocal("replaceObj", partObj)
            }

        }
    }

    const categoryFieldsConfig = {
        fan: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'staticPressure05', label: 'Static Pressure 0.5', type: 'number' },
            { name: 'staticPressure075', label: 'Static Pressure 0.75', type: 'number' },
            { name: 'staticPressure1', label: 'Static Pressure 1.0', type: 'number' },
            { name: 'staticPressure125', label: 'Static Pressure 1.25', type: 'number' },
            { name: 'staticPressure15', label: 'Static Pressure 1.5', type: 'number' },
            { name: 'staticPressure175', label: 'Static Pressure 1.75', type: 'number' },
        ],
        freqDrive: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'brand', label: 'Brand', required: true, },
            { name: 'voltage', label: 'Voltage', type: 'number', required: true, },
        ],
        motor: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'voltage', label: 'Voltage', type: 'number', required: true, },
        ],
    };
    return (
        <form onSubmit={(e) => handlePartUpdate(e)}>
            <Typography sx={{ mb: 1 }}>Update a part:</Typography>
            <Box mb={1} display="flex" justifyContent="space-evenly">
                <Autocomplete
                    freeSolo={true}
                    disableClearable
                    inputValue={formData.partNumber || ""}
                    onInputChange={(e) => handleLocal(e, "partNumber")}
                    onBlur={(e) => handleChange(e, "partNumber")}
                    name="partNumber"
                    id="partNumber"
                    options={partsList}
                    getOptionLabel={(option) => option.partNumber}
                    isOptionEqualToValue={(option, value) => option.partNumber === value.partNumber}
                    filterOptions={(options, state) => {
                        const inputValue = (state.inputValue || '').toLowerCase();
                        return options.filter((option) => {
                            const description = option.description || '';
                            const partNumber = option.partNumber || '';
                            return partNumber.toLowerCase().includes(inputValue) ||
                                description.toLowerCase().includes(inputValue)
                        }
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="partNumber"
                            value={formData.partNumber || ""}
                            required
                            sx={{ width: "24ch" }}
                            fullWidth
                            label="Part #"
                        />
                    )}
                />
                <Autocomplete
                    freeSolo={true}
                    disableClearable
                    inputValue={formData.description || ""}
                    onInputChange={(e) => handleLocal(e, "description")}
                    onBlur={(e) => handleChange(e, "description")}
                    name="description"
                    id="description"
                    options={partsList}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) => option.description === value.description}
                    filterOptions={(options, state) => {
                        const inputValue = (state.inputValue || '').toLowerCase();
                        return options.filter((option) => {
                            const description = option.description || '';
                            const partNumber = option.partNumber || '';
                            return description.includes(inputValue) ||
                                partNumber.toLowerCase().includes(inputValue)
                        }
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            name="description"
                            value={formData.description || ""}
                            required
                            {...params}
                            sx={{ width: "100%", minWidth: "70ch" }}
                            label="Description"
                        />
                    )}
                />
            </Box>
            <Box mb={1} display="flex" justifyContent="space-evenly">
                <TextField
                    id="price"
                    name="price"
                    label="Price"
                    type="number"
                    required={formData.category !== '' ? true : false}
                    value={formData.price || ""}
                    onChange={handleLocal}
                    sx={{ width: "35%", mr: 1 }}
                />
                <Tooltip title="It is important to use caution when selecting a category. Certain places within the portal populate parts based on the category. If you do not know how to categorize a part then reach out to an Admin or a software dev. Otherwise leave this blank. If a category is chosen then the price is required.">
                    <HelpIcon sx={{ paddingTop: 2, paddingRight: 1 }} />
                </Tooltip>
                <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                        id="category"
                        name="category"
                        label="Category"
                        value={formData.category || ""}
                        onChange={handleLocal}
                        sx={{ width: "35%" }}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        {categories.length > 0 && categories.map((category) => {
                            return <MenuItem
                                key={category}
                                value={category} >
                                {category}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Tooltip title="By discontinueing a part (checking the box) you will prevent the part from being seen in the parts list of all sheets.">
                    <HelpIcon sx={{ paddingTop: 2, paddingRight: 1 }} />
                </Tooltip>
                <FormControlLabel
                    control={<Checkbox
                        name="discontinued"
                        onChange={handleLocal}
                        checked={formData.discontinued || false} />}
                    label="Discontinued"
                    value={formData.discontinued || false}
                    onChange={handleLocal}
                />
            </Box>
            <Box mb={1}>
                {formData.category === 'fan' ||
                    formData.category === 'freqDrive' ||
                    formData.category === 'motor' ? <>
                    {categoryFieldsConfig[formData.category].map(field => (
                        <TextField
                            key={field.name}
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            required={field.required}
                            type={field.type || 'text'}
                            value={formData[field.name] || ''}
                            onChange={handleLocal}
                            sx={{ width: '25%', mr: 1, mb: 1 }}
                        />
                    ))}
                </> : null}
            </Box>
            <Divider />
            <Box mt={2} mr={5} display="flex" justifyContent="flex-end">
                <Typography sx={{ mr: 5 }}>
                    After updating a part, you must refresh the page to see the changes made.
                </Typography>
                <Button type="submit">
                    Update
                </Button>
            </Box>
        </form>
    )
}

export default PartUpdate;
