import React, { useState, useEffect } from "react"
import SheetsApi from "../../api"
import Part from "./Part";
import {
    Button, Box, TextField, Paper,
    TableRow, TableHead, Table, TableBody, TableCell,
    TableContainer, InputAdornment,
} from "@mui/material";
import usePartsStore from "../../stores/partsStore";
import { useTheme } from '@mui/material/styles';

function PartsSheet({ toggle, calcOn }) {
    const [partsList, setPartsList] = useState([])
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [calcValue, setCalc] = useState(0)
    const [addPart, parts, addMiscPart, setTotal] = usePartsStore(state =>
        [state.addPart, state.parts, state.addMiscPart, state.setTotal])

    const partObj = {
        qty: "",
        type: "",
        part: "",
        description: "",
        cost: "",
        isMisc: false
    }

    const miscPartObj = {
        isMisc: true,
        qty: "",
        type: "",
        part: "",
        description: "",
        cost: "",
        location: "",
        name: "",
        invoice: "",
        po: ""
    }


    const additionalPart = (e) => {
        e.preventDefault()
        return addPart(partObj)
    }

    const additionalMiscPart = (e) => {
        e.preventDefault()
        return addMiscPart(miscPartObj)
    }

    useEffect(() => {
        async function getParts() {
            try {
                const parts = await SheetsApi.getAllActiveParts()
                return parts
            } catch (err) {
                console.log(err)
            }
        }
        getParts().then(parts => {
            setPartsList(parts)
        })
    }, [])

    const calculator = function () {
        let total = 0;
        parts.forEach(part => {
            let cost = parseFloat(part.cost) || 0
            let qty = parseFloat(part.qty) || 0
            cost = parseFloat((cost * qty).toFixed(2))
            total += cost
        })
        setTotal(total);
        setCalc(total);
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                Parts details
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parts.map((item, index) => (
                            <Part
                                toggle={toggle}
                                calculator={calculator}
                                key={index}
                                id={index}
                                item={item}
                                partsList={partsList}
                            />
                        ))}
                        <TableRow>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box m={1}>
                    <Box
                        mb={1}
                        display="flex"
                        justifyContent="space-between">
                        <Box sx={{ display: 'flex' }} className="hideForPrint">
                            <Box ml={1}>
                                <Button
                                    style={{ color: defaultColor, fontWeight: "bold" }}
                                    onClick={additionalPart}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Add Part
                                </Button>
                            </Box>
                            <Box ml={1} mr={1}>
                                <Button
                                    style={{ color: defaultColor, fontWeight: "bold" }}
                                    onClick={additionalMiscPart}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    ADD SERPART
                                </Button>
                            </Box>
                        </Box>
                        {calcOn && toggle && <>
                            <Box ml={2} mb={1} mr={2} display="flex" align="right">
                                <Box display="inline" ml={1} mt={0.5}>
                                    <TextField
                                        sx={{ width: "10.5ch" }}
                                        variant="standard"
                                        type="number"
                                        value={calcValue}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment
                                                    position="start">
                                                    $
                                                </InputAdornment>,
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </>}
                    </Box>
                </Box>
            </TableContainer>

        </>
    )
}

export default React.memo(PartsSheet);



