import React, { useEffect, useState } from 'react';
import {
    Page, Document, StyleSheet, PDFViewer, Font, View, Text, Image,
} from '@react-pdf/renderer';
import QuoteHeader from './QuoteComponents/QuoteHeader';
import useBidStoreMain from '../../../stores/BidStore/bidStoreMain';
import useBidStoreSub from '../../../stores/BidStore/bidStoreSub';
import useBidStoreMisc from '../../../stores/BidStore/bidStoreMisc';
import useBidPartsStore from '../../../stores/BidStore/bidPartsStore';
import useBidTotalsStore from '../../../stores/BidStore/bidTotalsStore';
import QuoteTable from './QuoteComponents/QuoteTable';
import useImageStore from '../../../stores/imageStore';
import useBidQuoteStore from '../../../stores/BidStore/bidQuoteStore';
import { v4 as uuid } from 'uuid';
import QuoteAgreement from './QuoteComponents/QuoteAgreement';
import SheetsApi from '../../../api'
import BidSignatureAndSave from './BidSignatureAndSave';
import BidQuoteFunctions from '../BidFunctions/BidQuoteFunctions';
import VfdPage from './QuoteComponents/Vfd/VfdPage';
import Commas from '../BidFunctions/commas';
import asCoHeader from "../../../images/BidHeaders/asCoHeader.jpg";
import asNwHeader from "../../../images/BidHeaders/asNwHeader.jpg";
import asTfHeader from "../../../images/BidHeaders/asTfHeader.jpg";
import gellertHeader from "../../../images/BidHeaders/gellertHeader.jpg";

function BidQuote({ gellertQuote }) {
    const gellertQuotePass = gellertQuote;
    const [renderCheck, setRenderCheck] = useState(false)
    const [signature, signature2] = useImageStore(state => [state.signature, state.signature2]);
    const [doors, dampers, lightBlock, subTotals] = useBidStoreSub(state =>
        [state.door, state.damper, state.lightBlock, state.totals])
    const [bidBase, totals, fans, motors, freqDrives, vfdTable] = useBidStoreMain(state =>
        [state.bidBase, state.totals, state.fan, state.motor, state.freqDrive, state.vfdTable])
    const [humidifiers, controls, climaCellParts, actuators,
        climaCellStatic, partsTotals] = useBidPartsStore(state =>
            [state.humidifier, state.control, state.climaCell, state.actuator,
            state.climaCellStatic, state.climaCellTotals])
    const [miscTotals, option, custom, labor] = useBidStoreMisc(state =>
        [state.totals, state.option, state.custom, state.labor]);
    const [grandTotal, otherTotal, add, clear] = useBidQuoteStore(state =>
        [state.total, state.otherTotal, state.add, state.clear])
    const [terms] = useBidTotalsStore(state => [state.terms]);
    const [pages, setPages] = useState([]);
    const [laborIncluded, setLaborIncluded] = useState(false);
    const noVentilation = bidBase.noVentilation;

    useEffect(() => {
        if (Object.keys(bidBase).length > 0) {
            const getOverHead = async function () {
                const settings = await SheetsApi.getSettings()
                return settings[0].value
            }
            getOverHead().then((overHead) => {
                clear();
                let extraClimaData = {}
                let extraDamperData = {}
                lightBlock.forEach((lb) => {
                    lb.description = `24" Wide X ${lb.height}" High`
                    lb.total = lb.priceTotal
                })
                const fans2 = fans.map((fan) => ({
                    ...fan,
                    description: fan.description + ' Complete with AMCA Cast Aluminum Propellers'
                }));
                const doors2 = doors.map((door) => ({
                    ...door,
                    total: door.price,
                    description: `${door.width}" Wide X ${door.height}" with heat tape`
                }));
                const dampers2 = dampers.map((damper) => ({
                    ...damper,
                    description: `${damper.width}" Wide X ${damper.height} High Aluminuim Louver`,
                }));
                climaCellStatic.forEach((item) => {
                    if (item.climaCellMediaPartNumber) {
                        extraClimaData = { ...extraClimaData, media: item }
                    } else if (item.tankPartNumber) {
                        extraClimaData = { ...extraClimaData, tank: item }
                    } else if (item.partNumber) {
                        extraClimaData = { ...extraClimaData, edgeCoat: item }
                    }
                })
                extraClimaData = {
                    ...extraClimaData,
                    ...partsTotals[0],
                    sqFtReq: Math.ceil(subTotals.climaCellSqFtReq),
                    sqFtTotal: Math.ceil(subTotals.climaCellSqFtTotal),
                }
                extraDamperData = {
                    sqFtReq: subTotals.damperSqFtReq,
                    sqFtTotal: subTotals.damperSqFtTotal,
                }

                const allTables2 = [
                    { title: 'Aerovent Galvanized Panel Fans:', data: fans2, id: uuid() },
                    { title: 'Fan Motors:', data: motors, id: uuid() },
                    { title: 'Frequency Drives:', data: freqDrives, id: uuid() },
                    { title: 'ClimaSeal Fresh Air Doors:', data: [...doors2, ...actuators], extraData: { sqFtTotal: subTotals.doorsSqFtTotal, sqFtReq: subTotals.doorsSqFtReq }, skipQtyCalc: true },
                    { title: 'ClimaCell Evaporative Cooler:', data: climaCellParts, extraData: extraClimaData },
                    { title: 'Gellert Bahnson Humidifiers:', data: humidifiers },
                    { title: "Ruskin Aluminum Exhaust Dampers:", data: dampers2, extraData: extraDamperData, skipQtyCalc: true },
                    { title: "Total-E-Clips Light Block:", data: lightBlock, skipQtyCalc: true },
                    { title: 'Controls:', data: controls },
                    { title: custom[0].title, data: custom, skipQtyCalc: true },
                ]
                if (gellertQuote) {
                    const allTables3 = tableTotalCalculatorGellert(allTables2);
                    setPages(BidQuoteFunctions.tableToPageCalc(allTables3, noVentilation));
                } else {
                    const allTables3 = BidQuoteFunctions.setProfitAndMargin(allTables2, overHead, bidBase, add);
                    BidQuoteFunctions.getTaxLaborMaterialsPrice(allTables3.total, miscTotals, overHead, bidBase, add);
                    setPages(BidQuoteFunctions.tableToPageCalc(allTables3.tables, noVentilation));
                }
                labor.forEach((tech) => { // If  there is a tech present then pass a variable that will change the verbage on the final agreement.
                    if (tech.technician) {
                        return setLaborIncluded(true);
                    }
                })
                setRenderCheck(true)
            }
            )
        }
    }, [])

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf', fontWeight: 700, fontStyle: 'bold' },
        ],
    });

    const styles = StyleSheet.create({
        page: {
            margin: 10,
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 30,
            paddingBottom: 50,
            flexGrow: 1,
            fontSize: 12,
            fontFamily: "Roboto",
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
            flexDirection: 'row',
        },
        container2: {
            marginTop: 50,
            marginBottom: 50,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '100%',
            borderBottom: 1,
        },
        box: { padding: '10px' },
        underline: { textDecoration: 'underline' },
        flexDirection: { flexDirection: 'row' },
        image: { width: '111.2%', height: '20%', marginTop: '-40px', left: '-40px' },
        total: {
            fontSize: 20,
            marginLeft: 360,
            marginTop: 10,
        },
        header: {
            borderTop: 'none',
            justifyContent: 'space-evenly',
            display: 'flex',
        },
        kw1Row: {
            width: '12%',
            fontSize: '12px',
        },
        kw2Row: {
            width: '12%',
            fontSize: '12px',
        },
        kw3Row: {
            width: '12%',
            fontSize: '12px',
        },
        kw4Row: {
            width: '12%',
            fontSize: '12px',
        },
        kw5Row: {
            paddingLeft: '35px',
            width: '30%',
            fontSize: '12px',
        },
        kw6Row: {
            width: '12%',
            fontSize: '12px',
        },
        kw7Row: {
            width: '10%',
            fontSize: '12px',
        },
    });

    const tableTotalCalculatorGellert = function (tables) {
        for (let i = tables.length - 1; i >= 0; i--) {
            const table = tables[i];
            if (table.title === 'Options:') {
                tables.splice(i, 1);
                continue;
            }
            let tableTotal = 0;
            if (table.title === 'ClimaCell Evaporative Cooler:') {
                let tankTotal = table.extraData.tankTotal || 0;
                let mediaTotal = table.extraData.mediaTotal || 0;
                tableTotal += tankTotal + mediaTotal;
                table.climaCellTotals = { tankTotal, mediaTotal };
            }
            table.data.forEach((item) => {
                item.id = uuid();
                if (table.skipQtyCalc) {
                    tableTotal = item.total2 || item.total || 0;
                } else {
                    let itemTotal = item.price * item.qty || 0;
                    item.total = parseFloat(itemTotal.toFixed(2)) || 0;
                    tableTotal += itemTotal || 0;
                }
            });
            table.total = parseFloat(tableTotal.toFixed(2)) || 0;
            add(table.total)
        }
        return tables;
    };

    const selectHeader = function () {
        const company = bidBase.company
        if (gellertQuote) return gellertHeader;
        else if (company === 'Agri-Stor Companies') return asTfHeader;
        else if (company === 'Agri-Stor Company North West') return asNwHeader;
        else if (company === 'Agri-Stor Company Colorado') return asCoHeader;
        else if (company === 'The Gellert Company') return gellertHeader;
        else return null;
    };

    return (
        <>
            <PDFViewer width={1200} height={1200}>
                <Document>
                    {renderCheck && <>
                        {pages.map((page, index) => {
                            const isLastPage = index === pages.length - 1;
                            return (
                                <Page key={index} wrap size="A4" style={styles.page}>
                                    {index === 0 && (<>
                                        <Image style={styles.image} src={selectHeader()} />
                                        <QuoteHeader
                                            gellertQuote={gellertQuotePass}
                                            bidBase={bidBase}
                                            totals={totals}
                                            grandTotal={grandTotal}
                                            noVentilation={noVentilation}
                                        />
                                    </>)}
                                    {page.map((obj) => {
                                        if (obj.table.data.length !== 0) {
                                            return (
                                                <QuoteTable
                                                    key={obj.id}
                                                    gellertQuote={gellertQuotePass}
                                                    bidBase={bidBase}
                                                    title={obj.table.title}
                                                    data={obj.table.data}
                                                    extraData={obj.table.extraData}
                                                    climaCellTotals={obj.table.climaCellTotals}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                    {isLastPage && gellertQuote && (
                                        <View style={styles.container}>
                                            <Text style={styles.total}>Total: ${Commas.add(grandTotal)}</Text>
                                        </View>
                                    )}
                                </Page>
                            )
                        })}
                        {!gellertQuote && (
                            <>
                                {bidBase.showVfdRec && (
                                    <Page wrap size="A4" style={styles.page}>
                                        <Image style={styles.image} src={selectHeader()} />
                                        <VfdPage bidBase={bidBase} vfdTable={vfdTable} />
                                    </Page>
                                )}
                                <Page wrap size="A4" style={styles.page}>
                                    <QuoteAgreement
                                        signature={signature}
                                        signature2={signature2}
                                        company={bidBase.company}
                                        bidBase={bidBase}
                                        option={option}
                                        otherTotal={otherTotal}
                                        grandTotal={grandTotal}
                                        terms={terms}
                                        laborIncluded={laborIncluded}
                                    />
                                </Page>
                            </>
                        )}
                    </>}
                </Document>
            </PDFViewer>
            {!gellertQuote && <BidSignatureAndSave />}
        </>
    )
}

export default BidQuote;
