import { create } from 'zustand'

const emptyChemAppForm = {
    lat: 0,
    long: 0,
    instructions: '',
    wps: '',
    cfm: '',
    ftPerMin: '',
    vfd: '',
    purge: false,
    total1: 0,
    total2: 0,
    bay1: '',
    bay2: '',
    temp1_1: 0,
    temp1_2: 0,
    temp1_3: 0,
    temp1_4: 0,
    temp2_1: 0,
    temp2_2: 0,
    temp2_3: 0,
    temp2_4: 0,
    topPileconditions: '',
    topPileconditionsOtherText: '',
    plenumDry: false,
    plenumOther: false,
    plenumOtherText: '',
    peeping: false,
    quarterInch: false,
    halfInch: false,
    eyesOpen: false,
    none: false,
    sproutingOtherText: '',
    outDoorTemp: 0,
    clear: false,
    cloudy: false,
    rain: false,
    windy: false,
    windDirection: '',
    weatherOther: false,
    weatherOtherText: '',
    ducts: false,
    ductsOther: false,
    ductsOtherText: '',
    plenum2: false,
    plenum2OtherText: '',
    refrigeration: false,
    refrigerationOtherText: '',
    climaCell: false,
    climaCellOtherText: '',
    notes: '',
    epaReg: '',
    epaReg2: '',
    batchLot: '',
    batchLot2: '',
    endTime: '',
    startTime: '',
    applicatorLic: '',
    cleanedYes: false,
    cleanedOther: false,
    cleanedOtherText: '',
    airSysOk: false,
    airSysOkOther: false,
    airSysOkOtherText: '',
}

const emptyChemAppAgreement = {
    billing: "",
    phone: "",
    appType: "",
    cwt1: "",
    cwt2: "",
    amount: "",
    product1: "",
    product2: "",
    product3: "",
    rate1: "",
    rate2: "",
    rate3: "",
    total1: 0,
    total2: 0,
    total3: 0,
    disinfectantCost: '',
    disinfectantHours: '',
}

const useChemAppStore = create((set) => ({
    chemAppForm: emptyChemAppForm,
    chemAppAgreement: emptyChemAppAgreement,

    setChemAppForm: function (name, value) {
        set(prevState => ({
            ...prevState,
            chemAppForm: {
                ...prevState.chemAppForm,
                [name]: value
            },
        }))
    },

    setChemAppAgreement: function (name, value) {
        set(prevState => ({
            ...prevState,
            chemAppAgreement: {
                ...prevState.chemAppAgreement,
                [name]: value
            }
        }))
    },

    clearChemAppForm: function () {
        set(prevState => ({
            ...prevState,
            chemAppForm: emptyChemAppForm
        }))
    },

    clearChemAppAgreement: function () {
        set(prevState => ({
            ...prevState,
            chemAppAgreement: emptyChemAppAgreement
        }))
    },

    setChemAppFormObj: function (obj) {
        set(prevState => ({
            ...prevState,
            chemAppForm: obj
        }))
    },

    setChemAppAgreementObj: function (obj) {
        set(prevState => ({
            ...prevState,
            chemAppAgreement: obj
        }))
    },

}))

export default useChemAppStore;