import React from "react";
import {
    Text, View, StyleSheet
} from '@react-pdf/renderer';
function BidRefCondenser({ coolerData }) {

    const styles = StyleSheet.create({
        partContainer: {
            marginLeft: 50
        },
        subPartContainer: {
            marginLeft: 50,
        }
    });

    return (
        <>
            <View style={styles.partContainer}>
                <Text> {coolerData.qty} - {coolerData.description} </Text>
                <View style={styles.subPartContainer}>
                    <Text> - 5/8" copper tube</Text>
                    <Text> - {coolerData.rows} row</Text>
                    <Text> - {coolerData.fpi} fpi</Text>
                    <Text> - {coolerData.fins} fin</Text>
                    <Text> - Liquid line solenoids</Text>
                    {coolerData.expansionValve === "none" ? null :
                        <Text> - {coolerData.expansionValve.toUpperCase()}'s</Text>}
                </View>
            </View>
        </>
    )
}

export default BidRefCondenser;