import React from "react";
import SheetsApi from "../api";
import useFields from "../Hooks/useFields";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid"
import { TextField } from "@mui/material";
import { Link } from "react-router-dom"
import { useTheme } from '@mui/material/styles';
import useNotification from '../Hooks/useNotification'

function CompanyCard({ company }) {
    const INITIAL_STATE = {
        company: company.name,
        customer: ""
    }
    const [formData, handleChange, resetFormData] = useFields(INITIAL_STATE)
    const messanger = useNotification()
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const backgroundColor = theme.palette.background.main

    const handleSubmit = async (e) => {
        e.preventDefault()
        let result = await SheetsApi.addCustomer(formData)
        messanger(result.message, result.error ? "error" : "success")
        resetFormData()
    }
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card sx={{ bgcolor: backgroundColor }} elevation={24}>
                <React.Fragment>
                    <CardContent>
                        <Typography variant="h5" component="div" style={{ color: defaultColor }}>
                            {company.name}
                        </Typography>
                        <Grid container justifyContent="flex-end" >
                            <Link to={`/customers/${company.name}`} style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small">
                                    View Customers
                                </Button>
                            </Link>
                        </Grid>
                    </CardContent>
                    <form onSubmit={handleSubmit}>
                        <Grid ml={1} mr={1} mt={1}>
                            <Typography variant="" component="div" style={{ color: defaultColor }}>
                                Add a customer to this company:
                            </Typography>
                            <TextField
                                autoComplete="customer"
                                name="customer"
                                variant="filled"
                                sx={{ input: { background: "white" } }}
                                required
                                fullWidth
                                id="customer"
                                label="Customer Name"
                                autoFocus
                                value={formData.customer}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid m={1} container justifyContent="left" >
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="small">
                                Add Customer
                            </Button>
                        </Grid>
                    </form>

                </React.Fragment>
            </Card>
        </Box>
    )
}

export default CompanyCard