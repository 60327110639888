import React, { useEffect, useState } from "react";
import useFields from "../../../Hooks/useFields";
import {
    Box, TextField, Select, MenuItem, InputLabel, FormControl,
    Tooltip, Typography, Divider, Button,
} from "@mui/material"
import SheetsApi from "../../../api";
import HelpIcon from '@mui/icons-material/Help';
function PartAdd({ savePart }) {
    const [categories, setCategories] = useState([])
    const [formData, handleLocal, resetFormData] = useFields({
        partNumber: "",
        description: "",
        price: "",
        category: "",
        additionalData: {},
        discontinued: false,
    })

    useEffect(() => {
        const getCategories = async function () {
            return await SheetsApi.getPartsCategories()
        }
        getCategories().then((response) => {
            const categoryArray = [];
            for (let obj of response.categories) {
                categoryArray.push(obj.category);
            }
            setCategories(categoryArray)
        })
    }, [])

    useEffect(() => {
        handleLocal("replaceObj", {
            partNumber: "",
            description: "",
            price: "",
            category: formData.category,
            additionalData: {},
            discontinued: false,
        })
    }, [formData.category])

    const handlePartAdd = (e) => {
        e.preventDefault();
        savePart(formData, "post")
        return resetFormData()
    }

    const categoryFieldsConfig = {
        fan: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'staticPressure05', label: 'Static Pressure 0.5', type: 'number', required: true },
            { name: 'staticPressure075', label: 'Static Pressure 0.75', type: 'number', required: true },
            { name: 'staticPressure1', label: 'Static Pressure 1.0', type: 'number', required: true },
            { name: 'staticPressure125', label: 'Static Pressure 1.25', type: 'number', required: true },
            { name: 'staticPressure15', label: 'Static Pressure 1.5', type: 'number', required: true },
            { name: 'staticPressure175', label: 'Static Pressure 1.75', type: 'number', required: true },
        ],
        freqDrive: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'brand', label: 'Brand', required: true, },
            { name: 'voltage', label: 'Voltage', type: 'number', required: true, },
        ],
        motor: [
            { name: 'hp', label: 'Horse Power', type: 'number', required: true, },
            { name: 'voltage', label: 'Voltage', type: 'number', required: true, },
        ],
    };
    return (
        <form onSubmit={(e) => handlePartAdd(e)}>
            <Typography sx={{ mb: 1 }}>Add a part to the parts database:</Typography>
            <Box mb={1} display="flex" justifyContent="space-evenly">
                <TextField
                    id="partNumber"
                    name="partNumber"
                    label="Part Number"
                    required
                    value={formData.partNumber || ""}
                    onChange={handleLocal}
                    sx={{ width: "35%", mr: 1 }}
                />
                <TextField
                    id="description"
                    name="description"
                    label="Description"
                    required
                    value={formData.description || ""}
                    onChange={handleLocal}
                    sx={{ width: "100%", mr: 1 }}
                />
            </Box>
            <Box mb={1} display="flex" justifyContent="space-evenly">
                <TextField
                    id="price"
                    name="price"
                    label="Price"
                    type="number"
                    required={formData.category !== '' ? true : false}
                    value={formData.price || ""}
                    onChange={handleLocal}
                    sx={{ width: "35%", mr: 1 }}
                />
                <Tooltip title="It is important to use caution when selecting a category. Certain places within the portal populate parts based on the category. If you do not know how to categorize a part then reach out to an Admin or a software dev. Otherwise leave this blank. If a category is chosen then the price is required.">
                    <HelpIcon sx={{ paddingTop: 2 }} />
                </Tooltip>
                <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                        id="category"
                        name="category"
                        label="Category"
                        value={formData.category || ""}
                        onChange={handleLocal}
                        sx={{ width: "35%" }}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        {categories.length > 0 && categories.map((category) => (
                            <MenuItem
                                key={category}
                                value={category} >
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box mb={1}>
                {formData.category === 'fan' ||
                    formData.category === 'freqDrive' ||
                    formData.category === 'motor' ? <>
                    {categoryFieldsConfig[formData.category].map(field => (
                        <TextField
                            key={field.name}
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            required={field.required}
                            type={field.type || 'text'}
                            value={formData[field.name] || ''}
                            onChange={handleLocal}
                            sx={{ width: '25%', mr: 1, mb: 1 }}
                        />
                    ))}
                </> : null}
            </Box>
            <Divider />
            <Box mt={2} mr={5} display="flex" justifyContent="flex-end">
                <Button type="submit">
                    Add
                </Button>
            </Box>
        </form>
    )
}

// hp, cfm, brand, voltage
export default PartAdd;