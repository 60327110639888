import React from "react";
import useCompressorStore from "../../stores/refrigerationStore"
import Compressor from "./Compressor";
import { Button } from "@mui/material"
import { useTheme } from '@mui/material/styles';

function CompressorSheet({ edit }) {
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [addCompressor, compressors] = useCompressorStore(state =>
        [state.addCompressor, state.compressors])
    const editPass = edit
    const compressorObj = {
        comp: false,
        runsys: false,
        oil: false,
        refrigerant: false,
        suction: "",
        discharge: "",
        ampheater: "",
        loadedp1: "",
        loadedp2: "",
        loadedp3: "",
        fan1_1: "",
        fan3: "",
        fan4: "",
        fan2: "",
        fan5: "",
        fan6: "",
    }

    const additionalCompressor = (e) => {
        e.preventDefault()
        return addCompressor(compressorObj)
    }

    return (
        <>
            {compressors.map((item, index) => (
                <Compressor
                    key={index}
                    id={index}
                    item={item}
                    edit={editPass}
                />
            ))}
            <div className="hideForPrint" >
                <Button
                    style={{ color: defaultColor, fontWeight: "bold" }}
                    onClick={additionalCompressor}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Add Compressor
                </Button>
            </div>
        </>
    )
}

export default React.memo(CompressorSheet);