import React from "react";
import {
    Card, CardContent, CardHeader, Box, useTheme,
} from '@mui/material';
import DataEntryHeader from "./DataEntryHeader";
import useLabDataStore from "../../../stores/labDataStore";
import DataEntryFries from "./DataEntryFries";
import DataEntryChips from "./DataEntryChips";
import DataEntryFooter from "./DataEntryFooter";

function DataEntryCard({ render, saveEntry }) {
    const theme = useTheme()
    const defaultColor = theme.palette.default.main;
    const background = theme.palette.background.main;
    const [selectedEntry] = useLabDataStore(state => [state.selectedEntry]);

    return (<>
        <Card sx={{ width: '100%', bgcolor: defaultColor }}>
            {render && <>
                <Box display="flex" justifyContent="space-between">
                    <CardHeader sx={{ color: background }} title={`SAMPLE ID: ${selectedEntry.sampleId || 'None'}`} />
                    <CardHeader sx={{ color: background }} title={`ID: ${selectedEntry.id || 'None'}`} />
                </Box>
                <CardContent>
                    {selectedEntry.id &&
                        <Card key={selectedEntry.id} sx={{ bgcolor: background }}>
                            <CardContent>
                                <DataEntryHeader />
                                {selectedEntry.process === 'Fries' ?
                                    <DataEntryFries />
                                    : selectedEntry.process === 'Chips' ?
                                        <DataEntryChips />
                                        : null}
                                <DataEntryFooter saveEntry={saveEntry} />
                            </CardContent>
                        </Card>
                    }
                </CardContent>
            </>}
        </Card>
    </>)
}

export default DataEntryCard;