import React, { useEffect, useState } from "react";
import {
    Box, TextField, FormControl, InputLabel,
    Select, MenuItem, InputAdornment,
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import useBidStoreSub from "../../../../stores/BidStore/bidStoreSub";
import Commas from "../../BidFunctions/commas";
function Door({ doorId, handleDoorTotals, edit, doorData }) {
    const [setDoor, setObj] = useBidStoreSub(state =>
        [state.setPart, state.setObj])
    const [formData, handleLocal, resetFormData] = useFields({
        qty: 0,
        doorType: "",
        hingeType: "",
        width: 0,
        height: 0,
        priceSqFt: 0,
        sqFtPerDoor: 0,
        totalSqFtDoor: 0,
    })

    useEffect(() => {
        resetFormData()
        if (edit && doorData[doorId]) {
            setObj("door", doorData[doorId], doorId)
            handleLocal("replaceObj", doorData[doorId]);
        }
    }, []);

    useEffect(() => {
        // calculate sq ft totals accross a single fan 
        // calculate price depedning on the size of door 58.78 / 52.59
        const height = formData.height
        const width = formData.width
        let priceSqFt = formData.priceSqFt
        if (height && width) {
            let sqFt = width * height / 144
            const totalSqFt = sqFt * formData.qty
            if (priceSqFt === 52.59 || priceSqFt === 58.78
                || priceSqFt === 0 || priceSqFt === "") {
                if (sqFt > 20) {
                    handleLocal("invalidInput", "priceSqFt", 52.59);
                    priceSqFt = 52.59;
                }
                else {
                    handleLocal("invalidInput", "priceSqFt", 58.78);
                    priceSqFt = 58.78;
                }
            }
            const priceTotal = totalSqFt * priceSqFt
            setDoor("door", "price", parseFloat(priceTotal.toFixed(2)), doorId)
            setDoor("door", "totalSqFtDoor", parseFloat(totalSqFt.toFixed(2)), doorId)
            setDoor("door", "sqFtPerDoor", parseFloat(sqFt.toFixed(2)), doorId)
            setDoor("door", "priceSqFt", parseFloat(priceSqFt), doorId)
            handleLocal("invalidInput", "price", parseFloat(priceTotal.toFixed(2)))
            handleLocal("invalidInput", "sqFtPerDoor", parseFloat(sqFt.toFixed(2)))
            handleLocal("invalidInput", "totalSqFtDoor", parseFloat(totalSqFt.toFixed(2)))
        }
    }, [formData.width, formData.height, formData.qty, formData.priceSqFt])

    useEffect(() => {
        handleDoorTotals()
    }, [formData.totalSqFtDoor, formData.priceSqFt])

    return (
        <>
            <Box m={1}>
                <Box ml={1} display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <FormControl>
                        <InputLabel>Master / Volume</InputLabel>
                        <Select
                            name="doorType"
                            id="doorType"
                            label="Master / Volume"
                            sx={{ width: "18ch" }}
                            value={formData.doorType || ""}
                            onChange={handleLocal}
                            onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}>
                            <MenuItem
                                value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem
                                value="master">
                                Master
                            </MenuItem>
                            <MenuItem
                                value="volume">
                                Volume
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} display="inline">
                    <FormControl>
                        <InputLabel>Hinge Type</InputLabel>
                        <Select
                            name="hingeType"
                            id="hingeType"
                            label="Hinge Type"
                            sx={{ width: "16ch" }}
                            value={formData.hingeType || ""}
                            onChange={handleLocal}
                            onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}>
                            <MenuItem
                                value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem
                                value="top">
                                Top
                            </MenuItem>
                            <MenuItem
                                value="side">
                                Side
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="width"
                        id="width"
                        label="Width"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.width || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="height"
                        id="height"
                        label="Height"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.height || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="priceSqFt"
                        id="priceSqFt"
                        label="Price Sq/Ft"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.priceSqFt || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setDoor("door", e.target.name, e.target.value, doorId)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="sqFtPerDoor"
                        id="sqFtPerDoor"
                        label="Sq. Ft. Per Door"
                        type="number"
                        variant="standard"
                        value={formData.sqFtPerDoor || ""}
                        sx={{ width: "14ch" }}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="totalSqFtDoor"
                        id="totalSqFtDoor"
                        label="Total Sq. Ft."
                        type="number"
                        variant="standard"
                        value={formData.totalSqFtDoor || ""}
                        sx={{ width: "14ch" }}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="price"
                        id="price"
                        variant="standard"
                        label="Price"
                        value={Commas.add(formData.price) || ""}
                        sx={{ width: "12ch" }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Door;