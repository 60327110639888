import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import UserContext from "../Users/UserContext";
import CompanyPage from "./HomeComponents/CompanyPage";
import { useParams } from "react-router-dom";

function Home() {
    const { currentUser } = useContext(UserContext);
    const { company } = useParams();

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <img src={process.env.PUBLIC_URL + '/logo.png'} style={{ width: '400px', height: 'auto' }} ></img>
            </Box>
            <Typography
                textAlign="center"
                variant="h3"
                sx={{ m: 1 }}>
                {company === "Agri-Stor Companies" ? "Agri-Stor Twin Falls" : company}
            </Typography>
            <CompanyPage company={company || currentUser.company} />
        </ >
    )
}

export default Home;