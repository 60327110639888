
class Calculator {
    static profitAndOverHead(qty, amount, overHead, profit) {
        //calculates a selling price based on dealer cost, profit margin and overhead
        const profit2 = 1 - (profit / 100);
        const overHead2 = (overHead / 100) + 1;
        const dealerCost = amount * qty;
        const total = parseFloat((dealerCost * overHead2 / profit2).toFixed(2));
        return total;
    }

    static async prepairOldAndNewFans(newParts, settings, currentParts) {
        const fanArray = newParts.refinedFanArray;
        const freqDrives = newParts.freqDrives;
        const motors = newParts.motors;
        // takes the recommended combo of fans / freq drives / motors and calculates the price / hp,
        // also grabs the price of the currently selected fans / freq drives / motors and stores them for comparison later.
        const freqDriveSettingObj = settings.find(setting => setting.name === "recommendedFreqDrive")
        const newFreqDriveArray = [];
        const newMotorArray = [];
        for (let fan of fanArray) {
            // for each fan, find a matching freq drive and motor.
            // push to new seperate arrays.
            const foundMotor = motors.find(motor => motor.additionalData.hp === fan.additionalData.hp);
            const foundFreqDrive = freqDrives.find((freqDrive) => {
                return freqDrive.additionalData.brand === freqDriveSettingObj.value
                    && freqDrive.additionalData.hp === fan.additionalData.hp
            });
            newFreqDriveArray.push({ ...foundFreqDrive, qty: fan.qty })
            newMotorArray.push({ ...foundMotor, qty: fan.qty })
        }
        let currentTotal = 0;
        let currentHpTotal = 0;
        // add a current fan group total / hp
        for (let partType in currentParts) {
            if (currentParts.hasOwnProperty(partType)) {
                const parts = currentParts[partType];
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    currentTotal += (part.price * part.qty);
                    // add current hp total here
                    if (partType === 'currMotors') currentHpTotal += part.additionalData.hp
                }
            }
        }
        let priceTotal = 0;
        let hpTotal = 0;
        // itterate through each parts array to get a price total and hp total
        newFreqDriveArray.forEach((part) => {
            priceTotal += (part.price * part.qty);
        })
        newMotorArray.forEach((part) => {
            priceTotal += (part.price * part.qty);
            hpTotal += (part.additionalData.hp)
        })
        fanArray.forEach((part) => {
            priceTotal += (part.price * part.qty);
        })

        const refinedNewParts = {
            newFans: fanArray,
            newFreqDrives: newFreqDriveArray,
            newMotors: newMotorArray,
            hpTotal,
            priceTotal,
        }
        currentParts.priceTotal = currentTotal;
        currentParts.hpTotal = currentHpTotal;
        return { refinedNewParts, currentParts }
    }

    static async compareFansPowerUsage(newMaxFanSpeed, currMaxFanSpeed, oldAndNewParts, currActualCfm) {
        console.log(oldAndNewParts)
        // Returns several objects with values that belong to a table inside of the ventilation pdf
        // below are the recommended fan speeds during storage operation phases.
        const recommendedOperation = {
            load: newMaxFanSpeed - 50,
            cure: newMaxFanSpeed,
            ramp: newMaxFanSpeed,
            winter: newMaxFanSpeed - 30,
            spring: newMaxFanSpeed - 20,
            summer: newMaxFanSpeed - 20,
            unload: newMaxFanSpeed - 50,
        }
        const currOperation = {
            load: currMaxFanSpeed - 50,
            cure: currMaxFanSpeed,
            ramp: currMaxFanSpeed,
            winter: currMaxFanSpeed - 30,
            spring: currMaxFanSpeed - 20,
            summer: currMaxFanSpeed - 20,
            unload: currMaxFanSpeed - 50,
        }

        const powerSpentCalc = function (operationObj, totalHp) {
            // Returns an obj containing how much power is being used by the motors being used at specific fan speeds.
            const newOperationObj = {}
            for (let storageType in operationObj) {
                const fanSpeedPercent = parseFloat((operationObj[storageType] / 100).toFixed(2));
                const VfdkW = totalHp * 1 * 0.746 / .9 * Math.pow(fanSpeedPercent, 2.6) / .9;
                newOperationObj[storageType] = parseFloat(VfdkW.toFixed(2));
            }
            return newOperationObj;
        }
        const calculateSavedKw = function (recommendedPowerUsed, currPowerUsed) {
            // Returns an obj containing the difference between our recommendation and the currently selected setup.
            const newSavedKwObj = {};
            for (let storageType in recommendedPowerUsed) {
                const kwSaved = currPowerUsed[storageType] - recommendedPowerUsed[storageType];
                newSavedKwObj[storageType] = parseFloat(kwSaved.toFixed(2));
            }
            return newSavedKwObj;
        }
        const calculateSavedKwH = function (kwSaved) {
            // Returns an obj containing the kWh used in each phase of the storage throughout the year.
            // This assumes we are running the storage for a total of 325 days.
            const newkwhSavedObj = {};
            let hours = 0;
            for (let storageType in kwSaved) {
                if (storageType === 'load') hours = 168;
                else if (storageType === 'cure') hours = 336;
                else if (storageType === 'ramp') hours = 1200;
                else if (storageType === 'winter') hours = 2880;
                else if (storageType === 'spring') hours = 1440;
                else if (storageType === 'summer') hours = 1440;
                else if (storageType === 'unload') hours = 336;
                newkwhSavedObj[storageType] = parseFloat((kwSaved[storageType] * hours).toFixed(2));
            }
            return newkwhSavedObj;
        }
        const calculateAirFlow = function (operationObj, currActualCfm) {
            // Returns an obj containing the amount of air flow (cfm) at specific fan speeds.
            const newAirFlowObj = {};
            for (let storageType in operationObj) {
                newAirFlowObj[storageType] = parseFloat(((operationObj[storageType] / 100) * currActualCfm).toFixed(2));
            }
            return newAirFlowObj;
        }

        const newPriceObj = {
            hpTotal: oldAndNewParts.refinedNewParts.hpTotal,
            priceTotal: oldAndNewParts.refinedNewParts.priceTotal,
            oldPriceTotal: oldAndNewParts.currentParts.priceTotal
        }
        const recommendedPowerUsed = powerSpentCalc(recommendedOperation, oldAndNewParts.refinedNewParts.hpTotal);
        const currPowerUsed = powerSpentCalc(currOperation, oldAndNewParts.currentParts.hpTotal);
        const kwSaved = calculateSavedKw(recommendedPowerUsed, currPowerUsed);
        const kwhSaved = calculateSavedKwH(kwSaved);
        const currAirflow = calculateAirFlow(currOperation, currActualCfm);
        const recommendedAirflow = calculateAirFlow(recommendedOperation, currActualCfm);

        return { recommendedPowerUsed, currPowerUsed, kwSaved, kwhSaved, currAirflow, recommendedAirflow, newPriceObj };
    }
}

export default Calculator;