import React, { useEffect } from "react";
import {
    Card, TextField, Typography, Box, Button, InputAdornment,
} from "@mui/material"
import useBidStoreSub from "../../../stores/BidStore/bidStoreSub";
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import useFields from "../../../Hooks/useFields";
import Door from "./BidParts/Door";
import { shallow } from "zustand/shallow"
import Commas from "../BidFunctions/commas";
function Doors({ edit, doorData }) {
    const editPass = edit
    const [totalFanSpeed, totalCfm] = useBidStoreMain(state =>
        [state.totals.fanSpeed, state.totals.totalCfm], shallow)
    const [setTotal, doors, addDoor, setPart] = useBidStoreSub(state =>
        [state.setTotal, state.door, state.addPart, state.setPart])
    const [formData, handleLocal] = useFields({
        sqFtrequired: "",
    })
    const getTotalSqFtReq = function () {
        const fanSpeed = totalFanSpeed / 100;
        const calculation = totalCfm * parseFloat(fanSpeed) / 1400;
        return parseFloat(calculation.toFixed(2));
    }
    useEffect(() => {
        const sqFtRequired = getTotalSqFtReq()
        handleLocal("invalidInput", "sqFtRequired", sqFtRequired);
    }, [totalFanSpeed, totalCfm]);

    useEffect(() => {

        if (edit) {
            for (let i = 1; i < doorData.length; i++) {
                addDoor("door", 3)
            }
        }
    }, [])

    const handleDoorTotals = function () {
        let sqFtTotal = 0;
        let priceTotal = 0;
        doors.forEach((door) => {
            if (isNaN(door.price)) door.price = 0;
            //calculate the total sq ft across all doors as well as total the prices
            const totalSqFtDoor = door.totalSqFtDoor
            if (totalSqFtDoor === 0) {
                //do nothing
                return
            } else if (totalSqFtDoor !== isNaN) {
                sqFtTotal = sqFtTotal + totalSqFtDoor
                priceTotal = priceTotal + door.price
            }
        })
        priceTotal = parseFloat(priceTotal.toFixed(2))
        sqFtTotal = parseFloat(sqFtTotal.toFixed(2));
        const sqFtRequired = getTotalSqFtReq()
        setTotal("doorsSqFtTotal", sqFtTotal)
        setTotal("doorsSqFtReq", sqFtRequired)
        if (sqFtTotal) {
            //calculate variance using total sq ft
            const variance = parseFloat((sqFtTotal / sqFtRequired * 100 - 100).toFixed(2)) || 0
            handleLocal("invalidInput", "variance", variance)
            setPart("variance", "doors", variance, 0)
        } else {
            handleLocal("invalidInput", "variance", "")
        }
        handleLocal("invalidInput", "totalSqFt", sqFtTotal)
        handleLocal("invalidInput", "price", priceTotal)
        setTotal("doorsTotal", priceTotal)
    }

    return (
        <>
            <Card sx={{ ml: 1, width: '130ch' }} >
                <Box m={1} display="flex" justifyContent="space-between">
                    <Box alignItems="flex-start">
                        <Typography ml={4} variant="h5">
                            ClimaSeal™ Fresh Air Doors
                        </Typography>
                    </Box>
                </Box>
                {doors && doors.map((door) => {
                    return <Door
                        edit={editPass}
                        doorData={doorData}
                        doorId={door.doorId}
                        key={door.doorId}
                        handleDoorTotals={handleDoorTotals} />
                })}
                <Box
                    display="flex"
                    justifyContent="space-between">
                    <Box align="left" mb={1} mr={1} ml={1}>
                        <Button
                            variant='contained'
                            onClick={() => addDoor("door", 3)}>
                            Add Door
                        </Button>
                    </Box>
                    <Box mb={1} sx={{ marginLeft: 'auto' }}>
                        <TextField
                            name="sqFtRequired"
                            id="sqFtRequired"
                            label="Sq. Ft. Required"
                            type="number"
                            sx={{ width: "12ch" }}
                            variant="standard"
                            value={formData.sqFtRequired || ""}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="totalSqFt"
                            id="totalSqFt"
                            label="Total Sq. Ft."
                            variant="standard"
                            sx={{ width: "12ch" }}
                            value={formData.totalSqFt || ""}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="variance"
                            id="variance"
                            label="Variance"
                            sx={{ width: "8ch" }}
                            variant="standard"
                            error={
                                formData.variance >= -10
                                    && formData.variance <= 10
                                    ? false : true
                            }
                            value={formData.variance || ""}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            InputLabelProps={{ shrink: true }}

                        />
                    </Box>
                    <Box ml={2} mb={1} mr={2} align="right">
                        <TextField
                            id='price'
                            name='price'
                            label=" "
                            sx={{ width: '12ch' }}
                            value={Commas.add(formData.price) || ""}
                            variant="standard"
                            InputProps={{
                                startAdornment:
                                    <InputAdornment
                                        position="start">
                                        $
                                    </InputAdornment>,
                                readOnly: true,
                            }}
                        />
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default Doors;