import { create } from 'zustand';

const useReceiptStore = create((set) => ({
    receipts: [],

    setReceipts: function (receipts) {
        set(prevState => {
            return { ...prevState, receipts: receipts }
        })
    },

    clearReceipts: function () {
        set(prevState => {
            return { ...prevState, receipts: [] }
        });
    },

}));

export default useReceiptStore;