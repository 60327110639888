import React, { useEffect, useState } from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';
import Commas from "../../BidFunctions/commas";
import ClimaCellQuoteLine from "./CustomTableLine/ClimaCellQuoteLine";
import SqFtCompare from '../QuoteComponents/CustomTableLine/SqFtCompare'
import QuoteTableLine from "./QuoteTableLine";

function QuoteTable({ data, extraData, title, bidBase, climaCellTotals, gellertQuote }) {
    const [tableTotal, setTableTotal] = useState(0);
    const [renderCheck, setRenderCheck] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '100%',
            borderBottom: 1,
        },
        header: { borderTop: 'none' },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
        },
        box: { padding: '10px' },
        underline: { textDecoration: 'underline' },
        flexDirection: { flexDirection: 'row' },
        qtyRow: { width: '10%', fontSize: '12px' },
        descriptionRow: { width: '75%', fontSize: '12px' },
        priceRow: {
            marginLeft: '20px',
            width: '15%',
            fontSize: '12px',
        },
        priceContainer: {
            borderColor: 'black',
            borderBottom: 1,
            borderTop: 1,
            borderLeft: 1,
            borderRight: 1,
            width: '22%',
            marginLeft: 420,
            marginTop: 10,
            paddingRight: 2,
        },
    });

    useEffect(() => {
        let total = 0;
        data.forEach((item) => {
            total += item.total
        });
        if (title === 'ClimaCell Evaporative Cooler:') {
            total += climaCellTotals.tankTotal + climaCellTotals.mediaTotal
        }
        if (gellertQuote) {
            setShowPrice(false);
        } else {
            setShowPrice(bidBase.noPrices);
        }

        setTableTotal(parseFloat((total).toFixed(2)));
        setRenderCheck(true);
    }, []);

    return (
        <View>
            {renderCheck && <>
                <Text>{title}</Text >
                <View style={styles.table}>
                    {title === 'ClimaCell Evaporative Cooler:' && extraData.climaCellQty ?
                        <ClimaCellQuoteLine
                            noPrices={showPrice}
                            tankTotal={climaCellTotals.tankTotal}
                            mediaTotal={climaCellTotals.mediaTotal}
                            extraData={extraData}
                        />
                        : title === 'Ruskin Aluminum Exhaust Dampers:' ||
                            title === 'ClimaSeal Fresh Air Doors:' ?
                            <SqFtCompare data={extraData} />
                            : <>
                                <View style={[styles.row, styles.header]}>
                                    <Text style={styles.qtyRow}>{title !== "Options:" ? "Qty" : ""}</Text>
                                    <Text style={styles.descriptionRow}>Description</Text>
                                </View>
                            </>}
                    {data.length > 0 && data.map((item) => {
                        if (item.qty || title === "Options:") {
                            return <QuoteTableLine
                                key={item.id}
                                qty={item.qty}
                                title={title}
                                description={item.description}
                                total={item.total}
                                noPrices={showPrice}
                            />
                        } else return null;
                    })}
                    {title !== "Options:" && !showPrice ?
                        <View style={[styles.container, styles.priceContainer]}>
                            <Text >$ {Commas.add(tableTotal)}</Text>
                        </View> : <View>
                            <Text> </Text>
                            <Text> </Text>
                        </View>
                    }
                </View>
            </>}
        </View >
    )
}
export default QuoteTable;