import React, { useContext } from "react";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UndoIcon from '@mui/icons-material/Undo';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Box, Typography, Tooltip, IconButton, Menu, MenuItem, Button, Link,
} from "@mui/material"
import { NavLink, useHistory } from "react-router-dom"
import SheetsApi from "../api";
import UserContext from "../Users/UserContext";
import DayJS from "dayjs"
import useSettingsStore from "../stores/settingsStore";
import { useTheme } from '@mui/material/styles';
import dayjs from "dayjs";

function Followup({ notification, handleFullClose, indexId }) {
    const { currentUser } = useContext(UserContext)
    const [removeNotification] = useSettingsStore(state => [state.removeNotification])
    const history = useHistory();
    const theme = useTheme()
    const primary = theme.palette.primary.main;
    const background = theme.palette.background.main;
    const displayIcon = function (sheetType) {
        if (sheetType === "refrigeration") {
            return <AcUnitIcon />
        } else if (sheetType === "preseason") {
            return <UndoIcon />
        } else if (sheetType === "inspections") {
            return <SearchIcon />
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const handleClose = () => {
        handleFullClose();
    };
    const handleFollowupDelete = async () => {
        await SheetsApi.removeNotification({
            id: notification.id,
            sheetId: notification.sheetId,
            sheetType: notification.sheetType,
        });
        handleClose();
        removeNotification(indexId);
    }
    const handleSheetCreate = async (e) => {
        //  add new sheet with data,send user to new sheet
        const date = DayJS().format("YYYY-MM-DD")
        let deptValue;
        if (currentUser.dept === 'admin' || currentUser.dept === 'sales') deptValue = 'service';
        else deptValue = currentUser.dept;
    
        const sheetObj = {
            sheet: {
                customerName: notification.customer,
                locationId: notification.locationId,
                po: `followup to ${notification.sheetType} ID# ${notification.sheetId}`,
                creator: currentUser.username,
                company: currentUser.company,
                dateServiced: date,
                dateCreated: date,
                actionUser: currentUser.username,
                timeStamp: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
                dept: deptValue,
            }
        }
        await SheetsApi.addServiceSheet(sheetObj).then((response) => {

            history.push(`/sheets/edit/${response.id}`);
        })
        await SheetsApi.removeNotification({
            id: notification.id,
            sheetId: notification.sheetId,
            sheetType: notification.sheetType
        })
        handleClose();
        removeNotification(indexId);
    }
    return (
        <>
            <div>
                <MenuItem
                    style={{ background: background }}
                    disableRipple
                >
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            {displayIcon(notification.sheetType)}
                        </Box>
                        <Box mt={0.7} >
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                            >
                                ID# {notification.sheetId}</Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Tooltip title="Open Menu">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="followup-menu"
                                open={open}
                                onClose={handleClose2}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        background: background,
                                        width: 250,
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Box
                                    mt={1} mr={2} mb={1}
                                    display="flex"
                                    justifyContent="right"
                                    sx={{ textAlign: 'left', flex: 1 }}
                                >
                                    <Box sx={{ mr: 17 }}>
                                        {displayIcon(notification.sheetType)}
                                    </Box>
                                    <NavLink
                                        style={{ color: 'inherit' }}
                                        onClick={handleClose}
                                        to={`/${notification.sheetType}/edit/${notification.sheetId}`}
                                    >
                                        <Typography textAlign="center">ID# {notification.sheetId}</Typography>
                                    </NavLink>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%">
                                    <Typography sx={{ ml: 2, mr: 2 }}>
                                        {notification.creator}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%">
                                    <Typography sx={{ ml: 2, mr: 2 }}>
                                        {notification.customer}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%">
                                    <Typography sx={{ ml: 2, mr: 2 }} >
                                        {notification.location}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    ml={1} mt={1} mr={1}>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={handleSheetCreate}
                                        sx={{ color: primary, mt: 1 }}>
                                        Add follow-up
                                    </Button>
                                    <Tooltip title="Remove Follow-up notification">
                                        <IconButton
                                            onClick={handleFollowupDelete}
                                            sx={{ color: primary, mt: 1 }}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Menu>
                        </Box>
                    </Box>
                </MenuItem>
            </div>
        </>
    )
}

export default Followup;