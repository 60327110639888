import React, { useEffect, useState } from "react";
import useFields from '../../../Hooks/useFields';
import useChemAppStore from "../../../stores/chemAppStore";
import {
    Box, FormControlLabel, Checkbox, Grid,
    TextField, Typography, InputAdornment,
} from "@mui/material/"
import { v4 as uuid } from "uuid";
function Chemicals({ sprayBar }) {
    const [chemAppForm, chemAppAgreement, setChemAppForm,
        setChemAppAgreement] = useChemAppStore((state => (
            [state.chemAppForm, state.chemAppAgreement,
            state.setChemAppForm, state.setChemAppAgreement])))
    const [products, setProducts] = useState([]);
    let INITIAL_STATE = {
        product1: "",
        product2: "",
        product3: "",
        rate1: "",
        rate2: "",
        rate3: "",
        cwt1: "",
        total1: 0,
        total2: 0,
        total3: 0,
        wps: "",
        ftPerMin: "",
        vfd: "",
        purge: false,
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);

    useEffect(() => {
        const chemicalObj = {
            product1: chemAppAgreement.product1,
            product2: chemAppAgreement.product2,
            product3: chemAppAgreement.product3,
            rate1: chemAppAgreement.rate1,
            rate2: chemAppAgreement.rate2,
            rate3: chemAppAgreement.rate3,
            cwt1: chemAppAgreement.cwt1,
            total1: chemAppAgreement.total1,
            total2: chemAppAgreement.total2,
            total3: chemAppAgreement.total3,
            ftPerMin: chemAppForm.ftPerMin,
            vfd: chemAppForm.vfd,
            wps: chemAppForm.wps,
            purge: chemAppForm.purge,
        }
        handleLocal("replaceObj", chemicalObj)

        setProducts([{ id: uuid(), pName: "product1", rName: "rate1", title: "1", tName: "total1" },
        { id: uuid(), pName: "product2", rName: "rate2", title: "2", tName: "total2" },
        { id: uuid(), pName: "product3", rName: "rate3", title: "3", tName: "total3" },])
    }, [])

    const handleChange = function (e) {
        let value;
        const name = e.target.name
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        console.log(name, value)
        setChemAppForm(name, value)
    }

    return (
        <Box>
            <Box m={1}>
                <Typography
                    sx={{ fontWeight: 'bold' }}
                    variant="h5">
                    IN CASE OF A CHEMICAL EMERGENCY CALL (800) 424-9300
                </Typography>
            </Box>
            {products.length > 0 && products.map((product) => {
                return <Grid key={product.id} mb={1} container spacing={1}>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            name={product.pName}
                            id={product.pName}
                            label={`Product${product.title}`}
                            value={formData[product.pName] || ""}
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            name={product.rName}
                            id={product.rName}
                            label={`Rate ${product.title}`}
                            value={formData[product.rName] || ""}
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            name={product.tName}
                            id={product.tName}
                            label={`Total ${product.title}: GALS/LBS.`}
                            type="number"
                            value={formData[product.tName] || ""}
                            onChange={handleLocal}
                            onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            })}
            <Grid mt={1} container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                    <TextField
                        name="cwt1"
                        id="cwt1"
                        label="Potato volume treated"
                        value={formData.cwt1 || ""}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">CWT / TONS</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={2} md={2}>
                    <TextField
                        name="wps"
                        id="wps"
                        label="WPS"
                        value={formData.wps || ""}
                        onChange={handleLocal}
                        onBlur={handleChange}                        
                    />
                </Grid>
                {!sprayBar && <>
                    <Grid item xs={6} sm={2} md={2}>
                        <TextField
                            name="ftPerMin"
                            id="ftPerMin"
                            label="FT / Min"
                            value={formData.ftPerMin || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                        <TextField
                            name="vfd"
                            id="vfd"
                            label="VFD Speed"
                            type="number"
                            value={formData.vfd || ""}
                            onChange={handleLocal}
                            onBlur={handleChange}
                        />
                    </Grid>
                    <Grid mt={.5} item xs={6} sm={2} md={2}>
                        <FormControlLabel
                            label="Purge:"
                            labelPlacement="start"
                            control={<Checkbox
                                name="purge"
                                checked={formData.purge || false}
                                onChange={(e) => {
                                    handleLocal(e);
                                    handleChange(e);
                                }}
                            />}
                        />
                    </Grid>
                </>}
            </Grid>
        </Box>
    )
}

export default Chemicals;
