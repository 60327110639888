import React from "react";
import usePreSeasonStore from "../../stores/preSeasonStore";
import {
    Typography, Checkbox, FormControlLabel,
    Grid, Box, TextField
} from "@mui/material";
import { Masonry } from "@mui/lab";

function PreSeasonBase() {
    const [preSeasonForm, setPreSeasonForm] = usePreSeasonStore(state =>
        [state.preSeasonForm, state.setPreSeasonForm])
    const handleChange = function (e, checkBox) {
        const name = e.target.name
        let value = e.target.value
        if (checkBox) value = e.target.checked;
        setPreSeasonForm(name, value)
    }
    return (
        <>
            <Masonry columns={{ md: 1, lg: 2 }} >
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        Fresh Air Doors
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Lubricate actuator(s), run door(s) open and closed - check operation."
                                control={<Checkbox
                                    name="fadlube"
                                    checked={preSeasonForm.fadlube || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check settings and make necessary calibration and adjustments."
                                control={<Checkbox
                                    name="fadsetting"
                                    checked={preSeasonForm.fadsetting || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        Louvers
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Lubricate all linkage and ball joints, run louver(s) open and closed, check linkage and operation of mod motors."
                                control={<Checkbox
                                    name="louverlube"
                                    checked={preSeasonForm.louverlube || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check settings and make any necessary adjustments."
                                control={<Checkbox
                                    name="louversetting"
                                    checked={preSeasonForm.louversetting || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check exhaust louver(s)."
                                control={<Checkbox
                                    name="louverexhaust"
                                    checked={preSeasonForm.louverexhaust || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        Fans
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Inspect fan propellers and frames for cracks or other damage."
                                control={<Checkbox
                                    name="faninspect"
                                    checked={preSeasonForm.faninspect || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Run fan motor(s) and check for any unusual bearing noise."
                                control={<Checkbox
                                    name="fanmotor"
                                    checked={preSeasonForm.fanmotor || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check amperage of motor(s)."
                                control={<Checkbox
                                    name="fanamp"
                                    checked={preSeasonForm.fanamp || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                        {/* <Masonry columns={{ xs: 2, sm: 2, md: 4 }} > */}
                        <Box>
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp1"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp1 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp2"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp2 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp3"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp3 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp4"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp4 || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        <Box mt={1}>
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp5"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp5 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp6"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp6 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp7"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp7 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                sx={{ width: "14ch" }}
                                name="fanamp8"
                                style={{ margin: "0 4px" }}
                                value={preSeasonForm.fanamp8 || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        {/* </Masonry> */}
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check operation of Frequency Drive(s)."
                                control={<Checkbox
                                    name="fanfreq"
                                    checked={preSeasonForm.fanfreq || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        CimaCell
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Clean tank, check operation of float valve, and fill tank with fresh water."
                                control={<Checkbox
                                    name="climaclean"
                                    checked={preSeasonForm.climaclean || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check operation of pump and clean screens around pump."
                                control={<Checkbox
                                    name="climapump"
                                    checked={preSeasonForm.climapump || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Clean distribution pipe on top of ClimaCell."
                                control={<Checkbox
                                    name="climapipe"
                                    checked={preSeasonForm.climapipe || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="If applicable, clean aspirator cabinet and check wick."
                                control={<Checkbox
                                    name="climawick"
                                    checked={preSeasonForm.climawick || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check amperage of pump(s)."
                                control={<Checkbox
                                    name="climaamp"
                                    checked={preSeasonForm.climaamp || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Grid>
                        <TextField
                            size="small"
                            sx={{ width: "10ch" }}
                            name="climaamp1"
                            style={{ margin: "0 4px" }}
                            value={preSeasonForm.climaamp1 || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            size="small"
                            sx={{ width: "10ch" }}
                            name="climaamp2"
                            style={{ margin: "0 4px" }}
                            value={preSeasonForm.climaamp2 || ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        Humidifiers
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Clean water tubs, check operation of float valve, and fill with fresh water."
                                control={<Checkbox
                                    name="humclean"
                                    checked={preSeasonForm.humclean || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Run humidifier motor(s) and check for any unusual bearing noise."
                                control={<Checkbox
                                    name="hummotor"
                                    checked={preSeasonForm.hummotor || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check amperage of motor(s)."
                                control={<Checkbox
                                    name="humamp"
                                    checked={preSeasonForm.humamp || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "12ch" }}
                        name="humamp1"
                        style={{ margin: "0 4px" }}
                        value={preSeasonForm.humamp1 || ""}
                        onChange={handleChange}
                    />
                    <TextField
                        size="small"
                        sx={{ width: "12ch" }}
                        name="humamp2"
                        style={{ margin: "0 4px" }}
                        value={preSeasonForm.humamp2 || ""}
                        onChange={handleChange}
                    />
                    <TextField
                        size="small"
                        sx={{ width: "12ch" }}
                        name="humamp3"
                        style={{ margin: "0 4px" }}
                        value={preSeasonForm.humamp3 || ""}
                        onChange={handleChange}
                    />
                    <TextField
                        size="small"
                        sx={{ width: "12ch" }}
                        name="humamp4"
                        style={{ margin: "0 4px" }}
                        value={preSeasonForm.humamp4 || ""}
                        onChange={handleChange}
                    />
                    <TextField
                        size="small"
                        sx={{ width: "12ch" }}
                        name="humamp5"
                        style={{ margin: "0 4px" }}
                        value={preSeasonForm.humamp5 || ""}
                        onChange={handleChange}
                    />
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        style={{ textDecoration: 'underline' }}>
                        Control Panel
                    </Typography>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check modes of operation for proper function."
                                control={<Checkbox
                                    name="panelmode"
                                    checked={preSeasonForm.panelmode || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check calibration. (Outdoor air control, plenum sensor, low limit safety, humidity sensor)"
                                control={<Checkbox
                                    name="panelcal"
                                    checked={preSeasonForm.panelcal || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Check light bulbs in light tree."
                                control={<Checkbox
                                    name="panellight"
                                    checked={preSeasonForm.panellight || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Clean interior and exterior of control panel."
                                control={<Checkbox
                                    name="panelclean"
                                    checked={preSeasonForm.panelclean || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <Typography>
                            <FormControlLabel
                                label="Set plenum temperature at 55°. Control panel ok - ready to run."
                                control={<Checkbox
                                    name="panelset"
                                    checked={preSeasonForm.panelset || false}
                                    onChange={(e) => handleChange(e, true)}
                                />}
                            />
                        </Typography>
                    </Box>
                </Box>
            </Masonry>
        </>
    )
}

export default React.memo(PreSeasonBase);