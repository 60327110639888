import React, { useContext, useEffect, useState, } from "react";
import {
    Typography, Card, CardContent, Box, Divider,
    ToggleButton, Button,
} from "@mui/material";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UndoIcon from '@mui/icons-material/Undo';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import BalanceIcon from '@mui/icons-material/Balance';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useTheme } from "@mui/material/styles";
import UserContext from "../../Users/UserContext";
import HistoryCard from '../../Sheets/SheetComponents/SheetHistory/HistoryCard';
import SheetsApi from "../../api";
import useNotification from "../../Hooks/useNotification";
import { Link } from "react-router-dom";

function SalesNotification({ notification, setCustomerCards }) {
    const theme = useTheme()
    const background = theme.palette.background.main
    const { currentUser } = useContext(UserContext)
    const [logs, setLogs] = useState([])
    const [showHistory, setShowHistory] = useState(false)
    const messanger = useNotification()

    useEffect(() => {
        const getLogs1 = async () => {
            return await SheetsApi.getLogs({
                sheetId: notification.sheetId,
                sheetType: notification.sheetType,
            })
        }
        getLogs1().then((response) => {
            setLogs(response)
        })
    }, [])
    const pageRouter = function () {
        //sends user to proper page
        if (notification.sheetType === "sheet") {
            return `/sheets/edit/${notification.sheetId}`
        } else if (notification.sheetType === "refrigeration") {
            return `/refrigeration/edit/${notification.sheetId}`
        } else if (notification.sheetType === "preSeason") {
            return `preseason/edit/${notification.sheetId}`
        } else if (notification.sheetType === "sales") {
            return `salesOrders/edit/${notification.sheetId}`
        } else if (notification.sheetType === "inspections") {
            return `inspections/edit/${notification.sheetId}`
        } else if (notification.sheetType === "chemAppAgreement") {
            return `chemApps/agreement/edit/${notification.sheetId}`
        } else if (notification.sheetType === "freshPack") {
            return `freshPack/edit/${notification.sheetId}`
        }
    }
    const dismissNotification = async function () {
        const response = await SheetsApi.removeSalesNotification({
            sheetId: notification.sheetId,
            sheetType: notification.sheetType,
        })
        setCustomerCards(prevState => {
            // Finds this notification inside of state and removes it.
            const customerArray = [...prevState[notification.customer]];
            const updatedCustomerArray = customerArray.filter((noti) => {
                return noti.sheetId !== notification.sheetId;
            });
            return { ...prevState, [notification.customer]: updatedCustomerArray };
        });

        messanger(response.message, 'success');
    }
    const displayIcon = function () {
        if (notification.sheetType === "sheet") {
            return <WarehouseIcon />
        } else if (notification.sheetType === "refrigeration") {
            return <AcUnitIcon />
        } else if (notification.sheetType === "preSeason") {
            return <UndoIcon />
        } else if (notification.sheetType === "sales") {
            return <ReceiptIcon />
        } else if (notification.sheetType === "inspections") {
            return <SearchIcon />
        } else if (notification.sheetType === "chemAppAgreement") {
            return <CloudSyncIcon />
        } else if (notification.sheetType === "bid") {
            return <BalanceIcon />
        } else if (notification.sheetType === "freshPack") {
            return <PrecisionManufacturingIcon />
        }
    }

    return (
        <>
            <Card sx={{ m: 2, bgcolor: background }} >
                <CardContent>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <Link
                                style={{ color: 'inherit', textDecoration: 'none' }}
                                to={pageRouter()}>
                                <Box mt={.5} display='flex' justifyContent={currentUser.mobile ? "space-between" : ""}>
                                    {displayIcon()}
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} />
                                    <Typography
                                        sx={{ fontSize: currentUser.mobile ? '0.7 rem' : '1 rem' }}
                                        style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                        ID# {notification.sheetId}
                                    </Typography>
                                    {currentUser.mobile &&
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} />
                                    }
                                </Box>
                            </Link>
                            {!currentUser.mobile &&
                                <Divider
                                    sx={{
                                        mb: 2,
                                        flexGrow: 1,
                                        borderColor: 'black',
                                    }} />
                            }
                            <Box>
                                <Box display="flex" justifyContent="end">
                                    <Button
                                        id="dismiss"
                                        name="dismiss"
                                        onClick={dismissNotification}
                                        size="small"
                                        disabled={notification.currentStage === 'Archive' ? false : true}
                                    >
                                        Dismiss
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" >
                            <Box display="flex" sx={{ alignItems: 'center' }}>
                                <Typography>
                                    Current Stage:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: currentUser.mobile ? '0.7rem' : '1rem',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                        marginLeft: '5px'
                                    }}>
                                    {notification.currentStage}
                                </Typography>
                            </Box>
                            {!currentUser.mobile &&
                                <ToggleButton
                                    name="showHistory"
                                    id="showHistory"
                                    size="small"
                                    value={showHistory || false}
                                    onChange={() => setShowHistory(!showHistory)}
                                    sx={{ mt: 1, fontSize: 12, width: '70%' }}
                                >
                                    Show History
                                </ToggleButton>
                            }
                        </Box>
                        {currentUser.mobile &&
                            <ToggleButton
                                name="showHistory"
                                id="showHistory"
                                size="small"
                                value={showHistory || false}
                                onChange={() => setShowHistory(!showHistory)}
                                sx={{ mt: 1, fontSize: 12, width: '100%' }}
                            >
                                Show History
                            </ToggleButton>
                        }
                    </Box>
                    {logs.length > 0 && showHistory && (
                        <HistoryCard logs={logs} orientation="horizontal" />
                    )}
                </CardContent>
            </Card >
        </>
    )
}


export default SalesNotification;