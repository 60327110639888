import React, { useEffect, useState } from "react";
import {
    Box, Card, Typography, Button, TextField, InputAdornment,
} from "@mui/material";
import useBidStoreSub from "../../../stores/BidStore/bidStoreSub";
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import { shallow } from "zustand/shallow";
import useFields from "../../../Hooks/useFields";
import Damper from "./BidParts/Damper";
import SheetsApi from "../../../api";
import Commas from "../BidFunctions/commas";
function Dampers({ edit, damperData }) {
    const editPass = edit;
    const damperDataPass = damperData;
    const [renderCheck, setRenderCheck] = useState(false)
    const [damperParts, setDamperParts] = useState([{ partNumber: "", price: 0 }]);
    const [totalFanSpeed, totalCfm] = useBidStoreMain(state =>
        [state.totals.fanSpeed, state.totals.totalCfm], shallow)
    const [totals, setTotal, dampers, addDamper, setPart] = useBidStoreSub(state =>
        [state.totals, state.setTotal, state.damper, state.addPart, state.setPart])
    const [formData, handleLocal] = useFields({ totalSqFt: 0, totalSqFtReq: 0, variance: 0, })

    const calculateVariance = function (totalSqFtReq, totalSqFt) {
        if (isNaN(totalSqFtReq) || isNaN(totalSqFt)) return
        let variance = 0;
        variance = parseFloat(((totalSqFt / totalSqFtReq - 1) * 100).toFixed(1))
        setPart("variance", "damper", variance, 0)
        return handleLocal("invalidInput", "variance", variance);
    }

    useEffect(() => {
        if (edit) {
            for (let i = 1; i < damperData.length; i++) {
                addDamper("damper", 3)
            }
        }
        async function getDampers() {
            const dampers = await SheetsApi.getBidPartsByCategory({ category: 'damper' })
            const serPart = {
                qty: 0,
                partNumber: "SERPART",
                price: 0,
                total: 0,
                serPart: true,
            }
            setDamperParts([...dampers, serPart])
            setRenderCheck(true)
        }
        getDampers()
    }, [])

    useEffect(() => {
        handleDamperTotal()
        const totalSqFtReq = parseFloat((totalCfm / 1250 * (totalFanSpeed / 100)).toFixed(1))
        handleLocal("invalidInput", "totalSqFtReq", totalSqFtReq)
        setTotal("damperSqFtTotal", formData.totalSqFt)
        calculateVariance(totalSqFtReq, formData.totalSqFt)
    }, [totalCfm, totalFanSpeed, formData.totalSqFt])

    const handleDamperTotal = function () {
        let priceTotal = 0;
        let totalSqFt = 0;

        dampers.forEach((damper) => {
            priceTotal = parseFloat((priceTotal + damper.total).toFixed(2));
            totalSqFt = parseFloat((totalSqFt + damper.totalSqFt).toFixed(1));
        })
        calculateVariance(formData.totalSqFtReq, totalSqFt);
        handleLocal("invalidInput", "totalSqFt", totalSqFt);
        handleLocal("invalidInput", "priceTotal", priceTotal);
        setTotal("damperSqFtTotal", totalSqFt);
        setTotal("dampersTotal", priceTotal);
        setTotal("damperSqFtReq", formData.totalSqFtReq);
    }
    return (
        <>
            <Box ml={1} mt={1}>
                <Card sx={{ width: '130ch' }} >
                    <Box m={2}>
                        <Typography sx={{ textDecoration: "underline" }} ml={4} variant="h5">
                            Ruskin® Aluminum Exhaust Dampers
                        </Typography>
                    </Box>
                    <Box>
                        {renderCheck && dampers.map((objDamper) => {
                            return <Damper
                                key={objDamper.damperId}
                                id={objDamper.damperId}
                                damperParts={damperParts}
                                handleDamperTotal={handleDamperTotal}
                                edit={editPass}
                                damperData={damperDataPass}
                            />;
                        })}
                    </Box>
                    <Box
                        mb={1}
                        display="flex"
                        justifyContent="space-between">
                        <Box align="left" mr={1} ml={1}>
                            <Button
                                variant='contained'
                                onClick={() => addDamper("damper", 3)}>
                                Add Louver
                            </Button>
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <TextField
                                id="totalSqFtReq"
                                name="totalSqFtReq"
                                label="Total SQ. FT. Required"
                                variant="standard"
                                sx={{ width: "16ch" }}
                                value={formData.totalSqFtReq || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="totalSqFt"
                                name="totalSqFt"
                                label="Total SQ. FT."
                                variant="standard"
                                sx={{ width: "10ch" }}
                                value={formData.totalSqFt || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="variance"
                                name="variance"
                                label="Variance"
                                variant="standard"
                                error={
                                    formData.variance >= -10
                                        && formData.variance <= 10
                                        ? false : true
                                }
                                sx={{ width: "8ch" }}
                                value={formData.variance || 0}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            %
                                        </InputAdornment>,
                                    readOnly: true
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box ml={2} mb={1} mr={2} align="right">
                            <TextField
                                id='total'
                                name='total'
                                label=" "
                                sx={{ width: '12ch' }}
                                value={Commas.add(formData.priceTotal) || ""}
                                variant="standard"
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default Dampers;