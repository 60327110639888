import React, { useState, useEffect } from "react";
import {
    CardContent, Card, Box, Typography,
    ToggleButton, ToggleButtonGroup, Divider,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SheetCard2 from "./SheetCard2";
import useSettingsStore from "../stores/settingsStore";

function StageCard({ companyCode, toggleId, sheets, stage, company }) {

    const theme = useTheme()
    const background = theme.palette.background.main
    const defaultColor = theme.palette.default.main
    const [mobile, setMobile] = useState(false)
    const [toggleSettings, setToggle] = useSettingsStore(state =>
        [state.toggleSettings, state.setToggle])

    useEffect(() => {
        if (window.innerWidth < 500) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [])

    return (
        <>
            <Box mt={1} mb={1} sx={{ minWidth: '100%' }}>
                <Card
                    elevation={10}
                    sx={{
                        bgcolor: defaultColor,
                        borderRadius: '8px',
                        textAlign: 'center'
                    }}>
                    <CardContent>
                        <Box onClick={() => setToggle(companyCode, toggleId)} display="flex" alignItems="center">
                            <ToggleButtonGroup>
                                <ToggleButton
                                    value={toggleSettings[companyCode][toggleId] || false}>
                                    {toggleSettings[companyCode][toggleId] ? <MenuOpenOutlinedIcon
                                        sx={{ fontSize: 24, color: background }}
                                    /> : <MenuIcon
                                        sx={{ fontSize: 24, color: background }} />
                                    }
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box ml={3} style={{ flex: 1 }}>
                                <Typography
                                    style={{ textAlign: 'left', color: background }}
                                    variant="h5">
                                    {stage}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <Divider color={background} orientation="vertical" flexItem />
                                <Typography
                                    sx={{ ml: 2, color: background }}
                                    variant="h5">
                                    Total: {sheets.length}
                                </Typography>
                            </Box>
                        </Box>

                        {toggleSettings[companyCode][toggleId] &&
                            <Box mt={2}>
                                {sheets.length > 0 ? (
                                    sheets.map((sheet) => {
                                        let type = sheet.sheettype;
                                        if (sheet.sheet_type) type = sheet.sheet_type
                                        return <SheetCard2
                                            mobile={mobile}
                                            key={`${type}_${sheet.id}`}
                                            sheet={sheet}
                                        />
                                    })
                                ) : (
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <Typography variant="body1" color={background}>
                                            No cards available...
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default StageCard;
