import React, { useEffect } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import useFields from "../../../Hooks/useFields";
import useChemAppStore from "../../../stores/chemAppStore";

function DisinfectantCost({ edit }) {
    const [chemAppAgreement, setChemAppAgreement] = useChemAppStore(state =>
        [state.chemAppAgreement, state.setChemAppAgreement])
    const [formData, handleLocal] = useFields({
        disinfectantCost: '',
        disinfectantHours: '',
    });

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", {
                disinfectantCost: chemAppAgreement.disinfectantCost,
                disinfectantHours: chemAppAgreement.disinfectantHours,
            })
        }
    }, []);

    return (<>
        <Box mb={1} display="flex">
            <Box sx={{ width: '34%' }}>
                <Typography>
                    COST PER MAN HOUR:
                </Typography>
                <Typography sx={{ ml: 2 }}>
                    (Ventilation Pipe, Equipment, or Truck Beds, etc.)
                </Typography>
            </Box>
            <TextField
                id="disinfectantCost"
                name="disinfectantCost"
                value={formData.disinfectantCost || ''}
                onChange={handleLocal}
                onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                sx={{ width: '33%', mr: 1, ml: 1 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        $</InputAdornment>,
                }}
            />
            <TextField
                id="disinfectantHours"
                name="disinfectantHours"
                value={formData.disinfectantHours || ''}
                onChange={handleLocal}
                onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                sx={{ width: '32%', mr: 1 }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        HOURS: </InputAdornment>,
                }}
            />
        </Box>
    </>)
}

export default DisinfectantCost;