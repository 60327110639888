import React, { useEffect } from "react";
import {
    FormControl, InputLabel, Select, MenuItem,
    Box, TextField, InputAdornment
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import Commas from "../../BidFunctions/commas";
function LaborLine({ techs, id, handleLaborCalc, edit, laborData }) {
    const [setPart, setObj] = useBidStoreMisc(state =>
        [state.setPart, state.setObj])
    const [formData, handleLocal, resetFormData] = useFields({
        refrigeration: false,
        technician: "",
        regHours: 0,
        overTimePercent: 0,
        overTimeHours: 0,
        wageRate: 0,
        fuel: 0,
        fuelPrice: 0,
        cost: 0,
    })

    useEffect(() => {
        resetFormData();
        if (edit && laborData[id]) {
            handleLocal("replaceObj", laborData[id]);
            setObj("labor", laborData[id], id);
        }
    }, [])

    useEffect(() => {
        const fuel = formData.fuel || 0
        const fuelPrice = formData.fuelPrice || 0
        const wageRate = formData.wageRate || 0
        const regHours = formData.regHours || 0
        const overtimePercent = formData.overtimePercent / 100 || 0;
        const overtimeHours = regHours * overtimePercent || 0;

        const total = parseFloat(((regHours - overtimeHours)
            * wageRate + (overtimeHours * (wageRate * 1.5))
            + (fuel * fuelPrice)).toFixed(2))
        setPart("labor", "priceTotal", total, id)
        handleLocal("invalidInput", "overtimeHours", overtimeHours)
        handleLocal("invalidInput", "cost", total)
    }, [formData.regHours, formData.overTimeHours,
    formData.wageRate, formData.fuel, formData.fuelPrice])

    useEffect(() => {
        handleLaborCalc(formData.refrigeration)
    }, [formData.cost])

    const handleGlobalState = function (e) {
        let value;
        const name = e.target.name;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setPart("labor", name, value, id)
    }

    return (
        <>
            <Box m={1}>
                <FormControl>
                    <InputLabel>Technician</InputLabel>
                    <Select
                        inputlabelprops={{ shrink: true }}
                        onChange={(e) => handleLocal(e, "technician", e.target.value)}
                        onBlur={(e) => setPart("labor", e.target.name, e.target.value[0], id)}
                        value={formData.technician || ""}
                        id={`tech${id}`}
                        name="technician"
                        defaultValue=""
                        label="Technician"
                        sx={{ input: { background: "white" }, width: "29ch" }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {techs ? techs.map((tech) => {
                            return (
                                <MenuItem
                                    key={tech.username}
                                    value={tech.username || ""}>
                                    {tech.username}
                                </MenuItem>
                            )
                        })
                            : null}
                    </Select>
                </FormControl>
                <Box ml={1} display="inline">
                    <TextField
                        name="regHours"
                        id="regHours"
                        label="Regular Hours"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.regHours || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="overtimePercent"
                        id="overtimePercent"
                        label="Overtime %"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.overtimePercent || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>
                        }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="overtimeHours"
                        id="overtimeHours"
                        label="Overtime Hours"
                        variant="standard"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.overtimeHours || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="wageRate"
                        id="wageRate"
                        label="Wage Rate"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.wageRate || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>
                        }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="fuel"
                        id="fuel"
                        label="Gallons of Fuel"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.fuel || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="fuelPrice"
                        id="fuelPrice"
                        label="Cost of Fuel"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.fuelPrice || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>
                        }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="cost"
                        id="cost"
                        label="Estimated Labor"
                        variant="standard"
                        sx={{ width: "13ch" }}
                        value={Commas.add(formData.cost) || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default LaborLine;  