import React, { useEffect, useState } from "react";
import {
    TextField, Card, Box, Typography, InputAdornment, Button, CardContent
} from "@mui/material";
import useFields from "../../../../Hooks/useFields";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import CustomLine from "./CustomLine";
import Commas from "../../BidFunctions/commas";
function CustomTable({ edit, customData }) {
    const editPass = edit;
    const [custom, addPart, setTotal, setPart] = useBidStoreMisc(state =>
        [state.custom, state.addPart, state.setTotal, state.setPart])
    const [formData, handleLocal] = useFields({
        title: ""
    })
    const [render, setRender] = useState(false)
    const handleCalc = function () {
        let total = 0;
        custom.forEach((line) => {
            total += line.total || 0;
        })
        setTotal("customTotal", total, false);
        handleLocal("invalidInput", "total", total)
    }

    useEffect(() => {
        if (edit && customData.length > 0) {
            handleLocal("invalidInput", "title", customData[0].title);
            customData.forEach(() => {
                addPart("custom", 5)
            })
        }
        setRender(true)
    }, [])

    const handleTitle = function (e) {
        custom.forEach((part, index) => {
            setPart("custom", "title", e.target.value, index)
        })
    }
    return (<> {render && <>
        <Box mt={1} >
            <Card sx={{ width: '130ch' }}>
                <Box m={1} display="flex" justifyContent="flex-start">
                    <Typography sx={{ mt: 1, mr: 1, textDecoration: 'underline' }} ml={4} variant="h5">
                        Custom Table Title:
                    </Typography>
                    <TextField
                        id="title"
                        name="title"
                        value={formData.title || ""}
                        onChange={handleLocal}
                        onBlur={handleTitle}
                        sx={{ width: "50ch" }}
                    />
                </Box>
                <CardContent>
                    {custom.length > 0 && custom.map((line) => {
                        return <CustomLine
                            key={line.customId}
                            id={line.customId}
                            handleCalc={handleCalc}
                            title={formData.title}
                            edit={editPass}
                            customData={customData}
                        />
                    })}
                </CardContent>
                <Box
                    mb={1}
                    display="flex"
                    justifyContent="space-between">
                    <Box align="left" mb={1} mr={1} ml={1}>
                        <Button
                            variant='contained'
                            onClick={() => addPart("custom", 5)}
                        >
                            Add Line
                        </Button>
                    </Box>
                    <Box ml={2} mb={1} mr={2} align="right">
                        <TextField
                            id='total'
                            name='total'
                            sx={{ width: '12ch' }}
                            value={Commas.add(formData.total) || ""}
                            variant="standard"
                            InputProps={{
                                startAdornment:
                                    <InputAdornment
                                        position="start">
                                        $
                                    </InputAdornment>,
                                readOnly: true,
                            }}
                        />
                    </Box>
                </Box>
            </Card>
        </Box>
    </>} </>)
}

export default CustomTable;