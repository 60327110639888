import React, { useEffect, useContext, useState } from "react";
import useBaseStore from "../../stores/baseStore";
import BaseSheet from "../SheetComponents/BaseSheet"
import useFields from "../../Hooks/useFields";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import UserContext from "../../Users/UserContext";
import SheetsApi from "../../api";
import useChemAppStore from "../../stores/chemAppStore";
import ProductSelector from "./ProductSelector";
import Terms from "./Terms"
import { useHistory } from "react-router-dom"
import useNotification from "../../Hooks/useNotification";
import useSettingsStore from "../../stores/settingsStore";
import {
    Box, Grid, Typography, Button, Autocomplete,
    FormControl, InputLabel, TextField, MenuItem,
    ToggleButtonGroup, ToggleButton, InputAdornment,
    Select, useTheme,
} from "@mui/material/"
import { Masonry } from "@mui/lab"
import DeptSelect from "../SheetComponents/DeptSelect";
import DisinfectantCost from "./Disinfectant/DisinfectantCost";

function ChemAppAgreement({ disinfectant, sprayBar }) {
    const INITIAL_STATE = {
        phone: "",
        billing: "",
        appType: disinfectant ? 'storage' : sprayBar ? 'sprayBar' : "",
        amount: "",
        cwt1: "",
        cwt2: "",
        total1: 0,
        total2: 0,
    }
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const messanger = useNotification()
    const history = useHistory()
    const { currentUser } = useContext(UserContext)
    const [home] = useSettingsStore((state) => [state.home])
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [customers, setCustomers] = useState([])
    const [toggle, setToggle] = useState(false)
    const [baseForm, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [chemAppAgreement, clearChemAppForm, clearChemAppAgreement] = useChemAppStore(state =>
        [state.chemAppAgreement, state.clearChemAppForm, state.clearChemAppAgreement])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleToggle = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        clearChemAppAgreement();
        clearChemAppForm()
        clearBaseForm()
        resetFormData()
        setCustomers([])
    }, [])

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const chemAppAgreementForm = { ...chemAppAgreement, ...formData }
        console.log(chemAppAgreement)
        const sheetObj = CreateSubmitObj(
            chemAppAgreementForm, currentUser, messanger,
            baseForm, [], [])
        sheetObj.disinfectant = disinfectant;
        sheetObj.sprayBar = sprayBar;
        console.log(sheetObj)
        const result = await SheetsApi.addChemAppAgreement(sheetObj)
        clearBaseForm()
        resetFormData()
        clearChemAppAgreement()
        clearChemAppForm()
        messanger(result.message, result.error ? "error" : "success")
        history.push(home)
        return setIsSubmitting(false)
    }
    const getCustomers = function (customers) {
        setCustomers(customers)
    }
    const isValid = function (e) {
        //checks if the autocomplete input is a valid selection.
        const name = e.target.name.trim();
        const value = e.target.value.trim();
        if (name === "billing" && !customers.find(
            o => o.customer.trim() == value)) {
            handleLocal("invalidInput", name, "");
        }
    };
    return (
        <>
            <form onSubmit={handleFormSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BaseSheet
                            title={
                                `${disinfectant ? 'Disinfectant' : sprayBar ? 'Spray Bar' : 'Custom'}
                                Application Agreement, Notice, & Release **READ CAREFULLY**`
                            }
                            additionalCustomers={getCustomers} />
                        <Masonry columns={4}>
                            <TextField
                                name="phone"
                                id="phone"
                                label="Phone Number"
                                value={formData.phone || ""}
                                onChange={handleLocal}
                            />
                            <Autocomplete
                                disableClearable
                                inputValue={formData.billing || ""}
                                onInputChange={(e) => handleLocal(e, "billing")}
                                onBlur={(e) => isValid(e)}
                                name="billing"
                                id="billing"
                                options={customers ? customers.map((customer) => customer.customer) : null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="billing"
                                        variant="outlined"
                                        sx={{ input: { background: "white" } }}
                                        id="billing2"
                                        label="Billing Customer"
                                    />)}
                            />
                            <FormControl>
                                <InputLabel id="appTypeLabel">Application Type</InputLabel>
                                <Select
                                    labelId="appTypeLabel"
                                    id="appType"
                                    name="appType"
                                    label="Application Type"
                                    value={disinfectant ? 'storage' : sprayBar ? 'sprayBar' : formData.appType || ""}
                                    onChange={handleLocal}
                                >
                                    <MenuItem value="potato">Potatoes</MenuItem>
                                    <MenuItem value="onion">Onions</MenuItem>
                                    {disinfectant ?
                                        <MenuItem value="storage">Disinfect</MenuItem>
                                        : sprayBar ?
                                            <MenuItem value="sprayBar">Spray Bar</MenuItem>
                                            : null
                                    }
                                </Select>
                            </FormControl>
                            <ToggleButtonGroup
                                onChange={handleToggle}>
                                <ToggleButton
                                    id="toggleTerms"
                                    value={true}
                                >
                                    Toggle Terms
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Masonry>
                        <Typography>AGRI-STOR COMPANY AGREES TO APPLY AEROSOL POTATO SPROUT
                            INHIBITOR(S) OR DISINFECTANT(S) TO THE ABOVE NAMED CUSTOMER'S
                            AND/OR STORER'S CROP STORED IN A STORAGE FACILITY(S) LOCATED
                            AND/OR NAMED ABOVE.
                        </Typography>
                        <Box mb={1}>
                            {toggle && <>
                                <Terms />
                            </>}
                        </Box>
                        <Box mb={1}>
                            <Typography>
                                IN CONSIDERATION OF THE APPLICATION, CUSTOMER AND/OR STORER AGREES TO PAY AGRI-STOR COMPANY AS FOLLOWS:
                            </Typography>
                        </Box>
                        <Masonry columns={3}>
                            <TextField
                                name="amount"
                                id="amount"
                                vairent="outlined"
                                type="number"
                                label="AGREED AMOUNT"
                                value={formData.amount || ""}
                                onChange={handleLocal}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        $</InputAdornment>,
                                }}
                            />
                            <TextField
                                name="cwt1"
                                id="cwt1"
                                label="AGREED CWT / TONS"
                                type="number"
                                value={formData.cwt1 || ""}
                                onChange={handleLocal}
                            />
                            <TextField
                                name="cwt2"
                                id="cwt2"
                                label=""
                                value={formData.cwt2 || ""}
                                onChange={handleLocal}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        $</InputAdornment>,
                                }}
                            />
                        </Masonry>
                        <DisinfectantCost />
                        <Typography>
                            11. Customer and/or storer agrees that the Aerosol Potato Sprout Inhibitor(s)
                            or Disinfectant(s) will be applied at the rate(s) noted below.
                        </Typography>
                        <Box mb={1} mt={1} >
                            <ProductSelector sprayBar={sprayBar} />
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                            <DeptSelect chemApp={true} />
                            <Button
                                disabled={isSubmitting}
                                variant="contained"
                                style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                type="submit">
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form >
        </>
    )
}

export default ChemAppAgreement;