import React, { useEffect } from "react";
import {
    Card, Box, Typography, FormControl, Select, MenuItem,
    TextField, InputLabel, InputAdornment, Checkbox, FormControlLabel,
} from "@mui/material";
import useFields from "../../../../../Hooks/useFields";
import BidEvap from "./CoolerComponents/BidEvap";
import BidUnitCooler from "./CoolerComponents/BidUnitCooler";
import useBidRefrigerationStore from "../../../../../stores/BidStore/bidRefrigerationStore";
import commas from "../../../BidFunctions/commas";
function BidRefCooler({ coolerData, edit }) {
    const editPass = edit;
    const INITIAL_STATE = {
        qty: "",
        partNumber: "",
        description: "",
        price: "",
        type: "evaporatorCoils",
        expansionValve: "txv",
        electricDefrost: false,
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [setPart, setObj] = useBidRefrigerationStore(state => [state.setPart, state.setObj])

    const handleGlobalState = function (e) {
        let value;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }
        setPart("cooler", e.target.name, value)
    }

    useEffect(() => {
        const qty = parseFloat(formData.qty) || 0;
        const price = parseFloat(formData.price) || 0;
        const total = parseFloat((qty * price).toFixed(2));
        handleLocal("invalidInput", "total", total);
        setPart("cooler", "total", total);
    }, [formData.qty, formData.price])

    useEffect(() => {
        if (edit && coolerData) {
            setObj("cooler", coolerData)
            const coolerDataBaseObj = {
                qty: coolerData.qty,
                partNumber: coolerData.partNumber,
                description: coolerData.description,
                price: coolerData.price,
                type: coolerData.type,
                expansionValve: coolerData.expansionValve,
                electricDefrost: coolerData.electricDefrost,
            }
            handleLocal("replaceObj", coolerDataBaseObj)
        }
    }, [])

    return (
        <>
            <Box m={1}>
                <Card>
                    <Box m={2} display="flex" justifyContent="left">
                        <Typography ml={4} sx={{ textDecoration: 'underline' }} variant="h5">
                            Evaporator Coils / Unit Cooler
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="left" m={1}>
                        <Box display="inline">
                            <TextField
                                name="qty"
                                id="qty"
                                label="Quantity"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: "10ch" }}
                                value={formData.qty || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="partNumber"
                                id="partNumber"
                                label="Part Number"
                                sx={{ width: "28ch" }}
                                value={formData.partNumber || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="description"
                                id="description"
                                label="Description"
                                sx={{ width: "61ch" }}
                                value={formData.description || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="price"
                                id="price"
                                label="Dealer Price"
                                type="number"
                                sx={{ width: "12ch" }}
                                value={formData.price || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="total"
                                id="total"
                                label="Total"
                                sx={{ width: "12ch" }}
                                variant="standard"
                                value={commas.add(formData.total) || ""}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box m={1} display="flex" justifyContent="left">
                        <Box display="inline">
                            <FormControl>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    inputlabelprops={{ shrink: true }}
                                    id="type"
                                    name="type"
                                    label="Type"
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    value={formData.type || "evaporatorCoils"}
                                    sx={{ input: { background: "white" }, width: "24ch" }}
                                >
                                    <MenuItem value="evaporatorCoils">
                                        Evaporator Coils
                                    </MenuItem>
                                    <MenuItem value="unitCooler">
                                        Unit Cooler
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {formData.type === "evaporatorCoils" ? <>
                            <BidEvap
                                handleGlobalState={handleGlobalState}
                                edit={editPass}
                                coolerData={coolerData}
                            />
                        </> : formData.type === "unitCooler" ? <>
                            <BidUnitCooler
                                handleGlobalState={handleGlobalState}
                                edit={editPass}
                                coolerData={coolerData}
                            />
                        </> : null}
                    </Box>
                    <Box m={1} display="flex" justifyContent="left">
                        <Box ml={1} display="inline">
                            <FormControl>
                                <InputLabel>Expansion Valve</InputLabel>
                                <Select
                                    inputlabelprops={{ shrink: true }}
                                    id="expansionValve"
                                    name="expansionValve"
                                    label="Expansion Valve"
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    value={formData.expansionValve || "txv"}
                                    sx={{ input: { background: "white" }, width: "18ch" }}
                                >
                                    <MenuItem value="none">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="txv">
                                        TXV
                                    </MenuItem>
                                    <MenuItem value="eev">
                                        EEV
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box ml={1} display="inline">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="electricDefrost"
                                        name="electricDefrost"
                                        checked={formData.electricDefrost || false}
                                        onChange={(e) => {
                                            handleLocal(e);
                                            handleGlobalState(e)
                                        }}
                                    />}
                                label="Electric Defrost"
                            />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default BidRefCooler;