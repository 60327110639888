import { create } from "zustand";

const useBidQuoteStore = create((set) => ({
    total: 0,
    otherTotal: 0,
    otherRefrigerationTotal: 0,

    add: function (number, other, refrigeration) {
        set(prevState => {
            if (other) {
                if (refrigeration) {
                    const newRefrigerationOtherTotal = prevState.otherRefrigerationTotal + number;
                    return { ...prevState, otherRefrigerationTotal: newRefrigerationOtherTotal };
                } else {
                    const newOtherTotal = prevState.otherTotal + number;
                    return { ...prevState, otherTotal: newOtherTotal }
                }
            } else {
                const newTotal = prevState.total + number;
                return { ...prevState, total: newTotal }
            }
        })
    },

    clear: function () {
        set(prevState => {
            return { ...prevState, total: 0, otherTotal: 0, otherRefrigerationTotal: 0, }
        })
    }
}))

export default useBidQuoteStore;