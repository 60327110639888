import React, { useEffect } from "react";
import useFields from "../../Hooks/useFields";
import useRefrigerationStore from "../../stores/refrigerationStore";
import {
    Typography, Checkbox, FormControlLabel,
    Box, TextField, Divider, Button,
} from "@mui/material";
import RemoveCircle from '@mui/icons-material/RemoveCircleOutlineSharp';

function Compressor({ id, item, edit }) {
    const INITIAL_STATE = {
        comp: false,
        runsys: false,
        oil: false,
        refrigerant: false,
        suction: "",
        discharge: "",
        ampheater: "",
        loaded1: "",
        loaded2: "",
        loaded3: "",
        fan1: "",
        fan3: "",
        fan4: "",
        fan2: "",
        fan5: "",
    }
    const [setCompressor, removeCompressor] = useRefrigerationStore(state =>
        [state.setCompressor, state.removeCompressor])
    const [formData, handleLocal] = useFields(INITIAL_STATE)

    useEffect(() => {
        if (edit) {
            for (let field in item) {
                handleLocal("invalidInput", field, item[field])
            }
        }
    }, [])

    const removeCompressorLocal = function (e) {
        e.preventDefault()
        removeCompressor(id)
    }

    const handleChange = function (e) {
        let value;
        const name = e.target.name
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setCompressor(name, value, id)
    }

    return (
        <>
            <Box mb={1}>
                <Box>
                    <Typography
                        variant="h5"
                        style={{
                            textDecoration: 'underline',
                            fontWeight: "bold",
                            display: 'inline-block'
                        }}>
                        Compressor {id + 1}
                    </Typography>
                    <Button
                        onClick={removeCompressorLocal}
                        color="secondary"
                        type="submit">
                        <RemoveCircle />
                    </Button>
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="Run System"
                        control={<Checkbox
                            name="runsys"
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e);
                            }}
                            checked={formData.runsys || false}
                        />}

                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="Check Oil"
                        control={<Checkbox
                            name="oil"
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e);
                            }}
                            checked={formData.oil || false}
                        />}

                    />
                </Box>
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label="Check Refrigerant Levels"
                        control={<Checkbox
                            onChange={(e) => {
                                handleLocal(e);
                                handleChange(e);
                            }}
                            name="refrigerant"
                            checked={formData.refrigerant || false}
                        />}

                    />
                </Box>
                <br></br>
                <Box
                    ml={2} mt={.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>Suction:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="suction"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.suction || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={2} mt={.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>Discharge:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="discharge"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.discharge || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={2} mt={.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>Heater Amps:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="ampheater"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.ampheater || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
            </Box>
            <Box>
                <Box>
                    <Box
                        ml={2}
                        mb={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Compressor Loaded:</Typography>
                    </Box>
                </Box>
                <Box
                    ml={2} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>P1:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="loaded1"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.loaded1 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={2} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>P2:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="loaded2"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.loaded2 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={2} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        style={{ display: 'inline-block' }}>
                        <Typography>P3:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "10ch" }}
                        name="loaded3"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.loaded3 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
            </Box>
            <Box mt={1} mb={1} >
                <Box
                    ml={2}
                    style={{ display: 'inline-block' }}>
                    <Typography>Fan Amps Loaded: </Typography>
                </Box>
            </Box>
            <Box ml={1} >
                <Box
                    ml={1} mt={1}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 1:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan1"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan1 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={1} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 2:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan2"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan2 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={1} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 3:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan3"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan3 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
            </Box>
            <Box mt={1} mb={1} ml={1} >
                <Box
                    ml={1}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 4:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan4"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan4 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={1} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 5:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan5"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan5 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
                <Box
                    ml={1} mt={0.5}
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1}
                        style={{ display: 'inline-block' }}>
                        <Typography>Fan 6:</Typography>
                    </Box>
                    <TextField
                        size="small"
                        sx={{ width: "17ch" }}
                        name="fan6"
                        style={{ margin: "0 4px" }}
                        inputProps={{
                            style: { fontSize: '14px', height: '14px' },
                        }}
                        value={formData.fan6 || ""}
                        onBlur={handleChange}
                        onChange={handleLocal} />
                </Box>
            </Box>
            <Box m={2}>
                <Divider />
            </Box>
        </>
    )
}

export default React.memo(Compressor);
