import React, { useEffect, useState } from "react";
import useLabDataStore from "../../../stores/labDataStore";
import {
    TextField, MenuItem, Box, useTheme, TextareaAutosize, Typography,
    FormControl, InputLabel, Select,
} from '@mui/material';
import useFields from "../../../Hooks/useFields";
import CustomSelector from '../../SheetComponents/CustomSelector';
import SearchableInput from '../../SheetComponents/SearchableInput';
import SheetsApi from "../../../api";
import DateInput from "../../SheetComponents/DateInput";

function DataEntryHeader() {
    const [labData, setLabData, selectedEntry, setSelectedEntry,
        setValue] = useLabDataStore(state => [state.labData, state.setLabData,
        state.selectedEntry, state.setSelectedEntry, state.setValue]);
    const [customers, setCustomers] = useState([])
    const [locations, setLocations] = useState([])
    const [customer, setCustomer] = useFields({ customer: selectedEntry.customer });
    const [location, setLocation] = useFields({ location_name: selectedEntry.location })
    const theme = useTheme()
    const background = theme.palette.background.main
    const [forceRender, setForceRender] = useState(false);

    useEffect(() => {
        const getCustomers = async function () {
            return await SheetsApi.getAllCustomers()
        }
        getCustomers().then((response) => {
            setCustomers(response)
        });
    }, []);

    useEffect(() => {
        setForceRender(!forceRender)
    }, [selectedEntry])

    useEffect(() => {
        setValue("customer", customer.customer)
        if (customer.customer !== "") {
            async function getLocations() {
                return await SheetsApi.getActiveLocations({ customerName: customer.customer });
            }
            getLocations().then(locations => {
                setLocations(locations)
            })
        }
    }, [customer])

    useEffect(() => {
        setValue("location", location.location_name)
        setValue("locationId", location.id)
    }, [location])

    return (<>
        <Box display="flex" justifyContent="space-between">
            <Box sx={{ width: '20%' }}>
                <SearchableInput
                    forceRender={forceRender}
                    edit={true}
                    editData={selectedEntry.customer}
                    setState={setCustomer}
                    searchableArray={customers}
                    label="Customer"
                    variableName='customer'
                    fullwidth={true}
                />
            </Box>
            <Box sx={{ width: '20%' }}>
                <SearchableInput
                    forceRender={forceRender}
                    edit={true}
                    editData={selectedEntry.location}
                    setState={setLocation}
                    searchableArray={locations}
                    label="Storage / Field"
                    variableName='location_name'
                    fullwidth={true}
                />
            </Box>
            <TextField
                id="binName"
                name="binName"
                label="Bin no./Name"
                value={selectedEntry.binName || ""}
                onChange={(e) => setValue(e.target.name, e.target.value)}
                sx={{ width: '20%', }}
            />
            <Box sx={{ width: '20%' }}>
                <DateInput
                    state={selectedEntry.dateSampled}
                    setState={setValue}
                    variableName="dateSampled"
                    label="Date Sampled"
                    required={false}
                />
            </Box>
        </Box>
        <Box mt={1} display="flex" justifyContent="space-between">
            <FormControl sx={{ width: '20%' }} fullWidth>
                <InputLabel>Report</InputLabel>
                <Select
                    id="report"
                    name="report"
                    label="Report"
                    value={selectedEntry.report || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Billed">Billed</MenuItem>
                    <MenuItem value="Done">Done</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '20%' }} fullWidth>
                <InputLabel>Report</InputLabel>
                <Select
                    id="billing"
                    name="billing"
                    label="Billing"
                    sx={{ width: '100%' }}
                    value={selectedEntry.billing || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value="Billed">ATP</MenuItem>
                    <MenuItem value="Done">Retail</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ width: '20%' }}>
                <TextField
                    id="sampleInfo"
                    name="sampleInfo"
                    label="Sample Info"
                    sx={{ width: '100%' }}
                    value={selectedEntry.sampleInfo || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
            </Box>
            <Box sx={{ width: '20%' }}>
                <TextField
                    id='processor'
                    name='processor'
                    label='Processor'
                    sx={{ width: '100%' }}
                    value={selectedEntry.processor || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
            </Box>
        </Box>

        <Box m={2} display="flex">
            <Box mr={1}>
                <CustomSelector
                    title="Variety"
                    name="variety"
                    menuOptions={[
                        "Lamoka", "Tilbury", "Atlantic", "Betty",
                        "Burbank", "Waneta", "Fontane", "Ivory",
                        "Palace", "Donata", "Tiger", "Clearwater",
                        "Kelly", "Organic", "Challenger", "Chip Variety"
                    ]}
                    handleGlobal={setValue}
                    edit={true}
                    data={selectedEntry.variety || ""}
                />
                <FormControl sx={{ mt: 1, width: '250px' }} fullWidth>
                    <InputLabel>Fries or Chips</InputLabel>
                    <Select
                        id="process"
                        name="process"
                        label="Fries or Chips"
                        value={selectedEntry.process || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                    >
                        <MenuItem value="Chips">Chips</MenuItem>
                        <MenuItem value="Fries">Fries</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography sx={{ ml: 2 }} variant="h5">Other Notes</Typography>
                <TextareaAutosize
                    id="otherNotes"
                    name="otherNotes"
                    value={selectedEntry.otherNotes || ""}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                    minRows={3.5}
                    style={{
                        backgroundColor: background,
                        width: "80%",
                        outline: '2px solid #000',
                        fontSize: "16px",
                        padding: '8px 12px',
                        borderRadius: '8px',
                    }}
                    aria-label="maximum height"
                />
            </Box>
        </Box>
    </>)
}

export default DataEntryHeader;