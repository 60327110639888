import { create } from 'zustand'

const emptyBayForm = {
    topFront: 0,
    topMiddle: 0,
    topBack: 0,
    bottomFront: 0,
    bottomMiddle: 0,
    bottomBack: 0,
    frontAvgDeltaT: 0,
    middleAvgDeltaT: 0,
    backAvgDeltaT: 0,
    topPileConditions: "",
    none: false,
    eyesOpen: false,
    peeping: false,
    buds: false,
    notes: "",
}

const emptyVisualInspectionForm = {
    doorsLouvers: false,
    climaCell: false,
    controlPanel: false,
    fans: false,
    humidifiers: false,
    plenumDucts: false,
}

const emptyInspectionEtc = {
    plenumSetPoint: "",
    systemMode: "",
    vfdFan: "",
    osaControl: "",
    osaTemp: "",
    osaHumidity: "",
    co2: "",
    ductLiquid: "",
    oders: "",
    bugs: "",
    billing: "",
    lastVisit: "",
    currentRunTime: 0,
    dailyAvg: 0,
}

const useBayStore = create((set) => ({
    bays: [emptyBayForm],
    visualInspectionForm: emptyVisualInspectionForm,
    inspectionEtc: emptyInspectionEtc,

    addBay: function () {
        set(prevState => {
            let bays = [...prevState.bays]
            bays.push(emptyBayForm)
            return { ...prevState, bays }
        })
    },

    setBayForm: function (name, value, id) {
        set(prevState => {
            const bays = [...prevState.bays];
            bays[id] = { ...bays[id], [name]: value };
            return { ...prevState, bays };
        })
    },

    setBayFormObject: function (bays1) {
        set(prevState => ({
            ...prevState,
            bays: bays1
        }));
    },

    removeBay: function (id) {
        set(prevState => {
            const bays = prevState.bays;
            bays.splice(id, 1);
            return { ...prevState, bays }
        })
    },

    clearInspectionForm: function () {
        set(prevState => ({
            ...prevState,
            bays: [emptyBayForm],
            visualInspectionForm: emptyVisualInspectionForm.climaCell,
            inspectionEtc: emptyInspectionEtc
        }));
    },

    setVisualInspectionForm: function (name, value) {
        set(prevState => ({
            ...prevState,
            visualInspectionForm: {
                ...prevState.visualInspectionForm,
                [name]: value
            }
        }))
    },

    setVisualInspectionFormObj: function (vInspectionForm) {
        set(prevState => ({
            ...prevState,
            visualInspectionForm: vInspectionForm
        }))
    },

    setInspectionEtc: function (name, value) {
        set(prevState => ({
            ...prevState,
            inspectionEtc: {
                ...prevState.inspectionEtc,
                [name]: value
            }
        }))
    },

    setInspectionEtcObj: function (inspectionEtc) {
        set(prevState => ({
            ...prevState,
            inspectionEtc: inspectionEtc
        }))
    },
}))

export default useBayStore;