import React, { useEffect } from "react";
import { Box, TextField, InputAdornment } from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import Commas from "../../BidFunctions/commas";
function CustomLine({ id, edit, customData, handleCalc, title }) {
    const [formData, handleLocal] = useFields({
        qty: "",
        partNumber: "",
        description: "",
        cost: "",
        total: "",
        title: title,
    });
    const [custom, setPart, setObj] = useBidStoreMisc(state =>
        [state.custom, state.setPart, state.setObj]);

    const handleGlobalState = function (e) {
        setPart("custom", e.target.name, e.target.value, id)
    }

    useEffect(() => {
        handleCalc()
    }, [custom[id]])

    useEffect(() => {
        const qty = parseFloat(formData.qty)
        const cost = parseFloat(formData.cost)
        const total = parseFloat(parseFloat((qty * cost).toFixed(2))) || 0;
        handleLocal("invalidInput", "total", total)
        setPart("custom", "total", total, id)
    }, [formData.qty, formData.cost])

    useEffect(() => {
        if (edit && customData[id]) {
            const obj = customData[id];
            const total = parseFloat((obj.qty * obj.cost).toFixed(2)) || 0;
            handleLocal("replaceObj", obj);
            setObj("custom", { ...obj, total: total }, id);
        }
    }, [])

    return (
        <>
            <Box m={1}>
                <Box display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="partNumber"
                        id="partNumber"
                        label="Part Number"
                        sx={{ width: "24ch" }}
                        value={formData.partNumber || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        sx={{ width: "60ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="cost"
                        id="cost"
                        label="Cost"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.cost || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                        }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "13ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default CustomLine;