import React, { useEffect, useContext, useState, useRef } from "react";
import useBaseStore from "../../stores/baseStore";
import useChemAppStore from "../../stores/chemAppStore";
import BaseSheet from "../SheetComponents/BaseSheet"
import useFields from "../../Hooks/useFields";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import UserContext from "../../Users/UserContext";
import Terms from "./Terms"
import ProductSelector from "./ProductSelector";
import { useParams } from "react-router-dom"
import SheetsApi from "../../api";
import {
    Box, Grid, Typography, Autocomplete,
    FormControl, InputLabel, TextField, MenuItem,
    InputAdornment, FormHelperText, Select
} from "@mui/material/"
import { Masonry } from "@mui/lab"
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import ESign from "../SheetComponents/images/ESign";
import useImageStore from "../../stores/imageStore";
import { setImage } from "../SheetComponents/images/imageUtilities/setImage";
import useNotification from "../../Hooks/useNotification";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import DeptSelect from "../SheetComponents/DeptSelect";
import DisinfectantCost from "./Disinfectant/DisinfectantCost";
import ChemAppFormPopUp from "./ChemAppFormPopUp";

function ChemAppAgreementEdit({ disinfectant, sprayBar }) {
    const INITIAL_STATE = {
        companySign: "",
        date: "",
        phone: "",
        billing: "",
        appType: "",
        amount: "",
        cwt1: "",
        cwt2: "",
        product1: "",
        product2: "",
        product3: "",
        rate1: "",
        rate2: "",
        rate3: "",
    }
    const componentRef = useRef()
    const messanger = useNotification();
    const { id } = useParams()
    const { currentUser } = useContext(UserContext)
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [customers, setCustomers] = useState([])
    const [signed, setSigned] = useState(false)
    const [logs, setLogs] = useState([]);
    const [openPopup, setOpenPopup] = useState(false)
    const [renderCheck, setRenderCheck] = useState(false)
    const [baseForm, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj])
    const [setChemAppAgreementObj, chemAppAgreement, setChemAppAgreement,
        clearChemAppForm, clearChemAppAgreement] = useChemAppStore(state =>
            [state.setChemAppAgreementObj, state.chemAppAgreement, state.setChemAppAgreement,
            state.clearChemAppForm, state.clearChemAppAgreement]);
    const [images, addImage, clearImages, signature, setSignature,
        clearSignature] = useImageStore(state =>
            [state.images, state.addImage, state.clearImages, state.signature,
            state.setSignature, state.clearSignature]);
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setSigned(false);
        clearChemAppAgreement()
        clearChemAppForm()
        setRenderCheck(false)
        clearBaseForm()
        resetFormData()
        setCustomers([])
        clearSignature()
        clearImages()
        async function getAgreement() {
            try {
                const agreement = await SheetsApi.getAgreement({ id, disinfectant, sprayBar })
                const locationId = agreement.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                agreement.location = locationName.location_name
                agreement.locationId = locationId
                console.log(agreement)
                setLogs(agreement.logs)
                return agreement
            } catch (err) {
                console.log(err)
            }
        }

        getAgreement().then(async sheet => {

            const baseFormObj = {
                company: sheet.company,
                creator: sheet.creator,
                customerName: sheet.customer,
                dateServiced: sheet.dateServiced,
                locationName: sheet.location,
                po: sheet.po,
                requestedBy: sheet.requestBy,
                stage: sheet.stage,
                dateCreated: sheet.dateCreated,
                locationId: sheet.locationId,
                comapnySign: sheet.comapnySign,
                date: sheet.date,
                dept: sheet.dept,
            }
            const chemAppAgreement = {
                billing: sheet.billing,
                phone: sheet.phone,
                appType: sheet.appType,
                cwt1: sheet.cwt1,
                cwt2: sheet.cwt2,
                product1: sheet.product1,
                product2: sheet.product2,
                product3: sheet.product3,
                rate1: sheet.rate1,
                rate2: sheet.rate2,
                rate3: sheet.rate3,
                total1: sheet.total1,
                total2: sheet.total2,
                total3: sheet.total3,
                disinfectantCost: sheet.disinfectantCost,
                disinfectantHours: sheet.disinfectantHours,
                amount: sheet.amount,
                companySign: sheet.companySign,
                date: sheet.date,
                stage: sheet.stage,
            }

            for (let img of sheet.images) {
                const imageObj = await setImage(img)
                if (img.imageType === 'signature') {
                    setSignature(imageObj)
                    setSigned(true)
                } else if (img.imageType === 'img' || img.imageType === 'receipt') {
                    addImage(imageObj)
                }
            }
            setChemAppAgreementObj(chemAppAgreement)
            setBaseFormObj(baseFormObj)
            handleLocal("replaceObj", chemAppAgreement)
            setRenderCheck(true)
        })
    }, [])

    useEffect(() => {
        // Changes the stage to a valid option.
        if (formData.stage === 'Signature Pending' || formData.stage === 'Final Review') {
            handleLocal("invalidInput", "stage", "Preliminary Review")
            setChemAppAgreement("stage", "Preliminary Review")
        }

    }, [baseForm.dept])

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true);
        if (currentUser.account_level === "Tech") {
            return messanger(`You must be an Admin or Manager to change information on an agreement`, "error")
        }
        const sheetObj = CreateSubmitObj(
            chemAppAgreement, currentUser, messanger,
            baseForm, [], [])
        sheetObj.id = id
        if (disinfectant) {
            sheetObj.sheetType = "disinfectantAgreement"
            sheetObj.sheet.sheetType = "disinfectantAgreement"
            sheetObj.disinfectant = disinfectant;
        } else if (sprayBar) {
            sheetObj.sheetType = "sprayBarAgreement"
            sheetObj.sheet.sheetType = "sprayBarAgreement"
            sheetObj.sprayBar = sprayBar;
        } else {
            sheetObj.sheetType = "chemAppAgreement"
            sheetObj.sheet.sheetType = "chemAppAgreement"
        }
        console.log(sheetObj)
        HandleImage.addAndRemove(
            [signature, ...images], sheetObj,
            disinfectant ? "disinfectantAgreement" : sprayBar ? "sprayBarAgreement" : "chemAppAgreement")
        const result = await SheetsApi.editChemAppAgreement(sheetObj)
        messanger(result.message, result.error ? "error" : "success")
        setRenderCheck(false)
        clearImages()
        setOpenPopup(true)
    }

    const getCustomers = function (customers) {
        setCustomers(customers)
    }
    const isValid = function (e) {
        //checks if the autocomplete input is a valid selection.
        const name = e.target.name.trim();
        const value = e.target.value.trim();
        if (customers.find(o => o.customer.trim() == value)) {
            setChemAppAgreement(name, value)
            handleLocal("invalidInput", name, value);
        }
    };
    return (
        <div ref={componentRef}>
            <ChemAppFormPopUp
                open={openPopup}
                setOpen={setOpenPopup}
                disinfectant={disinfectant}
                sprayBar={sprayBar}
                id={id}
            />
            <Grid container>
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <form onSubmit={handleFormSubmit}>
                        <div className="page custom-page">
                            <BaseSheet
                                id={id}
                                additionalCustomers={getCustomers}
                                title={`${disinfectant ? 'Disinfectant' : sprayBar ? 'Spray Bar' : 'Custom'} Application Agreement, Notice, & Release **READ CAREFULLY**`}
                            />
                            <Masonry columns={3}>
                                <TextField
                                    name="phone"
                                    id="phone"
                                    label="Phone Number"
                                    value={formData.phone || ""}
                                    onChange={handleLocal}
                                    onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                />
                                <Autocomplete
                                    disableClearable
                                    inputValue={formData.billing || ""}
                                    onInputChange={(e) => handleLocal(e, "billing")}
                                    onBlur={(e) => isValid(e)}
                                    name="billing"
                                    id="billing"
                                    getOptionLabel={(customer) => customer}
                                    options={customers ? customers.map((customer) => customer.customer) : null}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="billing"
                                                variant="outlined"
                                                sx={{ input: { background: "white" } }}
                                                id="billing2"
                                                label="Billing Customer"
                                            />
                                        </>
                                    )}
                                />
                                <FormControl>
                                    <InputLabel id="appTypeLabel">Application Type</InputLabel>
                                    <Select
                                        labelId="appTypeLabel"
                                        id="appType"
                                        name="appType"
                                        label="Application Type"
                                        value={formData.appType || ""}
                                        onChange={handleLocal}
                                        onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}>
                                        <MenuItem value="potato">Potatoes</MenuItem>
                                        <MenuItem value="onion">Onions</MenuItem>
                                        {disinfectant ?
                                            <MenuItem value="storage">Disinfect</MenuItem>
                                            : sprayBar ?
                                                <MenuItem value="sprayBar">Spray Bar</MenuItem>
                                                : null
                                        }
                                    </Select>
                                </FormControl>
                            </Masonry>
                            <Typography>AGRI-STOR COMPANY AGREES TO APPLY AEROSOL POTATO SPROUT
                                INHIBITOR(S) OR DISINFECTANT(S) TO THE ABOVE NAMED CUSTOMER'S
                                AND/OR STORER'S CROP STORED IN A STORAGE FACILITY(S) LOCATED
                                AND/OR NAMED ABOVE.
                            </Typography>
                            <Box mb={1}>
                                <Terms />
                            </Box>
                            <Box mb={1}>
                                <Typography>
                                    IN CONSIDERATION OF THE APPLICATION, CUSTOMER AND/OR STORER AGREES TO PAY AGRI-STOR COMPANY AS FOLLOWS:
                                </Typography>
                            </Box>
                            <Grid
                                display="grid"
                                className='displayColumns3'
                                gridTemplateColumns='repeat(auto-fill, minmax(350px, 1fr))'
                            >
                                <Box mt={1} display="flex" sx={{ width: "100%" }}>
                                    <TextField
                                        name="amount"
                                        id="amount"
                                        vairent="outlined"
                                        type="text"
                                        label="AGREED AMOUNT"
                                        value={formData.amount || ""}
                                        onChange={handleLocal}
                                        onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                        sx={{ width: "98%" }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                $</InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Box mt={1} display="flex" sx={{ width: "100%" }}>
                                    <TextField
                                        name="cwt1"
                                        id="cwt1"
                                        label="AGREED CWT / TONS"
                                        value={formData.cwt1 || ""}
                                        onChange={handleLocal}
                                        onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                        sx={{ width: "98%" }}
                                    />
                                </Box>
                                <Box mt={1} display="flex" sx={{ width: "100%" }}>
                                    <TextField
                                        name="cwt2"
                                        id="cwt2"
                                        label=""
                                        value={formData.cwt2 || ""}
                                        onChange={handleLocal}
                                        onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                        sx={{ width: "98%" }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                $</InputAdornment>,
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Typography>
                                11. Customer and/or storer agrees that the Aerosol Potato Sprout Inhibitor(s)
                                or Disinfectant(s) will be applied at the rate(s) noted below.
                            </Typography>
                            <DisinfectantCost edit={true} />
                            <Box mb={3} mt={1} >
                                {renderCheck && <>
                                    <ProductSelector edit={true} sprayBar={sprayBar} />
                                </>}
                            </Box>
                            <Grid
                                display="grid"
                                className='displayColumns3'
                                gridTemplateColumns='repeat(auto-fill, minmax(350px, 1fr))'
                            >
                                <FormControl sx={{ mr: 1 }}>
                                    <TextField
                                        id="companySign"
                                        name="companySign"
                                        variant="standard"
                                        value={formData.companySign || ""}
                                        onChange={handleLocal}
                                        onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                    >
                                    </TextField>
                                    <FormHelperText>
                                        AGRI-STOR COMPANY
                                    </FormHelperText>
                                </FormControl>
                                <Box style={{ position: 'relative', top: signed ? '-19px' : '15px' }}>
                                    <ESign
                                        setSigned={setSigned}
                                        formHelperText="CUSTOMER AND/OR STORER"
                                        sheetType={disinfectant ? "disinfectantAgreement" : sprayBar ? "sprayBarAgreement" : "chemAppAgreement"} />
                                </Box>
                                <Box ml={4}>
                                    <FormControl >
                                        <TextField
                                            id="date"
                                            name="date"
                                            variant="standard"
                                            value={formData.date || ""}
                                            onChange={handleLocal}
                                            onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                        >
                                        </TextField>
                                        <FormHelperText>DATE</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </div>
                        <Box
                            className="hideForPrint"
                            ml={2} mt={2}
                            display="flex"
                            flexDirection={{ xs: "column", lg: 'row' }}>
                            <Box>
                                {currentUser.account_level !== 'Technician' &&
                                    <FormControl style={{ width: '30ch' }}>
                                        <InputLabel>Stage</InputLabel>
                                        <Select
                                            label="Stage"
                                            onChange={handleLocal}
                                            id="stage"
                                            name="stage"
                                            value={formData.stage || ""}
                                            onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                            defaultValue=""
                                        >
                                            <MenuItem disabled={baseForm.dept !== 'chemApps'} value="Signature Pending">Signature Pending</MenuItem>
                                            <MenuItem value="Preliminary Review">Preliminary Review</MenuItem>
                                            <MenuItem value="Ready for Review">Ready for Review</MenuItem>
                                            <MenuItem disabled={baseForm.dept !== 'chemApps'} value="Final Review">Final Review</MenuItem>
                                            <MenuItem value="Ready for Invoicing">Ready for Invoicing</MenuItem>
                                            <MenuItem disabled={currentUser.dept !== 'admin'} value="Archive">Archive</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                <DeptSelect chemApp={true} />
                                <SubmitAndPrint
                                    isSubmitting={isSubmitting}
                                    width={300}
                                    height={280}
                                    componentRef={componentRef}
                                    showToggle={false}
                                />
                            </Box>
                            {logs.length > 0 &&
                                <HistoryCard logs={logs} />
                            }
                        </Box>
                    </form >
                </Grid>
            </Grid>
        </div >
    )
}

export default ChemAppAgreementEdit;