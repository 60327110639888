import React, { useContext, useEffect, useState } from "react";
import BaseSheet from "../SheetComponents/BaseSheet"
import CompressorSheet from "./CompressorSheet";
import PartsSheet from "../SheetComponents/PartsSheet"
import UserContext from "../../Users/UserContext";
import { useHistory } from "react-router-dom"
import usePartsStore from "../../stores/partsStore";
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";
import useRefrigerationStore from "../../stores/refrigerationStore";
import useSettingsStore from "../../stores/settingsStore";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import {
    Typography, Checkbox, FormControlLabel, useTheme,
    Grid, Box, Button, TextareaAutosize
} from "@mui/material";
import TechSheet from "../SheetComponents/TechSheet";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function Refrigeration() {
    const history = useHistory()
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const messanger = useNotification();
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home]);
    const { currentUser } = useContext(UserContext)
    const [renderCheck, setRenderCheck] = useState(false)
    const [techSheet, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])
    const [partSheet, clearPartsForm] = usePartsStore(state =>
        [state.parts, state.clearPartsForm])
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [refForm, setRefForm, clearRefForm,
        compressors, clearCompressorForm] = useRefrigerationStore(state =>
            [state.refForm, state.setRefForm, state.clearRefForm,
            state.compressors, state.clearCompressorForm])
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        clearBaseForm()
        clearRefForm()
        clearTechForm()
        clearPartsForm()
        clearCompressorForm()
        setRenderCheck(true)
    }, [])

    const handleSubmit = async (e) => {
        setIsSubmitting(true);
        e.preventDefault()
        const sheetObj = CreateSubmitObj(
            refForm, currentUser, messanger,
            baseSheet, partSheet, techSheet)
        if (sheetObj.error) return setIsSubmitting(false);

        sheetObj.compressors = []
        for (let compressor of compressors) {
            sheetObj.compressors = [...sheetObj.compressors, compressor]
        }
        console.log(sheetObj)
        await SheetsApi.addRefrigerationSheet(sheetObj).then((response) => {
            messanger(response.message, "success")
            getNotifications(currentUser)
        })
        setRenderCheck(false)
        clearBaseForm()
        clearPartsForm()
        clearTechForm()
        clearRefForm()
        clearCompressorForm()
        history.push(home)
        return setIsSubmitting(false)
    }
    const handleChange = function (e) {
        let value = e.target.value
        const name = e.target.name
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        }
        setRefForm(name, value)
    }

    return (
        <div>
            <Grid container>
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <Box ml={2}>
                        <form onSubmit={handleSubmit}>
                            <Box style={{ width: "100%" }}>
                                {renderCheck && <BaseSheet title="Refrigeration" />}
                            </Box>
                            <Typography
                                variant="h5"
                                style={{
                                    textDecoration: 'underline',
                                    fontWeight: "bold",
                                    display: 'inline-block'
                                }}>
                                Refrigeration:
                            </Typography>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Vacuum Control Panel(s)"
                                    control={<Checkbox
                                        name="vacuum"
                                        onChange={handleChange}
                                        checked={refForm.vacuum || false}
                                    />}
                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Crank Case Heaters"
                                    control={<Checkbox
                                        name="crankHeater"
                                        onChange={handleChange}
                                        checked={refForm.crankHeater || false}
                                    />}
                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Bypass Doors"
                                    control={<Checkbox
                                        name="doors"
                                        onChange={handleChange}
                                        checked={refForm.doors || false}
                                    />}
                                />
                            </Box>
                            <Box
                                ml={2}
                                style={{ display: 'inline-block' }}>
                                <FormControlLabel
                                    label="Inspect Contactors"
                                    control={<Checkbox
                                        name="contactors"
                                        onChange={handleChange}
                                        checked={refForm.contactors || false}
                                    />}

                                />
                            </Box>
                            <CompressorSheet toggle={true} edit={false} />
                            <Box
                                ml={2}
                                mt={1}
                            >
                                <FormControlLabel
                                    label="Check Controls (Calibration, Operation)"
                                    control={<Checkbox
                                        name="control"
                                        onChange={handleChange}
                                        checked={refForm.control || false}
                                    />}
                                />
                                <Box
                                    ml={2}
                                    style={{ display: 'inline-block' }}
                                >
                                    <FormControlLabel
                                        label="Check failure circuit operation"
                                        control={<Checkbox
                                            name="circuit"
                                            onChange={handleChange}
                                            checked={refForm.circuit || false}
                                        />}
                                    />
                                </Box>
                            </Box>
                            <Box
                                mt={2}
                                style={{ width: "100%" }}
                                sx={{ backgroundColor: primary }}
                            >
                                <Typography
                                    variant="h5"
                                    ml={1}
                                    style={{ color: defaultColor }}>
                                    Notes:
                                </Typography>
                            </Box>
                            <Box ml={0.25} >
                                <TextareaAutosize
                                    minRows={4}
                                    style={{
                                        width: "99.0%",
                                        outline: '2px solid #000',
                                        fontSize: "16px",
                                    }}
                                    aria-label="maximum height"
                                    name="notes"
                                    value={refForm.notes || ""}
                                    onChange={handleChange}
                                />
                            </Box>
                            {renderCheck && <PartsSheet toggle={true} />}
                            {renderCheck && <TechSheet edit={false} toggle={true} />}
                            <Box mt={2}>
                                <FollowUp
                                    state={refForm}
                                    setState={handleChange}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-start">
                                <DeptSelect />
                                <Button
                                    disabled={isSubmitting}
                                    variant="contained"
                                    display="flex"
                                    type="submit"
                                    style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default Refrigeration