import SheetsApi from "../../../../api";
import { createThumbnail } from "./createThumbnail";

async function setImage(img) {
    //determines if its a signature or photo, then gets image from azure db
    if (img.imageType === 'img') {
        const image = await SheetsApi.getImages({
            name: img.name,
            imageType: img.imageType
        })
        // convert string data into a file
        const binaryData = atob(image)
        const arrayBuffer = new ArrayBuffer(binaryData.length)
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'image/png' });
        const file = new File([blob], img.name, { type: 'image/png' });
        const thumbnail = await createThumbnail(file);
        const dataURL = URL.createObjectURL(file);
        return {
            imageType: 'img',
            name: img.name,
            thumbnail: thumbnail,
            downloadURL: dataURL,
            file: file,
        }

    } else if (img.imageType === 'signature') {
        const signature = await SheetsApi.getImages({
            name: img.name,
            imageType: img.imageType,
        })
        return {
            data: 'data:image/png;base64,' + signature,
            name: img.name,
        }
    }
}

export { setImage }