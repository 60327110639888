import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Divider, Collapse, TextField, InputAdornment,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import useBidStoreMain from '../../../../stores/BidStore/bidStoreMain';
import SheetsApi from '../../../../api';
import FreqDrive from '../BidParts/FreqDrive';
import commas from '../../BidFunctions/commas';

function FreqDriveContainer({ edit, fetchedBid }) {
    const [freqDrives, totals, setTotal, setObj, addPart] = useBidStoreMain(state =>
        [state.freqDrive, state.totals, state.setTotal, state.setObj, state.addPart]);
    const [fetchedFreqDrives, setFetchedFreqDrives] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        async function getFreqDrives() {
            const freqDriveData = await SheetsApi.getBidPartsByCategory({ category: "freqDrive" });
            setFetchedFreqDrives([...freqDriveData, {
                partNumber: 'SERPART',
                price: 0,
                description: '',
                additionalData: { hp: 0 }
            }]);
            if (edit) {
                fetchedBid.freqDrives.forEach((drive, index) => {
                    const foundObj = fetchedFreqDrives.find(o => o.partNumber.trim().toLowerCase() === drive.partNumber.trim().toLowerCase());
                    if (index > 0) addPart('freqDrive')
                    const driveObj = {
                        ...drive,
                        hp: foundObj?.additionalData.hp || 0,
                        voltage: foundObj?.additionalData.voltage || 0,
                    }
                    setObj("freqDrive", driveObj, index);
                })
            }
        }
        getFreqDrives();
    }, []);

    useEffect(() => {
        if (freqDrives.length === 1) {
            setIsExpanded(true);
        }
    }, [freqDrives]);

    const calculateTotal = function () {
        let newTotal = 0;
        freqDrives.forEach((drive) => {
            if (drive.partNumber === "SERPART") {
                newTotal += parseFloat(drive.total)
            } else newTotal += drive.price * parseFloat(drive.qty);
        })
        setTotal("freqDrivePrice", parseFloat((newTotal).toFixed(2)) || 0);
    }

    return (
        <Box>
            <Collapse in={isExpanded}>
                <Typography sx={{ ml: 5 }} variant="h5">
                    Frequency Drives
                </Typography>
                <Box m={1}>
                    <Divider />
                </Box>
                <TransitionGroup appear={true}>
                    {freqDrives.map((part, index) => (
                        <Collapse key={index}>
                            <Box>
                                <FreqDrive
                                    calculateTotal={calculateTotal}
                                    freqDriveId={index}
                                    fetchedFreqDrives={fetchedFreqDrives}
                                />
                            </Box>
                        </Collapse>
                    ))}
                </TransitionGroup>
                <Box display="flex" justifyContent="flex-end">
                    <TextField
                        value={commas.add(totals.freqDrivePrice) || 0}
                        sx={{ mr: 5, width: '15ch' }}
                        variant='standard'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Collapse>
        </Box>
    );
}

export default FreqDriveContainer;