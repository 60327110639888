import { create } from "zustand"

const imageArray = [];
const receiptObj = {
    partNumber: "",
    vehicle: "",
    largeJob: "",
    receiptDate: "",
}
const signatureObj = {
    name: "",
    imageType: "signature",
    data: null,
    optionsSig: false,
}
const signatureObj2 = {
    name: "",
    imageType: "signature",
    data: null,
    optionalSig: true
}
const useImageStore = create((set) => ({
    signature: signatureObj,
    signature2: signatureObj2,
    images: imageArray, // Sometimes images will be categorized as receipts.
    receipt: receiptObj,
    receiptsToPrint: [],

    setSignature: function (signatureData, optionalSig) {
        set(prevState => {
            let signature = {
                ...signatureData,
                imageType: "signature",
                optionalSig: optionalSig || false,
            }
            if (optionalSig) {
                let signature2 = signature;
                return { ...prevState, signature2 }
            }
            return { ...prevState, signature }
        })
    },
    clearSignature: function () {
        set(prevState => {
            return {
                ...prevState,
                signature2: signatureObj2,
                signature: signatureObj,
            }
        })
    },
    addImage: function (image) {
        set(prevState => ({
            ...prevState,
            images: [...prevState.images, image]
        }))
    },
    updateImageToReceipt: function (newReceipt) {
        set(prevState => {
            newReceipt.imageType = 'receipt';
            const updatedImages = prevState.images.map(image =>
                image.name === newReceipt.name ? newReceipt : image
            );
            return {
                ...prevState,
                images: updatedImages,
            }
        })
    },
    clearImages: function () {
        set(prevState => ({
            ...prevState,
            images: imageArray,
        }))
    },
    setReceiptObj: function (newObj) {
        set(prevState => ({
            ...prevState,
            receipt: newObj
        }))
    },
    setReceipt: function (name, value) {
        set(prevState => ({
            ...prevState,
            receipt: { ...prevState.receipt, [name]: value }
        }))
    },
    clearReceipt: function () {
        set(prevState => ({
            ...prevState,
            receipt: receiptObj
        }))
    },
    addReceiptsToPrint: function (receipts) {
        set(prevState => ({
            ...prevState,
            receiptsToPrint: receipts
        }))
    },
}))

export default useImageStore;