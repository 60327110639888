import React from "react";
import {
    Dialog, DialogTitle, DialogContent, TextField,
    DialogActions, Button, Typography,
} from "@mui/material";
import useFields from "../Hooks/useFields";
import SheetsApi from "../api";
import useNotification from "../Hooks/useNotification";

function AddCustomerPopUp({ open, setOpen, companyName }) {
    const [formData, handleLocal, resetForm] = useFields({ customer: "", company: companyName })
    const messanger = useNotification()
    const handleSubmit = async function (e) {
        e.preventDefault();
        if (formData.customer === "") {
            messanger("No customer was created", "warning")
            return setOpen(false);
        }
        const result = await SheetsApi.addCustomer(formData)
        messanger(result.message, result.error ? "error" : "success")
        resetForm()
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add New Customer</DialogTitle>
            <DialogContent>
                <Typography>Adding a customer to {companyName}:</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="customer"
                    name="customer"
                    label="Customer Name"
                    fullWidth
                    required
                    value={formData.customer || ""}
                    onChange={handleLocal}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Add Customer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddCustomerPopUp;

