import React, { useContext, useState } from "react";
import UserContext from '../../Users/UserContext';
import {
    List, ListItem, ListItemText, Box, Collapse, useTheme,
    ListItemButton, Divider, IconButton, Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useHistory } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function NavMenu({ name, companies, urlParam, handleDrawerClose }) {
    const history = useHistory()
    const { currentUser } = useContext(UserContext);
    const [toggleMenu, setToggleMenu] = useState(false);
    const theme = useTheme()
    const gray = theme.palette.default.secondary;
    const handleLink = function (company) {
        history.push(`/${urlParam}/${company}`)
        return handleDrawerClose()
    }
    return (
        <>
            {currentUser.account_level === 'Admin' ? <>
                <Box mt={1} display="flex" justifyContent="flex-start">
                    <ListItemButton
                        onClick={() => setToggleMenu(!toggleMenu)}>
                        <ListItemText
                            primary={name}
                            primaryTypographyProps={{ fontSize: '20px' }}
                            sx={{ color: 'white', ml: 1, mt: .5 }}
                        />
                        {toggleMenu ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                    </ListItemButton>
                </Box>
                <List>
                    <Collapse in={toggleMenu}>
                        {companies.length > 0 && companies.map((company) => (
                            <Box key={company.name}>
                                <Divider />
                                <ListItem>
                                    <ListItemButton onClick={() => handleLink(company.name)} >
                                        <ListItemText sx={{ color: 'white' }} primary={company.name === "Agri-Stor Companies" ? "Agri-Stor Twin Falls" : company.name} />
                                    </ListItemButton>
                                </ListItem>
                            </Box>
                        ))}
                    </Collapse>
                </List>
            </> :
                <>
                    <Box mb={1} mt={1} display="flex" justifyContent="flex-start">
                        <IconButton
                            onClick={() => handleLink(currentUser.company)}>
                            {name === 'Home' ?
                                <HomeIcon sx={{ color: 'white' }} />
                                : name === 'Customers' ?
                                    <PersonSearchIcon sx={{ color: 'white' }} />
                                    : null
                            }
                            <Typography
                                sx={{ color: 'white', ml: 1, mt: .5 }}>
                                {name}
                            </Typography>
                        </IconButton>
                    </Box>
                </>}
            <Divider sx={{ backgroundColor: gray }} />
        </>
    )
}

export default NavMenu;