import React, { useEffect, useState } from "react";
import {
    Card, TextField, Typography, Box, Button, InputAdornment,
    FormControl, InputLabel, Select, MenuItem,
} from "@mui/material"
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import useBidPartsStore from "../../../stores/BidStore/bidPartsStore";
import { shallow } from "zustand/shallow";
import useFields from "../../../Hooks/useFields";
import SheetsApi from "../../../api";
import ClimaCellPart from "./BidParts/ClimaCellPart";
import useBidStoreSub from "../../../stores/BidStore/bidStoreSub";
import Commas from "../BidFunctions/commas";

function ClimaCell({ edit, climaCellData, bidData }) {
    const editPass = edit;
    const [totalCfm, totalFanSpeed, setBidBase] = useBidStoreMain(state =>
        [state.totals.totalCfm, state.totals.fanSpeed, state.setBidBase], shallow)
    const [climaCellParts, setPart, setObj, appendPart,
        addClimaCellPart] = useBidPartsStore(state =>
            [state.climaCell, state.setPart, state.setObj,
            state.appendPart, state.addPart])
    const [setTotal] = useBidStoreSub(state => [state.setTotal])
    const [fetchedParts, setFetchedParts] = useState([])
    const [staticParts, setStaticParts] = useState([])
    const INITIAL_STATE = {
        climaCellQty: 0, climaCellDepth: 12,
        climaCellHeight: 12, climaCellWidth: 12,
        climaCellMediaPartNumber: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    useEffect(() => {
        if (edit) {
            for (let i = 1; i < climaCellData.length; i++) {
                addClimaCellPart("climaCell", 5)
            }
        }

        async function getClimaCellParts() {
            let climaCellParts = await SheetsApi.getBidPartsByCategory({ category: "climaCell" })
            const staticParts2 = []
            let returnValues = {}
            const serPart = {
                qty: 0,
                partNumber: "SERPART",
                description: "",
                price: 0,
                total: 0,
                serPart: true,
            };
            const updatedClimaCellParts = await climaCellParts.filter((climaCellPart) => {
                // Takes 2 static parts out of the list and returns their values for later use.
                // Assigns all other parts to an array
                const partNumber = climaCellPart.partNumber;
                if (partNumber === 'HMCCUFT' || partNumber === 'HMCTANK' || partNumber === 'HMCCUFTEC') {
                    if (partNumber === 'HMCTANK') {
                        const tank = {
                            tankDescription: climaCellPart.description,
                            tankPrice: edit ? bidData.tankPrice : climaCellPart.price,
                            tankPartNumber: partNumber,
                        }
                        appendPart("climaCellStatic", tank);
                        returnValues = { ...returnValues, tank };
                        return false; // Exclude object from array
                    }
                    if (partNumber === 'HMCCUFT') {
                        const media = {
                            climaCellMediaPartNumber: partNumber,
                            climaCellPrice: climaCellPart.price,
                            mediaDescription: climaCellPart.description,
                        }
                        appendPart("climaCellStatic", media);
                        staticParts2.push(climaCellPart);
                        returnValues = { ...returnValues, media };
                        return false;
                    }
                    if (partNumber === 'HMCCUFTEC') {
                        appendPart("climaCellStatic", climaCellPart)
                        staticParts2.push(climaCellPart);
                        return false;
                    }
                }
                return true; // Keep obj in array
            });
            setStaticParts(staticParts2);
            setFetchedParts([...updatedClimaCellParts, serPart]);
            return returnValues;
        }
        const setState = async function () {
            // sets the page up with proper data in the global and local state.
            const parts = await getClimaCellParts()
            if (edit) {
                const climaCellObj = {
                    climaCellQty: bidData.climaCellQty,
                    climaCellWidth: bidData.climaCellWidth,
                    climaCellHeight: bidData.climaCellHeight,
                    climaCellDepth: bidData.climaCellDepth,
                    climaCellMediaPartNumber: bidData.climaCellMediaPartNumber,
                    climaCellPrice: bidData.climaCellPrice,
                    tankQty: bidData.tankQty,
                    tankPrice: bidData.tankPrice,
                }
                const localObj = { ...parts.tank, ...parts.media, ...climaCellObj }
                handleLocal("replaceObj", localObj)
                setBidBase("climaCellQty", bidData.climaCellQty);
                setBidBase("climaCellWidth", bidData.climaCellWidth);
                setBidBase("climaCellHeight", bidData.climaCellHeight);
                setBidBase("climaCellDepth", bidData.climaCellDepth);
                setBidBase("climaCellprice", bidData.climaCellPrice);
                setBidBase("climaCellMediaPartNumber", bidData.climaCellMediaPartNumber);
                setObj("climaCellTotals", climaCellObj, 0)
            } else {
                handleLocal("replaceObj", { ...parts.media, ...parts.tank, ...INITIAL_STATE })
            }
        }
        setState()
    }, []);

    useEffect(() => {
        // gets the sq ft required and variance and fills inputs
        const depth = formData.climaCellDepth;
        const totalCFM = totalCfm
        const fanSpeed = totalFanSpeed / 100
        let totalSqFtReq;
        if (depth === 12) {
            totalSqFtReq = totalCFM / 650 * fanSpeed
        } else { // if depth isnt 12 then it will be 18
            totalSqFtReq = totalCFM / 550 * fanSpeed
        }
        setTotal("climaCellSqFtReq", totalSqFtReq)
        setTotal("climaCellSqFtTotal", formData.totalSqFt)
        const variance = parseFloat(((formData.totalSqFt / totalSqFtReq - 1) * 100).toFixed(2))
        setPart("variance", "climaCell", variance, 0)
        handleLocal("invalidInput", "totalSqFtReq", parseFloat(totalSqFtReq.toFixed(2)));
        handleLocal("invalidInput", "variance", variance);
    }, [formData.climaCellDepth, formData.totalSqFt, totalCfm])

    useEffect(() => {
        const handleMediaCalc = function (edgeCoatPrice) {
            const depth = formData.climaCellDepth || 12;
            const width = formData.climaCellWidth || 12;
            const height = formData.climaCellHeight || 12;
            const qty = formData.climaCellQty || 0;
            const tankQty = formData.tankQty || 0;
            let mediaQty = 0;
            const tankWidthQty = width * tankQty;
            const totalSqFt = width * height * qty;
            if (depth === 18) {
                mediaQty = width * height * 1.5 * qty;
            } else {
                mediaQty = width * height * qty;
            }
            const tankTotal = tankWidthQty * formData.tankPrice;
            const mediaTotal = mediaQty * edgeCoatPrice;
            handleLocal("invalidInput", "tankTotal", parseFloat(tankTotal.toFixed(2)));
            handleLocal("invalidInput", "mediaTotal", parseFloat(mediaTotal.toFixed(2)));
            handleLocal("invalidInput", "totalSqFt", parseFloat(totalSqFt.toFixed(2)));
        }
        handleMediaCalc(formData.climaCellPrice)
        handleClimaTotal()
    }, [formData.climaCellQty, formData.climaCellDepth, formData.climaCellHeight,
    formData.climaCellWidth, formData.climaCellMediaPartNumber,
    formData.climaCellPrice, formData.tankQty, formData.tankPrice])

    const handleMedia = function (e) {
        const foundPart = staticParts.find(part => part.partNumber === e.target.value)
        if (foundPart) {
            setPart("climaCellTotals", "climaCellMediaPartNumber", foundPart.partNumber, 0)
            handleLocal("invalidInput", "mediaDescription", foundPart.description)
            handleLocal("invalidInput", "climaCellPrice", foundPart.price)
        }
    }

    const handleClimaTotal = function () {
        const mediaTotal = formData.mediaTotal || 0
        const tankTotal = formData.tankTotal || 0
        let priceTotal = 0;
        climaCellParts.forEach((part) => {
            if (part.partNumber !== "" && !isNaN(part.total)) {
                priceTotal = priceTotal + part.total
            }
        })
        priceTotal = parseFloat((priceTotal + mediaTotal + tankTotal).toFixed(2))
        setPart("climaCellTotals", "climaCellPrice", parseFloat(formData.climaCellPrice), 0);
        setPart("climaCellTotals", "mediaTotal", mediaTotal, 0);
        setPart("climaCellTotals", "tankTotal", tankTotal, 0);
        setPart("climaCellTotals", "climaCellTotal", priceTotal, 0)
        setPart("climaCellTotals", "tankQty", parseFloat(formData.tankQty), 0);
        setPart("climaCellTotals", "tankPrice", parseFloat(formData.tankPrice), 0)
        setPart("climaCellTotals", "climaCellMediaPartNumber", formData.climaCellMediaPartNumber, 0)
        handleLocal("invalidInput", "priceTotal", priceTotal);
    }
    useEffect(() => {
        handleClimaTotal()
        //run calcs when totals change to get grand total
    }, [formData.tankTotal, formData.mediaTotal])

    const handleGlobalState = function (e) {
        setPart("climaCellTotals", e.target.name, e.target.value, 0);
    }

    return (
        <>
            <Box ml={1} mt={1}>
                <Card sx={{ width: '130ch' }} >
                    <Box m={2} display="flex" justifyContent="space-between">
                        <Box alignItems="flex-start">
                            <Typography sx={{ textDecoration: "underline" }} ml={4} variant="h5">
                                ClimaCell™ Evaporative Cooler
                            </Typography>
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <TextField
                                name="climaCellQty"
                                id="climaCellQty"
                                label="Quantity"
                                sx={{ width: "8ch" }}
                                type="number"
                                value={formData.climaCellQty || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Box display="inline" ml={1} mr={1}>
                                <TextField
                                    name="climaCellWidth"
                                    id="climaCellWidth"
                                    label="Width"
                                    type="number"
                                    sx={{ width: "8ch" }}
                                    value={formData.climaCellWidth || 12}
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box display="inline" mr={1}>
                                <TextField
                                    name="climaCellHeight"
                                    id="climaCellHeight"
                                    label="Height"
                                    type="number"
                                    sx={{ width: "8ch" }}
                                    value={formData.climaCellHeight || 12}
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <FormControl >
                                <InputLabel>Depth</InputLabel>
                                <Select
                                    name="climaCellDepth"
                                    id="climaCellDepth"
                                    label="Depth"
                                    sx={{ width: "8ch" }}
                                    value={formData.climaCellDepth || 12}
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                >
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={18}>18</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box m={1}>
                        <Box ml={1} display="inline">
                            <TextField
                                name="mediaQty"
                                id="mediaQty"
                                label="Quantity"
                                type="number"
                                variant="standard"
                                sx={{ width: "8ch" }}
                                value={formData.climaCellQty || ""}
                                onChange={handleLocal}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>

                        <Box display="inline">
                            <FormControl>
                                <InputLabel sx={{ ml: 1 }}>Media</InputLabel>
                                <Select
                                    name="climaCellMediaPartNumber"
                                    id="climaCellMediaPartNumber"
                                    label="Media"
                                    sx={{ width: '24ch', ml: 1, mr: 1 }}
                                    value={formData.climaCellMediaPartNumber || ""}
                                    onChange={handleLocal}
                                    onBlur={handleMedia}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {staticParts.length > 0 && staticParts.map(part => {
                                        return <MenuItem
                                            key={part.partNumber}
                                            value={part.partNumber || ""}>
                                            {part.partNumber}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="mediaDescription"
                                id="mediaDescription"
                                label="Description"
                                variant="standard"
                                sx={{ width: "64ch" }}
                                value={formData.mediaDescription || ""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="climaCellPrice"
                                id="climaCellPrice"
                                label="Dealer Price"
                                type="number"
                                variant="outlined"
                                sx={{ width: "14ch", ml: 1, mr: 1 }}
                                value={formData.climaCellPrice || ""}
                                onChange={handleLocal}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="mediaTotal"
                                id="mediaTotal"
                                label="Total"
                                variant="standard"
                                sx={{ width: "12ch" }}
                                value={Commas.add(formData.mediaTotal) || ""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box m={1}>
                        <Box mr={1} display="inline">
                            <TextField
                                name="tankQty"
                                id="tankQty"
                                label="Quantity"
                                type="number"
                                sx={{ width: "10ch" }}
                                value={formData.tankQty || ""}
                                onChange={handleLocal}
                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="tankPartNumber"
                                id="tankPartNumber"
                                label="Part Number"
                                variant="standard"
                                sx={{ width: "24ch" }}
                                value={formData.tankPartNumber || ""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}

                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="tankDescription"
                                id="tankDescription"
                                label="Description"
                                variant="standard"
                                sx={{ width: "65ch" }}
                                value={formData.tankDescription || ""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="tankPrice"
                                id="tankPrice"
                                label="Dealer Price"
                                type="number"
                                variant="outlined"
                                sx={{ width: "14ch", ml: 1, mr: 1 }}
                                value={formData.tankPrice || ""}
                                onChange={handleLocal}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="tankTotal"
                                id="tankTotal"
                                label="Total"
                                variant="standard"
                                sx={{ width: "12ch" }}
                                value={Commas.add(formData.tankTotal) || ""}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </Box>
                    </Box>
                    {fetchedParts.length > 0 && climaCellParts.map((part) => {
                        return <ClimaCellPart
                            key={part.climaCellId}
                            edit={editPass}
                            climaCellData={climaCellData}
                            id={part.climaCellId}
                            parts={fetchedParts}
                            handleClimaTotal={handleClimaTotal} />
                    })}
                    <Box
                        m={1}
                        display="flex"
                        justifyContent="space-between">
                        <Box align="left" mb={1} mr={1} ml={1}>
                            <Button
                                variant='contained'
                                onClick={() => addClimaCellPart("climaCell", 5)}
                            >
                                Add Part
                            </Button>
                        </Box>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <TextField
                                id="totalSqFtReq"
                                name="totalSqFtReq"
                                label="Total SQ. FT. Required"
                                variant="standard"
                                sx={{ width: "16ch" }}
                                value={formData.totalSqFtReq || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="totalSqFt"
                                name="totalSqFt"
                                label="Total SQ. FT."
                                variant="standard"
                                sx={{ width: "10ch" }}
                                value={formData.totalSqFt || ""}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                id="variance"
                                name="variance"
                                label="Variance"
                                variant="standard"
                                error={
                                    formData.variance >= -10
                                        && formData.variance <= 10
                                        ? false : true
                                }
                                sx={{ width: "8ch" }}
                                value={formData.variance || 0}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            %
                                        </InputAdornment>,
                                    readOnly: true
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Box ml={2} display="inline" >
                                <TextField
                                    id='priceTotal'
                                    name='priceTotal'
                                    type='number'
                                    sx={{ width: '12ch' }}
                                    label=" "
                                    value={formData.priceTotal || ""}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment
                                                position="start">
                                                $
                                            </InputAdornment>,
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default ClimaCell;