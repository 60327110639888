import React from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';
import Commas from "../../../BidFunctions/commas";
import SqFtCompare from "./SqFtCompare";

function ClimaCellQuoteLine({ extraData, tankTotal, mediaTotal, noPrices }) {
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 8,
            width: '100%',
        },
        header: {
            borderTop: 'none',
            borderBottom: 1,
            borderColor: 'black',
            paddingBottom: 8,
            paddingTop: 8,
        },
        qtyRow: { width: '5%', fontSize: '12px' },
        descriptionRow: { width: '75%', fontSize: '12px' },
        priceRow: {
            marginLeft: '20px',
            width: '15%',
            fontSize: '12px',
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
        },
        underline: { textDecoration: 'underline', fontSize: '12px' },
        flexDirection: { flexDirection: 'row' },
    });

    return (
        <>
            <View style={[styles.container, styles.flexDirection]}>
                <Text style={styles.underline}>Quantity: {extraData.climaCellQty}</Text>
                <Text style={styles.underline}>Depth: {extraData.climaCellDepth}"</Text>
                <Text style={styles.underline}>Width: {extraData.climaCellWidth}"</Text>
                <Text style={styles.underline}>Height: {extraData.climaCellHeight}"</Text>
                <Text style={styles.underline}>Edge Coat: {extraData.climaCellMediaPartNumber === 'HMCCUFTEC' ? "Yes" : "no"}</Text>
            </View>
            <SqFtCompare data={extraData} />
            {extraData.tankQty &&
                <View style={styles.row}>
                    <Text style={styles.qtyRow}>{extraData.tankQty}</Text>
                    <Text style={styles.qtyRow}>-</Text>
                    <Text style={styles.descriptionRow}>{extraData.tank.tankDescription}</Text>
                    {!noPrices &&
                        <Text style={styles.priceRow}>$ {Commas.add(tankTotal)}</Text>
                    }
                </View>
            }
            {extraData.climaCellMediaPartNumber === "HMCCUFTEC" ?
                // With edgecoat
                <View style={styles.row}>
                    <Text style={styles.qtyRow}>{extraData.climaCellQty}</Text>
                    <Text style={styles.qtyRow}>-</Text>
                    <Text style={styles.descriptionRow}>{extraData.edgeCoat.description}</Text>
                    {!noPrices &&
                        <Text style={styles.priceRow}>$ {Commas.add(mediaTotal)}</Text>
                    }
                </View>
                : // without edgecoat
                <View style={styles.row}>
                    <Text style={styles.qtyRow}>{extraData.climaCellQty}</Text>
                    <Text style={styles.qtyRow}>-</Text>
                    <Text style={styles.descriptionRow}>{extraData.media.mediaDescription}</Text>
                    {!noPrices &&
                        <Text style={styles.priceRow}>$ {Commas.add(mediaTotal)}</Text>
                    }
                </View>
            }
        </>
    )
}

export default ClimaCellQuoteLine;