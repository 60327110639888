import React, { useContext, useEffect, useState } from "react";
import {
    Card, CardContent, Typography, Box, ToggleButton, ToggleButtonGroup, Divider,
} from "@mui/material";
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from "@mui/material/styles";
import UserContext from "../../Users/UserContext";
import SheetsApi from "../../api";
import useSettingsStore from "../../stores/settingsStore";
import SalesNotification from "./SalesNotificaton";

function SalesDashboard() {
    const theme = useTheme()
    const { currentUser } = useContext(UserContext)
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const background = theme.palette.background.main
    const [customerCards, setCustomerCards] = useState({})
    const [companyCode, setCompanyCode] = useState('')
    const [toggleSettings, setToggle] = useSettingsStore(state =>
        [state.toggleSettings, state.setToggle])
    function removeSpaces(str) {
        //this function needs to the same as the one in settingsStore / createSettings()
        return str.replace(/[\s-']/g, '');
    }
    useEffect(() => {
        const getUserNotifications = async function () {
            return await SheetsApi.getSalesNotifications({ username: currentUser.username })
        }
        getUserNotifications().then(async (response) => {
            let refinedObj = {}
            for (let noti of response) {
                const customer = noti.customer;
                if (customer in refinedObj) {
                    // Customer already exists as a key, push notification to the existing array
                    refinedObj[customer].push(noti);
                } else {
                    // Customer doesnt exist as a key, create a new key with notification.
                    refinedObj[customer] = [noti];
                }
            }
            setCustomerCards(refinedObj);
        });
        setCompanyCode(removeSpaces(currentUser.company))
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <img src={process.env.PUBLIC_URL + '/logo.png'} style={{ width: '400px', height: 'auto' }} ></img>
            </Box>
            <Card sx={{ ml: 2, mr: 2, mb: 2, bgcolor: primary }}>
                <Typography
                    variant="h3"
                    sx={{ m: 2, textAlign: 'center', color: defaultColor }}
                >
                    Service Viewer
                </Typography>
                {Object.keys(customerCards).length === 0 &&
                    <Typography
                        variant="h5"
                        sx={{ textAlign: 'center', color: defaultColor }}
                    >
                        You have 0 notifications
                    </Typography>
                }
                <CardContent>
                    {Object.entries(customerCards).map(([customer, notifications]) => {
                        return (
                            <Card
                                key={customer}
                                sx={{ borderRadius: '8px', m: 2, bgcolor: defaultColor }}>
                                <CardContent>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex">
                                            <ToggleButtonGroup
                                                onChange={() => setToggle(companyCode, customer)}>
                                                <ToggleButton
                                                    sx={{ maxHeight: 50 }}
                                                    value={toggleSettings[companyCode][customer] || false}>
                                                    {toggleSettings[companyCode][customer] ? <MenuOpenOutlinedIcon
                                                        sx={{ fontSize: 24, color: background }}
                                                    /> : <MenuIcon
                                                        sx={{ fontSize: 24, color: background }} />
                                                    }
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            <Typography variant={currentUser.mobile ? 'h6' : 'h5'} sx={{ mt: 1, ml: 2, color: background }}>
                                                {customer}
                                            </Typography>
                                        </Box>
                                        <Box display="flex">
                                            {!currentUser.mobile &&
                                                <Divider color={background} orientation="vertical" flexItem />
                                            }
                                            <Typography variant={currentUser.mobile ? 'h7' : 'h5'} sx={{ ml: 2, mt: 1, mr: 3, color: background }}>
                                                Total: {notifications.length}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {notifications.length > 0 && toggleSettings[companyCode][customer] && notifications.map((noti) => {
                                            return <SalesNotification
                                                key={noti.id}
                                                notification={noti}
                                                setCustomerCards={setCustomerCards}
                                            />
                                        })}
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </CardContent>
            </Card>
        </>

    )
}

export default SalesDashboard;