import React, { useEffect, useState } from "react";
import { Box, TextField, InputAdornment } from "@mui/material"
import useFields from "../../Hooks/useFields";
import useBayStore from "../../stores/bayStore";
function DeltaT({ bayId, edit, item, topName, bottomName, avgDeltaTName }) {
    const [bays, setBayForm] = useBayStore(state => [state.bays, state.setBayForm])
    const [formData, handleLocal] = useFields({});
    const [labelData, setlabelData] = useState({ front: "", bottom: "", avgDeltaT: "" })
    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", item)
        }
        const topLabel = camelCaseToTitleCase(topName)
        const bottomLabel = camelCaseToTitleCase(bottomName)
        const avgDeltaTLabel = camelCaseToTitleCase(avgDeltaTName)
        setlabelData({ front: topLabel, bottom: bottomLabel, avgDeltaT: avgDeltaTLabel })
        calculator()
    }, [])

    useEffect(() => {
        calculator();
    }, [bays[bayId][topName], bays[bayId][bottomName]])

    const handleChange = function (e) {
        const properties = ['topFront', 'topMiddle', 'topBack', 'bottomFront', 'bottomMiddle', 'bottomBack']
        const name = e.target.name;
        let value = e.target.value;
        properties.forEach((property) => {
            // convert string vlaues to floats
            if (property === name) return value = parseFloat(value);
        })
        setBayForm(name, value, bayId);
    }

    const calculator = function () {
        const top = bays[bayId][topName]
        const bottom = bays[bayId][bottomName]
        if (top && bottom) {
            let avgDeltaT = 0;
            avgDeltaT = parseFloat((top - bottom).toFixed(3)) || 0;
            setBayForm(avgDeltaTName, avgDeltaT, bayId);
            handleLocal("invalidInput", avgDeltaTName, avgDeltaT)
        }
    }

    function camelCaseToTitleCase(string) {
        // split the string at each capital letter
        const result = string.replace(/([A-Z])/g, " $1");
        // Capitalize the first letter and return the result
        return result.charAt(0).toUpperCase() + result.slice(1);

    }

    return (
        <>
            <Box>
                <Box
                    ml={1} mt={1}
                    style={{ display: 'inline-block' }}>
                    <TextField
                        name={topName}
                        id={topName}
                        label={labelData.front}
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData[topName] || ""}
                        onBlur={handleChange}
                        onChange={handleLocal}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °F</InputAdornment>,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box
                    ml={1} mt={1}
                    style={{ display: 'inline-block' }}>
                    <TextField
                        name={bottomName}
                        id={bottomName}
                        label={labelData.bottom}
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData[bottomName] || ""}
                        onBlur={handleChange}
                        onChange={handleLocal}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °F</InputAdornment>,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box
                    style={{ display: 'inline-block' }}>
                    <Box
                        ml={1} mt={1}>
                        <TextField
                            name={avgDeltaTName}
                            id={avgDeltaTName}
                            label={labelData.avgDeltaT}
                            variant="standard"
                            sx={{ width: '13ch' }}
                            value={formData[avgDeltaTName] || 0}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    °F</InputAdornment>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default DeltaT;