import React, { useEffect, useContext, useState } from "react";
import useBidPartsStore from "../../../../stores/BidStore/bidPartsStore";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import useBidStoreSub from "../../../../stores/BidStore/bidStoreSub";
import { shallow } from "zustand/shallow";
import {
    Table, TableHead, TableRow, TableContainer, TableCell,
    TableBody, Box, Card, Button, TextField, InputAdornment,
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import Submit from "./Submit";
import SheetsApi from "../../../../api";
import UserContext from "../../../../Users/UserContext";
import DayJS from "dayjs"
import { useParams } from "react-router-dom";
import Commas from "../../BidFunctions/commas";
import useNotification from "../../../../Hooks/useNotification";
import { useHistory } from "react-router-dom";
import useBidRefrigerationStore from "../../../../stores/BidStore/bidRefrigerationStore";
import RefSubmit from "./RefSubmit";

function GrandTotal({ edit, refrigerationBid }) {
    const editPass = edit;
    const { currentUser } = useContext(UserContext);
    const { id } = useParams();
    const messanger = useNotification();
    const history = useHistory();
    const [setPart, actuatorTotal, controlTotal,
        humidifierTotal, refrigerationTotal, actuator, climaCell, climaCellTotals,
        control, humidifier, refrigeration] = useBidPartsStore(state =>
            [state.setPart, state.totals[0].actuatorTotal, state.totals[0].controlTotal,
            state.totals[0].humidifierTotal, state.totals[0].refrigerationTotal,
            state.actuator, state.climaCell, state.climaCellTotals, state.control,
            state.humidifier, state.refrigeration], shallow)
    const [otherTotal, contractorTotal, rentalEquipmentTotal, laborTotal,
        hardwareTotal, labor, rentalEquipment, contractor, other,
        option, hardware, refrigerationOtherTotal, refrigerationContractorTotal,
        refrigerationRentalEquipmentTotal, refrigerationLaborTotal,
        refrigerationHardwareTotal, custom, customTotal] = useBidStoreMisc(state =>
            [state.totals.otherTotal, state.totals.contractorTotal,
            state.totals.equipmentTotal, state.totals.laborTotal,
            state.totals.hardwareTotal, state.labor, state.equipment,
            state.contractor, state.other, state.option, state.hardware,
            state.refrigerationTotals.otherTotal, state.refrigerationTotals.contractorTotal,
            state.refrigerationTotals.equipmentTotal, state.refrigerationTotals.laborTotal,
            state.refrigerationTotals.hardwareTotal, state.custom, state.totals.customTotal], shallow)
    const [freqDrivePrice, motorPrice, fanPrice, bidBase, setBidBase,
        fan, motor, freqDrive] = useBidStoreMain(state =>
            [state.totals.freqDrivePrice, state.totals.motorPrice,
            state.totals.fanTotal, state.bidBase, state.setBidBase,
            state.fan, state.motor, state.freqDrive], shallow);
    const [dampersTotal, doorsTotal, lightBlockTotal, door, damper,
        lightBlock] = useBidStoreSub(state =>
            [state.totals.dampersTotal, state.totals.doorsTotal,
            state.totals.lightBlockTotal, state.door, state.damper,
            state.lightBlock], shallow)
    const [cooler, condenser] = useBidRefrigerationStore(state => [state.cooler, state.condenser], shallow)
    const [formData, handleLocal] = useFields({
        grandTotal: 0,
        damperSqFtTotal: 0,
        laborTotal: 0,
        equipmentTotal: 0,
        contractorTotal: 0,
        otherTotal: 0,
        overHeadTotal: 0,
        profit: 15.6,
        taxRate: 6,
        overHead: 20,
        taxTotal: 0,
    })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async function () {
        setIsSubmitting(true);
        if (bidBase.dateServiced === "") {
            bidBase.dateServiced = DayJS().format("MM-DD-YYYY")
        }
        if (bidBase.customer === "") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            messanger("The customer name you have entered is not valid.", "warning")
            return setIsSubmitting(false)
        }
        if (DayJS(bidBase.dateServiced).isValid()) {
            if (!edit) {
                bidBase.creator = currentUser.username
                bidBase.dateCreated = DayJS().format("MM-DD-YYYY")
                bidBase.company = currentUser.company
            }
            const newBidBase = { ...bidBase, ...climaCellTotals[0] }
            newBidBase.total = 0;

            const bidSheet = {
                bidBase: { ...newBidBase }, id: id, fans: [...fan],
                motors: [...motor], freqDrives: [...freqDrive],
                climaCell: [...climaCell], actuator: [...actuator],
                door: [...door], humidifier: [...humidifier],
                damper: [...damper], lightBlock: [...lightBlock],
                control: [...control], labor: [...labor],
                equipment: [...rentalEquipment], contractor: [...contractor],
                other: [...other], option: [...option], condenser: condenser,
                cooler: cooler, refrigeration: [...refrigeration],
                hardware: [...hardware], custom: [...custom],
            }
            console.log(bidSheet);
            if (!edit && refrigerationBid) {
                const response = await SheetsApi.addBidRef(bidSheet);
                messanger(response.message, response.error ? "error" : "success");
            } else if (edit && refrigerationBid) {
                const response = await SheetsApi.updateBidRef(bidSheet);
                messanger(response.message, response.error ? "error" : "success");
            } else if (!edit) {
                const response = await SheetsApi.addBid(bidSheet);
                history.push(`/`);
                messanger(response.message, response.error ? "error" : "success");
            } else if (edit) {
                const response = await SheetsApi.updateBid(bidSheet);
                messanger(response.message, response.error ? "error" : "success");
            }
            return setIsSubmitting(false);
        } else {
            return messanger("The date you entered is not valid. Please check the Service Date and try again.", 'warning')
        }
    }

    const handleCopy = async () => {
        SheetsApi.copyBid({ id }).then((response) => {
            messanger(response.message, "success")
            history.push(`/bids/edit/${response.id}`)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    }

    const calculateTotal = function () {
        let grandTotal = 0;
        let grandPartsTotal = 0;
        // calculate total for taxable items
        const partsTotalArr = [
            actuatorTotal, humidifierTotal, controlTotal,
            climaCellTotals[0].climaCellTotal, fanPrice,
            motorPrice, freqDrivePrice, doorsTotal,
            dampersTotal, lightBlockTotal, condenser.total,
            cooler.total, refrigerationTotal, customTotal,
        ];

        partsTotalArr.forEach((price) => {
            if (isNaN(price)) price = 0;
            grandPartsTotal += price;
        });

        // this is taxable but we add it serpeatly because we want to display it to the user seperatly 
        grandTotal += hardwareTotal;
        grandTotal += refrigerationHardwareTotal;
        const taxRate = parseFloat((formData.taxRate / 100));
        const taxTotal = parseFloat(((grandPartsTotal + hardwareTotal + refrigerationHardwareTotal) * taxRate).toFixed(2)) || 0;
        // add all items together - include non-taxables
        const grandTotalArr = [
            grandPartsTotal, taxTotal, otherTotal,
            contractorTotal, rentalEquipmentTotal,
            laborTotal, refrigerationOtherTotal,
            refrigerationContractorTotal,
            refrigerationRentalEquipmentTotal,
            refrigerationLaborTotal,
        ];
        grandTotalArr.forEach((amount) => {
            grandTotal += amount;
        });
        const overHeadTotal = parseFloat((grandTotal * (formData.overHead / 100)).toFixed(2));
        grandTotal = parseFloat((grandTotal + overHeadTotal).toFixed(2)) || 0;
        setPart("totals", "equipmentTotal", parseFloat(hardwareTotal.toFixed(2)), 0);
        setPart("totals", "taxTotal", taxTotal, 0);
        handleLocal("invalidInput", "taxTotal", Commas.add(taxTotal));
        handleLocal("invalidInput", "laborTotal", Commas.add(laborTotal));
        handleLocal("invalidInput", "equipmentTotal", Commas.add(grandPartsTotal));
        handleLocal("invalidInput", "rentalEquipmentTotal", Commas.add(rentalEquipmentTotal));
        handleLocal("invalidInput", "partsTotal", Commas.add(hardwareTotal));
        handleLocal("invalidInput", "contractorTotal", Commas.add(contractorTotal));
        handleLocal("invalidInput", "otherTotal", Commas.add(otherTotal));
        handleLocal("invalidInput", "overHeadTotal", Commas.add(overHeadTotal));
        handleLocal("invalidInput", "grandTotal", Commas.add(grandTotal));
    };

    useEffect(() => {
        if (edit) {
            handleLocal("invalidInput", "overHead", bidBase.overHead)
            handleLocal("invalidInput", "taxRate", bidBase.taxRate)
        }
    }, [])
    return (
        <>
            <Box m={2}>
                <Card sx={{ width: '128ch' }} >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        m={2}>
                        <Card>
                            <Box m={4} align="left">
                                <TableContainer>
                                    <Table sx={{ maxWidth: 400 }} aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    Category
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Equipment</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.equipmentTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Hardware / Supplies</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.partsTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Labor / Fuel</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.laborTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Equipment Rental</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.rentalEquipmentTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Contractors</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.contractorTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Other</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.otherTotal || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <TextField
                                                        id="taxRate"
                                                        name="taxRate"
                                                        label="Tax Rate"
                                                        size="small"
                                                        sx={{ width: "12ch" }}
                                                        InputLabelProps={{ shrink: true }}
                                                        value={formData.taxRate === 0 ? 0 : formData.taxRate || 6}
                                                        onChange={handleLocal}
                                                        onBlur={(e) => setBidBase("taxRate", e.target.value)}
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment
                                                                    position="end">
                                                                    %
                                                                </InputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">$ {formData.taxTotal}</TableCell>
                                            </TableRow>
                                            {currentUser.account_level === 'Admin' || currentUser.account_level === 'Regional Admin' ?
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            id="overHead"
                                                            name="overHead"
                                                            label="Overhead"
                                                            size="small"
                                                            sx={{ width: "12ch" }}
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formData.overHead || ""}
                                                            onChange={handleLocal}
                                                            onBlur={(e) => setBidBase("overHead", e.target.value)}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment
                                                                        position="end">
                                                                        %
                                                                    </InputAdornment>,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell align="right">$ {formData.overHeadTotal || 0}</TableCell>
                                                </TableRow> :
                                                <TableRow>
                                                    <TableCell>Overhead</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell align="right">$ {formData.overHeadTotal || 0}</TableCell>
                                                </TableRow>
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        onClick={calculateTotal}>
                                                        Calculate
                                                    </Button>
                                                </TableCell>
                                                <TableCell >Total</TableCell>
                                                <TableCell align="right">$ {formData.grandTotal}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Card>
                        <Box align="left" >
                            {refrigerationBid ?
                                <RefSubmit
                                    isSubmitting={isSubmitting}
                                    edit={editPass}
                                    grandTotal={formData.grandTotal}
                                    handleSubmit={handleSubmit}
                                    handleCopy={handleCopy}
                                />
                                : <Submit
                                    isSubmitting={isSubmitting}
                                    edit={editPass}
                                    grandTotal={formData.grandTotal}
                                    handleSubmit={handleSubmit}
                                    handleCopy={handleCopy}
                                />
                            }
                        </Box>
                    </Box>
                </Card >
            </Box>
        </>
    )
}

export default GrandTotal;

