import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Divider, Collapse, TextField,
    InputAdornment,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import useBidStoreMain from '../../../../stores/BidStore/bidStoreMain';
import SheetsApi from '../../../../api';
import Motor from '../BidParts/Motor';
import Commas from '../../BidFunctions/commas';
function MotorContainer({ edit, fetchedBid }) {
    const [motors, totals, setTotal, setObj, addPart] = useBidStoreMain(state =>
        [state.motor, state.totals, state.setTotal, state.setObj, state.addPart]);
    const [fetchedMotors, setFetchedMotors] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        async function getMotors() {
            const motorData = await SheetsApi.getBidPartsByCategory({ category: "motor" });
            setFetchedMotors([...motorData, {
                partNumber: 'SERPART',
                price: 0,
                description: '',
                additionalData: { hp: 0 }
            }]);
            if (edit) {
                fetchedBid.motors.forEach((motor, index) => {
                    //adds motors to global state, add additionlData (hp, brand, power) from the db.
                    if (index > 0) addPart('motor')
                    const foundMotor = motorData.find(motor2 => motor2.partNumber === motor.partNumber);
                    let additionalData;
                    if (foundMotor) {
                        additionalData = foundMotor.additionalData;
                    } else {
                        const hpValue = findHpValue(motor.description)
                        additionalData = { hp: hpValue }
                    }
                    setObj("motor", { ...motor, additionalData }, index)
                })
            }
        }
        getMotors();
    }, []);

    useEffect(() => {
        if (motors.length === 1) {
            setIsExpanded(true);
        }
    }, [motors]);

    const calculateTotal = function () {
        let newTotal = 0;
        motors.forEach((motor) => {
            if (motor.partNumber === "SERPART") {
                newTotal += parseFloat(motor.total)
            } else newTotal += motor.price * parseFloat(motor.qty);
        })
        setTotal("motorPrice", parseFloat((newTotal).toFixed(2)) || 0);
    }

    const findHpValue = function (text) {
        // Brought to you by ChatGpt - finds HP value in a string.
        // Define the regex pattern to match numbers (1-2 digits) followed by 'HP' (case insensitive)
        const pattern = /\b(\d{1,2})\s*hp\b/i;
        // Search for the pattern in the text
        const match = text.match(pattern);
        if (match) return parseInt(match[1], 10);
        else return 0;
    }

    return (
        <Box>
            <Collapse in={isExpanded}>
                <Typography sx={{ ml: 5 }} variant="h5">
                    Fan Motors
                </Typography>
                <Box m={1}>
                    <Divider />
                </Box>
                <TransitionGroup appear={true}>
                    {motors.map((part, index) => {
                        if (motors[index]) {
                            return <Collapse key={index}>
                                <Box>
                                    <Motor
                                        calculateTotal={calculateTotal}
                                        motorId={index}
                                        fetchedMotors={fetchedMotors}
                                    />
                                </Box>
                            </Collapse>
                        } else return null;
                    })}
                </TransitionGroup>
                <Box display="flex" justifyContent="flex-end">
                    <TextField
                        value={Commas.add(totals.motorPrice) || 0}
                        sx={{ mr: 5, width: '15ch' }}
                        variant='standard'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Collapse >
        </Box >
    );
}

export default MotorContainer;


