import React from "react";
import useBayStore from "../../stores/bayStore";
import Bay from "./Bay";
import { Button } from "@mui/material"
import { useTheme } from "@mui/material/styles"

function BaySheet({ edit }) {
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [addBay, bays] = useBayStore(state =>
        [state.addBay, state.bays])
    const bayObject = {
        bay: "",
        top1: "",
        top2: "",
        top3: "",
        bottom1: "",
        bottom2: "",
        bottom3: "",
        avgDeltaT: 0,
        topPileConditions: "",
        none: false,
        eyesOpen: false,
        peeping: false,
        buds: false,
        notes: "",
    }
    const editPass = edit

    const additionalBay = (e) => {
        e.preventDefault();
        return addBay(bayObject);
    }

    return (
        <>
            {bays.map((item, index) => (
                <Bay
                    key={index}
                    id={index}
                    item={item}
                    edit={editPass}
                />
            ))}
            <Button
                className="hideForPrint"
                style={{ fontWeight: "bold" }}
                onClick={additionalBay}
                variant="contained"
                color="primary"
                type="submit"
            >
                Add Bay
            </Button>
        </>
    )
}

export default BaySheet;