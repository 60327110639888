import React, { useEffect, useState, useContext } from "react"
import SheetsApi from "../../api"
import UserContext from "../../Users/UserContext";
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper,
    TextField, Typography, Box, Button
} from "@mui/material";
import Tech from "./Tech"
import useTechStore from "../../stores/techStore";
import useBaseStore from "../../stores/baseStore";

function TechList({ toggle, edit, timeTrack, chemApp }) {
    const chemAppPass = chemApp;
    const { currentUser } = useContext(UserContext)
    const [techs, setTechs] = useState({ managers: [], technicians: [], other: [] })
    const [renderCheck, setRenderCheck] = useState(false)
    const [calcValue, setCalc] = useState(0)
    let [technicians, addTech] = useTechStore(state =>
        [state.techs, state.addTech])
    const [setBaseForm] = useBaseStore(state =>
        [state.setBaseForm])
    const timeTrackPass = timeTrack

    useEffect(() => {
        async function getTechs() {
            try {
                if (edit && currentUser.account_level === 'Admin') {
                    return await SheetsApi.getInactive()
                } else {
                    return await SheetsApi.getAllUsers()
                }
            } catch (err) {
                console.log(err)
            }
        }
        getTechs().then(techs => {
            const categorizedUsers = { managers: [], technicians: [], other: [] };
            techs.forEach(tech => {
                if (tech.account_level === 'Manager') categorizedUsers.managers.push(tech);
                if (tech.account_level === 'Technician') categorizedUsers.technicians.push(tech);
                else if (tech.account_level === 'Admin' || tech.account_level === 'Regional Admin') {
                    categorizedUsers.other.push(tech);
                }
            })
            setTechs(categorizedUsers);
            setRenderCheck(true);
        })
    }, [])

    const additionalTech = (e) => {
        e.preventDefault()
        addTech()
    }
    const calculator = function () {
        let total = 0
        technicians.forEach(tech => {
            let labor = tech.labor
            let travel = tech.travel
            if (labor === "") {
                labor = 0
            }
            if (travel === "") {
                travel = 0
            }
            labor = parseFloat(tech.labor) || 0
            travel = parseFloat(tech.travel) || 0
            let newTotal = labor + travel
            total += newTotal
        })
        setCalc(total);
        return setBaseForm("total", total)
    }

    return (
        <div>
            {renderCheck ? <>
                <Box mt={2} mb={2}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        Technician List
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {technicians && technicians.map((item, index) => (
                                    <Tech
                                        toggle={toggle}
                                        calculator={calculator}
                                        key={index}
                                        id={index}
                                        item={item}
                                        techs={techs}
                                        timeTrack={timeTrackPass}
                                        chemApp={chemAppPass || false}
                                    />
                                ))}
                                {!timeTrack &&
                                    <TableRow>
                                        <TableCell
                                            colSpan={1}
                                            rowSpan={1}
                                            style={{ display: "flex" }}
                                        >
                                            <Typography
                                                style={{ fontSize: "32px" }}
                                            >
                                                Total:
                                            </Typography>
                                            <TextField
                                                sx={{ width: "11ch" }}
                                                align="right"
                                                type="number"
                                                value={calcValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Box className="hideForPrint" mt={1}>
                            <Button
                                style={{ fontWeight: "bold" }}
                                onClick={additionalTech}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Add Technician
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </> : null}
        </div>
    )
}

export default React.memo(TechList);