import React from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';

function QuoteHeader({ refrigerationQuote, gellertQuote, builderSizes, bidBase, totals, noVentilation }) {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
        },
        container2: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        divider: {
            borderBottom: 2,
            borderColor: 'black',
            flexGrow: 2,
            marginTop: 2,
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '2px solid',
            fontWeight: 'bold'
        },
        smallerText: {
            marginLeft: 250,
            fontSize: 10,
        },
        title: {
            marginTop: 10,
            marginLeft: 10,
            color: '#e71b2c',
            fontWeight: 'bold',
        },
        headerContainer: { flexGrow: 1 },
        addressContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        customerInfo: {
            marginLeft: 120,
            display: 'flex'
        },
        date: {
            marginTop: 60,
            marginRight: 32,
            fontWeight: 'bold',
        },
        proposal: {
            fontSize: 32,
            marginRight: 20,
        },
        total: {
            fontSize: 20,
            marginLeft: 360,
        },
        divider2: {
            display: 'inline',
            borderBottom: 2,
            borderColor: 'black',
            marginTop: 2,
            marginBottom: 10,
            marginRight: '10px',
            borderTop: '2px solid',
            fontWeight: 'bold'
        },
        bold: {
            fontWeight: 700,
            textDecoration: 'underline',
            fontStyle: 'bold'
        },
        red: { color: 'red' },
    })

    return (
        <View>
            <View style={styles.container}>
                <Text>Submitted To:   {bidBase.customer}</Text>
                <Text style={styles.proposal}>
                    {gellertQuote ? "Gellert Order Form" : builderSizes ? "Framed Openings" : "Proposal"}
                </Text>
            </View>
            <View style={styles.addressContainer}>
                <View style={styles.customerInfo}>
                    {gellertQuote ?
                        <>
                            <Text>Submitted By: {bidBase.creator}</Text>
                            <Text>Job Name: {bidBase.jobName}</Text>
                            <Text>Delivery Date:</Text>
                        </> : <>
                            <Text>{bidBase.jobName}</Text>
                            <Text>ATTN: {bidBase.contact} </Text>
                            <Text>{bidBase.address}</Text>
                            <Text>{bidBase.city} {bidBase.state !== "" && bidBase.state + ","} {bidBase.zip}</Text>
                        </>}
                </View>
                <Text style={styles.date}>{bidBase.dateServiced}</Text>
            </View >
            <View style={styles.divider2} />
            {
                gellertQuote ? <>
                    <Text>
                        <Text style={styles.bold}>One</Text> lot of equipment to consist of the following:
                    </Text>
                    <View style={styles.container}>
                        <Text> </Text>
                    </View>
                </> : <>
                    {!builderSizes && <>
                        <Text>
                            Thank you for the opportunity to provide you with a proposal for your storage needs.
                        </Text>
                        <Text>
                            Our proposal includes installation labor and the equipment described below.
                        </Text>
                    </>}
                    <View style={styles.container2}>
                        <Text> </Text>
                        {!noVentilation && <>
                            {
                                bidBase.selectWeight === 'cwt' ?
                                    <Text>{bidBase.cwt} CWT Storage</Text> :
                                    <Text>{bidBase.tons} TON Storage</Text>
                            }
                            {refrigerationQuote ? <>
                                <Text>{bidBase.buildingType}</Text>
                                <Text>{totals.totalCfm} CFM @ {bidBase.staticPressure}"sp</Text>
                                <Text>{totals.actualCfm} CFM / TON</Text>
                                <Text>
                                    <Text style={styles.bold}>One</Text> lot of equipment to consist of the following:
                                </Text>
                                <Text> </Text>
                                <Text style={styles.bold}>Refrigeration:</Text>
                            </> : <>
                                <Text>{totals.actualCfm} CFM / TON</Text>
                                {!builderSizes && <>
                                    <Text>Equipment sized for {Math.floor((totals.fanSpeed / 100) * totals.totalCfm)} CFM at {totals.cfmRequested} CFM / TON</Text>
                                    <Text>At the fan speed of
                                        <Text style={styles.red}> {totals.fanSpeed}%</Text>
                                    </Text>
                                </>}
                                <Text> </Text>
                            </>}
                        </>}
                    </View>
                </>
            }
        </View>
    )
}

export default QuoteHeader;