import React, { useContext, useEffect, useState, useRef } from "react";
import BaseSheet from "../SheetComponents/BaseSheet"
import PreSeasonBase from "./PreSeasonBase";
import {
    Box, Grid, Typography, Card, CardContent, TextareaAutosize,
} from "@mui/material"
import PartsSheet from "../SheetComponents/PartsSheet";
import usePreSeasonStore from "../../stores/preSeasonStore";
import usePartsStore from "../../stores/partsStore";
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import useImageStore from "../../stores/imageStore";
import UserContext from "../../Users/UserContext";
import { useHistory, useParams } from "react-router-dom"
import SheetsApi from "../../api";
import TechSheet from "../SheetComponents/TechSheet";
import { useTheme } from "@mui/material/styles"
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import StageSelector from "../SheetComponents/StageSelector";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import useSettingsStore from "../../stores/settingsStore";
import useNotification from "../../Hooks/useNotification";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function PreSeasonEdit() {
    const componentRef = useRef()
    const { id } = useParams()
    const [toggle, setToggle] = useState(true)
    const messanger = useNotification()
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home]);
    const [preSeasonForm, setPreSeasonForm, setPreSeasonFormObj,
        clearPreSeasonForm] = usePreSeasonStore(state => (
            [state.preSeasonForm, state.setPreSeasonForm, state.setPreSeasonFormObj,
            state.clearPreSeasonForm]))
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj])
    const [partSheet, clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.parts, state.clearPartsForm, state.setPartsObj])
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj])
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages])
    const [logs, setLogs] = useState([]);
    const [renderCheck, setRenderCheck] = useState(false)
    const [renderCheckPart, setRenderCheckPart] = useState(false)
    const [renderCheckTech, setRenderCheckTech] = useState(false)
    const { currentUser } = useContext(UserContext)
    const history = useHistory()
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setRenderCheck(true)
        clearBaseForm()
        clearPartsForm()
        clearPreSeasonForm()
        clearImages()
    }, [])

    const handleSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const preSeasonObj = CreateSubmitObj(
            preSeasonForm, currentUser, messanger,
            baseSheet, partSheet, techSheet)
        if (preSeasonObj.error) return setIsSubmitting(false);
        console.log(preSeasonObj)
        preSeasonObj.id = id
        preSeasonObj.sheet.sheetType = "preseason"
        HandleImage.addAndRemove(images, preSeasonObj)
        await SheetsApi.editPreSeasonSheet(preSeasonObj).then((response) => {
            messanger(response.message, "success")
            getNotifications(currentUser)
        })
        setRenderCheck(false)
        setRenderCheckPart(false)
        setRenderCheckTech(false)
        clearTechForm()
        clearBaseForm()
        clearPartsForm()
        clearPreSeasonForm()
        clearImages()
        history.push(home);
        return setIsSubmitting(false)
    }
    const handleToggle = () => {
        setToggle(!toggle)
    }
    useEffect(() => {
        setToggle(true)
        clearPreSeasonForm()
        clearTechForm()
        clearPartsForm()
        clearBaseForm()
        async function getPreSeasonSheet() {
            try {
                const preSeasonSheet = await SheetsApi.getPreSeasonSheet({ id })
                const images = preSeasonSheet.images
                const parts = await SheetsApi.getPartsById({ id, type: "preSeason_id" })
                const techs = await SheetsApi.getTechsById({ id, type: "preSeason_id" })
                const locationId = preSeasonSheet.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                preSeasonSheet.location = locationName.location_name
                preSeasonSheet.locationId = locationId
                setLogs(preSeasonSheet.logs)
                return { preSeasonSheet, parts, techs, images }
            } catch (err) {
                console.log(err)
            }
        }
        getPreSeasonSheet().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'preSeasonSheet') {
                    const split = sheet[section]
                    let baseFormObj = {
                        company: split.company,
                        creator: split.creator,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                        po: split.po,
                        requestedBy: split.request_by,
                        dateCreated: split.dateCreated,
                        locationId: split.locationId,
                        stage: split.stage,
                        dept: split.dept,
                    }

                    setBaseFormObj(baseFormObj)
                    setPreSeasonFormObj({ ...split })

                } else if (section === 'parts') {
                    let partsArry = []
                    for (let obj of sheet[section]) {
                        let partObj = {
                            qty: obj.quantity,
                            type: obj.quantity_type,
                            part: obj.part_number,
                            description: obj.description,
                            cost: obj.cost,
                            isMisc: obj.ismisc,
                            location: obj.location,
                            name: obj.name,
                            invoice: obj.invoice,
                            po: obj.po
                        }
                        partsArry.push(partObj)
                    }
                    setPartsObj(partsArry)
                    setRenderCheckPart(true)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes
                        }
                        setTechObj(techObj, id)
                    }
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        if (img.imageType === 'img' || img.imageType === 'receipt') {
                            addImage(imageObj)
                        }
                    }
                }
            }
            setRenderCheckTech(true)
        })
    }, [])
    const handleChange = function (e) {
        const name = e.target.name
        let value = e.target.value
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        }
        setPreSeasonForm(name, value)
    }

    return (
        <div ref={componentRef} >
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <div className="page">
                            <Box m={2}>
                                {renderCheck && <BaseSheet id={id} title="Pre-Season" />}
                                <PreSeasonBase />
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{
                                        backgroundColor: primary,
                                        "@media print": {
                                            backgroundColor: primary,
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        ml={1}
                                        style={{ color: defaultColor }}>
                                        Notes
                                    </Typography>
                                </Box>
                                <Box ml={0.25}>
                                    <TextareaAutosize
                                        minRows={5}
                                        style={{
                                            width: "99.0%",
                                            outline: '2px solid #000',
                                            fontSize: "16px",
                                        }}
                                        name="notes"
                                        value={preSeasonForm.notes || ""}
                                        onChange={handleChange}
                                    />
                                </Box>
                                {renderCheckPart && <PartsSheet toggle={toggle} />}
                                {renderCheckTech && <TechSheet edit={true} toggle={toggle} />}
                            </Box>
                            <Box m={1}>
                                <FollowUp
                                    state={preSeasonForm}
                                    setState={handleChange}
                                />
                            </Box>
                            <Box ml={2}>
                                <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                                    <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                        <CardContent>
                                            <Box mt={1} display="flex" justifyContent="left" >
                                                <Box>
                                                    <StageSelector
                                                        currentUser={currentUser}
                                                        handleChange={handleChange}
                                                        value={preSeasonForm.stage}
                                                    />
                                                    <DeptSelect />
                                                    <SubmitAndPrint
                                                        isSubmitting={isSubmitting}
                                                        type="preseason"
                                                        componentRef={componentRef}
                                                        showToggle={true}
                                                        toggle={toggle}
                                                        handleToggle={handleToggle}
                                                    />
                                                </Box>
                                                {!currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="preseason" /> : null}
                                            </Box>
                                            {currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="preseason" /> : null}
                                        </CardContent>
                                    </Card>
                                    {logs.length > 0 &&
                                        <HistoryCard logs={logs} />
                                    }
                                </Box>
                            </Box>
                        </div>
                    </Grid >
                </Grid >
            </form >
        </div >
    )
}

export default PreSeasonEdit