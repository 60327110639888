import React from "react";
import {
    Text, View, StyleSheet
} from '@react-pdf/renderer';

function BidRefCondenser({ condenserData }) {

    const styles = StyleSheet.create({
        partContainer: {
            marginLeft: 50
        },
        subPartContainer: {
            marginLeft: 50,
        }
    });

    return (
        <>
            <View style={styles.partContainer}>
                <Text>
                    {condenserData.qty} - {condenserData.partNumber} {condenserData.description}
                </Text>
                <View style={styles.subPartContainer}>
                    <Text> - Design: {condenserData.ambientTemp}° ambient {condenserData.suctionTemp}° suction </Text>
                    <Text> - Capacity: {condenserData.capacity} Tons</Text>
                    {/* <Text> - Ratio: {(bidBase.tons / condenserData.capacity).toFixed(2)}</Text> */}
                    <Text> - {condenserData.refrigerant} refrigerant</Text>
                    <Text> - {condenserData.voltage} Volt 3P 60Hz / {condenserData.amps} Amp MCA</Text>
                    <Text> - Liquid line replaceable filter / dryer</Text>
                    <Text> - Suction line replaceable filter / dryer</Text>
                    <Text> - Phase monitor</Text>
                    <Text> - High pressure alarm</Text>
                    <Text> - Oil fail alarm</Text>
                    <Text> - Suction accumulator</Text>
                    <Text> - {condenserData.unloader}-Step unloading per compressor</Text>
                </View>
                <Text>

                </Text>
            </View>
        </>
    )
}

export default BidRefCondenser;