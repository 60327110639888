import React, { useContext } from "react";
import UserContext from "../../Users/UserContext";
import { NavLink } from "react-router-dom";
import { Box, Typography, Divider, } from "@mui/material"
import { useTheme } from '@mui/material/styles';

function ReceiptMenu({ handleDrawerClose }) {
    const { currentUser } = useContext(UserContext);
    const theme = useTheme();
    const defaultStyle = { textDecoration: 'none', color: "#004e7c" }
    const gray = theme.palette.default.secondary;
    return (
        <>
            {currentUser.dept === 'admin' ?
                <NavLink to="/receipts" style={defaultStyle}>
                    <Box
                        onClick={handleDrawerClose}
                        sx={{
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'space-between',
                            m: 2,
                        }}>
                        <Typography variant="h6">Receipts</Typography>
                    </Box>
                </NavLink >
                : null
            }
            <Divider sx={{ borderColor: gray }} />
        </>)
}

export default ReceiptMenu;