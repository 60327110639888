import React from "react";
import {
    Text, View, StyleSheet,
} from '@react-pdf/renderer';

function SqFtCompare({ data }) {
    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '100%',
            borderBottom: 1,
        },
        header: { borderTop: 'none' },
        qtyRow: { width: '10%', fontSize: '12px' },
        descriptionRow: { width: '75%', fontSize: '12px' },
        priceRow: {
            marginLeft: '20px',
            width: '15%',
            fontSize: '12px',
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        flexDirection: { flexDirection: 'column' },
        smallText: { fontSize: '14px' }
    })
    return (
        <>
            <View style={[styles.container, styles.flexDirection]}>
                {data.sqFtReq &&
                    <Text style={[styles.smallText, styles.margineTop]}>
                        Total Sq. Ft. Required: {data.sqFtReq}
                    </Text>
                }
                <Text style={[styles.smallText, styles.margineTop]}>Total Sq. Ft. {data.sqFtTotal}</Text>
            </View>
            <View style={[styles.row, styles.header]}>
                <Text style={styles.qtyRow}>Qty</Text>
                <Text style={styles.descriptionRow}>Description</Text>
            </View>
        </>
    )
}

export default SqFtCompare;