import React, { useEffect } from "react";
import {
    Card, Box, Typography, TextField, InputAdornment,
    FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import useFields from "../../../../Hooks/useFields";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import useBidRefrigerationStore from "../../../../stores/BidStore/bidRefrigerationStore";
import commas from "../../BidFunctions/commas"
function BidRefCondenser({ condenserData, edit }) {
    const INITIAL_STATE = {
        qty: "",
        partNumber: "",
        description: "",
        price: "",
        ambientTemp: "",
        suctionTemp: "",
        capacity: "",
        refrigerant: "",
        amps: "",
        voltage: 240,
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [bidBase] = useBidStoreMain(state => [state.bidBase])
    const [setPart, setObj] = useBidRefrigerationStore(state =>
        [state.setPart, state.setObj])

    useEffect(() => {
        const qty = parseFloat(formData.qty) || 0;
        const price = parseFloat(formData.price) || 0;
        const total = parseFloat((qty * price).toFixed(2)) || 0;
        handleLocal("invalidInput", "total", total);
        setPart("condenser", "total", total);
    }, [formData.qty, formData.price])

    useEffect(() => {
        if (edit && condenserData) {
            setObj("condenser", condenserData)
            handleLocal("replaceObj", condenserData)
        }
    }, [])

    const handleGlobalState = function (e) {
        setPart('condenser', e.target.name, e.target.value)
    }

    return (
        <>
            <Box m={1}>
                <Card>
                    <Box m={2} display="flex" justifyContent="left">
                        <Typography sx={{ textDecoration: 'underline' }} ml={4} variant="h5">
                            Condensing Unit
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="left" m={1}>
                        <Box display="inline">
                            <TextField
                                name="qty"
                                id="qty"
                                label="Quantity"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: "10ch" }}
                                value={formData.qty || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="partNumber"
                                id="partNumber"
                                label="Part Number"
                                sx={{ width: "28ch" }}
                                value={formData.partNumber || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="description"
                                id="description"
                                label="Description"
                                sx={{ width: "61ch" }}
                                value={formData.description || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="price"
                                id="price"
                                label="Dealer Price"
                                type="number"
                                sx={{ width: "12ch" }}
                                value={formData.price || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="total"
                                id="total"
                                label="Total"
                                variant="standard"
                                sx={{ width: "12ch" }}
                                value={commas.add(formData.total) || ""}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment
                                            position="start">
                                            $
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box mb={2} ml={1} display="flex" justifyContent="left">
                        <Box mr={1} display="inline">
                            <Typography sx={{ fontSize: 30 }}>
                                Design:
                            </Typography>
                        </Box>
                        <Box display="inline">
                            <TextField
                                name="ambientTemp"
                                id="ambientTemp"
                                label="Ambient"
                                type="number"
                                sx={{ width: "13ch" }}
                                value={formData.ambientTemp || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            °F
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="suctionTemp"
                                id="suctionTemp"
                                label="Suction"
                                type="number"
                                sx={{ width: "13ch" }}
                                value={formData.suctionTemp || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            °F
                                        </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <TextField
                                name="capacity"
                                id="capacity"
                                label="Capacity"
                                type="number"
                                sx={{ width: "13ch" }}
                                value={formData.capacity || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline" >
                            <TextField
                                name="ratio"
                                id="ratio"
                                label="Ratio"
                                variant="standard"
                                sx={{ width: "13ch" }}
                                value={(bidBase.tons / formData.capacity).toFixed(2) || ""}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <FormControl>
                                <InputLabel>Refrigerant</InputLabel>
                                <Select
                                    inputlabelprops={{ shrink: true }}
                                    id="refrigerant"
                                    name="refrigerant"
                                    label="Refrigerant"
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    value={formData.refrigerant || ""}
                                    sx={{ input: { background: "white" }, width: "18ch" }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="R407A">
                                        R407A
                                    </MenuItem>
                                    <MenuItem value="R404A">
                                        R404A
                                    </MenuItem>
                                    <MenuItem value="R407C">
                                        R407C
                                    </MenuItem>
                                    <MenuItem value="R22">
                                        R22
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box ml={1}>
                            <TextField
                                name="amps"
                                id="amps"
                                label="Amp MCA"
                                type="number"
                                sx={{ width: "13ch" }}
                                value={formData.amps || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobalState}
                            />
                        </Box>
                        <Box ml={1} display="inline">
                            <FormControl>
                                <InputLabel>Voltage</InputLabel>
                                <Select
                                    inputlabelprops={{ shrink: true }}
                                    id="voltage"
                                    name="voltage"
                                    label="Voltage"
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    defaultValue={240}
                                    value={formData.voltage || ""}
                                    sx={{ input: { background: "white" }, width: "12ch" }}
                                >
                                    <MenuItem value={240}>
                                        240V
                                    </MenuItem>
                                    <MenuItem value={480}>
                                        460V
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box ml={1} display="inline">
                            <FormControl>
                                <InputLabel>Unloader</InputLabel>
                                <Select
                                    inputlabelprops={{ shrink: true }}
                                    id="unloader"
                                    name="unloader"
                                    label="unloader"
                                    onChange={handleLocal}
                                    onBlur={handleGlobalState}
                                    defaultValue={240}
                                    value={formData.unloader || ""}
                                    sx={{ input: { background: "white" }, width: "12ch" }}
                                >
                                    <MenuItem value={1}>
                                        1-Step
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        2-Step
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        3-Step
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default BidRefCondenser;

