import React, { useEffect } from "react";
import SelectInput from "../../Sheets/SheetComponents/SelectInput";
import {
    Card, CardHeader, Box, List, ListItem, useTheme, CardContent,
    CardActions, Button, Divider, TextField,
} from "@mui/material";
import useFields from "../../Hooks/useFields";

function UserEdit({ selectedUser, companies, handleGlobal, resetPassword, submitUpdate }) {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const defaultColor = theme.palette.default.main;
    const [formData, handleLocal] = useFields({})

    useEffect(() => {
        handleLocal("invalidInput", "email", selectedUser.email);
    }, [selectedUser])

    return (<>
        <Card sx={{ width: '100%', bgcolor: defaultColor, paddingBottom: 2 }}>
            <Box display="flex" justifyContent="space-between">
                <CardHeader
                    sx={{ color: background }}
                    title={`${selectedUser.first_name + " " + selectedUser.last_name}'s account settings`}
                />
                <Box m={3}>
                    <CardActions>
                        <Button onClick={() => resetPassword(selectedUser.username)} variant="contained">
                            Reset Password
                        </Button>
                    </CardActions>
                </Box>
            </Box>
            <Divider sx={{ ml: 2, mr: 2, bgcolor: background }} />
            <CardContent>
                <List>
                    <Box display='flex' justifyContent="space-between">
                        <ListItem sx={{ color: background }}>
                            <SelectInput
                                darkTheme={true}
                                edit={true}
                                editData={selectedUser.company}
                                label="Company"
                                name="company"
                                options={companies}
                                setGlobal={handleGlobal}
                            />
                        </ListItem>
                        <Box mt={1}>
                            <TextField
                                name="email"
                                id="email"
                                label="Email"
                                value={formData.email || ""}
                                onChange={handleLocal}
                                onBlur={handleGlobal}
                                sx={{
                                    width: "300px",
                                    "& .MuiInputLabel-root": {
                                        color: background,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: background,
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "white",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "white",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        color: background,
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <ListItem>
                        <SelectInput
                            darkTheme={true}
                            edit={true}
                            editData={selectedUser.dept}
                            label="Department"
                            name="dept"
                            options={['service', 'chemApps', 'labs', 'sales', 'admin']}
                            setGlobal={handleGlobal}
                        />
                    </ListItem>
                    <Box display='flex' justifyContent="space-between">
                        <ListItem>
                            <SelectInput
                                darkTheme={true}
                                edit={true}
                                editData={selectedUser.account_level}
                                label="Account Level"
                                name="account_level"
                                options={['Admin', 'Regional Admin', 'Manager', 'Technician', 'Inactive']}
                                setGlobal={handleGlobal}
                            />
                        </ListItem>
                        <Box mr={3} mt={3}>
                            <Button onClick={submitUpdate} variant="contained">
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </List>
            </CardContent>
        </Card>
    </>)
}

export default UserEdit;