import React, { useEffect, useContext } from 'react';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Box } from "@mui/material";
import UserContext from '../../../Users/UserContext';

function CalendarDisplay({ events, refresh, cals, setPrimaryCal }) {
    const { currentUser } = useContext(UserContext)
    useEffect(() => {
        const calendarEl = document.getElementById('calendar');
        const footerButtons = createFooterButtons();
        const footerString = Object.keys(footerButtons).join(' ');
        const calendar = new Calendar(calendarEl, {
            height: currentUser.mobile ? 800 : 'auto',
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            initialView: currentUser.mobile ? 'listWeek' : 'dayGridMonth',
            events: events,
            headerToolbar: {
                left: currentUser.mobile ? 'title,refresh' : 'refresh dayGridMonth,dayGridWeek,listMonth',
                center: currentUser.mobile ? '' : 'title',
                right: 'prev,next today',
            },
            footerToolbar: {
                center: footerString
            },
            customButtons: {
                refresh: {
                    text: 'Refresh',
                    click: () => refresh(),
                },
                ...footerButtons,
            },
        });
        calendar.render();
    }, [events]);

    const createFooterButtons = function () {
        function removeSpaces(str) {
            return str.replace(/\s+/g, '');
        }
        const footerButtons = {};
        cals.forEach((cal) => {
            const buttonName = removeSpaces(cal.name);
            footerButtons[buttonName] = {
                text: cal.name,
                click: () => setPrimaryCal(cal.name)
            };
        });

        return footerButtons;
    }

    return (<>
        <style>
            {`
                .fc-daygrid-day-frame {
                    height: 150px;
                    overflow-y: auto;
            }
                .fc-daygrid-event {
                    white-space: nowrap;
            }`}
        </style>
        <Box m={1} id="calendar"></Box>
    </>)
}

export default CalendarDisplay;