import React, { useEffect } from "react";
import useFields from "../../../../Hooks/useFields";
import { Box, TextField, InputAdornment } from "@mui/material";
import useBidStoreMisc from "../../../../stores/BidStore/bidStoreMisc";
import Commas from "../../BidFunctions/commas";
function HardwareLine({ category, id, edit, miscData, handleMiscCalc }) {
    const INITIAL_STATE = {
        qty: "",
        partNumber: "",
        description: "",
        cost: "",
        total: "",
    }
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE);
    const [parts, setPart, setObj] = useBidStoreMisc(state =>
        [state[category], state.setPart, state.setObj])
    const localPart = parts[id];

    const handleGlobalState = function (e) {
        let value;
        const name = e.target.name;
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setPart(category, name, value, id)
    }

    useEffect(() => {
        resetFormData()
        if (edit && miscData[id]) {
            handleLocal("replaceObj", miscData[id]);
            setObj(category, miscData[id], id);
        }
    }, [])

    useEffect(() => {
        handleMiscCalc()
    }, [localPart])

    useEffect(() => {
        const qty = formData.qty || 0;
        const cost = formData.cost || 0;
        const total = qty * cost;
        handleLocal("invalidInput", "total", total);
        setPart("hardware", "priceTotal", total, id);
    }, [formData.qty, formData.cost])

    return (
        <>
            <Box m={1}>                
                <Box display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="partNumber"
                        id="partNumber"
                        label="Part Number"
                        sx={{ width: "25ch" }}
                        value={formData.partNumber || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        sx={{ width: "62ch" }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="cost"
                        id="cost"
                        label="Cost"
                        type="number"
                        sx={{ width: "13ch" }}
                        value={formData.cost || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                        }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "13ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default HardwareLine;