import React, { useEffect, useState } from "react";
import {
    Page, Document, StyleSheet, PDFViewer, Font, Text, Image,
} from '@react-pdf/renderer';
import QuoteHeader from "./QuoteComponents/QuoteHeader";
import useBidQuoteStore from "../../../stores/BidStore/bidQuoteStore";
import useBidStoreMain from "../../../stores/BidStore/bidStoreMain";
import useBidRefrigerationStore from "../../../stores/BidStore/bidRefrigerationStore";
import BidRefCondenser from "./RefrigerationQuoteComponents/BidRefCondenser";
import BidRefCooler from "./RefrigerationQuoteComponents/BidRefCooler";
import BidRefAgreement from "./RefrigerationQuoteComponents/BidRefAgreement";
import useImageStore from "../../../stores/imageStore";
import useBidStoreMisc from "../../../stores/BidStore/bidStoreMisc";
import SheetsApi from "../../../api";
import useBidPartsStore from "../../../stores/BidStore/bidPartsStore";
import BidQuoteFunctions from "../BidFunctions/BidQuoteFunctions";
import QuoteTable from "./QuoteComponents/QuoteTable";
import asCoHeader from "../../../images/BidHeaders/asCoHeader.jpg";
import asNwHeader from "../../../images/BidHeaders/asNwHeader.jpg";
import asTfHeader from "../../../images/BidHeaders/asTfHeader.jpg";
import gellertHeader from "../../../images/BidHeaders/gellertHeader.jpg";

function BidRefrigerationQuote() {
    const [grandTotal, otherTotal, add, clear] = useBidQuoteStore(state =>
        [state.total, state.otherRefrigerationTotal, state.add, state.clear])
    const [bidBase, totals] = useBidStoreMain(state =>
        [state.bidBase, state.totals])
    const [cooler, condenser] = useBidRefrigerationStore(state =>
        [state.cooler, state.condenser])
    const [signature, signature2] = useImageStore(state => [state.signature, state.signature2]);
    const [miscTotals, option] = useBidStoreMisc(state => [state.refrigerationTotals, state.option])
    const [refrigerationParts] = useBidPartsStore(state => [state.refrigeration])
    const [pages, setPages] = useState([]);
    const noVentilation = bidBase.noVentilation;
    const styles = StyleSheet.create({
        page: {
            margin: 10,
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 30,
            paddingBottom: 50,
            flexGrow: 1,
            fontSize: 12,
            fontFamily: 'Roboto'
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginRight: '10px',
            flexDirection: 'row',
        },
        image: { width: '111.2%', height: '20%', marginTop: '-40px', left: '-40px' },
    });

    useEffect(() => {
        const getOverHead = async function () {
            const settings = await SheetsApi.getSettings()
            return settings[0].value
        }
        getOverHead().then((overHead) => {
            clear();
            const refinedOptionsArray = []
            for (let obj of option) {
                obj.refrigeration && refinedOptionsArray.push(obj)
            }
            let allTables = [
                refrigerationParts[0].partNumber ?
                    { title: 'Refrigeration Misc. Install Components:', data: refrigerationParts, skipQtyCalc: false } : null,
                { title: "Condenser", data: [condenser], skipQtyCalc: false },
                { title: "Cooler", data: [cooler], skipQtyCalc: false },
                refinedOptionsArray.length > 0 ? { title: "Options:", data: refinedOptionsArray } : null,
            ].filter((item) => item !== null)

            const allTables2 = BidQuoteFunctions.setProfitAndMargin(allTables, overHead, bidBase, add)
            const refrigerationPartsTotal = parseFloat((allTables2.total).toFixed(2))
            BidQuoteFunctions.getTaxLaborMaterialsPrice(refrigerationPartsTotal, miscTotals, overHead, bidBase, add, true)
            setPages(BidQuoteFunctions.tableToPageCalc(allTables2.tables, noVentilation, true))
        })
    }, [])

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf', fontWeight: 700, fontStyle: 'bold' },
        ],
    })

    const selectHeader = function () {
        const company = bidBase.company
        if (company === 'Agri-Stor Companies') return asTfHeader;
        else if (company === 'Agri-Stor Company North West') return asNwHeader;
        else if (company === 'Agri-Stor Company Colorado') return asCoHeader;
        else if (company === 'The Gellert Company') return gellertHeader;
        else return null;
    }

    return (
        <>
            <PDFViewer width={1200} height={1200}>
                <Document>
                    {pages.map((page, index) => {
                        return (
                            <Page key={index} wrap size="A4" style={styles.page}>
                                {index === 0 && (<>
                                    <Image style={styles.image} src={selectHeader()} />
                                    <QuoteHeader
                                        refrigerationQuote={true}
                                        bidBase={bidBase}
                                        totals={totals}
                                        noVentilation={noVentilation}
                                    />
                                    <BidRefCondenser
                                        bidBase={bidBase}
                                        condenserData={condenser}
                                    />
                                    <Text> </Text>
                                    <BidRefCooler coolerData={cooler} />
                                    <Text> </Text>
                                </>)}
                                {page.map((obj) => {
                                    if (obj.table.data.length !== 0) {
                                        return (
                                            <QuoteTable
                                                key={obj.id}
                                                bidBase={bidBase}
                                                title={obj.table.title}
                                                data={obj.table.data}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Page>
                        );
                    })}
                    <Page wrap size="A4" style={styles.page}>
                        <BidRefAgreement
                            bidBase={bidBase}
                            signature={signature}
                            signature2={signature2}
                            otherTotal={otherTotal}
                            grandTotal={grandTotal}
                        />
                    </Page>
                </Document>
            </PDFViewer>
        </>
    )
}

export default BidRefrigerationQuote;