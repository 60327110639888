import React, { useEffect } from "react";
import useBidStoreSub from "../../../../stores/BidStore/bidStoreSub";
import { shallow } from "zustand/shallow";
import {
    Box, TextField, InputAdornment,
} from "@mui/material"
import useFields from "../../../../Hooks/useFields";
import SearchableInput from "../../../SheetComponents/SearchableInput";
import Commas from "../../BidFunctions/commas";
function LightBlockPart({ id, localLightBlock, handleLightBlockTotal, edit, lightBlockData }) {
    const editPass = edit;
    const [formData, handleLocal, resetFormData] = useFields({ qty: 0, height: 0 });
    const [dampers, lightBlock, setLightBlock, setObj] = useBidStoreSub(state =>
        [state.damper, state.lightBlock, state.setPart, state.setObj], shallow)

    useEffect(() => {
        const width = 24
        const height = parseFloat(formData.height) || 0;
        const qty = parseFloat(formData.qty) || 0;
        const price = parseFloat(formData.price) || 0;
        if (!isNaN(height)) {
            const sqFt = ((width * height / 144).toFixed(1))
            handleLocal("invalidInput", "sqFt", sqFt)
            if (!isNaN(qty)) {
                const totalSqFt = parseFloat((sqFt * qty).toFixed(1))
                const total = parseFloat((price * totalSqFt).toFixed(2))
                handleLocal("invalidInput", "sqFtTotal", totalSqFt)
                handleLocal("invalidInput", "total", total)
                setLightBlock("lightBlock", "priceTotal", total, id)
                setLightBlock("lightBlock", "totalSqFt", totalSqFt, id)
            }
        }
    }, [formData.qty, formData.height, formData.price])

    useEffect(() => {
        handleLightBlockTotal()
    }, [lightBlock[id]])

    useEffect(() => {
        resetFormData()
        if (edit && lightBlockData[id]) {
            handleLocal("replaceObj", lightBlockData[id])
            setObj("lightBlock", lightBlockData[id], id)
        }
    }, [])

    useEffect(() => {
        if (dampers[id]) {
            handleLocal("invalidInput", "height", dampers[id].height)
            setLightBlock("lightBlock", "height", dampers[id].height)
        }
    }, [dampers[id]?.height])

    useEffect(() => {
        setLightBlock("lightBlock", "partNumber", formData.partNumber, id)
    }, [formData.partNumber])

    const handleGlobalState = function (e) {
        setLightBlock("lightBlock", e.target.name, e.target.value, id)
    }
    return (
        <>
            <Box m={1}>
                <Box ml={1} display="inline">
                    <TextField
                        name="qty"
                        id="qty"
                        label="Quantity"
                        type="number"
                        sx={{ width: "10ch" }}
                        value={formData.qty || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <SearchableInput
                        edit={editPass}
                        editData={lightBlockData?.[id]?.partNumber}
                        setState={handleLocal}
                        variableName="partNumber"
                        searchableArray={localLightBlock}
                        label="Part Number"
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="width"
                        id="width"
                        label="Width"
                        variant="standard"
                        type="number"
                        sx={{ width: "14ch" }}
                        value={24}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="height"
                        id="height"
                        label="Height"
                        type="number"
                        sx={{ width: "14ch" }}
                        value={formData.height || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="sqFt"
                        id="sqFt"
                        label="Sq. Ft. Per Louver"
                        variant="standard"
                        sx={{ width: "17ch" }}
                        value={formData.sqFt || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="sqFtTotal"
                        id="sqFtTotal"
                        label="Total Sq. Ft."
                        variant="standard"
                        sx={{ width: "14ch" }}
                        value={formData.sqFtTotal || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box ml={1} mr={1} display="inline">
                    <TextField
                        name="price"
                        id="price"
                        label="Dealer Price"
                        type="number"
                        sx={{ width: "14ch" }}
                        value={formData.price || ""}
                        onChange={handleLocal}
                        onBlur={handleGlobalState}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box display="inline">
                    <TextField
                        name="total"
                        id="total"
                        label="Total"
                        variant="standard"
                        sx={{ width: "14ch" }}
                        value={Commas.add(formData.total) || ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment:
                                <InputAdornment
                                    position="start">
                                    $
                                </InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}

export default LightBlockPart;