import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import Commas from "../../../BidFunctions/commas";

function VfdTotal({ kwhSaved, newPriceObj, bidBase }) {
    const [totalSavings, setTotalSavings] = useState(0);
    const [payBackYear, setPayBackYear] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const priceDifference = newPriceObj.priceTotal - newPriceObj.oldPriceTotal;
        setTotal(Commas.add(priceDifference));
        const savings = bidBase.energyCost * kwhSaved
        const savingsWithDemandSavings = parseFloat((savings + ((bidBase.demandSavings / 100) * savings) || 0).toFixed(2))
        setTotalSavings(Commas.add(savingsWithDemandSavings))
        const yearsToPayBack = parseFloat((priceDifference / savingsWithDemandSavings).toFixed(2))
        setPayBackYear(yearsToPayBack)
    }, [])

    const styles = StyleSheet.create({
        container: {
            margin: 10,
        },
        line: {
            paddingBottom: 8,
            paddingTop: 8,
            borderColor: 'black',
            width: '80%',
            borderBottomWidth: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        text: { display: 'flex', flexDirection: 'row' },
    })

    return (
        <View style={styles.container}>
            <View style={styles.line}>
                <Text>Energy Cost ($/kWh):</Text>
                <Text>${bidBase.energyCost}</Text>
            </View>
            <View style={styles.line}>
                <Text>Projected Annual Savings:</Text>
                <Text>${totalSavings}</Text>
            </View>
            <View style={styles.line}>
                <Text>Total Additional Cost:</Text>
                <Text>${total}</Text>
            </View>
            <View style={styles.line}>
                <Text>Payback (year):</Text>
                <Text>{payBackYear}</Text>
            </View>
        </View>
    )
}

export default VfdTotal;