import React from "react";
import { Typography } from "@mui/material";
function Terms() {
    return (
        <>
            <Typography sx={{ fontSize: '144x' }}>
                1. Customer and/or storer agrees that they have been warned that CIPC Potato Sprout
                Inhibitor should not be used on, around, or in the near proximity of potatoes that
                may be used as seed. Customer and/or storer does hereby release Agri-store Company
                from any liability for any damage to seed potatoes stored against this warning.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                2. Customer and/or storer agrees that there is a risk of fire during the application.
                An Aerosol Potato Sprout Inhibitor or Disinfectant will not cause a fire; however,
                there is a risk of fire damage during application not related to the product nor
                related to the method of application but generally due to methods and materials of
                construction of the storage, including but not limited to wiring and insulation.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                3. Customer and/or storer acknowledges that potatoes may deteriorate or be otherwise
                damaged by bacteria, fungi, disease, insects, aging effects such as internal sprouting,
                environmental factors (humidity, temperature, and air flow), and other causes, which may
                occur regardless of whether an Aerosol Potato Sprout Inhibitor or Disinfectant is or
                is not applied to the potatoes.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                4. Customer and/or storer acknowledges that the degree of sprout control or disinfection
                is affected by the physical condition of the potatoes, varietal characteristics, the air
                handling system and storage layout, environmental factors, the time not within the control
                of the applicator, including but not limited to electrical and/or other application
                equipment which may be provided by Agri-Store Company at the customer's request which
                may or may not be adequate for the application of Aerosol Potato Sprout Inhibitors
                or Disinfectants.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                5. Agri-Stor Company has warranted only that the product shall conform to the manufacturer's
                specifications according to the manufacturer's recommendations and has made no other
                representations or warranties, expressed or implied, and has not warranted that the
                product would be merchantable or that it would be suitable for any particular purpose,
                and disclaims any warranty of and liability for economic damage, including loss of profit.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                6. Agri-Stor Company is applying Aerosol Potato Sprout Inhibitor(s) according to the label and
                in accordance with applicable U.S. and state laws and regulations. Agri-Store Company, however,
                does not provide export consulting services and does not make any attempt to ensure compliance
                with any foreign laws or regulations, including but not limited to foreign maximum residue
                limits ("MRL") regulations associated with any particular pesticide. Thus, to the extent customer
                and/or storer intends to export its stored potatoes or other crops, customer and/or storer is
                advised to consult with appropriate third parties to ensure compliance with any foreign laws or
                regulations, including but not limited to foreign MRL regulations. <strong>Customer and/or storer
                    is solely responsible for ensuring compliance with applicable foreign laws or regulations as
                    they may apply to exported potatoes or other crops.</strong>
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                7. Customer and/or storer also agrees to release Agri-Stor Company, their respective successors,
                assigns, agents, representatives, and employees and to save and hold harmless the same from and
                against any and all suits or claims, in law or in equity, that may be alleged to have occurred
                in the course of the performance of this agreement by the applicator.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                8. Customer and/or storer, if not the owner of the aforesaid warehouse(s), shall advise said owner(s)
                of this notice prior to application.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                9. Agri-Store Company will assume <strong>NO</strong> liability if: the potatoes have had water and/or
                other substances applied to them either before or after application and/or if there is excessive
                sprouting (1/2" or greater) on potatoes.
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
                10. Payment shall be due to AGRI-STOR COMPANY according to the terms listed in the Invoice. All
                accounts not paid by the due date shall be charged a monthly finance charge of 1-1/2% of the unpaid
                balance per month. This is an annual percentage rate of 18% unless otherwise stated herein. In the
                event of non-payment by the customer and/or storer of any of the sums due under agreement, the
                customer and/or storer hereby agrees that in the event this agreement is placed with an attorney
                for collection or if suit is instituted for its collection, the customer and/or storer agrees to
                pay, in either case, reasonable attorney fees, together with the costs of the suit. <strong>Customer
                    and/or storer agrees that if the full amount due is not paid within 30 days, Agri-Stor Company
                    may file a lien against customer and/or storer's potatoes.</strong>
            </Typography>
        </>
    )
}

export default Terms;