import { create } from 'zustand'
const emptyFreshPack = {
    visitReason: "",
    currPpm: 0,
    lastVisit: "",
    membrane: "",
}

const emptyPump = {
    currSettings: "",
    barrelLevel: 0,
    gals: 0,
    comments: "",
}
const useFreshPackStore = create((set) => ({
    freshPackForm: emptyFreshPack,
    pump1: { ...emptyPump, id: 1 },
    pump2: { ...emptyPump, id: 2 },

    setFreshPackFrom: function (name, value) {
        set(prevState => ({
            ...prevState,
            freshPackForm: {
                ...prevState.freshPackForm,
                [name]: value,
            }
        }))
    },

    setFreshPackPump: function (id, name, value) {
        set(prevState => {
            const newPump = prevState[`pump` + id];
            newPump[name] = value;
            return { ...prevState, [`pump` + id]: newPump };
        })
    },

    setFreshPackObjs: function (freshPackForm, pump1, pump2) {
        set(prevState => {
            return { ...prevState, freshPackForm, pump1, pump2 }
        })
    },

    clearFreshPack: function () {
        set(prevState => ({
            ...prevState,
            freshPackForm: emptyFreshPack,
            pump1: { ...emptyPump, id: 1 },
            pump2: { ...emptyPump, id: 2 },
        }))
    }
}))

export default useFreshPackStore;