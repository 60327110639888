import { create } from "zustand"
const emptyBidBase = {
    customer: "",
    creator: "",
    company: "",
    dateCreated: "",
    dateServiced: "",
    jobName: "",
    contact: "",
    phone: "",
    builder: "",
    crop: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    cwt: 0,
    tons: 0,
    requestedCfm: 0,
    voltage: 0,
    taxRate: 6,
    overHead: 20,
    profit: 15.6,
    noPrices: true,
    showVfdRec: false,
    demandSavings: 0,
    energyCost: 0,
    climaCellDepth: 12,
    climaCellWidth: 12,
    climaCellHeight: 12,
    pipsize: 0,
    selectWeight: "cwt",
    staticPressure: 1.25,
    proposalIncludes: `Installation of listed equipment into framed openings. 12-Month warranty on all equipment and parts.  Warranty period begins on the date of equipment start-up and is only valid if payment has been received in-full.`,
    terms: `Customer will provide 50% of total job price at the time of order placement 40% upon equipment delivery and remaining balance of total job price is due upon completion of project. Prices provided are current and are guaranteed for 30-days from the date issued. Agri-stor reserves the right to modify this quote following 30-days from the date issued.  If total job price is not paid in-full per the listed terms, a finance charge of 1.5% per month will be assessed on any past-due balance and a lien may be filed against Customer’s property.`,
    customerToProvide: `Fan house, air plenum, framed equipment openings, and all concrete equipment pads as required per the proposed equipment. In addition, Customer will provide: all power wiring, motor controls, fused disconnects and/or circuit breakers to listed/proposed equipment as required per the National Electric Code (NEC), data/communication cabling, valved water supply of 20-psi (1/2”-minimum pipe size) connected to each humidification system, waste water removal piping, all state and local taxes, all permits – including electrical permit, and liability insurance for equipment provided under this proposal, once equipment is at the jobsite.`,
};
const emptyTotals = {
    fanPrice: 0,
    fanSpeed: 0,
    totalCfm: 0,
    motorPrice: 0,
    actualCfm: 0,
    doorPrice: 0,
    actuatorPrice: 0,
    climaCellPrice: 0,
    humidifierPrice: 0,
    damperPrice: 0,
    lightBlockPrice: 0,
    damperSqFtTotal: 0,
    partsTotal: 0,
    sqFtRequiredDoors: 0,
};
const emptyMotor = {
    motorId: 0,
    partNumber: "",
    description: "",
    hp: "",
    price: 0,
    total: 0,
};
const emptyFreqDrive = {
    freqDriveId: 0,
    partNumber: "",
    description: "",
    price: 0,
    hp: "",
    voltage: 0,
    total: 0,
};
const emptyFan = {
    fanId: 0,
    qty: 0,
    total: 0,
    totalCFM: 0,
    partNumber: "",
    hp: 0,
    cfm: 0,
    additionalData: {
        hp: 0,
        staticPressure1: 0,
        staticPressure05: 0,
        staticPressure15: 0,
        staticPressure075: 0,
        staticPressure125: 0,
        staticPressure175: 0,
    }
};

const useBidStoreMain = create((set) => ({
    //The array name needs to be the same as the category of the part (in the DB).
    //The part ID also needs to be [category name]Id
    //This is so we can use one universal set of functions to make changes.
    //There is another global state called bidStore2
    bidBase: emptyBidBase,
    fan: [],
    totals: emptyTotals,
    motor: [],
    freqDrive: [],
    recommendedFans: [],
    vfdTable: {},

    resetState: function () {
        set(prevState => {
            return {
                ...prevState,
                bidBase: emptyBidBase,
                fan: [],
                totals: emptyTotals,
                motor: [],
                freqDrive: [],
                reccomendedFans: [],
            }
        })
    },
    removeLine: function (category, id) {
        set(prevState => {
            let parts = [...prevState[category]];
            const updatedParts = parts.filter(part => part[category + "Id"] !== id);
            const updatedIdParts = updatedParts.map((part, index) => {
                part[category + "Id"] = index;
                return part;
            });
            return { ...prevState, [category]: updatedIdParts };
        });
    },
    setPart: function (category, name, value, id) {
        set(prevState => {
            const array = [...prevState[category]];
            array[id] = { ...array[id], [name]: value };
            return { ...prevState, [category]: array }
        })
    },
    setTotal: function (name, value) {
        set(prevState => {
            const totals = { ...prevState.totals }
            totals[name] = value;
            return { ...prevState, totals: totals }
        });
    },
    setTotalCfm: function () {
        set(prevState => {
            let fans = prevState.fan;
            const totals = prevState.totals;
            let totalCfm = 0;
            fans.forEach((fan) => {
                totalCfm = totalCfm + (fan.cfm * parseFloat(fan.qty));
            })
            totals.totalCfm = totalCfm;
            return { ...prevState, totals }
        })
    },
    setFanObj: function (
        fanPrice, fanSpeed, totalCfm, actualCfm, cfmRequested) {
        set(prevState => {
            const totals = { ...prevState.totals };
            totals.fanTotal = fanPrice;
            totals.fanSpeed = fanSpeed;
            totals.totalCfm = totalCfm;
            totals.actualCfm = actualCfm;
            totals.cfmRequested = cfmRequested;
            return { ...prevState, totals: totals }
        })
    },
    setBidBase: function (name, value) {
        set(prevState => {
            const bidBase = { ...prevState.bidBase, [name]: value };
            return { ...prevState, bidBase: bidBase };
        })
    },
    setBidBaseObj: function (obj) {
        set(prevState => {
            const bidBase = { ...prevState.bidBase, ...obj };
            return { ...prevState, bidBase: bidBase };
        })
    },
    addPart: function (category, count) {
        count = count || 1;
        set(prevState => {
            const parts = [...prevState[category]];
            for (let i = 0; i < count; i++) {
                if (parts.length < 3) {
                    let newPart;
                    if (category === 'motor') newPart = { ...emptyMotor, motorId: parts.length };
                    else if (category === 'freqDrive') newPart = { ...emptyFreqDrive, freqDriveId: parts.length };
                    else if (category === 'fan') newPart = { ...emptyFan, fanId: parts.length };
                    parts.push(newPart);
                }
            }
            return { ...prevState, [category]: parts };
        });
    },
    setObj: function (category, obj, id) {
        set(prevState => {
            const objects = [...prevState[category]];
            objects[id] = { ...objects[id], ...obj };
            return { ...prevState, [category]: objects };
        })
    },
    setRecommendedFans: function (recommendedFans) {
        set(prevState => {
            return { ...prevState, recommendedFans: recommendedFans }
        })
    },
    setVfdObj: function (vfdObj) {
        set(prevState => {
            return { ...prevState, vfdTable: vfdObj }
        })
    }
}))

export default useBidStoreMain;
