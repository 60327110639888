
class Commas {
    static add(number) {
        if (typeof number !== 'number' && !/^-?\d+(\.\d+)?$/.test(number)) {
            return number;
        }
        // Convert the number to a string and split into integer and decimal parts
        const parts = number.toFixed(2).split('.');
        let integerPart = parts[0];
        const decimalPart = parts[1] ? `.${parts[1]}` : '';
        // Add commas to the integer part
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Return the formatted number
        return integerPart + decimalPart;
    }


    static remove(numberString) {
        if (typeof numberString === 'number') {
            return numberString
        }
        const stringWithoutCommas = numberString.replace(/,/g, '');
        return parseFloat(stringWithoutCommas);
    }
}

export default Commas;