import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Divider, Collapse, TextField,
    InputAdornment,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import useBidStoreMain from '../../../../stores/BidStore/bidStoreMain';
import SheetsApi from '../../../../api';
import CFMFan from '../BidParts/CFMFan';
import Vfd from '../bidCFM/Vfd';
import commas from '../../BidFunctions/commas';
function FanContainer({ edit, fetchedBid, setRenderCheckCfm, handleFanTotals }) {
    const editPass = edit;
    const [bidBase, fans, totals, setTotal, setObj, addPart] = useBidStoreMain(state =>
        [state.bidBase, state.fan, state.totals, state.setTotal, state.setObj, state.addPart]);
    const [fetchedFans, setFetchedFans] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [triggerEditCalc, setTriggerEditCalc] = useState(false);

    useEffect(() => {
        if (fans.length === 1) setIsExpanded(true);
    }, [fans]);

    useEffect(() => {
        async function getFans() {
            const fanData = await SheetsApi.getBidPartsByCategory({ category: "fan" });
            const staticPressureKey = 'staticPressure' + bidBase.staticPressure.toString().replace(/\./g, '');
            fanData.forEach((fan) => {
                fan.cfm = fan.additionalData[staticPressureKey];
            });
            setFetchedFans([...fanData, {
                partNumber: 'SERPART',
                price: 0,
                description: '',
                cfm: 0,
                additionalData: { cfm: 0 }
            }]);
            if (edit) {
                fetchedBid.fans.forEach((fan, index) => {
                    if (index > 0) addPart('fan')
                    const foundFan = fanData.find(fan2 => fan2.partNumber === fan.partNumber);
                    setObj("fan", { ...foundFan, ...fan, }, index)
                })
                setTriggerEditCalc(true)
                setRenderCheckCfm(true)
            }
        }
        getFans()
    }, [])

    useEffect(() => {
        if (triggerEditCalc) handleFanTotals();
    }, [triggerEditCalc]);

    const calculateTotal = function () {
        let newTotal = 0;
        fans.forEach((fan) => {
            if (fan.partNumber === "SERPART") {
                newTotal += parseFloat(fan.total)
            } else {
                const qty = parseFloat(fan.qty)
                newTotal += fan.price * qty
            };
        })
        setTotal("fanPrice", parseFloat((newTotal).toFixed(2)) || 0);
        handleFanTotals()
    }
    return (
        <Box>
            <Collapse in={isExpanded}>
                <Typography sx={{ ml: 5 }} variant="h5">
                    Aerovent® Galvanized Panel Fans
                </Typography>
                <Box m={1}>
                    <Divider />
                </Box>
                <TransitionGroup appear={true}>
                    {fans.map((part, index) => {
                        if (fans[index]) {
                            return <Collapse key={index}>
                                <Box>
                                    <CFMFan
                                        setRenderCheckCfm={setRenderCheckCfm}
                                        calculateTotal={calculateTotal}
                                        fanId={index}
                                        fetchedFans={fetchedFans}
                                    />
                                </Box>
                            </Collapse>
                        } else return null;
                    })}
                </TransitionGroup>
                <Box display="flex" justifyContent="space-between">
                    <Vfd
                        cwt={bidBase.cwt}
                        tons={bidBase.tons}
                        staticPressure={bidBase.staticPressure}
                        selectWeight={bidBase.selectWeight}
                        fetchedFans={fetchedFans}
                        edit={editPass}
                        bidData={fetchedBid}
                    />
                    <TextField
                        value={commas.add(totals.fanPrice) || 0}
                        sx={{ mr: 5, width: '15ch' }}
                        variant='standard'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                        }}
                    />
                </Box>
            </Collapse >
        </Box >
    );
}

export default FanContainer;



