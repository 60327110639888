import React, { useContext, useEffect, useState } from "react";
import BaseSheet from "../SheetComponents/BaseSheet"
import PreSeasonBase from "./PreSeasonBase";
import {
    Box, Grid, Button, Checkbox,
    FormControlLabel, Typography, TextareaAutosize
} from "@mui/material"
import PartsSheet from "../SheetComponents/PartsSheet";
import usePreSeasonStore from "../../stores/preSeasonStore";
import usePartsStore from "../../stores/partsStore";
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import UserContext from "../../Users/UserContext";
import { useHistory } from "react-router-dom"
import SheetsApi from "../../api";
import TechSheet from "../SheetComponents/TechSheet";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj"
import { useTheme } from '@mui/material/styles'
import useNotification from "../../Hooks/useNotification";
import useSettingsStore from "../../stores/settingsStore";
import DeptSelect from "../SheetComponents/DeptSelect";
import FollowUp from "../SheetComponents/FollowUp";

function PreSeason() {
    const [preSeasonForm, setPreSeasonForm, clearPreSeasonForm] = usePreSeasonStore(state => (
        [state.preSeasonForm, state.setPreSeasonForm, state.clearPreSeasonForm]))
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [partSheet, clearPartsForm] = usePartsStore(state =>
        [state.parts, state.clearPartsForm])
    const [techSheet, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])
    const [renderCheck, setRenderCheck] = useState(false)
    const messanger = useNotification()
    const [getNotifications, home] = useSettingsStore(state =>
        [state.getNotifications, state.home]);
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const { currentUser } = useContext(UserContext)
    const history = useHistory()
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setRenderCheck(true)
        clearBaseForm()
        clearPartsForm()
        clearPreSeasonForm()
        clearTechForm()
    }, [])

    const handleChange = function (e) {
        let name = e.target.name
        let value = e.target.value
        if (e.target.className === "PrivateSwitchBase-input css-1m9pwf3") {
            value = e.target.checked
        }
        setPreSeasonForm(name, value)
    }

    const handleSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true);
        const sheetObj = CreateSubmitObj(
            preSeasonForm, currentUser, messanger,
            baseSheet, partSheet, techSheet
        )
        if (sheetObj.error) return setIsSubmitting(false);
        console.log(sheetObj)
        await SheetsApi.addPreSeasonSheet(sheetObj).then((response) => {
            messanger(response.message, response.error ? "error" : "success")
            getNotifications(currentUser)
        })

        setRenderCheck(false)
        clearBaseForm()
        clearPreSeasonForm()
        clearPartsForm()
        clearTechForm()
        history.push(home)
        return setIsSubmitting(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <Box m={2}>
                            {renderCheck && <BaseSheet title="Pre-Season" />}
                            <PreSeasonBase />
                            <Box
                                style={{ width: "100%" }}
                                sx={{
                                    backgroundColor: primary,
                                    "@media print": {
                                        backgroundColor: primary,
                                    },
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    ml={1}
                                    style={{ color: defaultColor }}>
                                    Notes
                                </Typography>
                            </Box>
                            <Box ml={0.25}>
                                <TextareaAutosize
                                    minRows={5}
                                    style={{
                                        width: "99.0%",
                                        outline: '2px solid #000',
                                        fontSize: "16px",
                                    }}
                                    name="notes"
                                    value={preSeasonForm.notes || ""}
                                    onChange={handleChange}
                                />
                            </Box>
                            {renderCheck && <PartsSheet toggle={true} />}
                            {renderCheck && <TechSheet edit={false} toggle={true} />}
                            <Box mt={2} mb={2}>
                                <FollowUp
                                    state={preSeasonForm}
                                    setState={handleChange}
                                />
                            </Box>
                            <Box justifyContent="flex-start" display="flex">
                                <DeptSelect />
                                <Button
                                    disabled={isSubmitting}
                                    variant="contained"
                                    display="flex"
                                    type="submit"
                                    style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

            </form>
        </>
    )
}

export default PreSeason