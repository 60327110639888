import React, { useEffect } from "react";
import {
    Dialog, DialogTitle, DialogContent, Typography,
    DialogActions, Button, TextareaAutosize,
} from "@mui/material";
import useFields from "../../../../Hooks/useFields";
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";

function TermsPopUp({ open, setOpen }) {
    const INITIAL_STATE = {
        terms: `Customer will provide 50% of total job price at the time of order placement 40% upon equipment delivery and remaining balance of total job price is due upon completion of project. Prices provided are current and are guaranteed for 30-days from the date issued. Agri-stor reserves the right to modify this quote following 30-days from the date issued.  If total job price is not paid in-full per the listed terms, a finance charge of 1.5% per month will be assessed on any past-due balance and a lien may be filed against Customer’s property.`,
        customerToProvide: `Fan house, air plenum, framed equipment openings, and all concrete equipment pads as required per the proposed equipment. In addition, Customer will provide: all power wiring, motor controls, fused disconnects and/or circuit breakers to listed/proposed equipment as required per the National Electric Code (NEC), data/communication cabling, valved water supply of 20-psi (1/2”-minimum pipe size) connected to each humidification system, waste water removal piping, all state and local taxes, all permits – including electrical permit, and liability insurance for equipment provided under this proposal, once equipment is at the jobsite.`,
        proposalIncludes: `Installation of listed equipment into framed openings. 12-Month warranty on all equipment and parts.  Warranty period begins on the date of equipment start-up and is only valid if payment has been received in-full.`
    }
    const [bidBase, setBidBase] = useBidStoreMain(state =>
        [state.bidBase, state.setBidBase])
    const [formData, handleLocal] = useFields(INITIAL_STATE);

    useEffect(() => {
        handleLocal("replaceObj", {
            proposalIncludes: bidBase.proposalIncludes,
            terms: bidBase.terms,
            customerToProvide: bidBase.customerToProvide
        })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setBidBase("proposalIncludes", formData.proposalIncludes);
        setBidBase("terms", formData.terms);
        setBidBase("customerToProvide", formData.customerToProvide);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Edit Terms</DialogTitle>
            <DialogContent>
                <form id="add-customer-form" onSubmit={handleSubmit}>
                    <Typography variant="h5">This Proposal Includes:</Typography>
                    <TextareaAutosize
                        autoFocus
                        id="proposalIncludes"
                        name="proposalIncludes"
                        minRows={6}
                        style={{ width: '75ch' }}
                        value={formData.proposalIncludes}
                        onChange={handleLocal}
                    />
                    <Typography variant="h5">Customer to Provide:</Typography>
                    <TextareaAutosize
                        id="customerToProvide"
                        name="customerToProvide"
                        minRows={10}
                        style={{ width: '75ch' }}
                        value={formData.customerToProvide}
                        onChange={handleLocal}
                    />
                    <Typography variant="h5">Terms:</Typography>
                    <TextareaAutosize
                        id="terms"
                        name="terms"
                        minRows={10}
                        style={{ width: '75ch' }}
                        value={formData.terms}
                        onChange={handleLocal}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" form="add-customer-form">Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TermsPopUp;
