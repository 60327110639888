import React, { useEffect, useContext } from "react";
import {
    Box, Card, Typography, TextField, InputAdornment,
    Button, InputLabel, FormControl, Select, MenuItem,
} from "@mui/material"
import useBidStoreMain from "../../../../stores/BidStore/bidStoreMain";
import useBidStoreSub from "../../../../stores/BidStore/bidStoreSub";
import useBidPartsStore from "../../../../stores/BidStore/bidPartsStore";
import useFields from "../../../../Hooks/useFields";
import UserContext from "../../../../Users/UserContext";
import Commas from "../../BidFunctions/commas";
import PdfSelect from "./PdfSelect";

function Submit({ grandTotal, handleSubmit, edit, handleCopy, isSubmitting }) {
    const { currentUser } = useContext(UserContext);
    const [totals, bidBase, setBidBase, actualCfm, requestedCfm,
        fanSpeed, stage] = useBidStoreMain(state =>
            [state.totals, state.bidBase, state.setBidBase,
            state.bidBase.actualCfm, state.bidBase.requestedCfm,
            state.bidBase.fanSpeed, state.bidBase.stage])
    const [variance] = useBidStoreSub(state =>
        [state.variance])
    const [climaCellVariance] = useBidPartsStore(state =>
        [state.variance[0].climaCell])
    const [formData, handleLocal] = useFields({ profit: 15.6 })

    useEffect(() => {
        // need to import overhead and add it to calculation ~
        const profitPercent = 1 - (formData.profit / 100);
        const grandTotalFloat = Commas.remove(grandTotal);
        const profitTotal = parseFloat((grandTotalFloat / profitPercent - grandTotalFloat).toFixed(2)) || 0
        const priceEstimate = parseFloat((profitTotal + grandTotalFloat).toFixed(2)) || 0
        handleLocal("invalidInput", "profitTotal", Commas.add(profitTotal))
        handleLocal("invalidInput", "priceEstimate", Commas.add(priceEstimate))
    }, [formData.profit, grandTotal])

    useEffect(() => {
        if (edit) {
            handleLocal("invalidInput", "profit", bidBase.profit)
            setBidBase("profit", bidBase.profit)
        }
    }, [])

    return (
        <>
            <Card sx={{ width: '70ch' }} >
                <Box m={1}>
                    <Typography ml={4} variant="h5">
                        Submit and Finalize
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Typography variant="h6">Total Job Cost: $ {grandTotal}</Typography>
                </Box>
                <Box display="flex" mb={2} mt={2} ml={2}>
                    <Box>
                        <TextField
                            id="profit"
                            name="profit"
                            label="Profit"
                            type="number"
                            sx={{ width: "14ch" }}
                            value={formData.profit === 0 ? 0 : formData.profit || 15.6}
                            onChange={handleLocal}
                            onBlur={(e) => setBidBase("profit", e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment
                                        position="end">
                                        %
                                    </InputAdornment>,
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box ml={3}>
                        <TextField
                            id="profitTotal"
                            name="profitTotal"
                            type=""
                            label="Profit Total"
                            variant="standard"
                            value={formData.profitTotal || 0}
                            sx={{ width: "16ch" }}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment
                                        position="start">
                                        $
                                    </InputAdornment>,
                                readOnly: true
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Box>
                <Box ml={2} mb={2} >
                    <Typography
                        variant="h4">
                        Price Estimate: $ {formData.priceEstimate}
                    </Typography>
                </Box>
                <Box m={1}>
                    <Typography>
                        Warning! Before saving this Bid Sheet please verify all of the numbers below are correct.
                        It is important for you and the customer to understand that the door sizes, damper sizes, and climacell
                        are all designed around the fan speed of {totals.fanSpeed}% If the fans are running above the target
                        then the building will not be running at optimal performance.
                    </Typography>
                </Box>
                <Box m={1}>
                    <TextField
                        id="cfmRequested"
                        name="cfmRequested"
                        variant="standard"
                        label="CFM Requested"
                        value={requestedCfm || 0}
                        sx={{ width: "13ch" }}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="actualCFM"
                        name="actualCFM"
                        variant="standard"
                        label="Actual CFM"
                        value={actualCfm || 0}
                        sx={{ width: "13ch" }}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="fanSpeed"
                        name="fanSpeed"
                        variant="standard"
                        label="Max Fan Speed"
                        value={fanSpeed || 0}
                        sx={{
                            width: "13ch",
                            '& input': { textAlign: 'right' },
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                            readOnly: true
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box m={1} display="flex">
                    <TextField
                        id="doorVariance"
                        name="doorVariance"
                        label="Door Variance"
                        variant="standard"
                        sx={{
                            width: "13ch",
                            '& input': { textAlign: 'right' },
                        }}
                        value={variance[0].doors}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                            readOnly: true
                        }}
                        error={
                            variance[0].doors >= -10
                                && variance[0].doors <= 10
                                ? false : true
                        } />
                    <TextField
                        id="climaCellVariance"
                        name="climaCellVariance"
                        label="ClimaCell Variance"
                        variant="standard"
                        sx={{
                            width: "13ch",
                            '& input': { textAlign: 'right' },
                        }}
                        value={climaCellVariance || 0}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                            readOnly: true
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                            climaCellVariance >= -10
                                && climaCellVariance <= 10
                                ? false : true
                        } />
                    <TextField
                        id="damperVariance"
                        name="damperVariance"
                        label="Damper Variance"
                        variant="standard"
                        sx={{
                            width: "13ch",
                            '& input': { textAlign: 'right' },
                        }}
                        value={variance[0].damper || 0}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                            readOnly: true
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                            variance[0].damper >= -10
                                && variance[0].damper <= 10
                                ? false : true
                        } />
                    <TextField
                        id="lightBlockVariance"
                        name="lightBlockVariance"
                        label="Light Block Variance"
                        variant="standard"
                        sx={{
                            width: "13ch",
                            '& input': { textAlign: 'right' },
                        }}
                        value={variance[0].lightBlock || 0}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end">
                                    %
                                </InputAdornment>,
                            readOnly: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                            variance[0].lightBlock >= -10
                                && variance[0].lightBlock <= 10
                                ? false : true
                        } />
                </Box>
                <Box m={1}>
                    <Typography>
                        It is recommended to keep the variances within a 10% range of zero.
                        Zero being optimal and positive numbers being better than negative.
                    </Typography>
                </Box>
                {edit && <PdfSelect handleSubmit={handleSubmit} />}
                <Box mr={1} justifyContent="right" display="flex">
                    <Box m={1}>
                        <Button
                            disabled={isSubmitting}
                            onClick={() => (handleSubmit())}
                            variant="contained">
                            Save
                        </Button>
                    </Box>
                    {edit && <>
                        <Box mt={1} mb={1} mr={1} >
                            <Button
                                onClick={handleCopy}
                                variant="contained">
                                Copy
                            </Button>
                        </Box>
                        <Box mb={1} className="hideForPrint">
                            {currentUser.account_level !== 'Technician' &&
                                <FormControl style={{ width: '200px' }}>
                                    <InputLabel>Stage</InputLabel>
                                    <Select
                                        label="Stage"
                                        onChange={(e) => setBidBase("stage", e.target.value)}
                                        id="stage"
                                        name="stage"
                                        value={stage || ""}
                                        defaultValue=""
                                    >
                                        <MenuItem value="Signature Pending">Signature Pending</MenuItem>
                                        <MenuItem value="Down Payment">Down Payment</MenuItem>
                                        <MenuItem value="Progress Payment">Progress Payment</MenuItem>
                                        <MenuItem value="Final Payment">Final Payment</MenuItem>
                                        <MenuItem value="Archive">Archive</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                    </>}
                </Box>
            </Card>
        </>
    )
}

export default Submit;