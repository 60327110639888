import React, { useEffect, useState } from "react";
import BidRefCondenser from "./BidComponents/BidRefrigeration/BidRefCondenser";
import BidRefCooler from "./BidComponents/BidRefrigeration/BidRefCooler/BidRefCooler";
import BidBase from "./BidComponents/BidBase";
import useBidStoreMain from "../../stores/BidStore/bidStoreMain";
import useBidStoreSub from "../../stores/BidStore/bidStoreSub";
import useBidPartsStore from "../../stores/BidStore/bidPartsStore";
import useBidStoreMisc from "../../stores/BidStore/bidStoreMisc";
import usePartsStore from "../../stores/partsStore";
import PartContainer from "./BidComponents/BidContainers/PartContainer";
import Labor from "./BidComponents/BidMisc/Labor";
import Misc from "./BidComponents/BidMisc/Misc";
import useBidRefrigerationStore from "../../stores/BidStore/bidRefrigerationStore";
import GrandTotal from "./BidComponents/BidSubmit/GrandTotal";
import { Grid } from "@mui/material"
function BidRef() {
    const [render, setRender] = useState(false)
    const [resetStateMain] = useBidStoreMain(state =>
        [state.resetState])
    const [resetStateSub] = useBidStoreSub(state => [state.resetState])
    const [resetStateMisc] = useBidStoreMisc(state => [state.resetState])
    const [resetStateParts] = useBidPartsStore(state => [state.resetState])
    const [resetStateRefrigeration] = useBidRefrigerationStore(state =>
        [state.resetState])
    const [clearPartsForm] = usePartsStore(state => [state.clearPartsForm])
    const defaultHardware = [
        {
            qty: 0,
            partNumber: "Serpart1",
            description: "Nuts, Bolts, Unistrut, misc",
            cost: 250,
            total: "",
        },
        {
            qty: 0,
            partNumber: "Serpart2",
            description: "Wire",
            cost: 250,
            total: "",
        },
        {
            qty: 0,
            partNumber: "Serpart3",
            description: "ClimaCell plumbing parts",
            cost: 200,
            total: "",
        }
    ]
    useEffect(() => {
        resetStateMain();
        resetStateSub();
        resetStateParts();
        resetStateMisc();
        clearPartsForm();
        resetStateRefrigeration();
        setRender(true);
    }, [])
    return (<> {render && <>
        <Grid container >
            <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                <BidBase />
                <BidRefCondenser />
                <BidRefCooler />
                <PartContainer
                    category="refrigeration"
                    title="Refrigeration Misc"
                    maxParts={8}
                />
                <PartContainer
                    category="control"
                    title="Control Panel"
                    maxParts={20}
                />
                <Labor refrigeration={true} />
                <Misc
                    title="Hardware and Supplies: (SerParts, Wire, Tie wraps, etc.)"
                    category="hardware"
                    miscData={defaultHardware}
                />
                <Misc
                    title="Equipment Rental: (Scissor Life, Fork Lift, Crane, Etc.)"
                    category="equipment"
                    miscData={[]}
                />
                <Misc
                    title="Sub Contractors: (Concrete, Builder, Electrical, etc.)"
                    category="contractor"
                    miscData={[]}
                />,
                <Misc
                    title="Other: (Licenses, Permits, Disposals, Hotels, Meals, etc.)"
                    category="other"
                    miscData={[]}
                />
                <Misc
                    title="Options:"
                    category="option"
                    miscData={[]}
                />
                <GrandTotal refrigerationBid={true} />
            </Grid>
        </Grid >
    </>} </>)
}

export default BidRef;