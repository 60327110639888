import React from "react";
import {
    TextField, Box, Button,
} from '@mui/material';
import useLabDataStore from "../../../stores/labDataStore";
import ImageDropZone from '../../SheetComponents/images/imageDropZone';

function DataEntryFooter({ saveEntry }) {
    const [selectedEntry, setValue] = useLabDataStore(state =>
        [state.selectedEntry, state.setValue]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Box mt={2}>
                    <TextField
                        id='glucose'
                        name='glucose'
                        label='% Glucose (FW)'
                        type="number"
                        value={selectedEntry.glucose || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                    />
                    <TextField
                        id='sucrose'
                        name='sucrose'
                        label='Sucrose Rating (%FW x 10)'
                        type="number"
                        sx={{ ml: 1 }}
                        value={selectedEntry.sucrose || ""}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                    />
                </Box>
                <Box>
                    <Box sx={{ borderRadius: 2 }} bgcolor="white">
                        <ImageDropZone sheetType="labData" noReceipt={true} />
                    </Box>
                </Box>
            </Box>
            <Box mt={4} mr={2}>
                <Button variant="contained" onClick={() => saveEntry(selectedEntry.id, true)}>SAVE</Button>
            </Box>
        </Box>)
}

export default DataEntryFooter;