import React, { useEffect, useState } from 'react';
import useFields from '../../../../Hooks/useFields';
import {
    TextField, Box, InputAdornment, CardContent,
    Card, Select, MenuItem, InputLabel, FormControl,
} from "@mui/material"
import useBidStoreMain from '../../../../stores/BidStore/bidStoreMain';
import AddCFMPart from './AddCFMPart';

function CFMSelector({ edit, fetchedBid, setRenderCheckCfm }) {
    const editPass = edit;
    const [editable, setEditable] = useState(true);
    const [totals, fans, setFanObj, setBidBase] = useBidStoreMain((state) =>
        [state.totals, state.fan, state.setFanObj, state.setBidBase]);
    const [formData, handleLocal] = useFields({
        requestedCFM: "",
        fanSpeed: "",
        qty: 0,
        fan: "",
        fanTotal: 0,
        voltage: 0,
        cwt: "",
        tons: "",
        totalCFM: "",
        staticPressure: 1.25,
        selectWeight: "cwt",
    })
    const handleFanTotals = function () {
        let fanSpeed;
        let priceTotal = 0;
        let CFMTotal = 0;
        let CFMActual = 0;
        let requestedCfm = parseFloat(formData.requestedCfm) || 0;
        const tons = editable ? formData.cwt / 20 : formData.tons;
        const cwt = !editable ? formData.tons * 20 : formData.cwt;
        fans.forEach((fan) => {
            if (fan.partNumber !== "" && fan.qty !== "") {
                priceTotal = priceTotal + fan.total;
                CFMTotal = CFMTotal + fan.totalCFM;
            }
        })
        if (requestedCfm > 0 && tons > 0 && CFMTotal > 0) {
            CFMActual = parseFloat((CFMTotal / tons).toFixed(2));
            fanSpeed = parseFloat(((requestedCfm / CFMActual) * 100).toFixed(2))
        }
        requestedCfm = parseFloat(requestedCfm);
        setBidBase("cwt", parseFloat(cwt));
        setBidBase("tons", parseFloat(tons));
        setBidBase("requestedCfm", requestedCfm);
        setBidBase("actualCfm", CFMActual);
        setBidBase("fanSpeed", fanSpeed);
        setFanObj(parseFloat(priceTotal.toFixed(2)),
            fanSpeed, CFMTotal, CFMActual, requestedCfm);
    }

    useEffect(() => {
        //This refreshes the sq ft req on climCall and FaD components
        handleFanTotals()
    }, [formData.cwt, formData.tons, formData.selectWeight, setRenderCheckCfm])

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", fetchedBid.bidBase);
            const editable = fetchedBid.bidBase.selectWeight === 'cwt' ? true : false;
            setEditable(editable)
        }
    }, [])

    const selectWeight = function (e) {
        handleLocal(e);
        if (e.target.value === "cwt") setEditable(true)
        else if (e.target.value === "tons") setEditable(false);
    };

    return (<>
        <Box m={1} >
            <Card sx={{ width: '120ch' }}>
                <CardContent>
                    <Box m={1}>
                        <Box mr={1} style={{ display: "inline" }}>
                            <FormControl>
                                <InputLabel>Select Weight</InputLabel>
                                <Select
                                    name="selectWeight"
                                    id="selectWeight"
                                    label="Select Weight"
                                    onChange={(e) => selectWeight(e)}
                                    onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                    value={formData.selectWeight || "cwt"}
                                    sx={{ width: "20ch" }}>
                                    <MenuItem value="cwt">CWT</MenuItem>
                                    <MenuItem value="tons">TONS</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="cwt"
                                id="cwt"
                                label="CWT"
                                type="number"
                                sx={{ width: "20ch" }}
                                variant={!editable ? "standard" : "outlined"}
                                onChange={handleLocal}
                                disabled={!editable}
                                value={editable ? formData.cwt || 0 : formData.tons * 20 || ""}
                                onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="tons"
                                id="tons"
                                label="Tons"
                                type="number"
                                sx={{ width: "20ch" }}
                                onChange={handleLocal}
                                variant={editable ? "standard" : "outlined"}
                                disabled={editable}
                                value={!editable ? formData.tons || 0 : formData.cwt / 20 || ""}
                                onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="actualCFM"
                                id="actualCFM"
                                label="Actual CFM"
                                type="number"
                                variant="standard"
                                sx={{ width: "20ch" }}
                                value={totals.actualCfm || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <FormControl>
                                <InputLabel>Static Pressure</InputLabel>
                                <Select
                                    name="staticPressure"
                                    id="staticPressure"
                                    label="Static Pressure"
                                    onChange={handleLocal}
                                    onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                    value={formData.staticPressure || 1.25}
                                    sx={{ width: "13ch" }}>
                                    <MenuItem value={.5}>0.50"</MenuItem>
                                    <MenuItem value={.75}>0.75"</MenuItem>
                                    <MenuItem value={1}>1.00"</MenuItem>
                                    <MenuItem value={1.25}>1.25"</MenuItem>
                                    <MenuItem value={1.5}>1.50"</MenuItem>
                                    <MenuItem value={1.75}>1.75"</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <FormControl>
                                <InputLabel>Voltage</InputLabel>
                                <Select
                                    name="voltage"
                                    id="voltage"
                                    label="Voltage"
                                    onChange={handleLocal}
                                    onBlur={(e) => setBidBase(e.target.name, e.target.value)}
                                    value={formData.voltage || 0}
                                    sx={{ width: "11ch" }}>
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    <MenuItem value={240}>240V</MenuItem>
                                    <MenuItem value={480}>480V</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box m={1}>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="requestedCfm"
                                id="requestedCfm"
                                label="Requested CFM"
                                type="number"
                                sx={{ width: "18ch" }}
                                value={formData.requestedCfm || ""}
                                onChange={handleLocal}
                                onBlur={handleFanTotals}
                            />
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="totalCFM"
                                id="totalCFM"
                                label="Total CFM"
                                variant="standard"
                                type="number"
                                sx={{ width: "24ch" }}
                                value={totals.totalCfm || 0}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box mr={1} style={{ display: "inline" }}>
                            <TextField
                                name="fanSpeed"
                                id="fanSpeed"
                                label="Max Fan Speed"
                                variant="standard"
                                type="number"
                                sx={{ width: "20ch" }}
                                value={totals.fanSpeed || 0}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end">
                                            %
                                        </InputAdornment>,
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </Box>
                    <AddCFMPart
                        handleFanTotals={handleFanTotals}
                        setRenderCheckCfm={setRenderCheckCfm}
                        edit={editPass}
                        fetchedBid={fetchedBid}
                    />
                </CardContent>
            </Card>
        </Box>
    </>)
}

export default CFMSelector;