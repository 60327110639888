import { create } from "zustand"

const emptyRefForm = {
    vacuum: false,
    crankHeater: false,
    doors: false,
    contactors: false,
    notes: "",
    followup: false,
    control: false,
    circuit: false,
}

const emptyCompForm = {
    comp: false,
    runsys: false,
    oil: false,
    refrigerant: false,
    suction: "",
    discharge: "",
    ampheater: "",
    loaded1: "",
    loaded2: "",
    loaded3: "",
    fan1: "",
    fan3: "",
    fan4: "",
    fan2: "",
    fan5: "",
    fan6: "",
}

const useRefrigerationStore = create((set) => ({
    refForm: emptyRefForm,

    compressors: [emptyCompForm],

    setRefForm: function (name, value) {
        set(prevState => ({
            ...prevState,
            refForm: {
                ...prevState.refForm,
                [name]: value
            }
        }))
    },

    clearRefForm: function () {
        set(prevState => ({
            ...prevState,
            refForm: emptyRefForm
        }))
    },

    setRefFormObj: function (obj) {
        set(prevState => ({
            ...prevState,
            refForm: obj
        }))
    },

    addCompressor: function (compressorObj) {
        set(prevState => {
            let compressors = [...prevState.compressors]
            compressors.push(compressorObj)
            return { ...prevState, compressors }
        })
    },

    setCompressor: function (name, value, id) {
        set(prevState => {
            const compressors = [...prevState.compressors];
            compressors[id] = { ...compressors[id], [name]: value };
            return { ...prevState, compressors };
        });
    },

    removeCompressor: function (id) {
        set(prevState => {
            const compressors = prevState.compressors;
            compressors.splice(id, 1);
            return { ...prevState, compressors }
        })
    },

    clearCompressorForm: function () {
        set(prevState => ({
            ...prevState,
            compressors: [emptyCompForm]
        }))
    },

    setCompressorFormObj: function (compressors1) {
        set(prevState => ({
            ...prevState,
            compressors: compressors1
        }))
    }

}))

export default useRefrigerationStore;